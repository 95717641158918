import React from 'react';
import styled from 'styled-components';
import { initials, makeName } from '../utility';
import { Avatar } from '../components';

import Redact from './Redact';
import { BigTooltip } from './Menu';

const PERSON_SIZE = 24;
const PERSON_HEIGHT = PERSON_SIZE;

const StyledPeople = styled.div`
  display: flex;
  align-items: ${p => (p.expand ? 'flex-end' : 'center')};
  height: ${PERSON_HEIGHT}px;
  cursor: default;
  border-radius: 999px;
  &:hover {
    background: ${p => p.hoverColor};
  }
`;

const StyledPerson = styled.div`
  display: flex;
  align-items: center;
  backdrop-filter: blur(${p => (p.full ? 0 : 10)}px);
  border-radius: 999px;
  height: ${PERSON_HEIGHT}px;
  width: ${p => (p.full ? '' : PERSON_HEIGHT + 'px')};
  margin-left: ${p => (p.full ? 0 : -2)}px;
  margin-bottom: ${p => (p.full ? 8 : 0)}px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-bottom: 0px;
  }

  * {
    cursor: default;
  }
  .sp-name {
    margin-left: 8px;
    font-size: var(--fp);
    font-weight: 500;
    flex: 1;
    min-width: 200px;
  }
  .sp-detail {
    flex: 0;
    opacity: 0.5;
    font-size: var(--ffp) !important;
    font-weight: 500;
    padding-left: 16px;
  }
  .sp-name,
  .sp-detail {
    max-width: 400px;
  }
`;

export const Person = ({ person, detail, full }) => {
  return (
    <StyledPerson full={full} style={{ color: 'var(--c-fg-0)' }}>
      <Avatar
        initials={initials(person)}
        image={person?.avatarImage}
        size={PERSON_SIZE}
        color="adminPerson"
      />
      {!!full && (
        <>
          <div className="sp-name">
            <Redact>{makeName(person)} </Redact>
          </div>

          <div className="sp-detail">{person[detail]}</div>
        </>
      )}
    </StyledPerson>
  );
};

export const People = ({ people, detail, popupPlacement }) => {
  return (
    <BigTooltip
      placement={popupPlacement}
      content={
        <>
          {people?.map((person, idx) => (
            <Person full key={person?.id} detail={detail} count={people?.length} person={person} idx={idx} />
          ))}
        </>
      }
    >
      <StyledPeople count={people?.length} hoverColor={'var(--c-border-light)'}>
        {people?.map((person, idx) => (
          <Person key={person?.id} detail={detail} count={people?.length} person={person} idx={idx} />
        ))}
      </StyledPeople>
    </BigTooltip>
  );
};

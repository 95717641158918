import { Scroll, Button, Modal, StyledBottomBar, Header } from 'src/web/components';
import { Form, Field } from 'src/forms';
import { useEdit } from './useEdit';

/**
 * The EditModal component handles updating fields
 * - We accept initial values, the fields to ask about, and a submit function
 * - We can trigger this universally by using the useEdit hook
 */
export function EditModal() {
  const { isOpen, close, title, initialValues, fields, onSubmit } = useEdit();

  if (!isOpen) return null;

  const handleSubmit = async (vals) => {
    await onSubmit(vals);
    close();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        <Scroll transparent>
          <Header title={title || 'Edit'} />
          {fields.map((field) => <Field key={field.name} {...field} />)}
        </Scroll>
        <StyledBottomBar>
          <Button buttonType="submit">
            Update
          </Button>
        </StyledBottomBar>
      </Form>
    </Modal>
  );
}
import { gql } from '@apollo/client';

export const FRAGMENT_PERSON = gql`
  fragment PersonFragment on User {
    id
    email
    nickname
    givenName
    familyName
    avatarImage
    createdOn
    intercomID
    signupCode
  }
`;

export const FRAGMENT_TASK = gql`
  fragment TaskFragment on Task {
    id
    priority
    createdOn
    lastUpdated
    status
    type
    user {
      id
      email
      nickname
      givenName
      familyName
      avatarImage
      createdOn
      intercomID
      signupCode
    }
    assignee {
      id
      email
      nickname
      givenName
      familyName
      avatarImage
    }
  }
`;

export const FRAGMENT_HEALTH_POLICY = gql`
  fragment HealthPolicyFragment on HealthInsuranceEnrollment {
    policy {
      id
      policyStatus
      paymentStatus
      planName
      premium
      premiumEffective
      policyNumber
      exchangePolicyNumber
      startDate
      endDate
      enrolledDependentIDs
      agentID
      subscriberID
      providerPlan {
        issuer {
          id
          name
        }
        name
        metalLevel
        type
        deductibles {
          amount
          type
          familyCost
          networkTier
        }
        benefits {
          name
          covered
          costSharings {
            coinsuranceOptions
            coinsuranceRate
            copayAmount
            copayOptions
            networkTier
            csr
            displayString
          }
          explanation
          hasLimits
          limitUnit
          limitQuantity
        }
      }
    }
  }
`;

export const FRAGMENT_HEALTH_POLICY_LONG = gql`
  fragment HealthPolicyFragmentLong on HealthInsuranceEnrollment {
    policy {
      id
      policyStatus
      paymentStatus
      planName
      premium
      premiumEffective
      policyNumber
      exchangePolicyNumber
      startDate
      endDate
      enrolledDependentIDs
      agentID
      subscriberID
      providerPlan {
        issuer {
          id
          name
        }
        name
        metalLevel
        type
        deductibles {
          amount
          type
          familyCost
          networkTier
        }
        benefits {
          name
          covered
          costSharings {
            coinsuranceOptions
            coinsuranceRate
            copayAmount
            copayOptions
            networkTier
            csr
            displayString
          }
          explanation
          hasLimits
          limitUnit
          limitQuantity
        }
      }
    }
  }
`;

export const FRAGMENT_HEALTH_APP = gql`
  fragment HealthApplicationFragment on HealthInsuranceEnrollment {
    healthApplication {
      id
      coverageState
      coverageYearNumber
      householdIncome
      # numTaxDependents
      lastUpdated
      lastUserUpdate
      signatureText
      signatureDate
      status
      pathwayType
      selectedPlanID
      ... on HealthApplication {
        id
        creationOriginType
        submissionOriginType
        createdOn
        applicationContext
        eligibilityLastFetched
        # screeningPhase
        lastUsedRoute
        applicationOrigin
        isEligibilityDetermined
        hasSubmittedPermissions
        appliedCSRLevel
        aptcAgreement
        signatureText
        aptcSignature
        areAllSameAddress
        areDependentsLivingWithParentNotOnTaxReturn
        incomeExplanationDocumentedAmount
        incomeExplanationRequiredIndicator
        incomeExplanationRequiredReason
        insuranceApplicationIdentifier
        isAbsentParentAgreement
        isChangeInformationAgreement
        isClaimingAllDependentsOnTax
        isCoveredThroughJobOrCobra
        isDependentSingleChild25OrYounger
        isEligibilityDetermined
        isIndianOrAlaskan
        isMedicaidRequirementAgreement
        isNonIncarcerationAgreement
        isPenaltyOfPerjuryAgreement
        isPlanningToFileTax
        isPrefill
        isRenewalAgreement
        isRequestingFinancialAssistance
        isResidesInApplicationState
        isResponsibleForChildNotOnTaxReturn
        isReuseTaxDataPermission
        isSelfCoverage
        isSpouseCoverage
        isTerminateCoverageOtherMecFoundAgreement
        lastUsedRoute
        planPremium
        planPremiumEffective
        processStatus
        renewEligibilityYearQuantity
        selectedMetalLevel
        selectedPlanIssuerID
        signatureType
        hasAnyMedicaidChipEligibleMembers
        hasAllMedicaidChipEligibleMembers
        applicant {
          id
          givenName
          familyName
          age
          isSmoker
          isPregnant
          middleName
          relation
          sex
          taxReturnFilingStatusType
          dob
          email
          ssn
          contactID
          isRequestingCoverage
          homeAddress {
            street1
            street2
            city
            state
            zip
          }
          phone {
            number
          }
        }
        dmis {
          id
          status
          verificationType
          subType
          resolutionStatus
          resolveBy
          lastReviewed
          nonEscMecType
        }
        svis {
          id
          statusCode
          status
          resolveBy
          lastReviewed
        }
        enrollmentGroups {
          id
          plan {
            id
            deductibles {
              amount
              type
              familyCost
              networkTier
            }
            benefits {
              name
              covered
              costSharings {
                coinsuranceOptions
                coinsuranceRate
                copayAmount
                copayOptions
                networkTier
                csr
                displayString
              }
              explanation
              hasLimits
              limitUnit
              limitQuantity
            }
            name
            metalLevel
            type
            premium
            premiumWithCredit
            issuer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FRAGMENT_HEALTH_APP_LONG = gql`
  fragment HealthApplicationLongFragment on HealthInsuranceEnrollment {
    healthApplication {
      id
      coverageState
      coverageYearNumber
      householdIncome
      # numTaxDependents
      lastUpdated
      lastUserUpdate
      signatureText
      signatureDate
      status
      pathwayType
      selectedPlanID
      ... on HealthApplication {
        id
        createdOn
        applicationOrigin
        creationOriginType
        submissionOriginType
        hasAnyMedicaidChipEligibleMembers
        hasAllMedicaidChipEligibleMembers
        hasSubmittedPermissions
        eligibilityLastFetched
        # screeningPhase
        lastUpdated
        eligibility {
          totalAptc
          csrLevel
          isMedicaid
          isChip
        }
        dmis {
          id
          status
          verificationType
          subType
          resolutionStatus
          resolveBy
          lastReviewed
          nonEscMecType
        }
        svis {
          id
          statusCode
          status
          resolveBy
          lastReviewed
        }
        plan {
          id
          deductibles {
            amount
            type
            familyCost
            networkTier
          }
          benefits {
            name
            covered
            costSharings {
              coinsuranceOptions
              coinsuranceRate
              copayAmount
              copayOptions
              networkTier
              csr
              displayString
            }
            explanation
            hasLimits
            limitUnit
            limitQuantity
          }
          name
          metalLevel
          type
          premium
          premiumWithCredit
          issuer {
            id
            name
          }
        }
        appliedCSRLevel
        aptcAgreement
        signatureText
        aptcSignature
        areAllSameAddress
        areDependentsLivingWithParentNotOnTaxReturn
        incomeExplanationDocumentedAmount
        incomeExplanationRequiredIndicator
        incomeExplanationRequiredReason
        insuranceApplicationIdentifier
        isAbsentParentAgreement
        isChangeInformationAgreement
        isClaimingAllDependentsOnTax
        isCoveredThroughJobOrCobra
        isDependentSingleChild25OrYounger
        isEligibilityDetermined
        isIndianOrAlaskan
        isMedicaidRequirementAgreement
        isNonIncarcerationAgreement
        isPenaltyOfPerjuryAgreement
        isPlanningToFileTax
        isPrefill
        isRenewalAgreement
        isRequestingFinancialAssistance
        isResidesInApplicationState
        isResponsibleForChildNotOnTaxReturn
        isReuseTaxDataPermission
        isSelfCoverage
        isSpouseCoverage
        isTerminateCoverageOtherMecFoundAgreement
        lastUsedRoute
        planPremium
        planPremiumEffective
        processStatus
        renewEligibilityYearQuantity
        selectedMetalLevel
        selectedPlanIssuerID
        signatureType
        enrollmentGroups {
          id
          plan {
            id
            issuer {
              id
              name
            }
            deductibles {
              amount
              type
              familyCost
              networkTier
            }
            name
            metalLevel
            type
            premium
            premiumWithCredit
          }
          plans {
            plans {
              name
              metalLevel
              type
              premium
              premiumWithCredit
              planId: id
              issuer {
                id
                name
              }
              deductibles {
                amount
                type
                familyCost
                networkTier
              }
            }
          }
        }
        members {
          id
          givenName
          familyName
          age
          isSmoker
          isPregnant
          middleName
          relation
          sex
          dob
          email
          ssn
          contactID
          isRequestingCoverage
          initialEnrollmentEligibility {
            startDate
            endDate
            earliestQhpEffective
            latestQhpEffective
          }
          aptcEligibility {
            startDate
            endDate
            amount
          }
          csrEligibility {
            startDate
            endDate
            csrLevel
          }
          exchangeEligibility {
            startDate
            endDate
          }
          sepEligibility {
            startDate
            endDate
            earliestQhpEffective
            latestQhpEffective
            sepReason
            verificationPending
          }
          chipEligibility {
            startDate
            endDate
          }
          medicaidEligibility {
            startDate
            endDate
          }

          # SEP/QLE events
          isLostCoverageLast60Days
          isWillLoseCoverageNext60Days
          isMarriedLast60Days
          isReleasedFromIncarceration
          isMoved
          isGainDependent

          # SEP/QLE details

          whenMarried
          whenReleasedFromIncarceration
          whenRelocated
          whenBecomeADependent
        }

        applicant {
          id
          givenName
          familyName
          age
          isSmoker
          isPregnant
          middleName
          relation
          sex
          dob
          email
          ssn
          contactID
          isRequestingCoverage

          # Eligibility
          initialEnrollmentEligibility {
            startDate
            endDate
            earliestQhpEffective
            latestQhpEffective
          }
          aptcEligibility {
            startDate
            endDate
            amount
          }
          csrEligibility {
            startDate
            endDate
            csrLevel
          }
          exchangeEligibility {
            startDate
            endDate
          }
          sepEligibility {
            startDate
            endDate
            earliestQhpEffective
            latestQhpEffective
            sepReason
            verificationPending
          }
          chipEligibility {
            startDate
            endDate
          }
          medicaidEligibility {
            startDate
            endDate
          }
          homeAddress {
            street1
            street2
            city
            state
            zip
            zip4
          }
          phone {
            number
          }
          # SEP/QLE events
          isLostCoverageLast60Days
          isWillLoseCoverageNext60Days
          isMarriedLast60Days
          isReleasedFromIncarceration
          isMoved
          isGainDependent

          # SEP/QLE details
          coverageLostDate
          whenMarried
          whenReleasedFromIncarceration
          whenRelocated
          whenBecomeADependent
        }
      }
    }
  }
`;

export const FRAGMENT_HEALTH_APP_PLANS = gql`
  fragment HealthApplicationPlansFragment on HealthInsuranceEnrollment {
    healthApplication {
      id
      coverageState
      coverageYearNumber
      householdIncome
      # numTaxDependents
      lastUpdated
      lastUserUpdate
      signatureText
      signatureDate
      status
      pathwayType
      selectedPlanID
      ... on HealthApplication {
        id
        createdOn
        eligibilityLastFetched
        # screeningPhase
        applicationOrigin
        hasAnyMedicaidChipEligibleMembers
        hasAllMedicaidChipEligibleMembers
        plan {
          id
          deductibles {
            amount
            type
            familyCost
            networkTier
          }
          benefits {
            name
            covered
            costSharings {
              coinsuranceOptions
              coinsuranceRate
              copayAmount
              copayOptions
              networkTier
              csr
              displayString
            }
            explanation
            hasLimits
            limitUnit
            limitQuantity
          }
          name
          metalLevel
          type
          premium
          premiumWithCredit
          issuer {
            id
            name
          }
        }
        enrollmentGroups {
          id
          members {
            id
            givenName
            familyName
            relation
            isRequestingCoverage
            isTaxDependent
            hasAbsentParent
          }
          plan {
            id
            issuer {
              id
              name
            }
            deductibles {
              amount
              type
              familyCost
              networkTier
            }
            name
            metalLevel
            type
            premium
            premiumWithCredit
          }
          plans {
            plans {
              planId: id
              issuer {
                id
                name
              }
              deductibles {
                amount
                type
                familyCost
                networkTier
              }
              name
              metalLevel
              type
              premium
              premiumWithCredit
            }
          }
        }
      }
    }
  }
`;

export const FRAGMENT_HEALTH_PLAN = gql`
  fragment HealthPlanFieldsFragment on ScoredHealthPlan {
    healthPlan {
      planId: id
      issuer {
        id
        name
      }
      deductibles {
        amount
        type
        familyCost
        networkTier
      }
      benefits {
        name
        covered
        costSharings {
          coinsuranceOptions
          coinsuranceRate
          copayAmount
          copayOptions
          networkTier
          csr
          displayString
        }
        explanation
        hasLimits
        limitUnit
        limitQuantity
      }
      name
      metalLevel
      type
      premium
      premiumWithCredit
    }
    scores {
      score
      budgetScore
      comprehensiveScore
      deductible
      maxAnnualCost
      premiumSubscore
      deductibleSubscore
      maxAnnualCostSubscore
      planTypeSubscore
      drugsSubscore
      providersSubscore
      avSubscore
      preferredPartnerSubscore
    }
  }
`;

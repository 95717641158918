import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../auth/useAuth';
import { Navbar } from 'src/web/components';
import { useRegisterActions } from 'kbar';
import { KACTIONS } from 'src/web/kbar';
import { useAdmins } from 'src/web/hooks';

export function AuthenticatedLayout() {
  const { logout } = useAuth();
  const { admin } = useAdmins();

  useRegisterActions([
    {
      ...KACTIONS.SEARCH,
      hide: true,
      perform: () => {
        document.getElementById('search').focus();
      },
    },
  ]);

  return (
    <>
      <Navbar onSignOut={logout} adminId={admin} />
      <Outlet />
      <div id="modal-root" />
    </>
  );
}

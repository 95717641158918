import styled from 'styled-components';

const StyledScroll = styled.div<{ $loading: boolean }>`
  opacity: ${({ $loading }) => $loading ? 0.5 : 1};
  transition: opacity 100ms ease-in-out;

  position: absolute;
  left: 0px;
  right: 0px;
  top: ${p => (p.toolbar ? 'var(--navHeight)' : '0px')};
  bottom: ${p => p.bottomBar ? '95px' : '0px'};
  padding: ${p => (p.noPadding ? 0 : 'var(--padding)')};
  padding-top: ${p => (p.inspector || p.noPadding ? 0 : 'var(--padding)')};
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: var(
    ${p =>
      p.transparent
        ? 'transparent'
        : p.inspector || p.inspectorColor
        ? '--cCard2'
        : p.sidebarColor
        ? '--fga'
        : '--bg'}
  );
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledInner = styled.div`
  margin: auto;
`;

export interface ScrollProps {
  loading?: boolean;
  children: React.ReactNode;
  large?: boolean;
  small?: boolean;
  noPadding?: boolean;
  toolbar?: boolean;
  bottomBar?: boolean;
  padded?: boolean;
  inspector?: boolean;
  transparent?: boolean;
  sidebarColor?: boolean;
  inspectorColor?: boolean;
}

/**
 * @deprecated
 * use Scroll from src/kit
 */
export function Scroll({
  loading,
  large,
  small,
  children,
  noPadding,
  toolbar,
  bottomBar,
  padded,
  inspector,
  transparent,
  sidebarColor,
  inspectorColor,
}: ScrollProps) {
  return (
    <StyledScroll
      $loading={loading}
      transparent={inspector || transparent}
      large={large}
      small={small}
      noPadding={noPadding}
      toolbar={toolbar}
      bottomBar={bottomBar}
      inspector={inspector}
      inspectorColor={inspectorColor}
      sidebarColor={sidebarColor}
      padded={padded}
    >
      <StyledInner>{children}</StyledInner>
    </StyledScroll>
  );
}
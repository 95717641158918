import React from 'react';
import { Icon } from 'src/web/components';
import styled from 'styled-components';
import { DEFAULT_URL } from 'src/web/utility';

const StyledTile = styled.div`
  width: 120px;
  height: 120px;
  font-size: var(--fp);
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  align-items: center;
  font-weight: 600;
  text-align: center;
  padding: 0px 16px;
  margin: 8px;
  border-radius: var(--radius);
  background: ${p => (p.selected ? 'var(--cAdminAccentLight)' : 'transparent')};
  cursor: var(--cursor);
  * {
    line-height: 1.25;
    cursor: default;
  }
  &:hover {
    background: ${p => (p.selected ? 'var(--cAdminAccentLight)' : 'var(--cSkeleton)')};
  }
`;

const Tile = ({ selected, onPress, name, idx, size = 42, logo, icon, detail, ...rest }) => {
  return (
    <StyledTile selected={selected} onClick={onPress}>
      <Icon
        square
        size={size}
        logo={logo}
        icon={icon}
        {...rest}
        style={{
          marginBottom: 8,
          opacity: (!!logo || !!icon) && logo !== DEFAULT_URL ? 1 : 0.25,
        }}
      />
      <div
        style={{
          height: !detail ? 48 : '',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: `-webkit-linear-gradient(var(--c-fg-0) 29px, transparent 48px)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          marginBottom: !detail ? -18 : 2,
        }}
      >
        {name}
      </div>
      {!!detail && (
        <div
          style={{
            overflow: 'hidden',
            marginBottom: -18,
            color:
              detail === 'EDE'
                ? 'var(--cCredit)'
                : detail === 'Oscar'
                ? 'var(--cLink)'
                : 'var(--cTextSubtle)',
            fontWeight: 600,
            fontSize: 'var(--ffp)',
          }}
        >
          {detail}
        </div>
      )}
    </StyledTile>
  );
};

export default Tile;

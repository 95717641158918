import React from 'react';
import styled from 'styled-components';

const StyledPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--navSecondary);
  z-index: 999999999999999;
  background: ${p => (p.locked ? 'var(--c-bad-1)' : 'var(--c-menu-light)')};
  border-right: 1px solid var(--c-border-light);
  max-height: calc(100vh - var(--navHeight));
  overflow-y: scroll;
  @media (max-width: 1024px) {
    background: var(--c-menu);
  }
`;

const StyledNavbar = styled.div`
  box-sizing: border-box;

  padding: var(--padding) 24px;
  width: var(--navSecondary);
  z-index: 999999999;
  -webkit-user-select: none;
`;

const SectionNav = ({ children, locked }) => {
  return (
    <StyledPanel locked={locked} className="navIn">
      <StyledNavbar width={'var(--navSecondary)'}>{children}</StyledNavbar>
    </StyledPanel>
  );
};

export default SectionNav;

import styled from 'styled-components';
import Lottie from 'react-lottie';
import loader from 'src/assets/lottie/loader.json';

const size = 32;

export interface SpinnerProps {
  loading: boolean
}

export function Spinner({ loading }: SpinnerProps) {
  if (!loading) return null;

  return (
    <Lottie 
      options={{ loop: true, autoplay: true, animationData: loader }}
      height={size}
      width={size}
    />
  );
}
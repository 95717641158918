import React from 'react';
import styled from 'styled-components';

import { makeAddress } from 'src/web/utility';
import I from '../I';
import {
  IdentityFinding,
  IdentityProvider,
  IdentityRecordType,
  IdentityVerificationAttempt,
} from 'src/web/types/identity';

const SCard = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.inInspector ? '100%' : '250px 1fr')};
  @media (min-width: 1800px) {
    grid-template-columns: ${p => (p.inInspector ? '100%' : '1fr 2fr')};
  }
  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
  color: var(--c-fg-0);
  grid-gap: 12px;
  border-top: ${p => (p.borderless ? '' : '1px solid var(--c-border-light)')};
  padding: 12px 0;
  eb {
    font-weight: 500;
    font-size: var(--ffp);
    color: var(--c-fg-1);
    line-height: 1;
  }
  .value {
    font-weight: 600;
  }
  > div {
    overflow: hidden;
  }
  > div:first-child {
    padding-top: ${p => (p.inInspector ? '0' : '8px')};
  }
`;

const SVariant = styled.div`
  padding: 12px;
  background: ${p =>
    p.systemMismatch
      ? 'var(--c-bad-1)'
      : p.explicitMismatch
      ? 'var(--c-medium-1)'
      : p.matches
      ? 'var(--c-good-1)'
      : 'var(--c-border-light)'};
  margin-bottom: 1px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: var(--radius);
  font-size: var(--ffp);
  max-width: 100%;
  overflow: hidden;
  color: var(--c-fg-0);
  .value {
    font-weight: 500;
    line-height: 1;
    .l {
      font-weight: 400;
      color: var(--c-fg-1);
      line-height: 1;
      margin-top: 6px;
    }
  }
  .source {
    font-weight: 400;
    color: var(--c-fg-1);
    width: 24px;
    flex-shrink: 0;
    margin-top: -2px;
    margin-bottom: -8px;
    opacity: 0.5;
  }
`;

interface IdentityCardProps {
  type: IdentityRecordType;
  primary: IdentityFinding;
  findings: Array<IdentityFinding>;
  searches?: Array<IdentityFinding>;
  borderless?: boolean;
}

interface VariantProps {
  value?: any;
  baseline?: any;
  source?: IdentityProvider;
  verification?: Array<IdentityVerificationAttempt>;
  note?: string;
  searches?: Array<IdentityFinding>;
  type: IdentityRecordType;
}

const iconMap = {
  plaid: 'Plaid',
  ede: 'EDE',
  unit: 'Unit',
  provided: 'Edit',
};

const Variant = ({
  value,
  source,
  type,
  verification,
  note,
  baseline,
  searches,
  logo,
  systemMismatch,
}: VariantProps) => {
  const val = value?.toString()?.toLowerCase();
  const bas = baseline?.toString()?.toLowerCase();

  var matches = source !== 'provided' && (bas === val || val?.includes(bas) || bas?.includes(val));

  const explicitMismatch = (type === 'Phone' || type === 'Email') && val !== bas;

  searches?.forEach(search => {
    if (val?.includes(search?.value?.toString())) matches = true;
  });

  return (
    <SVariant explicitMismatch={explicitMismatch} matches={matches}>
      <div className="source">
        {logo ? (
          <img src={`data:image/jpeg;base64, ${logo}`} height={16} width={16} />
        ) : (
          <I hasFill={source !== 'provided'} name={iconMap[source]} color="var(--c-fg-0)" size={16} />
        )}
      </div>
      <div className="value mono">
        {value?.toString()} {!!note && <div className="l">{note}</div>}
      </div>
    </SVariant>
  );
};

export const IdentityCard = ({
  type,
  primary,
  findings,
  searches,
  borderless,
  inInspector,
}: IdentityCardProps) => {
  return !!primary?.value ? (
    <SCard borderless={borderless} inInspector={inInspector}>
      <div>
        <eb>{type}</eb>
        <div className="value">{type === 'Address' ? makeAddress(primary.value) : primary.value}</div>
      </div>
      <div>
        {findings
          ?.sort((a, b) => (a?.source === 'provided' ? -1 : 1))
          ?.map((finding, idx) => (
            <Variant
              type={finding.type}
              value={
                type === 'Address' && finding.source !== 'plaid' ? makeAddress(finding.value) : finding.value
              }
              baseline={
                type === 'Address' && primary.source !== 'plaid' ? makeAddress(primary.value) : primary.value
              }
              searches={searches}
              source={finding.source}
              note={finding.note}
              logo={finding.logo}
              systemMismatch={finding.systemMismatch}
            />
          ))}
      </div>
    </SCard>
  ) : (
    undefined
  );
};

export default IdentityCard;

import { ClosedReasonEnum, ConversionStatusEnum, ConversionStatusType, PolicyFragment } from 'src/data';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

export enum ModalType {
  EDIT_CONVERSION = 'conversion',
  CREATE_POLICY = 'createPolicy',
  UPDATE_POLICY = 'updatePolicy',
  // Add other modal types as needed
}

interface EditConversionData {
  userId: string;
  year: number;
  type: ConversionStatusType;
  status?: ConversionStatusEnum;
  closedReason?: ClosedReasonEnum;
}

interface CreatePolicyData {
  userId: string;
}

interface UpdatePolicyData {
  userId: string;
  policy: PolicyFragment;
}

// Create a mapping from ModalType to data interfaces
type ModalDataMap = {
  [ModalType.EDIT_CONVERSION]: EditConversionData;
  [ModalType.CREATE_POLICY]: CreatePolicyData;
  [ModalType.UPDATE_POLICY]: UpdatePolicyData;
  // Add other mappings as needed
};

// Use a mapped type to define ModalConfig
type ModalConfig = {
  [K in keyof ModalDataMap]: { id: string; type: K; data: ModalDataMap[K] };
}[keyof ModalDataMap];

// Define component props based on modal data interfaces
export type ModalComponentProps<T extends ModalType> = {
  type: T;
  data: ModalDataMap[T];
  closeModal: () => void;
};

// Define the store
export const useModal = create<{
  modals: Array<ModalConfig>;
  openModal: <T extends ModalType>(type: T, data: ModalDataMap[T]) => void;
  closeModal: (id: string) => void;
  closeAll: () => void;
}>((set, get) => ({
  modals: [],
  openModal: (type, data) => {
    const modals = get().modals;
    modals.push({ id: uuidv4(), type, data });
    set({ modals });
  },

  closeModal: id => {
    const modals = get().modals.filter(modal => modal.id !== id);
    set({ modals });
  },

  closeAll: () => set({ modals: [] }),
}));

import { useRef, useEffect } from 'react';
import { I } from 'src/web/components';

export function SearchBarResult({ action, active }) {
  const ownRef = useRef();
  
  useEffect(() => {
    if (active) {
      // wait for the KBarAnimator to resize, _then_ scrollIntoView.
      // https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
      window.requestAnimationFrame(() =>
        window.requestAnimationFrame(() => {
          const element = ownRef.current;
          if (!element) {
            return;
          }
          // @ts-ignore
          element.scrollIntoView({
            block: 'nearest',
            behavior: 'smooth',
            inline: 'start',
          });
        }),
      );
    }
  }, [active]);
  
  return typeof action === 'string' ? (
    <div style={headerStyle}>{action}</div>
  ) : (
    <div
      ref={ownRef}
      // {...handlers}
      style={{
        padding: '0 16px',
        boxSizing: 'border-box',
        background: active ? 'var(--c-border-light)' : 'var(--card2)',
        borderLeft: `2px solid ${active ? 'var(--fg)' : 'transparent'}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        color: 'var(--c-fg-0)',
        height: 50,
      }}
    >
      <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        {typeof action.icon === 'string' ? (
          <I
            name={action.icon}
            size={24}
            color={
              action?.destructive ? 'var(--c-bad-0)' : action?.subtle ? 'var(--c-fg-2)' : 'var(--c-fg-0)'
            }
          />
        ) : (
          action.icon
        )}
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: 'var(--fp)', fontWeight: 400 }}>
          {action.pretitle && (
            <span
              style={{
                color: 'var(--c-fg-1)',
                marginRight: 6,
              }}
            >
              {action.pretitle}
            </span>
          )}
          <span style={{ fontWeight: 450 }}>{action.name}</span>
          {action.subtitle && (
            <span
              style={{
                color: 'var(--c-fg-2)',
                marginLeft: 6,
              }}
            >
              {action.subtitle}
            </span>
          )}
        </div>
      </div>
      {action?.shortcut?.length ? (
        <div style={{ display: 'grid', gridAutoFlow: 'column', gap: '1px' }}>
          {action?.shortcut?.map(sc => (
            <span
              className="mono technical"
              key={sc}
              style={{
                padding: '6px 6px 5.5px',
                background: 'var(--fga)',
                color: 'var(--c-fg-1)',
                borderRadius: '4px',
                fontSize: 'calc(var(--ffp) - 1px)',
                lineHeight: 1,
                marginRight: 1,
                textTransform: /Enter|Backspace/.test(sc) || 'uppercase',
              }}
            >
              {sc
                ?.replace('Command', '⌘')
                ?.replace('Shift', '⇧')
                ?.replace('Option', '⌥')}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}

    
  const headerStyle = {
    padding: '8px 16px 0',
    boxSizing: 'border-box',
    background: 'var(--fgaa)',
    fontSize: 'calc(var(--ffp) - 1px)',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    color: 'var(--c-fg-1)',
    height: 50,
  
    textTransform: 'uppercase',
  };
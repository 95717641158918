import { createContext } from 'react';

export const SelectionContext = createContext({
  navbarOpen: false,
  inspectorClosed: false,
  privacyMode: localStorage.privacyMode === 'true',
  sidebarSecondaryOpen: localStorage.sidebarSecondary === 'open',
  togglePrivacyMode: () => {},
  toggleNavbar: () => {},
  setNavbarOpen: (val: boolean) => {},
  setInspectorClosed: (val: boolean) => {},
  admins: [],
  admin: '',
  loading: true,
  adminUser: null,
});
import { gql } from '../generated';

gql(`
  fragment Flags on FeatureFlags {
    id
    hasAccessToFintech
  }
`);

export const GET_FLAGS = gql(`
  query FeatureFlags($personID: ID!) {
    person(id: $personID) {
      id
      flags {
        ...Flags
      }
    }
  }
`);

export const UPDATE_FLAGS = gql(`
  mutation UpdateFlags($personID: ID!, $input: UpdateFlagsInput!) {
    updateFlags(personID: $personID, input: $input) {
      ...Flags
    }
  }
`);

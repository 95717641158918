import { gql } from '@apollo/client';

export const DISABLE_USER = gql`
  mutation DisableUser($personId: ID!, $linkedAccountId: ID) {
    disableUser(personID: $personId, linkedAccountID: $linkedAccountId) {
      error
    }
  }
`;

export const SYNC_HEALTH = gql`
  mutation SyncHealth($personId: ID, $forceLink: Boolean, $forcePermissions: Boolean) {
    syncHealth(personID: $personId, forceLink: $forceLink, forcePermissions: $forcePermissions)
  }
`;

export const SUBMIT_HEALTH = gql`
  mutation SubmitHealth($id: ID!, $personId: ID!) {
    submitHealthApplicationNew(
      id: $id
      personID: $personId
      input: {
        isAbsentParentAgreement: YES
        isChangeInformationAgreement: YES
        isMedicaidRequirementAgreement: YES
        isPenaltyOfPerjuryAgreement: YES
        isRenewalAgreement: YES
        isTaxCreditReconciled: YES
        isTerminateCoverageOtherMecFoundAgreement: YES
        lastUsedRoute: null
        renewEligibilityYearQuantity: 5
        signatureDate: null
        signatureText: null
        signatureType: null
      }
    ) {
      errors {
        code
      }
    }
  }
`;

export const SUBMIT_HEALTH_ENROLLMENT = gql`
  mutation SubmitHealthEnrollment($id: ID!, $personId: ID!) {
    submitEnrollment(id: $id, personID: $personId) {
      currentAppStatus
      error {
        code
        field
        message
      }
      healthServiceError {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserNew($input: UpdateUserInput!, $personId: ID!) {
    updateUserNew(input: $input, personID: $personId) {
      user {
        id
        givenName
        familyName
        nickname
        middleName
        dba
        ein
      }
    }
  }
`;

// EDE

export const EDE_LOOKUP = gql`
  mutation EdeApplicationLookup($personId: ID!) {
    edeApplicationSearch(personID: $personId) {
      hasActivePolicy
      hasPreviousActivePolicy
      hasSubmittedApplication
      existingApplications {
        id
        familyName
        givenName
        coverageYearNumber
        state
        applicationIdentifier
        applicationVersion
        providerLastUpdatedDate
        applicationStatus
        hasExistingPersonTrackingNumbersTiedToApplication
        policyInfo
        matchesWithAnotherUser
      }
    }
  }
`;

export const DELETE_APPLICATION = gql`
  mutation UpsertHealthApplication($appId: ID, $personId: ID!) {
    upsertHealthApplication(
      input: { id: $appId, status: DELETED, applicationPhase: TWO }
      personID: $personId
    ) {
      id
      status
    }
  }
`;

export const UPSERT_LAST_ROUTE = gql`
  mutation UpsertLastApplicationRoute($input: UpsertHealthApplicationInput!, $personId: ID!) {
    upsertHealthApplication(input: $input, personID: $personId) {
      id
      lastUsedRoute
      isRequestingFinancialAssistance
      lastUserUpdate
    }
  }
`;

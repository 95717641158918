import React from 'react';
import styled from 'styled-components';

import { formatDate } from 'src/web/utility';
import { Icon, FontIcon, I } from 'src/web/components';
import { RowGroup } from './RowGroup';

const StyledRowLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding: ${p => (p.large ? '0 12px 0' : p.tiny ? '0 4px' : '0 6px')};
  margin: 0 1px 0 1px;
  height: ${p => (p.large ? 30 : p.tiny ? 13 : 16)}px;
  line-height: 1;
  text-align: center;
  font-weight: 550;
  font-size: ${p => (p.tiny ? 'calc(var(--ffp) - 3px)' : 'calc(var(--ffp) - 2px)')};
  background: ${p => p.color || 'var(--c-border-light)'};
  color: ${p => (p.invert ? 'var(--c-bg-1)' : 'var(--c-fg-1)')};
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'tnum' 1;
  flex-shrink: 0;
  &:first-child {
    margin-left: ${p => (p.large ? 0 : 4)}px;
  }
`;

export const RowLabel = ({ text, color, style, large, tiny }) => {
  const _color =
    text === 'Connected'
      ? 'var(--c-good-1)'
      : text === '✕ NPN' || text === 'Stuck' || text === 'Stride'
      ? 'var(--c-bad-0)'
      : text === '✓ NPN'
      ? 'var(--c-good-0)'
      : text === 'No NPN'
      ? 'var(--c-bad-0)'
      : text === 'DMI' || text === 'SVI'
      ? 'var(--c-medium-1)'
      : null;
  const invert = _color === 'var(--c-good-0)' || _color === 'var(--c-bad-0)';
  return !text || text === '' ? (
    <></>
  ) : (
    <StyledRowLabel large={large} style={style} color={_color || color} invert={invert} tiny={tiny}>
      {text}
    </StyledRowLabel>
  );
};

const StyledRow = styled.div`
  flex-direction: row;
  // position: ${p => (p.timeline ? 'relative' : 'static')};
  cursor: var(--cursor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding:  6px 12px 6px 12px;
  margin: ${p => (p.highlight ? '6px -12px' : '0 -12px')} !important;
  max-width: 1024px;
  @media (min-width: 1024px) {
    margin-top: ${p => (p.highlight ? 12 : 0)}px !important;
    margin-bottom: ${p => (p.highlight ? 12 : 0)}px !important;
    margin-right: ${p => (p.compact ? '-12px' : 'auto')} !important;
    margin-left: ${p => (p.compact ? '-12px' : 'auto')} !important;
  }
  border-radius: var(--radius);
  box-sizing: border-box;
  font-weight: ${p => (p.large ? '600' : '400')};
  font-size: var(--fp);
  overflow: hidden;
  background: ${p => (p.selected ? 'var(--cSkeleton)' : '')};
  z-index: 999999;
  border-bottom-color: ${p => (!p.borderless ? p.borderColor : 'transparent')};
  .b-size-collapse {
    transform-origin: center;
    transition: transform var(--transition);
    transform: translateY(2px) scale(0.4) rotate(${p => (p.open ? 90 : 0)}deg);
    opacity: 0.35;
  }
  &:hover {
    background-color: ${p => (p.selected ? 'var(--fga)' : !p.header || !!p.caret ? 'var(--fga)' : '')};
    z-index: 999;
    .StyledDateHover {
      opacity: 1;
    }
  }
  input,
  select {
    font-size: var(--p);
    font-family: 'InterVar', 'Inter';
    font-weight: 500;
  }

  .copyRow {
    opacity: 0;
  }
  &:hover .copyRow {
    opacity: 0.5;
  }
  .copyRow:hover {
    opacity: 1;
  }
`;

const StyledDateHover = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  padding: 0 12px 0;
  opacity: 0;
  color: var(--c-fg-1);
  background: transparent;
  backdrop-filter: blur(40px) saturate(500%);

  font-size: var(--ffp);
  text-align: right;
  font-weight: 500;
  z-index: 999999999;
`;

const StyledTimeline = styled.div`
  width: 1px;
  top: 0;
  bottom: 0;
  position: absolute;
  left: 95px;
  background: ${p => p.color};
`;

export const StyledGroup = styled.div`
  margin-top: ${p => (p.length > 1 ? 32 : 2)}px;
  margin-bottom: ${p => (p.length > 1 ? 32 : 2)}px;
  &:first-child {
    margin-top: 0;
  }
`;

function ActivityRow({
  header,
  label,
  label2,
  value,
  onPress,
  selected,
  icon,
  borderless,
  logo,
  timeline,
  date,
  large,
  squareLogo,
  borderLogo,
  postValue,
  timestamp,
  iconColor,
  iconBorderColor,
  caret,
  open,
  compact,
  first,
  auto,
  tiny,
  highlight,
}) {
  return (
    <StyledRow
      tiny={tiny}
      first={first}
      open={open}
      caret={caret}
      onClick={e => {
        e.stopPropagation();
        if (onPress) onPress();
      }}
      className={`${header ? '' : 'flex-h'} ${header && 'header'}`}
      selected={selected}
      interactive={onPress}
      borderless={borderless}
      date={date}
      large={large}
      compact={compact}
      timeline={timeline}
      highlight={highlight}
      style={{
        borderColor: 'var(--c-border)',
        color: 'var(--c-fg-0)',
      }}
    >
      <div
        className={header ? '' : 'flex-h'}
        style={{
          color: 'var(--c-fg-0)',
        }}
      >
        {(!!timeline || !!date) && (
          <div
            className="tabular"
            style={{
              width: compact ? 64 : 96,
              fontWeight: !!date ? 500 : 400,
              fontSize: 'var(--ffp)',
              opacity: !!date ? 1 : 0.35,
              color: 'var(--c-fg-0)',
            }}
          >
            {date}
          </div>
        )}
        {logo ? (
          <Icon
            logo={logo}
            icon={icon}
            size={large ? 24 : 20}
            className="icon"
            contain={!logo}
            square={squareLogo}
            border={borderLogo}
            noShadow
            iconColor={iconColor || 'var(--c-bg-0)'}
            borderColor={'var(--c-border)'}
            iconBorderColor={iconBorderColor}
            style={{
              marginRight: compact ? 8 : 12,
            }}
          />
        ) : (
          <div
            style={{
              width: 20,
              height: 20,
              marginRight: compact ? 8 : 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon && <I name={icon} size={20} color={iconColor || 'var(--c-fg-0)'} />}
          </div>
        )}
        {!!timeline && <StyledTimeline className="timeline" />}
        {!!caret && (
          <FontIcon
            icon="play"
            className="b-size-collapse"
            style={{
              marginRight: 8,
              display: 'inline-block',
            }}
          />
        )}
        {!!label && label}

        <span
          style={{
            opacity: 0.5,
            fontWeight: 400,
            paddingLeft: 6,
            maxWidth: 200,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            mask: 'linear-gradient(to right, #000000 175px, #00000000 200px)',
            WebkitMask: 'linear-gradient(to right, #000000 175px, #00000000 200px)',
          }}
        >
          {!!label2 && label2}
        </span>
      </div>
      <div>
        <div
          style={{
            fontWeight: 500,
            fontSize: large ? 'var(--fp)' : compact ? 'var(--ffp)' : 'var(--fp)',
            textAlign: 'right',
            color: 'var(--c-fg-0)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!!auto && <I size={20} name="Auto" style={{ marginRight: 8 }} color="var(--c-cerise-0)" />}
          {!value || Array.isArray(value) || value.constructor === Object
            ? React.isValidElement(value)
              ? value
              : ''
            : value}
        </div>
        {!!postValue && <div style={{ flex: 0 }}>{postValue}</div>}

        {!!timestamp && (
          <StyledDateHover
            className="StyledDateHover tabular"
            backgroundColor={'var(--c-bg-1)'}
            textColor={'var(--c-fg-0)'}
            compact={compact}
          >
            {formatDate({
              date: timestamp,
              length: 'LONG',
            })}
          </StyledDateHover>
        )}
      </div>
    </StyledRow>
  );
}

export default ActivityRow;

import { useMemo } from "react";
import { ModalContent } from "src/web/components";
import { Form, Field } from 'src/forms';
import { ADD_CONSENT_REQUEST, ConsentType, CREATE_CONSENT_REQUEST, GET_USER, UPDATE_USER, useMutation, useQuery } from "src/data";

export function SendBrokerConsent({ userID, closeModal }) {
  const { loading, data } = useQuery(GET_USER, {
    variables: { id: userID },
    skip: !userID,
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER)
  const [createConsent, { loading: creating }] = useMutation(CREATE_CONSENT_REQUEST, {
    update: (cache, result) => ADD_CONSENT_REQUEST(cache, result.data.createConsentRequest, userID)
  });

  const initialValues = useMemo(() => {
    return {
      givenName: data?.viewer?.user?.givenName,
      familyName: data?.viewer?.user?.familyName,
    };
  }, [data]);

  const handleSubmit = async ({ givenName, familyName }) => {
    // updates the user's name
    await updateUser({ 
      variables: {
        personID: userID,
        input: {
          givenName,
          familyName
        }
      }
    });

    // then sends broker consent
    createConsent({
      variables: {
        input: {
          consentType: ConsentType.Broker,
          personID: userID,
        }
      }
    })

    closeModal();
  }

  const disabled = useMemo(() => { 
    return updating || creating;
  }, [updating, creating])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      <ModalContent title="Send Broker Consent" onSubmit={() => {}} cta="Send" disabled={disabled}>
        <Field 
          name="givenName"
          type="text"
          label="First Name"
          autoFocus
          required
        />
        <Field 
          name="familyName"
          type="text"
          label="Last Name"
          required
        />
      </ModalContent>
    </Form>
  );
}
import React from 'react';
import styled from 'styled-components';
import { Status, Icon, RowLabel, I } from 'src/web/components';

import { formatCurrency, formatPercentage } from 'src/web/utility';

const StyledCard = styled.div`
  background: ${p => (p.selected ? 'var(--c-border-light)' : 'transparent')};
  padding: 16px 12px;
  margin: 0 ${p => (p.small ? 0 : -10)}px 1px;
  min-width: 350px;
  cursor: var(--cursor);
  border-radius: var(--radiusLarge);
  font-size: var(--fp);
  &:hover {
    background: ${p => (p.selected ? 'var(--c-border-light)' : 'var(--c-border-light)')};
    z-index: 999;
  }
`;

const StyledText = styled.div`
  font-size: var(--p);
  font-weight: 500;
  flex: 1;
  color: var(--c-fg-0);
  max-width: ${p => (p.small ? '200px' : '250px')};
  white-space: nowrap;
  mask-image: linear-gradient(to right, black ${p => (p.small ? '135px' : '225px')}, #00000000 100%);
`;

const StyledSubtitle = styled.div`
  font-size: var(--fp);
  font-weight: 400;
  flex: 1;
  color: var(--c-fg-1);
  max-width: ${p => (p.small ? '150px' : '250px')};
  white-space: nowrap;
  mask-image: linear-gradient(to right, black ${p => (p.small ? '135px' : '225px')}, #00000000 100%);
`;

const StyledDetailSection = styled.div`
  width: ${p => (p.small ? 24 : '185')}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--c-fg-0);
  flex-shrink: 0;
`;

const GoalCard = ({
  name,
  subtitle,
  balance,
  percentage,
  status,
  selected,
  logo,
  icon,
  iconColor,
  onPress,
  fillLogo,

  iconBorderColor,
  secondStatus,
  small,
  labels = [],
  labelIcons = [],
  style,
  children,
}) => {
  return (
    <StyledCard
      className={`goalCard fadeInSubtle flex-h ${!!selected && 'selected'}`}
      selected={selected}
      style={style}
      onClick={e => {
        e.stopPropagation();
        onPress();
      }}
    >
      <Icon
        size={40}
        noShadow
        square
        logo={logo}
        render
        icon={icon}
        fillLogo={fillLogo}
        iconColor={iconColor}
        style={{ marginRight: 12, flexShrink: 0 }}
        containLg={!!icon}
        iconBorderColor={iconBorderColor}
      />
      <div className="grow">
        <div className="flex-h">
          <div style={{ flex: 1 }}>
            <StyledText small={small}>{name}</StyledText>
            {subtitle && <StyledSubtitle small={small}>{subtitle}</StyledSubtitle>}
          </div>
          {!!percentage && !small && (
            <StyledDetailSection>
              {!!percentage && formatPercentage({ number: percentage })}
            </StyledDetailSection>
          )}

          {balance !== undefined && (
            <StyledDetailSection small={small}>{formatCurrency({ value: balance })}</StyledDetailSection>
          )}
          {children}
          <div>
            <StyledDetailSection small={small}>
              {!!secondStatus && (
                <Status hideText={small} small={small} compact={small} reverse status={secondStatus} />
              )}
              {!!status && <Status hideText={small} small={small} compact={small} reverse status={status} />}
            </StyledDetailSection>
            {!!labels && (
              <StyledDetailSection small={small} style={{ paddingTop: small ? 10 : 4 }}>
                {labelIcons?.map(icon => (
                  <I
                    style={{ opacity: icon.fade ? 0.5 : 1, marginLeft: 2 }}
                    size={16}
                    name={icon.icon}
                    color={icon.color}
                  />
                ))}
                {labels?.map(lbl => lbl && <RowLabel key={lbl} text={lbl || ' '} tiny={small} />)}
              </StyledDetailSection>
            )}
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default GoalCard;

import React, { useState } from 'react';
import styled from 'styled-components';
import { formatStatus } from './Status';

const StyledSteps = styled.div`
  padding: 4px 0;
`;

const StyledGroup = styled.div`
  padding-bottom: 16px;
`;

const StyledStep = styled.div`
  padding: 6px 24px 6px 32px;
  display: flex;
  align-items: center;
  font-size: var(--fp);
  font-weight: ${p => (p.active ? 600 : 400)};
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  transition: 0.25s var(--transitionCurve);
  cursor: var(--cursor);
  will-change: color font-weight;
  border-radius: 2px;
  color: ${p => (p.active ? 'var(--c-cerise-0)' : p.visited ? 'var(--c-fg-0)' : 'var(--c-fg-1)')} !important;
  &:hover {
    background: ${p => (p.visited ? 'var(--c-border-light)' : '')};
    color: ${p => (p.visited ? 'var(--c-cerise-0)' : '')} !important;
    .dot {
      background: ${p => (p.visited ? 'var(--c-cerise-0)' : '')};
      transform: ${p => (p.visited ? 'scaleY(1)' : '')};
    }
  }
`;

const StyledDot = styled.div`
  width: 2px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 1px;
  will-change: transform background;
  transform-origin: center;
  transition: 0.25s var(--transitionCurve);
  background: ${p => (p.active ? 'var(--c-cerise-0)' : p.visited ? 'var(--c-fg-0)' : 'var(--c-border)')};
  transform: ${p =>
    p.active ? 'scaleY(1) scaleX(1)' : p.visited ? 'scaleY(1) ' : 'scaleY(0.75) scaleX(0.5)'};
`;

export interface StepsProps<T extends string> {
  onSelect: (value: T) => void;
  current?: T;
  groups: Array<Array<T>>;
}

export function Steps<T extends string>({ current, groups, onSelect }: StepsProps<T>) {

  const steps = groups.flat(Infinity);

  const selectedIndex = steps.indexOf(current);
  const [hoveredIndex, setHoverIndex] = useState(selectedIndex);
  const reset = () => setHoverIndex(selectedIndex);

  return (
    <StyledSteps onMouseLeave={reset}>
      {groups.map(group => (
        <StyledGroup>
          {group.map(step => {
            const index = steps.indexOf(step);

            return (
              <StyledStep
                key={step}  
                onMouseEnter={() => setHoverIndex(index)}
                onClick={() => onSelect(step)}
                active={selectedIndex === index}
                visited={index <= hoveredIndex}
              >
                <StyledDot
                  className="dot"
                  active={selectedIndex === index && hoveredIndex === index}
                  visited={index <= hoveredIndex}
                />
                {formatStatus(step)}
              </StyledStep>
            );
          })}
        </StyledGroup>
      ))}
    </StyledSteps>
  );
}
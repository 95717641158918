import { useMemo } from 'react';
import { Scroll } from 'src/kit';
import { ConversionStatusType, useQuery, USER_CONVERSION } from 'src/data';
import { IAction, RowGroup } from 'src/web/components';
import { ConversionCard } from 'src/components/conversion';
import { ModalType, useModal } from 'src/hooks/useModal';
import { propercase } from 'src/_utils/format';
import { iconMapping } from 'src/utils';
import { DEFAULT_CONVERSION_YEAR, POSSIBLE_CONVERSION_YEARS } from 'src/components/conversion/config';

export interface ConversionPageProps {
  userId: string;
}

export function ConversionPage({ userId }: ConversionPageProps) {
  const { openModal } = useModal();

  const { loading, data } = useQuery(USER_CONVERSION, {
    variables: { id: userId },
    skip: !userId,
  });


  /**
   * Groups first by year, then breaks down by year.
   */
  const conversionsByYearAndType = useMemo(() => {
    const statuses = data?.person?.conversionStatuses || [];

    const allYears = new Set([...POSSIBLE_CONVERSION_YEARS, ...statuses.map(status => status.year)]);

    // we initialize a status object in this order
    const statusObject = {
      [ConversionStatusType.Health]: [],
      [ConversionStatusType.Dental]: [],
      [ConversionStatusType.Vision]: [],
    };

    // this automatically grabs any other conversion statuses that aren't explicitly defined
    for (const key in ConversionStatusType) {
      statusObject[ConversionStatusType[key]] = [];
    }

    const byYear = Array.from(allYears).map(year => ({ year, statusesByType: { ...statusObject } }));

    return statuses.reduce((acc, status) => {
      return acc.map((item) => {

        if (item.year !== status.year) return item;

        const existing = item.statusesByType[status.type];

        return {
          year: item.year,
          statusesByType: {
            ...item.statusesByType,
            [status.type]: [...existing, status],
          }
        }
      });
    }, byYear);
  }, [data]);

  return (
    <Scroll loading={loading}>
      {conversionsByYearAndType.map(({ year, statusesByType }, index) => (
        <RowGroup loading key={year} title={year} close={year !== DEFAULT_CONVERSION_YEAR}>

          {Object.keys(statusesByType).map((type: ConversionStatusType) => {
            const statuses = statusesByType[type];

            if (statuses.length === 0) {
              return (
                <IAction
                  key={type}
                  disabled={false}
                  icon={iconMapping[type] || 'Add'}
                  title={`Add ${propercase(type)} conversion status`}
                  onPress={() => openModal(ModalType.EDIT_CONVERSION, { userId, year, type })}
                />
              );
            } else {
              return statuses.map(conversion => (
                <ConversionCard key={conversion.id} conversion={conversion} />
              ))
            }
          })}
        </RowGroup>
      ))}
    </Scroll>
  );
};
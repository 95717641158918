import React from 'react';

import styled from 'styled-components';

const SNotesTitle = styled.div`
  margin-bottom: 4px;
  font-size: calc(var(--ffp) - 1px);
  font-weight: 600;
  color: var(--c-fg-2);
`;

const SNotes = styled.div`
  border-radius: 4px;
  background: var(--c-border-light);
  padding: 12px 16px;
  margin: 8px -16px;
  font-weight: 400;
  font-size: var(--fp);
  color: var(--c-fg-0);
`;

const Notes = ({ children }) => {
  return (
    <SNotes>
      <SNotesTitle>NOTES</SNotesTitle>
      {children || <span className="light faded">No Notes</span>}
    </SNotes>
  );
};

export default Notes;

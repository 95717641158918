import React from 'react';

import { useQuery } from '@apollo/client';
import { TASK } from 'src/web/data/tasks';
import { DebugView } from '..';

const PaymentDebugView = ({ id, u, onClose }) => {
  const { data, loading, error } = useQuery(TASK, {
    variables: { typename: 'TaxPaymentTask', userId: u, input: { id: id } },
    skip: !id,
  });
  return <DebugView type="TaxPaymentTask" id={id} isOpen={!!id} data={data} onClose={onClose} />;
};

export default PaymentDebugView;

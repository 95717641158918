import fetch from 'cross-fetch';
import { ApolloClient, HttpLink, from, InMemoryCache, QueryOptions, DefaultOptions } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { toast } from 'react-hot-toast';
import { Env } from 'src/_utils';
import { env, getAuthToken } from 'src/global';

import { logGQLError } from 'src/_utils/logger/sentry-logger';

const fetcher = (...args) => {
  return window.fetch(...args);
};

const abortController = new AbortController();

const adminLink = new HttpLink({
  uri: env.ADMIN_URL,
  fetchOptions: {
    mode: 'cors',
    fetch: fetcher,
    signal: abortController.signal,
  },
});

/**
 * Sets default options for queries
 * In admin, we want to always show error messages so that we can
 * surface them via toast, but we also want to display any data we have
 */
const options: DefaultOptions = {
  query: {
    errorPolicy: 'all',
    returnPartialData: true,
  },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
  },
};

/**
 * Attaches tokens to the GQL headers
 * Note: this can be shared across multiple clients as needed
 */
const withToken = setContext(async () => {
  const token = getAuthToken();
  let headers = {};
  if (token) headers['Authorization'] = `Bearer ${token}`;
  return { headers };
});

/**
 * Handles default error handling
 * When an error is encountered, pop a toast
 * letting the admin know something went wrong
 */
const withError = onError(({ operation, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      toast.error(`${operation.operationName}: ${err.message || 'Something went wrong'}`);
    });
  }
});

// Log users out whenever receiving a 401 unauthenticated
const logoutLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      logGQLError(err?.extensions?.exception, operation);
      toast.error(err?.extensions?.exception?.message);
    });
  }

  if (networkError && /5/.test(String(networkError.statusCode))) {
    // ClientError.getState().setDown();
  } else {
    // ClientError.getState().setOK();
  }

  if (graphQLErrors && networkError?.statusCode !== 401) {
    console.error(...graphQLErrors?.map(err => err.message));
  }
});

// -------
// Cache
// -------

const cache = new InMemoryCache({
  //freezeResults: false,

  typePolicies: {
    Goal: {
      merge: true,
    },
    Health: {
      merge: true,
      fields: {
        application: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthApplication',
              id: args.id || args.appId,
            });
          },
        },
        policy: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthPolicy',
              id: args.id || args.polId,
            });
          },
        },
      },
    },
    HealthTwo: {
      merge: true,
      fields: {
        enrollment: {
          read(_, { args, toReference }) {
            return (
              toReference({
                __typename: 'HealthInsuranceEnrollment',
                id: args.id || args.input.id || args.enrollId,
              }) ||
              toReference({
                __typename: 'HealthEnrollment',
                id: args.id || args.input.id || args.enrollId,
              })
            );
          },
        },
        application: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthApplication',
              id: args.id || args.appId,
            });
          },
        },
        policy: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthPolicy',
              id: args.id || args.polId,
            });
          },
        },
        existingApplicationInfo: {
          merge: true,
        },
      },
    },
    HealthInsuranceEnrollment: {
      merge: true,
    },
    HealthApplication: {
      merge: true,
    },
    HealthPolicy: {
      merge: true,
    },
    ApplicationMember: {
      merge: true,
      fields: {
        aptcEligibility: {
          merge: true,
        },
      },
    },
    ViewerTwo: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
      fields: {},
    },
    Viewer: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
      fields: {
        savingsAccountMetadata: {
          merge: true,
        },
        bankLink: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'BankLink',
              id: args.id,
            });
          },
        },

        incomeTransaction: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'IncomeTransaction',
              id: args.id,
            });
          },
        },
        transfer: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Transfer',
              id: args.id,
            });
          },
        },
        walletItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'WalletItem',
              id: args.id,
            });
          },
        },
        incomeAutomationRule: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'IncomeAutomationRule',
              id: args.id,
            });
          },
        },
        paidFeature: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'PaidFeature',
              type: args.type,
            });
          },
        },
        enrollment: {
          read(_, { args, toReference }) {
            return (
              toReference({
                __typename: 'HealthInsuranceEnrollment',
                id: args.id || args.input.id,
              }) ||
              toReference({
                __typename: 'HealthEnrollment',
                id: args.id || args.input.id,
              })
            );
          },
        },
        healthInsuranceEnrollment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthInsuranceEnrollment',
              id: args.input?.id,
            });
          },
        },
      },
    },
    Query: {
      fields: {
        viewer: {
          keyArgs: ['id'],
          merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
        },
        viewerTwo: {
          keyArgs: ['id'],
          merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
          fields: {
            identity: {
              read(_, { args, toReference, canRead }) {
                if (args.providerType) {
                  const ref = toReference({
                    __typename: 'Identity',
                    providerType: args.providerType,
                  });

                  if (canRead(ref)) {
                    return ref;
                  }
                }

                return null;
              },
            },
          },
        },
        task: {
          read(_, { args, field, variables, toReference }) {
            return toReference({
              __typename: variables?.typename || 'Task',
              id: args.input?.id,
            });
          },
        },
        healthInsuranceEnrollment: {
          read(_, { args, toReference }) {
            return (
              toReference({
                __typename: 'HealthInsuranceEnrollment',
                id: args.id || args.input.id,
              }) ||
              toReference({
                __typename: 'HealthEnrollment',
                id: args.id || args.input.id,
              })
            );
          },
        },

        healthEnrollment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthEnrollment',
              id: args.id,
            });
          },
        },

        savingsAccountMetadata: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
      },
    },
  },
});

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

const adminClient = new ApolloClient({
  name: 'admin',
  link: from([withToken, logoutLink, adminLink]),
  defaultOptions: options,
  cache,
  version: Env.version,
  devtools: {
    name: 'admin',
  },
});

const fintechLink = new HttpLink({ uri: env.FINTECH_URL, fetch });

/**
 * Fintech client
 */
export const fintechClient = new ApolloClient({
  name: 'fintech',
  link: from([withToken, withError, fintechLink]),
  cache: new InMemoryCache(),
  defaultOptions: options,
  devtools: {
    name: 'fintech',
  },
});

/**
 * Public client
 */
export const publicClient = new ApolloClient({
  name: 'public',
  uri: env.PUBLIC_URL,
  cache: new InMemoryCache(),
  devtools: {
    name: 'public',
  },
});

/**
 * Content client
 */
export const contentClient = new ApolloClient({
  name: 'hygraph',
  uri: env.HYGRAPH_URL,
  cache: new InMemoryCache(),
  devtools: {
    name: 'hygraph',
  },
});

export async function signApolloOut() {
  // terminate any active client processes
  adminClient.stop();
  await adminClient.clearStore();
  console.info('cleared store');
}

export async function signApolloIn() {
  await adminClient.resetStore();
  console.info('reset store');
}

export default adminClient;

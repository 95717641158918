import React from 'react';
import { useLocation } from 'react-router';

export default () => {
  return new URLSearchParams(useLocation().search);
};

export const useUrlParams = () => {
  var params = {};
  const _p = new URLSearchParams(useLocation().search);
  for (let p of _p) {
    params[p[0]] = p[1];
  }

  return params;
};

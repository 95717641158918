import React from 'react';
import styled from 'styled-components';
import { Header } from '..';

export const ModalNavLink = styled.div`
  padding: 5px 24px 4px;
  text-decoration: none;
  opacity: ${p => (p.disabled ? '0.25 !important' : 1)};
  font-weight: ${p => (p.bold ? 600 : 500)};
  color: var(--c-fg-0);
  opacity: ${p => (p.active ? 1 : 0.65)};
  overflow: hidden;
  margin: 0 -24px;
  cursor: default;
  background: ${p => p.active && p.theme.skeletonColor};
  &:hover {
    opacity: 1;
    color: var(--c-fg-0);
    background: ${p => p.theme.skeletonColor};
    * {
      opacity: 1;
      color: var(--c-fg-0);
    }
  }
`;

const StyledModalNav = styled.div`
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  width: var(--navPrimary);
  background: ${p => p.theme.adminHomeColor};
  border-right: 1px solid ${p => p.theme.borderLightColor};
  padding: calc(var(--navHeight) * 0.5) 24px 24px;
  color: var(--c-fg-0);
  font-size: var(--fp);
  font-weight: 500;
`;

export default ({ children, title }) => {
  return (
    <StyledModalNav>
      <Header title={title} />
      {children}
    </StyledModalNav>
  );
};

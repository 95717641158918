import { gql } from '../generated';

gql(`
  fragment ConversionStatus on ConversionStatus {
    id
    label
    type
    year
    status
    closedReason
    lastUpdated
    events {
      ...ActivityEvent
    }
  }
`);

export const USER_CONVERSION = gql(`
  query UserConversion($id: ID!) {
    person(id: $id) {
      id
      conversionStatuses {
        ...ConversionStatus
      }
      healthExplorerData {
        id
        possibleCoverageYears
      }
    }
  }
`);

export const UPSERT_CONVERSION_STATUS = gql(`
  mutation UpsertConversionStatus($personID: ID!, $input: ConversionStatusInput!) {
    upsertConversionStatus(personID: $personID, input: $input) {
      ...ConversionStatus
    }
  }
`);

export function UPDATE_CONVERSION_STATUS(cache, result, personId) {
  const conversionStatus = result?.data.upsertConversionStatus;

  const { person } = cache.readQuery({
    query: USER_CONVERSION,
    variables: { id: personId },
  });

  const existingStatuses = person.conversionStatuses || [];
  const statusIndex = existingStatuses.findIndex(status => status.id === conversionStatus.id);

  if (statusIndex < 0) {
    cache.writeQuery({
      query: USER_CONVERSION,
      variables: { id: personId },
      data: {
        person: {
          ...person,
          conversionStatuses: [...existingStatuses, conversionStatus],
        },
      },
    });
  }
}

import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Env } from 'src/_utils';
import { TabLink, Logo, Redact, RowGroup, Avatar, AppToolbar, ToolbarButton } from 'src/web/components';
import { initials, makeName } from 'src/web/utility';
import { SecondaryToolbar } from './SecondaryToolbar';
import { gql, useQuery } from '@apollo/client';
import { useBookmarks } from 'src/util/bookmarks';
import { useSelection } from 'src/util/selection';

const StyledNavPanel = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--navPrimary);
  position: fixed;
  z-index: 99999999;
  transition: var(--transition);
  border-right: 1px solid var(--c-border);
  background: ${Env.isDesktop ? 'transparent' : 'var(--c-bg-nav)'};
  @media (max-width: 1024px) {
    box-shadow: ${p => (p.open ? '0 0 50px -5px rgba(0, 0, 0, 0.25)' : '')};
    transform: translateX(${p => (p.open ? '0px' : 'calc(-1 * var(--navPrimary))')});
    background: var(--c-menu);
    backdrop-filter: blur(40px);
  }
`;

const StyledNavbar = styled.div`
  position: fixed;
  left: 0px;
  box-sizing: border-box;
  bottom: 0px;
  top: calc(var(--navHeight) * 1);
  padding: 0px 16px 0;
  width: var(--navPrimary);
  z-index: 999909999999;
  -webkit-user-select: none;
  overflow-x: hidden;
`;

const StyledInput = styled.input`
  background-image: url(/img/search.svg);
  @media (prefers-color-scheme: dark) {
    background-image: url(/img/search_dark.svg);
  }
  background-position: 21px;
  background-repeat: no-repeat;
  background-size: var(--iconSm);
  background-color: transparent;
  padding: 8px 0 8px 48px;
  margin: 0 -16px;
  height: var(--navHeight);
  width: calc(var(--navPrimary) - 1px);
  font-family: 'InterVar', 'Inter';
  font-weight: 400;
  font-size: var(--fp);
  color: var(--c-fg-0);
  border: 0px solid transparent;
  &:focus {
    background-color: var(--fga) !important;
    border-color: transparent !important;
  }
  &::placeholder {
    color: var(--cTextSubtle);
  }
`;

const BADGES = gql`
  query BadgeNumQuery($input: SearchPersonReferenceRequest!) {
    searchPersonReference(input: $input) {
      pageInfo {
        hasNextPage
        currentPageNumber
        pageSize
        totalItems
      }
    }
  }
`;

const BOOKMARKQ = gql`
  query BookmarkQuery($id: ID) {
    searchPersonReference(input: { id: $id, pagination: { pageNumber: 1, pageSize: 1 } }) {
      edges {
        id
        personReference {
          id
          givenName
          familyName
          email
          phoneNumber
          signupCode
          avatarImage
          healthApplicantEmail
          healthApplicantGivenName
          healthApplicantFamilyName
          isDeleted
        }
      }
    }
  }
`;

const Bookmark = ({ bookmark, onRemove }) => {
  const { data } = useQuery(BOOKMARKQ, {
    skip: !bookmark?.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: bookmark?.id,
    },
  });

  const person = data?.searchPersonReference?.edges?.[0]?.personReference;
  const name = makeName(person);

  return (
    <TabLink
      key={bookmark.id}
      to={`/users/${bookmark.id}`}
      customIcon={<Avatar image={person?.avatarImage} initials={initials(person)} />}
      onRemove={onRemove}
    >
      <Redact blur>{name || person?.email || person?.phoneNumber || bookmark.id}</Redact>
    </TabLink>
  );
};

function AdminNavigation({ adminId }) {
  const { navbarOpen, setNavbarOpen } = useSelection();
  const { data } = useQuery(BADGES, {
    pollInterval: 50000,
    variables: {
      input: {
        identityProviders: ['CMS'],
        identityStatuses: ['PENDING_MANUAL_REVIEW'],
        pagination: {
          pageNumber: 1,
          pageSize: 1,
        },
      },
    },
  });
  const _idb = data?.searchPersonReference?.pageInfo?.totalItems;
  const badge = _idb > 0 ? _idb : null;

  const { bookmarks, removeBookmark } = useBookmarks();
  const navigate = useNavigate();

  return (
    <StyledNavPanel className="drag" open={navbarOpen}>
      <AppToolbar className="no-mobile" />
      <SecondaryToolbar transparent>
        {!Env.isDesktop && <Logo />}
        <ToolbarButton
          icon="Menu"
          onPress={() => setNavbarOpen(false)}
          className="mobile-only"
        />
      </SecondaryToolbar>
      <StyledNavbar id="nav">
        <StyledInput
          id="search"
          type="text"
          placeholder="Search"
          onKeyUp={event => {
            if (event.key === 'Enter') {
              navigate(`/users?search=${encodeURIComponent(event.target.value)}`);
            } else if (event.key === 'Escape') {
              document.getElementById('search').blur();
            }
          }}
        />

        <TabLink bold exact to="/users" icon="User" bg="brand">
          Users
        </TabLink>
        <TabLink bold to="/explorer" icon="Map" bg="coverage">
          PX
        </TabLink>
        <TabLink bold to="/tasks/health" icon="DocumentShield" bg="coverage">
          Applications
        </TabLink>
        <TabLink bold to="/tasks/policies" icon="ShieldTick" bg="coverage">
          Policies
        </TabLink>
        <TabLink bold to="/tasks/id-review" icon="Fingerprint" badge={badge} bg="gray">
          ID Review
        </TabLink>
        <br />
        <TabLink to="/reference/links" icon="Link">
          Link Generator
        </TabLink>
        <TabLink to="/docs/ede" icon="Code">
          API Docs
        </TabLink>
        <br />
        <RowGroup borderless title="My Bookmarks">
          {Object.values(bookmarks)
            ?.sort((a, b) => a.lastViewDate - b.lastViewDate)
            ?.map(bookmark => (
              <Bookmark key={bookmark.id} bookmark={bookmark} onRemove={() => removeBookmark(bookmark.id)} />
            ))}
        </RowGroup>
      </StyledNavbar>
    </StyledNavPanel>
  );
}

export default AdminNavigation;

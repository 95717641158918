import React from 'react';
import styled from 'styled-components';

export const SecondaryToolbar = styled.div`
  position: absolute;
  top: ${p => (p.second ? 'var(--navHeight)' : '0px')};
  left: 0;
  width: 100%;
  height: var(--navHeight);
  display: flex;
  align-items: center;
  justify-content: ${p => (p.center ? 'center' : 'flex-start')};
  z-index: 9999999;
  border-bottom: 1px solid ${p => (p.borderless ? 'transparent' : 'var(--c-border-light)')};
  padding: 0 ${p => (p.toEdge ? 0 : 16)}px;
  background: ${p => (p.transparent ? 'transparent' : 'var(--bg)')};
`;

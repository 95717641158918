import React from 'react';

const FontIcon = ({ icon, style, color, innerStyle, className, task, size = 16, invert }) => {
  const ficon = icon;

  return (
    <span
      className={`b-size ${!!task ? 'task' : ''} ${className}`}
      data-size={size}
      style={{
        ...style,
      }}
    >
      <i
        className={`icons8-${ficon}`}
        style={{
          ...innerStyle,

          fontSize: size,
        }}
      ></i>
    </span>
  );
};

export default FontIcon;

import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Scroll, Header, IRow, IAction } from 'src/web/components';
import { HEALTH_LINKS, SYNC_HEALTH } from 'src/web/data';
import { InspectorPaneProps } from '../../types';

export function HealthLinkPane({ userId, id }: InspectorPaneProps) {
  const { data: linkData } = useQuery(HEALTH_LINKS, {
    skip: !id,
    variables: {
      id: userId,
    },
  });

  const [syncHealth, { loading: syncing }] = useMutation(SYNC_HEALTH, {
    variables: {
      personId: userId,
    },
  });

  const links = [...(linkData?.viewerTwo?.existingApplicationInfo?.existingApplications || [])];
  const link = links?.filter(item => item.id === id)?.[0];

  return (
    <Scroll inspector>
      <Header
        pii
        title={`${link?.applicationIdentifier}`}
        subtitle={'Health Lookup'}
      >
        <IAction title="Sync" icon="Refresh" onPress={syncHealth} loading={syncing} />
        <IRow label="App Status" type="status" value={link?.applicationStatus} />
        <IRow label="App ID" type="mono" value={link?.applicationIdentifier} />
        <IRow label="Coverage Year" value={link?.coverageYearNumber} />
        <IRow label="State" value={link?.state} />
        <IRow label="First Name" value={link?.givenName} />
        <IRow label="Last Name" value={link?.familyName} />
      </Header>
    </Scroll>
  );
};
import React, { useState } from 'react';

import styled from 'styled-components';

import { formatRelativeDateStrict, getSourceName, isActiveNow } from 'src/web/utility';
import { IAction } from 'src/web/components';
import PathModal from '../modal/PathModal';

const SBar = styled.div`
  width: 100%;
  height: 3px;
  background: var(--c-cerise-2);
  border-radius: 999px;
  overflow: hidden;
  margin: 12px auto;

  > div {
    height: 3px;
    width: ${p => p.width * 100}%;
    background: var(--c-cerise-0);
    will-change: width;
    transition: 0.5s var(--transitionCurve);
  }
`;

export const RouteProgress = ({ route, time, routes, userId, appId, task }) => {
  const [open, setOpen] = useState(false);
  const isActive = isActiveNow(time);
  const isLive = isActive === 'Now';
  return (
    <>
      <IAction
        color={isActive && 'var(--c-cerise-0)'}
        iconColor={isActive ? 'var(--c-cerise-0)' : 'var(--c-fg-0)'}
        icon="Location"
        text={getSourceName(route)}
        title={isLive ? isActive : formatRelativeDateStrict({ date: time })?.replace('about', '')}
        onPress={() => setOpen(true)}
      />
      <SBar width={(route ? routes?.indexOf(route) : 1) / routes?.length}>
        <div className={isLive ? 'pulse' : ''} />
      </SBar>
      {open && (
        <PathModal
          task={task}
          userId={userId}
          appId={appId}
          lastRoute={route}
          isRenew={false}
          onRequestClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default RouteProgress;

import React from 'react';
import { ModalComponentProps, ModalType, useModal } from 'src/hooks/useModal';
import ConversionModal from '../conversion/ConversionModal';
import CreatePolicyModal from '../policies/CreatePolicyModal';
import { UpdatePolicyModal } from '../policies/UpdatePolicyModal';

type ModalComponents = {
  [K in ModalType]: React.ComponentType<ModalComponentProps<K>>;
};

const content: ModalComponents = {
  [ModalType.EDIT_CONVERSION]: ConversionModal,
  [ModalType.CREATE_POLICY]: CreatePolicyModal,
  [ModalType.UPDATE_POLICY]: UpdatePolicyModal,
}

export function Modals() {
  const { modals, closeModal } = useModal();

  if (modals.length === 0) return null;

  return (
    <>
      {modals.map(modal => {
        const ModalComponent = content[modal.type];

        return (
          <ModalComponent 
            key={modal.id}
            type={modal.type}
            data={modal.data}
            closeModal={() => closeModal(modal.id)}
          />
        );
      })}
    </>
  );
}
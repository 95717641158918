import React from 'react';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { Scroll } from 'src/web/components';
import { Explorer } from 'src/web/components/health/Explorer';
import { EXPLORER, REMOVE_EXPLORER_APPLICANT, REMOVE_EXPLORER_MEMBER, UPDATE_EXPLORER_APPLICANTS, UPDATE_HEALTH_PREFERENCE, UPSERT_EXPLORER, UPSERT_EXPLORER_MEMBERS, UPSERT_HEALTH_PREFERENCES } from 'src/data';

export function ExplorerPage({ userId }) {
  const { data, loading, refetch, networkStatus } = useQuery(EXPLORER, {
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
  });

  const [upsertExplorer, { loading: upsertingExplorer }] = useMutation(UPSERT_EXPLORER);
  
  const [upsertExplorerMembers, { loading: upsertingMembers }] = useMutation(UPSERT_EXPLORER_MEMBERS, {
    update: (c, r) => UPDATE_EXPLORER_APPLICANTS(c, r, userId)
  });

  const [removeExplorerMember, { loading: removingMember }] = useMutation(REMOVE_EXPLORER_MEMBER, {
    update: (c, r) => REMOVE_EXPLORER_APPLICANT(c, r, userId)
  })

  const [upsertHealthPreferences, { loading: upsertingPreferences }] = useMutation(UPSERT_HEALTH_PREFERENCES, {
    update: UPDATE_HEALTH_PREFERENCE
  });

  return (
    <Scroll loading={loading || upsertingExplorer || upsertingPreferences || upsertingMembers || removingMember}>
      <Explorer
        loading={false}
        refetch={refetch}
        refetching={networkStatus === NetworkStatus.refetch}
        explorerType="HX"
        explorer={data?.viewerTwo?.healthExplorerData}
        pref={data?.viewerTwo?.health?.healthPreference}
        upsertExplorer={input => upsertExplorer({ variables: { input, personID: userId } })}
        upsertMembers={members => upsertExplorerMembers({ variables: { members, personID: userId }})}
        removeMember={memberID => removeExplorerMember({ variables: { memberID, personID: userId }})}
        upsertHealthPreferences={input => upsertHealthPreferences({ variables: { input, personID: userId }})}
      />
    </Scroll>
  );
};
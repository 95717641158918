import React from 'react';
import styled from 'styled-components';

const StyledAppSection = styled.div`
  height: 100%;
  transform-origin: top;
`;

export default ({ children }) => {
  return <StyledAppSection>{children}</StyledAppSection>;
};

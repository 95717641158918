import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

export interface FormProps {
  onSubmit: (vals: any) => void;
  initialValues?: object;
  children: React.ReactNode;
}

export function Form({ initialValues, onSubmit, children }: FormProps) {
  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {children}
        </form>
      )}
    />
  );
}
import React from 'react';
import styled from 'styled-components';
import { FontIcon, Modal } from 'src/web/components';

const StyledInner = styled.div`
  padding: 36px 24px;
`;

const StyledText = styled.div`
  font-size: var(--h3);
  font-weight: 400;
  text-align: center;
  color: var(--c-fg-0);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    color: var(--c-fg-0);
    text-align: center;
  }
  h2 {
    font-weight: 700;
    text-align: center;
  }
  h5 {
    font-weight: 500;
    text-align: center;
    color: ${p => p.theme.subtleColor};
  }
`;

export const ExportModal = ({ title = 'Export CSV', children, onCompleted }) => {
  return (
    <Modal presentation="squareSheet" onRequestClose={onCompleted}>
      <StyledInner>
        <StyledText>
          <FontIcon icon="download" tasks noShadow size={30} color="debitColor" />
          <h2>{title}</h2>
        </StyledText>
        {children}
      </StyledInner>
    </Modal>
  );
};

export default ExportModal;

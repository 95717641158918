import React, { useState } from 'react';
import styled from 'styled-components';

import { DebugView } from 'src/web/inspectors';
import { Node, NodeType } from 'src/web/types';
import { IAction, Header, IRow } from 'src/web/components';

const COLORS: Record<NodeType, string> = {
  'Tax Payment': 'var(--c-orange-1)',
  DDA: 'var(--c-lime-1)',
  'Bank Link': 'var(--c-border-light)',
  Goal: 'var(--c-lime-1)',
  IRA: 'var(--c-teal-1)',
  Income: 'var(--c-cerise-1)',
  'Income Source': 'var(--c-cerise-1)',
  User: 'var(--c-cerise-1)',
};

const SEntity = styled.div`
  border-radius: var(--radiusLarge);
  background: var(--cCard3);
  margin: 0 0 calc(0.5 * var(--padding)) 0;
  padding: 0 calc(0.5 * var(--padding)) calc(0.5 * var(--padding));
  background: ${p => p.gradient};
`;

const SBody = styled.div`
  max-height: 64px;
  mask: linear-gradient(to bottom, #000000 50%, #00000000 100%);
  transition: var(--transition);
  overflow: hidden;
  &.open {
    max-height: 500px;
    overflow: scroll;
    mask: none;
  }
`;

const SButton = styled.div`
  border-radius: var(--radius);
  height: 32px;
  margin: auto;
  padding: 0 12px;
  display: flex;
  font-size: var(--ffp);
  cursor: var(--cursor);
  font-weight: 400;
  color: var(--cAdminAccent);
  justify-content: center;
  align-items: center;
  margin: -24px 0 2px;
  z-index: 99999999999999;
  &:hover {
    background: var(--c-border-light);
  }
`;

// @ts-nocheck
const Entity = ({
  nodeType,
  icon,
  logo,
  title,
  children,
  status,
  debugData,
  avatar,
  actions = [],
  expanded,
  headerItems,
  id,
}: Node) => {
  const [debug, setDebug] = useState(false);
  const [open, setOpen] = useState();

  const _actions = [
    ...actions,
    { id: 'VIEW_DEBUG', title: 'Debug', icon: 'Code', onPress: () => setDebug(true) },
  ];

  return (
    <>
      <SEntity gradient={COLORS[nodeType]} className="fadeInContent">
        <Header title={title} subtitle={nodeType} icon={icon} logo={logo} avatar={avatar} squareIcon>
          <SBody className={(open || expanded) && 'open'}>
            <IRow type="status" label="Status" value={status} hideUnset />
            {headerItems}
            {children}
          </SBody>
        </Header>
        {!expanded && <SButton onClick={() => setOpen(!open)}>Show {open ? 'less' : 'more'}</SButton>}
        {_actions?.map((action, idx) => (!!action ? <IAction small key={idx} {...action} /> : null))}
      </SEntity>
      <DebugView isOpen={debug} data={debugData} onClose={() => setDebug(false)} type={nodeType} id={id} />
    </>
  );
};

export default Entity;

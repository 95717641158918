import React from 'react';
import { Form, Field } from 'react-final-form';
import { Scroll, Button, Modal, StyledBottomBar, Header, Input, InputLabel } from 'src/web/components';

export const ExplorerModal = ({ healthID, setHealthID, onRequestClose }) => {

  const F = ({ name, label, ...rest }) => (
    <Field name={name}>
      {props => (
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <InputLabel style={{ width: 200 }}>{label}</InputLabel>
          <Input
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            {...rest}
          />
        </div>
      )}
    </Field>
  );

  return (
    <Modal close onRequestClose={onRequestClose}>
      <Form
        onSubmit={vals => {
          setHealthID(vals.healthID);
          onRequestClose();
        }}
        initialValues={{ healthID }}
        render={({ handleSubmit }) => (
          <>
            <Scroll transparent>
              <Header title="Add Explorer Session" />
              <F name="healthID" label="PX Explorer ID (sid)" autoFocus />
            </Scroll>
            <StyledBottomBar>
              <Button onPress={handleSubmit}>
                Update link
              </Button>
            </StyledBottomBar>
          </>
        )}
      />
    </Modal>
  );
};

export default ExplorerModal;

import { ConversionStatusType } from 'src/data';
import { IconType } from 'src/web/components';

interface IconMapping {
  [key: string]: IconType;
}

export const iconMapping: IconMapping = {
  [ConversionStatusType.Health]: 'Medical',
  [ConversionStatusType.Dental]: 'Dental',
  [ConversionStatusType.Vision]: 'Eye',
};

import React from 'react';
import { useEDEResults } from 'src/web/hooks';
import { formatCurrency } from 'src/web/utility';
import { Banner } from 'src/web/components';
import { useQuery } from '@apollo/client';
import { EDE_ENROLLMENT_ELIG } from 'src/web/data';

const EligSummary = ({ enrollment, appId, userId }) => {
  const { data, loading } = useQuery(EDE_ENROLLMENT_ELIG, {
    skip: !appId || !userId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId,
      userId: userId,
    },
  });

  const application = data?.viewerTwo?.health?.application || enrollment?.healthApplication;
  const members = [application?.applicant, ...(application?.members || [])];

  const { isDetermined, wantsDiscount, aptc, canEnroll, hasSEP } = useEDEResults({
    application: application,
    members: members,
  });

  const t =
    !!loading && !application
      ? { title: 'Loading...' }
      : !isDetermined
      ? { title: 'Eligibility pending', bg: 'var(--c-border-light)' }
      : canEnroll
      ? !wantsDiscount
        ? { title: 'Can enroll at full price', subtitle: "Didn't apply for APTC", bg: 'var(--c-medium-1)' }
        : aptc?.household > 0
        ? {
            title: `${formatCurrency({ value: aptc?.household, omitCents: true })}/mo APTC`,
            subtitle: hasSEP ? 'Can enroll' : "Can't enroll– no life event",
            bg: hasSEP ? 'var(--c-good-1)' : 'var(--c-bad-1)',
          }
        : {
            title: hasSEP ? 'Can enroll at full price' : "Can't enroll– no life event",
            subtitle: 'Not eligible for APTC',
            bg: hasSEP ? 'var(--c-medium-1)' : 'var(--c-bad-1)',
          }
      : { title: 'Cannot enroll', bg: 'var(--c-bad-1)' };

  return (
    <>
      {!!application?.eligibility?.isMedicaid && (
        <div style={{ marginTop: 2 }}>
          <Banner tiny title="Medicaid Eligible" />
        </div>
      )}
      {!!t && (
        <div style={{ marginTop: 2 }}>
          <Banner background={t?.bg} tiny title={t?.title} subtitle={t?.subtitle} />
        </div>
      )}
    </>
  );
};

export default EligSummary;

import React from 'react';
import { useMatch, useParams, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';

import { Scroll, Toolbar } from 'src/web/components';

const StyledPanel = styled.div<{ $overflow: boolean; }>`
  overflow: ${p => (!!p.$overflow ? 'visible' : 'hidden')};
  box-sizing: border-box;
  right: 0px;
  position: absolute;
  top: 0;
  left: ${p => p.left};
  transition: var(--transition);
  bottom: 0px;
  overflow: hidden;
  @media (max-width: 1024px) {
    left: calc(${p => (p.secondary ? 'var(--navSecondary)' : '0px')});
  }
`;

const StyledInnerPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${p => (!!p.inspector && !p.grid ? 'var(--inspector)' : '0px')};
  @media (max-width: 1024px) {
    right: 0;
  }
`;

const StyledHeader = styled.div`
  position: absolute;
  left: calc(-1 * var(--navSecondary));
  right: 0;
  z-index: 0;
  height: 600px;
  background-size: original;
  background-position: right -36px;
  background-repeat: no-repeat;
  opacity: ${p => p.opacity};
`;

const AppPage = ({
  title,
  subtitle,
  main,
  userId,
  onRefreshPlaid,
  locked,
  topColor,
  noPanel,
  color,
  raw,
  username,
  inspector,
  icon,
  secondary,
  supportsGrid,
  pageActions,
  ...rest
}) => {
  const url = useResolvedPath("").pathname;
  const { id } = useParams();

  return (
    <>
      <StyledPanel
        $overflow
        color={color}
        leftMobile={!noPanel ? 'var(--navHeight)' : 0}
        secondary={secondary}
        left={
          secondary
            ? 'var(--navSecondary)'
            : `calc(var(--navPrimary) + ${noPanel ? '0px' : 'var(--navSecondary)'})`
        }
      >
        <StyledHeader topColor={!!topColor ? topColor || 'transparent' : 'transparent'} />

        {!raw ? (
          <>
            <Toolbar title={title} subtitle={subtitle} icon={icon} pageActions={pageActions} />
            <StyledInnerPanel inspector={!!inspector} grid={!!supportsGrid}>
              <Scroll toolbar large>
                <div>
                  {main({
                    path: url,
                    selectedId: id,
                    userId,
                    locked,
                    onRefreshPlaid,
                    username,
                    ...rest,
                  })}
                </div>
              </Scroll>
            </StyledInnerPanel>
            {inspector}
          </>
        ) : (
          <>
            <StyledInnerPanel inspector={!!inspector} grid={!!supportsGrid}>
              {main({
                path: url,
                selectedId: id,
                userId,
                locked,
                onRefreshPlaid,
                username,
                ...rest,
              })}
            </StyledInnerPanel>
            {inspector}
          </>
        )}
      </StyledPanel>
    </>
  );
};

export default AppPage;

import React from 'react';
import { useQuery } from '@apollo/client';
import { EDE_ENROLLMENT_ALL } from 'src/web/data/tasks';
import { DebugView } from '..';

const EnrollmentDebugView = ({ eobj, e, u, onClose }) => {
  const { data } = useQuery(EDE_ENROLLMENT_ALL, {
    skip: !e,
    variables: { userId: u, input: { id: e } },
  });
  return (
    <DebugView
      type="HealthInsuranceEnrollment"
      id={e}
      isOpen={!!e}
      data={{ ...eobj, ...data }}
      onClose={onClose}
    />
  );
};

export default EnrollmentDebugView;

import { BaseInspector } from '../base';
import {
  UserOverviewPane,
  UserIdentityPane,
  UserActivityPane,
  UserGoalsPane,
  UserCoveragePane,
} from '../panes';
import { InspectorPane } from '../types';

export interface UserInspectorProps {
  userId: string;
  fullHeight?: boolean;
}

export function UserInspector({ userId, fullHeight }: UserInspectorProps) {
  return (
    <BaseInspector
      userId={userId}
      fullHeight={fullHeight}
      universalTabs={[
        { title: 'User Overview', icon: 'User', Pane: UserOverviewPane },
        { title: 'User Identity', icon: 'Fingerprint', Pane: UserIdentityPane },
        { title: 'User Activity', icon: 'Clock', Pane: UserActivityPane },
        { title: 'User Goals', icon: 'Plan', Pane: UserGoalsPane },
        { title: 'User Coverage', icon: 'Coverage', Pane: UserCoveragePane },
      ]}
    />
  );
}

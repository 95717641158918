import React from 'react';

import { IRow, Scroll, IAction, Header, RowGroup } from 'src/web/components';
import { useUserSummary } from 'src/web/hooks';
import { InspectorPaneProps } from '../../types';
import { DateFormat } from 'src/utils';

export function UserOverviewPane({ userId }: InspectorPaneProps) {
  const user = useUserSummary({ id: userId });
  
  const {
    name,
    avatar,
    joinDate,
    jobTitle,
    joinCode,
    email,
    phone,
    zip,
    state,
    urlIntercom,
    urlCustomer,
    urlLogrocket,
    urlOpenphone,
    urlUnit,
    urlMixpanel,
    initials,
    bankPartnerId,
    signupContext,
    tier,
    ipAddress,
    urlAbound,
    urlCastle,
  } = user;

  const externalTools = [
    {
      id: 'unit',
      name: 'Unit',
      logo: '/img/products/unit.png',
      onPress: () => window.open(urlUnit, '_blank'),
      key: 'u',
    },
    {
      id: 'intercom',
      name: 'Intercom',
      logo: '/img/products/inter.png',
      onPress: () => window.open(urlIntercom, '_blank'),
      key: 'i',
    },
    {
      id: 'mixpanel',
      name: 'Mixpanel',
      logo: '/img/products/mixpanel.png',
      onPress: () => window.open(urlMixpanel, '_blank'),
      key: 'm',
    },
    {
      id: 'customer',
      name: 'Customer.io',
      logo: '/img/products/customer.png',
      onPress: () => window.open(urlCustomer, '_blank'),
      key: 'c',
    },
    {
      id: 'logrocket',
      name: 'LogRocket',
      logo: '/img/products/logrocket.png',
      onPress: () => window.open(urlLogrocket, '_blank'),
      key: 'l',
    },
    {
      id: 'openphone',
      name: 'OpenPhone',
      logo: '/img/products/openphone.png',
      onPress: () => window.open(urlOpenphone, '_blank'),
      key: 'p',
    },
    {
      id: 'abound',
      name: 'Abound',
      logo: '/img/products/abound.png',
      onPress: () => window.open(urlAbound, '_blank'),
      key: 'a',
    },
    {
      id: 'castle',
      name: 'Castle',
      logo: '/img/products/castle.png',
      onPress: () => window.open(urlCastle, '_blank'),
      key: 'k',
    },
  ];

  return (
    <Scroll inspector>
      {/* <RowGroup title="Actions">
        <SRow text="Change Name" icon="Edit" />
        <SRow text="Enabled" kb="L" accessory={<Switch />} icon="Lock" />
        <SRow text="Trading" kb="L" accessory={<Switch />} icon="Pause" />
        <SRow text="Tier" kb="L" accessory={tier} icon="Tag" />
        <SRow text="Bookmark" kb="L" accessory={<Switch />} icon="Bookmark" />
        <SRow text="Prevent Lock" kb="L" accessory={<Switch />} icon="Lock" destructive />
        <SRow text="Close Account" icon="Delete" destructive />
        <SRow text="Sync EDE" icon="Refresh" />
        <SRow text="Goto User" icon="Bookmark" />
        <SRow text="Debug" icon="Code" />
      </RowGroup> */}
      <Header title={name} subtitle="User" avatar={avatar} initials={initials}>
        <IRow label="Catch ID" type="mono" value={userId} />
        <IRow label="Bank ID" type="mono" value={bankPartnerId} />
        <IRow label="Tier" value={tier} type="tier" />
        <br />
        <IRow label="Email" value={email} />
        <IRow label="Phone" value={phone} />
        <IRow label="ZIP" value={zip} />
        <IRow label="State" value={state} />
        <IRow label="Job Title" value={jobTitle} />
        <br />
        <IRow label="Signup Date" type="date" value={joinDate} format={DateFormat.LONG} />
        <IRow label="Signup Code" type="mono" value={joinCode} />
        <IRow label="Signup Context" type="enum" value={signupContext} />
        <IRow label="IP Address" type="mono" value={ipAddress} />
      </Header>

      <RowGroup title="Open External" borderless>
        {externalTools?.map((action, idx) =>
          !!action ? <IAction small key={idx} shortcut={['o', action?.key]} {...action} /> : null,
        )}
      </RowGroup>
    </Scroll>
  );
};

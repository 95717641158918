import React, { useMemo } from 'react';
import styled from 'styled-components';

import { I, IconType } from 'src/web/components';
import { formatDate, DateFormat } from 'src/utils';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { eventConfig } from './config';
import { ActivityEventFragment } from 'src/data';

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: var(--cursor);
  border-radius: var(--radius);
  padding: 6px 12px 6px 12px;
  margin-left: -6px;
  margin-right: -6px;

  background: ${p => (p.selected ? 'var(--cSkeleton)' : '')};

  &:hover {
    background-color: ${p => (p.selected ? 'var(--fga)' : !p.header || !!p.caret ? 'var(--fga)' : '')};
    z-index: 999;
    .StyledDateHover {
      opacity: 1;
    }
  }
`;

const StyledGroup = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDate = styled.div`
  font-weight: 500;
  text-align: right;
  font-size: var(--ffp);
  color: var(--c-fg-0);
`;

const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const StyledLabel = styled.div`
  font-size: var(--fp);
`;

const StyledSublabel = styled.div`
  opacity: 0.5;
  font-weight: 400;
  font-size: var(--ffp);
`;

export interface EventRowProps {
  event: ActivityEventFragment;
}

interface EventConfig {
  icon: IconType;
  label: string;
  sublabel: string;
}

export function EventRow({ event }: EventRowProps) {
  const { selectedId, setSelected } = useInspector();

  const { icon, label, sublabel }: EventConfig = useMemo(() => {
    const config = eventConfig[event.eventType] || {};

    return {
      icon: 'Caret',
      label: config.getLabel ? config.getLabel(event) : event.label,
      sublabel: config.getSublabel ? config.getSublabel(event) : '',
    };
  }, [event]);

  return (
    <StyledRow
      selected={selectedId === event.id}
      onClick={() => setSelected(event.id, { type: SelectedType.EVENT })}
    >
      <StyledGroup>
        <StyledIcon>
          <I name={icon} size={20} color="var(--c-fg-0)" />
        </StyledIcon>
        <div>
          {!!label && <StyledLabel>{event.label}</StyledLabel>}
          {!!sublabel && <StyledSublabel>{sublabel}</StyledSublabel>}
        </div>
      </StyledGroup>
      <StyledDate className="tabular">{formatDate(event.createdOn, DateFormat.SHORT)}</StyledDate>
    </StyledRow>
  );
}

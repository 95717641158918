import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { Scroll, Header, Redact, IRow, IAction } from 'src/web/components';
import { UPLOADS } from 'src/web/data';

import { KACTIONS } from 'src/web/kbar';
import { InspectorPaneProps } from '../../types';
import { DateFormat } from 'src/utils';

const StyledTile = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 5px 25px rgba(6, 7, 10, 0.15);
  margin: auto;
  border-radius: 12px;
`;

export function UploadPane({ id, userId }: InspectorPaneProps) {
  const { data } = useQuery(UPLOADS, {
    variables: { id: userId },
    skip: !userId,
  });

  const selected = data?.viewerTwo?.documentUploads?.filter((doc, idx) => doc?.id === id)?.[0];

  return !id ? (
    <div className="full">Nothing selected</div>
  ) : !selected ? (
    <div className="full">Too new for details ¯\_(ツ)_/¯</div>
  ) : (
    <Scroll padded inspector>
      <Header
        title={(selected?.type || selected?.documentType)?.replace('PHOTOID', 'Photo ID')?.toProperCase()}
        subtitle={selected?.fileExt || selected?.ext}
      />

      {selected?.url && (
        <Redact blur inline>
          <StyledTile>
            <StyledImage src={selected?.url} />
          </StyledTile>
        </Redact>
      )}
      <IRow label="Document ID" type="mono" value={selected?.id} />
      <IRow label="Document Type" type="mono" value={selected?.documentType} />
      <IRow label="Created On" type="date" format={DateFormat.LONG} value={selected?.createdOn} />
      <IRow type="enum" label="Provider(s)" value={selected?.provider?.join(', ')} />
      <br />
      {selected?.url && (
        <IAction {...KACTIONS.DOWNLOAD_FILE} onPress={() => window.open(selected?.url, '_blank')} />
      )}
    </Scroll>
  );
};
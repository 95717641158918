import { HEALTH_LOOKUP, FETCH_ELIG } from './../mutations/ede';
import client from 'src/lib/apollo';
import { SUBMIT_HEALTH_ENROLLMENT, SYNC_HEALTH, UPSERT_LAST_ROUTE } from '../mutations';
import { ENSURE_HEALTH, IMPORT_ALL } from '../mutations/ede';
import { toast } from 'react-hot-toast';

export const lockUser = ({ userId, lock, notes }) => {
  const promise = client.mutate({
    mutation: LOCK_USER,
    refetchQueries: ['SessionInputs', 'SessionInputs2', 'UseUserInformation', 'UseAccountStatus'],
    variables: {
      personId: userId,
      input: {
        userAccountStatus: lock ? 'LOCKED' : 'READY',
        preventIdentityLock: 'NO',
        notes,
      },
    },
  });
  toast.promise(
    promise,
    lock
      ? {
          loading: 'Locking...',
          success: 'Locked',
          error: 'Possible error when locking; please refresh',
        }
      : {
          loading: 'Unlocking...',
          success: 'Unlocked',
          error: 'Possible error when unlocking; please refresh',
        },
  );
};

export const importAll = ({ userId }) => {
  const promise = client
    .mutate({
      mutation: IMPORT_ALL,
      refetchQueries: ['HealthPageNew'],
      variables: {
        personId: userId,
      },
    })
    .then(e => {
      const errors = e?.data?.importAllExistingApplications?.errors;
      if (errors && errors?.length > 0) alert(`Error ${errors?.[0]?.code}: ${errors?.[0]?.path}`);
    });
  toast.promise(promise, {
    loading: 'Importing...',
    success: 'Imported',
    error: 'Error importing',
  });
};

export const syncHealth = ({ userId, forceLink = false, forcePermissions = false }) => {
  if (!userId) console.log('Cannot sync without user id');
  else {
    console.log('Calling health sync...');
    const promise = client
      .mutate({
        awaitRefetchQueries: true,
        mutation: SYNC_HEALTH,
        refetchQueries: ['HealthPageNew', 'HealthEnrollmentBoardSingleFast', 'HealthEnrollments'],
        variables: {
          personId: userId,
          forceLink,
          forcePermissions,
        },
      })
      .then(e => {
        const errors = e?.data?.syncHealth?.errors;
        if (errors && errors?.length > 0) alert(`Error ${errors?.[0]?.code}: ${errors?.[0]?.path}`);
      });

    toast.promise(promise, {
      loading: 'Syncing...',
      success: 'Synced',
      error: 'Possible error during sync',
    });
  }
};

export const ensureHealth = ({ userId, appId }) => {
  if (!userId || !appId) console.log('Cannot ensure without user id');
  const promise = client
    .mutate({
      mutation: ENSURE_HEALTH,
      refetchQueries: ['HealthPageNew', 'HealthEnrollmentBoardSingleFast'],
      errorPolicy: 'ignore',
      variables: {
        appId: appId,
        personId: userId,
      },
    })
    .then(e => {
      const errors = e?.data?.ensureHealthApplication?.errors;
      if (errors && errors?.length > 0) alert(`Error ${errors?.[0]?.code}: ${errors?.[0]?.path}`);
    });
  toast.promise(promise, {
    loading: 'Ensuring...',
    success: 'Ensured',
    error: 'Error ensuring',
  });
};

export const lookupApplications = ({ userId }) => {
  const promise = client
    .mutate({
      mutation: HEALTH_LOOKUP,
      errorPolicy: 'ignore',
      variables: {
        personId: userId,
      },
    })
    .then(e => {
      const errors = e?.data?.importAllExistingApplications?.errors;
      if (errors && errors?.length > 0) alert(`Error ${errors?.[0]?.code}: ${errors?.[0]?.path}`);
      else {
        client.mutate({
          mutation: IMPORT_ALL,
          refetchQueries: ['HealthPageNew'],
          variables: {
            personId: userId,
          },
        });
      }
    });
  toast.promise(promise, {
    loading: 'Searching...',
    success: 'Found applications to link',
    error: 'Error searching',
  });
};

export const setLastRoute = ({ userId, appId, route }) => {
  const date = new Date();
  const promise = client.mutate({
    mutation: UPSERT_LAST_ROUTE,
    errorPolicy: 'ignore',
    variables: {
      personId: userId,
      input: {
        id: appId,
        lastUsedRoute: route,
        lastUserUpdate: date?.toISOString(),
        lastUpdated: date?.toISOString(),
      },
    },
  });

  toast.promise(promise, {
    loading: 'Setting...',
    success: 'Route set',
    error: 'Error setting route',
  });
};

export const submitEnrollment = ({ userId, appId }) => {
  console.log('Submitting enrollment...');
  const promise = client
    .mutate({
      mutation: SUBMIT_HEALTH_ENROLLMENT,
      refetchQueries: ['HealthEnrollment', 'Enrollment', 'HealthPageNew', 'HealthEnrollmentBoardSingleFast'],
      errorPolicy: 'ignore',
      variables: {
        id: appId,
        personId: userId,
      },
    })
    .then(e => {
      const _d = e?.data?.submitEnrollment;
      const error = _d?.error;
      if (error) {
        console.error(`Error ${error?.code}: ${error?.field}: ${error?.message}`);
      } else {
        console.log({ submitEnrollment: _d });
        setLastRoute({ appId, route: 'success', userId });
      }
    })
    .catch(e => console.error(e));
  toast.promise(promise, {
    loading: 'Enrolling...',
    success: 'Enrolled',
    error: 'Error submitting',
  });
};

export const fetchEligibility = ({ userId, appId, submit, forceRecreation }) => {
  console.log('Refetching eligibility...');

  const promise = client
    .mutate({
      mutation: FETCH_ELIG,
      refetchQueries: !!submit ? [] : ['HealthApplicationEligibility'],
      errorPolicy: 'ignore',
      variables: {
        personId: userId,
        appId: appId,
        forceNewEnrollmentGroups: forceRecreation,
      },
    })
    .then(e => {
      const errors = e?.errors;
      const hasData = e?.data?.fetchEligibility?.[0]?.aptcEligibility;
      if (!hasData && errors?.length > 0) {
        console.error(`Error ${errors?.[0]?.code}: ${errors?.[0]?.path}`);
      } else {
        console.log({ fetchElig: e?.data?.fetchEligibility });
        setLastRoute({ appId, route: 'results', userId });
        if (submit) submitEnrollment({ userId, appId });
      }
    })
    .catch(e => console.error(e));

  toast.promise(promise, {
    loading: 'Fetching eligibility...',
    success: 'Eligibility refetched',
    error: 'Error refetching',
  });
};

export const fetchEligibilityAndResubmit = ({ userId, appId }) => {
  console.log('Starting refetch/resubmit process...');
  fetchEligibility({ userId, appId, submit: true, forceRecreation: true });
};

import { GoalCard, Scroll, MenuDivider, Spinner } from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { GET_BANK_LINKS, useFintechQuery } from 'src/data';

export function LinkedAccountsPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();

  const { loading, data } = useFintechQuery(GET_BANK_LINKS, {
    variables: { customerID: userId },
    skip: !userId,
  });

  const bankLinks = data?.customer?.bankLink || [];

  return (
    <Scroll loading={loading}>
      {loading ? (
        <div className="full">
          <Spinner loading />
        </div>
      ) : bankLinks.length === 0 ? (
        <div className="full">No linked accounts</div>
      ) : (
        bankLinks.map(bankLink => (
          <>
            <GoalCard
              key={bankLink?.id}
              compact={inInspector}
              large
              borderless
              fillLogo
              name={'Institution Name'}
              selected={selectedId === bankLink.id}
              status={bankLink.status}
              onPress={() => setSelected(bankLink?.id, { type: SelectedType.BANK_LINK })}
              logo="/img/plaid.png"
            />
            {bankLink.linkedAccounts?.map(account => (
              <GoalCard
                key={account?.id}
                pii
                labelPii
                right
                logo="/img/plaid.png"
                status={bankLink.status}
                selected={selectedId === bankLink.id}
                name={account.name}
                subtitle={account.accountMask}
                small
                type="currency"
                onPress={() => setSelected(bankLink?.id, { type: SelectedType.BANK_LINK })}
              />
            ))}
            <MenuDivider invert />
          </>
        ))
      )}
    </Scroll>
  );
}

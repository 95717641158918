import React from 'react';

import { makeName } from 'src/web/utility';
import { TaxPaymentNode } from 'src/web/inspectors';
import { Scroll, Header } from 'src/web/components';
import { InspectorPaneProps } from '../../types';
import { BankAccountNode } from 'src/components/nodes';

export function TaxPaymentPane({ id, userId }: InspectorPaneProps) {
  const data = {};

  const payment = data?.viewerTwo?.taxPayment;
  const person = data?.viewer?.user;

  return (
    <>
      <Scroll inspector>
        <Header pii title={makeName(person)} subtitle="Payment" />
        <>
          {!!id && !!userId && (
            <>
              <TaxPaymentNode paymentId={payment?.id} uid={userId} />
              <BankAccountNode customerID={userId} />
            </>
          )}
        </>
      </Scroll>
    </>
  );
}

import React from 'react';

export default function Logo() {
  return (
    <div className="flex-h no-mobile" style={{ alignItems: 'flex-start' }}>
      <img
        src={`/icons/logo_dark.png`}
        width={24}
        height={24}
        style={{ marginRight: 12, filter: 'var(--filter)' }}
      />

      <div className="grow" />
    </div>
  );
}

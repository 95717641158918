import React from 'react';
import { Banner, Spinner } from 'src/web/components';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  padding-top: 32px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 100;
  background-color: #FFFFFFAA;
  width: calc(100% + 48px);
  height: 100%;
  padding-top: 150px;
  margin-left: -24px;
  margin-right: -24px;
`;

export interface SearchResultsProps {
  searching: boolean;
  results: Array<any>;
  addWildcard: (name: string) => void;
  renderItem: (result: any) => React.ReactNode;
  searchTerm?: string;
}

export function SearchResults({ searching, results, renderItem, addWildcard, searchTerm }: SearchResultsProps) {
  return (
    <Container>
      {searching && (
        <LoadingOverlay>
          <Spinner loading />
        </LoadingOverlay>
      )}
      <div>
        {searchTerm && (
          <>
            <Banner
              title="Don't see correct results?"
              subtitle={`Add "${searchTerm}"`}
              onPress={() => addWildcard(searchTerm)}
            />
            <br />
          </>
        )}



        {results.map(result => renderItem(result))}
      </div>
    </Container>
  );
}

import { format } from 'date-fns';

export enum DateFormat {
  TIME = 'hh:mmaaaaa',
  SHORTER = 'MMM d',
  SHORT = 'MMM dd',
  MEDIUM = 'MMM dd, yyyy',
  LONG = 'MMM dd, yyyy  hh:mmaaaaa',
  FULL = 'MMM dd, yyyy  hh:mm aa',
  ISO = 'yyyy-MM-dd',
  HUMAN = 'eeee, MMMM dd, yyyy',
  MONTH_HUMAN = 'MMMM yyyy',
  MONTH = 'yyyy-MM',
}

export function formatDate(date: string, dateFormat = DateFormat.MEDIUM): string {
  return date ? format(new Date(date), dateFormat) : '';
}

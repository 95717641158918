import styled from 'styled-components';
import { Field as ReactFinalField } from 'react-final-form';
import { Input, Select, InputLabel, Switch } from 'src/web/components';
import { ZipcodeInput } from './inputs/ZipcodeInput';
import { formatDate, parseDate } from 'src/_utils/format';

export type FieldType =  'dropdown' | 'text' | 'amount' | 'zip' | 'number' | 'switch' | 'date' | 'email';

export interface FieldProps {
  type: FieldType;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Array<{ label: string; value: string | number }>;

  // display
  stack?: boolean;
}

const Container = styled.div<{ $stack: boolean }>`
  display: flex;
  margin-bottom: 8px;
  flex-direction: ${({ $stack }) => $stack ? 'column' : 'row'};
`;

const Align = styled.div`
  display: flex;
  align-items: center;
`;

const placeholders: Record<FieldType, string> = {
  dropdown: '',
  text: '',
  email: '',
  number: '',
  amount: '$',
  zip: '00000',
  switch: '',
  date: 'MM/DD/YYYY',
}

const formatters: Record<FieldType, (val: any) => any> = {
  dropdown: val => val,
  text: val => val,
  email: val => val,
  amount: val => val ? `$ ${val.toLocaleString()}` : '',
  zip: val => val,
  number: val => `${val}`,
  switch: val => val,
  date: formatDate,
}

const parsers: Record<FieldType, (val: any) => any> = {
  dropdown: val => val,
  text: val => val,
  email: val => val,
  switch: val => val,
  amount: val => val?.replace(/[^\d]/g, '') ? parseFloat(val?.replace(/[^\d]/g, '')) : '',
  zip: (val = '') => val?.replace(/[^\d]/g, '')?.substring(0, 5),
  number: val => val?.replace(/[^\d]/g, '') ? parseInt(val?.replace(/[^\d]/g, '')) : '',
  date: parseDate,
}

export function Field({ type, name, label, autoFocus, options, placeholder, stack }: FieldProps) {

  const getField = (props) => {
    const format = formatters[type];
    const parse = parsers[type];
    const ph = placeholder || placeholders[type];

    switch(type) {
      case 'dropdown':
        return (
          <Select
            placeholder={ph}
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            autoFocus={autoFocus}
            options={options}
          />
        );
      case 'zip':
        return (
          <ZipcodeInput
            placeholder={ph}
            name={props.input.name}
            value={format(props.input.value)}
            onChange={e => props.input.onChange(parse(e.target.value))}
            autoFocus={autoFocus}
          />
        )
      case 'switch':
        return (
          <Align>
            <Switch value={props.input.value} onChange={props.input.onChange} />
          </Align>
        );
      case 'text':
      default:
        return (
          <Input
            placeholder={ph}
            name={props.input.name}
            value={format(props.input.value)}
            onChange={e => props.input.onChange(parse(e.target.value))}
            autoFocus={autoFocus}
          />
        );
    }
  }


  return (
    <ReactFinalField key={name} name={name}>
      {props => (
        <Container $stack={stack}>
          <InputLabel style={{ minWidth: 200, padding: '12px 0px' }}>{label}</InputLabel>
          {getField(props)}
        </Container>
      )}
    </ReactFinalField>
  );
}
import React, { useMemo } from 'react';
import { EnrollmentPathway } from "src/web/types";
import IRow from "../IRow";
import { MenuDivider, MenuItem } from "../Menu";
import { Env, capitalize } from 'src/_utils';
import { forceCapitalize, formatEnum, propercase } from 'src/_utils/format';
import { gql, useMutation } from '@apollo/client';
import { publicClient } from 'src/lib/apollo';
import IAction from '../IAction';
import { useEdit } from 'src/components/useEdit';
import { ExplorerQuery, FinancialPreferenceType, HealthExplorerDataInput, HealthExplorerDependentInput, HealthPreferenceInput, HealthSortWeightType, PublicExplorerQuery, PublicHealthExplorerDataInput, Relation, Sex } from 'src/data';
import { useToggle } from 'src/hooks/useToggle';
import { ProvidersModal } from 'src/components/ProvidersModal';
import { RXModal } from 'src/components/RXModal';

export interface ExplorerProps {
  loading: boolean;
  refetch: () => void;
  refetching: boolean;
  explorerType: 'PX' | 'HX';
  explorer?: ExplorerQuery['viewerTwo']['healthExplorerData'] | PublicExplorerQuery['getPublicHealthExplorerData'];
  pref?: Omit<ExplorerQuery['viewerTwo']['health']['healthPreference'], 'ownerID'>;
  upsertExplorer: (vals: HealthExplorerDataInput | PublicHealthExplorerDataInput) => void;
  upsertMembers: (members: Array<HealthExplorerDependentInput>) => void;
  removeMember: (memberID: string) => void;
  upsertHealthPreferences: (vals: HealthPreferenceInput) => void;
}

export const UPSERT_SESSION = gql`
  mutation UpsertSession($input: PublicHealthExplorerDataInput!) {
    upsertPublicHealthExplorerData(input: $input) {
      id
    }
  }
`;

export function Explorer({
  refetch,
  refetching,
  explorerType,
  explorer,
  pref,
  upsertExplorer,
  upsertMembers,
  removeMember,
  upsertHealthPreferences,
}: ExplorerProps) {
  const { edit } = useEdit();
  const providerToggle = useToggle();
  const drugToggle = useToggle();

  const { plan, members, providers, drugs } = useMemo(() => {
    const { providerPlanID, healthPlans } = explorer || {};

    const members = explorer?.__typename === 'PublicHealthExplorerData' ? explorer?.deps : explorer?.dependents;

    return {
      plan:  (healthPlans?.plans?.find(p => p.healthPlan.planID === providerPlanID))?.healthPlan,
      members: members || [],
      providers: pref?.providersWanted || [],
      drugs: pref?.drugsWanted || [],
    };
  }, [explorer, pref]);

  const goToPX = (id: string) => {
    const root = Env.isProd ? 'https://catch.co' : 'https://devsite.catch.co';
    const selectedPlan = explorer?.providerPlanID ?  `&planId=${explorer?.providerPlanID}` : '';
    window.open(`${root}/explorer/plans?sid=${id}${selectedPlan}`, '_blank');
  }

  const [create, { loading: creating }] = useMutation(UPSERT_SESSION, {
    client: publicClient,
    onCompleted: (data) => {
      goToPX(data?.upsertPublicHealthExplorerData?.id);
    }
  });

  const createMatchingPXSession = () => {
    if (explorer) {
      create({
        variables: {
          input: {
            providerPlanID: explorer.providerPlanID,
            zipcode: explorer.zipcode,
            coverageYear: explorer.coverageYear,
            countyfips: explorer.countyfips,
            state: explorer.state,
            income: explorer.income,
            pathwayType: explorer.pathwayType,
            financialPreference: pref?.financialPreference,
            plannedUsage: pref?.plannedUsage,
            providersWanted: pref?.providersWanted?.map(({ __typename, id, ...data }) => data),
            drugsWanted: pref?.drugsWanted?.map(({ __typename, id, ...data }) => data),
            dependents: members?.map(({ __typename, id, ...data }) => data),
          },
        }
      })
    }
  };

  const editProviders = (providers) => {
    upsertHealthPreferences({
      providersWanted: providers.map(provider => ({
        name: provider.name,
        npi: provider.id
      }))
    })
  };

  const editDrugs = (drugs) => {
    upsertHealthPreferences({
      drugsWanted: drugs.map(drug => ({
        name: drug.name,
        rxcui: drug.id
      }))
    })
  };

  const addMember = (member?: any) => {
    edit({
      title: member ? 'Edit Member' : 'Add Member',
      initialValues: {
        id: member?.id,
        relation: member?.relation || Relation.Self,
        age: member?.age,
        sex: member?.sex,
        isSmoker: member?.isSmoker,
      },
      fields: [
        { 
          type: 'dropdown',
          name: 'relation',
          label: 'Relation', 
          placeholder: 'Select relation',
          required: true,
          options: [
            { label: 'Self', value: Relation.Self },
            { label: 'Spouse', value: Relation.Spouse },
            { label: 'Child', value: Relation.Child },
          ],
        },
        {
          type: 'number',
          name: 'age',
          label: 'Age',
          placeholder: 'Age (in years)',
          required: true,
        },
        { 
          type: 'dropdown',
          name: 'sex',
          label: 'Sex',
          placeholder: 'Select sex',
          required: true,
          options: Object.values(Sex).map(s => ({
            label: propercase(s),
            value: s
          }))
        },
        {
          type: 'switch',
          name: 'isSmoker',
          label: 'Smoker',
        }
      ],
      onSubmit: (vals) => {
        if (member) {
          // if member already exists, update them in the dependents array
          const updated = members.reduce((acc, existing) => {
            return [...acc, member.id === existing.id ? vals : existing];
          }, []);

          upsertMembers(updated);
        } else {
          // otherwise, add to dependents
          const updated = [...members, vals];
          upsertMembers(updated);
        }
      },
    })
  };

  const editPreferences = () => {
    edit({
      title: 'Edit Preferences',
      initialValues: {
        financialPreference: pref?.financialPreference,
        plannedUsage: pref?.plannedUsage,
      },
      fields: [
        {
          type: 'dropdown',
          name: 'financialPreference',
          label: 'Financial Preference',
          options: Object.values(FinancialPreferenceType).map(t => ({
            label: formatEnum(t),
            value: t
          }))
        },
        {
          type: 'dropdown',
          name: 'plannedUsage',
          label: 'Planned Usage',
          options: Object.values(HealthSortWeightType).map(t => ({
            label: formatEnum(t, 'REC_'),
            value: t
          }))
        },
      ],
      onSubmit: (vals) => {
        upsertHealthPreferences({
          financialPreference: vals?.financialPreference,
          plannedUsage: vals?.plannedUsage,
        })
      }
    })
  }

  const editExplorer = () => {
    edit({
      initialValues: {
        coverageYear: explorer?.coverageYear,
        zipcode: explorer?.zipcode,
        countyfips: explorer?.countyfips,
        state: explorer?.state,
        pathwayType: explorer?.pathwayType,
        income: explorer?.income,
      },
      fields: [
        { type: 'dropdown', placeholder: "Select coverage year", name: 'coverageYear', label: 'Coverage Year', required: true, autoFocus: true, options: [{ label: '2024', value: 2024}] },
        { type: 'zip', name: 'zipcode', label: 'Zip Code', required: true, },
        { type: 'amount', name: 'income', label: 'Estimated Income' },
      ],
      onSubmit: (vals) => {
        upsertExplorer({
          coverageYear: vals.coverageYear ? parseInt(vals.coverageYear) : undefined,
          zipcode: vals.zipcode,
          countyfips: vals.countyfips,
          state: vals.state,
          pathwayType: vals.pathwayType,
          income: vals.income,
        });
      }
    });
  }

  return (
    <>
      {explorerType === 'HX' ? (
        <IAction
          disabled={!explorer}
          icon="External"
          iconColor={'var(--c-fg-0)'}
          title="Create PX Lookalike"
          onPress={createMatchingPXSession}
          text={creating ? 'Loading...' : ''}
        />
      ) : (
        <IAction
          disabled={!explorer}
          icon="External"
          iconColor={'var(--c-fg-0)'}
          title="View in PX"
          onPress={() => goToPX(explorer.id)}
        />
      )}
      <IAction
        icon="Refresh"
        iconColor={'var(--c-fg-0)'}
        title="Refresh"
        onPress={() => refetch()}
        text={refetching ? 'Refreshing...' : ''}
      />
      <MenuDivider invert />
      
      <IRow label="ID" value={explorer?.id} />
      <IRow label="Explorer Type" value={explorerType} />
      <MenuDivider invert />
      <IRow label="Coverage Year" value={explorer?.coverageYear} onPress={editExplorer} actionType="edit" />
      <IRow label="Pathway" value={explorer?.pathwayType} />
      <IRow label="State" type="state" value={explorer?.state} />
      <IRow label="Zip" value={explorer?.zipcode} onPress={editExplorer} actionType="edit" />
      <IRow label="County FIPS" value={explorer?.countyfips} />
      <IRow label="Income" type="currency"  value={explorer?.income} onPress={editExplorer} actionType="edit" />

      {members?.map( (m, idx) => (
        <IRow 
          key={m.id} 
          label={idx === 0 ? 'Members' : ' '}
          value={`${m.age}${m.sex ? m.sex?.[0] : ''} ${forceCapitalize(m.relation)} ${m.isSmoker ? '(Smoker)' : ''}`}
          actionType="menu"
          menuPlacement="bottomRight"
          menu={
            <>
              <MenuItem text="Edit" onPress={() => addMember(m)} />
              {explorerType ==='HX' && (<MenuItem text="Remove" onPress={() => removeMember(m?.id)} />)}
            </>
          }
        />
      ))}

      <IRow 
        label={members?.length > 0 ? ' ' : 'Members'}
        placeholder="Add Member"
        onPress={() => addMember()}
        actionType="plus"
        value=""
      />

      <MenuDivider invert />
      <IRow label="Plan ID" value={plan?.planID || 'Not selected'} />
      <IRow label="Plan Name" value={plan?.name} />
      <IRow label="Carrier" value={plan?.issuer?.name}  />
      <IRow label="Plan Type" value={plan?.type} />
      <IRow label="Metal Level" value={capitalize(plan?.metalLevel?.split('_').join(' '))}  />
      <IRow label="Premium" mono  value={plan?.premium ? `$${plan?.premium}` : ''} />
      <IRow label="After Credit" mono value={plan?.premiumWithCredit ? `$${plan?.premiumWithCredit}` : ''} />
      <IRow label="Plan Brochure" value={plan?.benefitsURL} linkText="View" />
      <MenuDivider invert />

      <IRow label="Financial" value={pref?.financialPreference} type="enum" actionType="edit" onPress={editPreferences} />
      <IRow label="Usage" value={pref?.plannedUsage} type="enum" actionType="edit" onPress={editPreferences} />
      {providers?.length === 0 ? (
        <IRow label="Providers" actionType="plus" onPress={providerToggle.open} value="" />
      ) : providers?.map((provider, i) => (
        <IRow label={i == 0 ? 'Providers' : ' '} value={`${provider.name} (${provider.npi})`} actionType="edit" onPress={providerToggle.open} />
      ))}

      {drugs?.length === 0 ? (
        <IRow label="Drugs" actionType="plus" onPress={drugToggle.open} value="" />
      ) : drugs?.map((drug, i) => (
        <IRow label={i === 0 ? 'Drugs' : ' '} value={`${drug.name} (${drug.rxcui})`} actionType="edit" onPress={drugToggle.open} />
      ))}

      <ProvidersModal
        isOpen={providerToggle.isOpen}
        onRequestClose={providerToggle.close}
        providers={providers}
        zip={explorer?.zipcode}
        editProviders={editProviders}
      />
      <RXModal
        isOpen={drugToggle.isOpen}
        onRequestClose={drugToggle.close}
        zip={explorer?.zipcode}
        drugs={drugs}
        editDrugs={editDrugs}
      />
    </>
  );
}
import React, { useState } from 'react';
import styled from 'styled-components';

const SSwitch = styled.div`
  width: 32px;
  height: 20px;
  border-radius: 20px;
  background: ${p => (p.on ? 'var(--c-fg-0)' : 'var(--c-border)')};
  position: relative;
  &:hover {
    background: ${p => (p.on ? 'var(--c-fg-0)' : 'var(--c-fg-2)')};
  }
`;

const SSwitchHandle = styled.div`
  position: absolute;
  top: 2px;
  bottom: 2px;
  width: 16px;
  left: 0px;
  transform: translateX(${p => (p.on ? '14px' : '2px')});
  will-change: transform, background;
  background: white;
  box-shadow: var(--shadow);
  border-radius: 20px;
  transition: var(--transition);
`;

const Switch = ({ value = false, onChange }) => {
  return (
    <SSwitch on={value} onClick={() => onChange(!value)}>
      <SSwitchHandle on={value}> </SSwitchHandle>
    </SSwitch>
  );
};

export default Switch;

import { gql } from '../generated';

export const UPSERT_HEALTH_PREFERENCES = gql(`
  mutation UpsertHealthPreference($input: HealthPreferenceInput!, $personID: ID!) {
    upsertHealthPreference(input: $input, personID: $personID) {
      ownerID
      financialPreference
      plannedUsage
      drugsWanted {
        ...Drug
      }
      providersWanted {
        ...Provider
      }
    }
  }
`);

import { IRow, Entity } from 'src/web/components';
import { GET_BANK_ACCOUNT, useFintechQuery } from 'src/data';

export const BankAccountNode = ({ customerID }) => {
  const { data } = useFintechQuery(GET_BANK_ACCOUNT, {
    variables: { customerID },
    skip: !customerID,
  });

  return (
    <Entity
      nodeType="DDA"
      title="Unit"
      status={data?.customer?.bankApplication?.status}
      logo={`/img/products/unit.png`}
      actions={[
        {
          pretitle: 'go to',
          name: 'Unit',
          logo: `/img/products/unit.png`,
          // onPress: () => window.open(urlUnit, '_blank'),
        },
      ]}
    >
      {/* <IRow label="Unit ID" type="mono" value="@todo" /> */}
      <IRow label="Account #" type="mono" value={data?.customer?.bankAccount?.accountMask} />
      <IRow type="currency" label="Total Balance" value={data?.customer?.bankAccount?.totalBalance} />
      <IRow type="currency" label="Pending Balance" value={data?.customer?.bankAccount?.pendingBalance} />
      <IRow type="currency" label="Available Balance" value={data?.customer?.bankAccount?.availableBalance} />
      {/* <IRow label="Routing #" type="mono" value={routingNumber} /> */}
    </Entity>
  );
};

import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { DISABLE_USER, FETCH_DISABLE_USER_STATUS } from 'src/web/data';
import { useUserSummary } from 'src/web/hooks';
import {
  Scroll,
  Button,
  Modal,
  Input,
  StyledBottomBar,
  InputLabel,
  Header,
  IRow,
  MenuItem,
} from 'src/web/components';

export const CloseAccountModal = ({ bankLinks, onRequestClose, userId }) => {
  const [disableText, setDisableText] = useState('');
  const [account, setAccount] = useState();

  const [setUserDisable, { loading }] = useMutation(DISABLE_USER, {
    onCompleted: data => {
      if (data?.disableUser?.error) alert(data?.disableUser?.error);
    },
    refetchQueries: ['SessionInputs', 'UseUserInformation', 'UseAccountStatus', 'DisableUserStatus'],
  });

  const { isDeleted, avatar, initials } = useUserSummary({
    id: userId,
  });

  const { data: status_data } = useQuery(FETCH_DISABLE_USER_STATUS, {
    skip: !userId,
    variables: { personId: userId },
  });

  const disable_status = status_data?.disableUserStatus?.status;
  const isPendingDisable = !!disable_status;

  return (
    <Modal title="Close Account" close onRequestClose={() => onRequestClose()}>
      <Scroll transparent>
        <Header title="Close Account" avatar={avatar} initials={initials} />
        {!isDeleted && !isPendingDisable && (
          <>
            <InputLabel>
              {isPendingDisable ? `Status: ${disable_status}` : 'Enter delete to confirm'}
            </InputLabel>
            <Input
              onChange={event => setDisableText(event.target.value)}
              type="text"
              placeholder="delete"
              value={disableText}
            />
            <InputLabel>Select an account for withdrawals</InputLabel>
            <IRow
              label=" "
              value={account ? `${account.name} ${account.accountNumber}` : 'Click to select'}
              actionType="menu"
              menuPlacement="bottom"
              menu={bankLinks?.map(bl =>
                bl.accounts?.map(acct => (
                  <MenuItem
                    key={acct?.id}
                    text={`${acct?.name} ${acct?.accountNumber}`}
                    onPress={() => setAccount(acct)}
                  />
                )),
              )}
            />
          </>
        )}
      </Scroll>
      <StyledBottomBar bottom flexible>
        <Button
          loading={loading}
          disabled={
            disableText !== 'delete' ||
            (bankLinks && bankLinks?.length && !account?.id) ||
            isDeleted ||
            isPendingDisable
          }
          onPress={() => {
            setUserDisable({
              variables: {
                personId: userId,
                linkedAccountId: account?.id,
              },
            });
            onRequestClose();
          }}
        >
          {isDeleted ? 'Already deleted' : isPendingDisable ? 'Pending deletion' : 'Confirm'}
        </Button>
      </StyledBottomBar>
    </Modal>
  );
};

export default CloseAccountModal;

import React, { useState } from 'react';
import styled from 'styled-components';

import { formatCurrency, GOAL_NAMES } from 'src/web/utility';
import {
  Scroll,
  Button,
  Input,
  InputLabel,
  StyledBottomBar,
  IRow,
  FullModal,
  Banner,
  RowGroup,
  OptionRow,
} from 'src/web/components';

const StyledRow = styled.div`
  font-size: var(--p);
  color: ${p => (p.active ? 'var(--c-cerise-0)' : 'var(--c-fg-0)')};
  font-weight: ${p => (p.active ? '600' : '400')};
  cursor: var(--cursor);
  border-bottom: 1px solid var(--c-border-light);
  padding: 8px 0;
  &::last-child {
    border: none;
  }
  &:hover {
    color: var(--c-cerise-0);
  }
`;

// @remove
export const RetirementTransferModal = ({ onRequestClose, id, userId, max }) => {
  const [amount, setAmount] = useState();
  const [selectedGoal, setSelectedGoal] = useState();

  const data = {};
  const transfer = () => {};

  return (
    <FullModal
      close
      onRequestClose={onRequestClose}
      title="Move to DDA"
      sidebarItem={
        <RowGroup borderless>
          <IRow type="currency" label="Available" value={max} />
        </RowGroup>
      }
    >
      <Scroll transparent>
        <Banner
          title="This transfers money from the IRA (Folio) to the DDA (Unit)"
          subtitle="Note: The funds must be in the Folio cash bucket first. Contact Product Operations to have this
            done."
        />
        <InputLabel>Amount</InputLabel>
        <Input
          style={{ background: 'var(--fga)' }}
          onChange={event => setAmount(event.target.value)}
          type="text"
          placeholder="Amount"
          value={amount}
        />
        <br /> <br />
        <InputLabel>Where to?</InputLabel>
        {data?.viewer?.goals
          ?.filter(g => g?.status === 'ACTIVE')
          ?.map(
            (goal, idx) =>
              goal?.slug !== 'retirement' && (
                <OptionRow
                  key={goal?.name}
                  title={goal?.name || GOAL_NAMES[goal.slug]}
                  subtitle={formatCurrency({ value: goal?.totalBalance })}
                  selected={selectedGoal === goal?.id}
                  onPress={() => setSelectedGoal(goal?.id)}
                />
              ),
          )}
      </Scroll>
      <StyledBottomBar>
        <Button
          disabled={!(parseFloat(amount) > 0) && parseFloat(amount) <= max}
          fullWidth
          onPress={() => transfer()}
        >
          Transfer
        </Button>
      </StyledBottomBar>
    </FullModal>
  );
};

export default RetirementTransferModal;

import React from 'react';
import { IAction } from 'src/web/components';
import { PARTNERS } from 'src/web/data';
import { useContent } from 'src/web/hooks';

export const cleanSource = source => source?.replace(' ', '')?.toLowerCase();

const PartnersPage = ({}) => {
  const { data } = useContent(PARTNERS);

  return (
    <div>
      {data?.partners?.map((page, idx) => (
        <IAction
          logo={`https://s.catch.co/img/sources/${cleanSource(page?.incomeSource)}.png`}
          iconColor={page?.color?.hex}
          title={page?.name}
          text={page?.slug}
          text2={page?.incomeSource}
          key={page?.slug}
        />
      ))}
    </div>
  );
};

export default PartnersPage;

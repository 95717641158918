export const IDP_ENUM = {
  ACC: 'Success',
  RF1: 'Call Experian – frozen credit file',
  RF2: 'Call Experian –  incorrect answers',
  RF3: 'Limit Exceeded',
  RF4: 'Cannot Complete',
};

export const IDP_ENUM_NEW = [
  'VERIFIED',
  'PENDING_MANUAL_REVIEW',
  'PENDING_OFFLINE_VERIFICATION',
  'NEEDS_QUIZ',
  'LOCKED_OUT',
  'DENIED',
];

export const IDP_ENUM_FULL = [
  'UNKNOWN',
  'DENIED',
  'PENDING_PROVIDER',
  'NEEDS_QUIZ',
  'PENDING_OFFLINE_VERIFICATION',
  'NEEDS_DOCS',
  'SERVER_ISSUE',
  'STUCK',
  'INITIAL',
  'LOCKED_OUT',
  'PENDING_MANUAL_REVIEW',
];

export const makeHealthStatusSet = ({ eStatus, appStatus, policyStatus, paymentStatus, issues }) => {
  return {
    enrollment: eStatus?.toProperCase(),
    application: appStatus?.toProperCase(),
    policy: policyStatus?.toProperCase(),
    payment: paymentStatus?.replaceAll('POLICY_PAYMENT_STATUS_', '')?.toProperCase(),
    issues: issues,
  };
};

export const makeHealthStatus = (e, a) => {
  if (a?.status === 'RENEWAL') {
    return 'RENEWING';
  }
  if (a?.status === 'ENROLLMENT_STUCK') {
    return 'STUCK';
  }
  if (e?.policy?.policyStatus === 'ACTIVE_PENDING_PAYMENT') {
    return 'ENROLLED';
  }
  if ((e?.status === 'INITIAL_OEP' || e?.status === 'INITIAL_SEP') && a?.status === 'SUBMITTED')
    return 'ENROLLING';

  if (e?.policy?.policyStatus === 'CANCELLED') return 'CANCELLED';

  const statusMap = {
    PENDING: 'ACTION_NEEDED',
    INITIAL_OEP: 'APPLYING',
    INITIAL_SEP: 'APPLYING',
    PENDING_SUPPORT_TASK: 'ACTION_NEEDED',
  };
  return e?.status !== 'INITIAL_OEP' &&
    e?.status !== 'INITIAL_SEP' &&
    a?.status === 'SUBMITTED' &&
    e?.status !== 'PENDING_SUPPORT_TASK'
    ? 'ENROLLING'
    : statusMap[e?.status] || e?.status;
};

// organize drug or provider coverage for plans
export const getCoverage = ({ plan, coverage, requested }) => {
  if (!coverage || requested === 0) return 1;
  const covered = coverage?.filter(item => {
    if (item?.plan_id !== plan?.id) return false;
    if (item?.coverage === 'NotCovered') return false;
    else return true;
  });
  return covered?.length / parseFloat(requested);
};
// gets deductible based on family situation
export const getDeductible = ({ plan, people }) => {
  var ppl = people?.length;
  var maxFam = 0;
  const arr = plan?.deductibles
    ?.map((d, idx) => {
      var a = 0;
      if (d.family_cost === 'Family' && ppl > 1) maxFam = parseFloat(d.amount);
      if (d.family_cost === 'Family Per Person' && ppl > 1) a = parseFloat(d.amount);
      if (d.family_cost === 'Individual' && ppl <= 1) a = parseFloat(d.amount);
      return { amt: Math.max(maxFam, a), ...d };
    })
    .sort((a, b) => {
      return b.amt - a.amt;
    });
  return arr?.[0]?.amt;
};
// gets deductible based on family situation
export const getMoop = ({ plan, people }) => {
  var ppl = people?.length;
  var maxFam = 0;
  const arr = plan?.moops
    ?.map((d, idx) => {
      var a = 0;
      if (d.family_cost === 'Family' && ppl > 1) maxFam = parseFloat(d.amount);
      if (d.family_cost === 'Family Per Person' && ppl > 1) a = parseFloat(d.amount);
      if (d.family_cost === 'Individual' && ppl <= 1) a = parseFloat(d.amount);
      return { amt: Math.max(maxFam, a), ...d };
    })
    .sort((a, b) => {
      return b.amt - a.amt;
    });
  return arr?.[0]?.amt;
};

export const makeHealthCSV = plans => {
  var rows = [
    [
      'id',
      'name',
      'premium_w_credit',
      'metal_level',
      'type',
      'catch_score',
      'catch_score_budget',
      'catch_score_overall',
      'catch_score_comprehensive',
      'score_av',
      'score_av',
      'score_premium',
      'score_deductible',
      'score_moop',
      'score_geo',
      'score_medicines',
      'score_doctors',
      'deductible',
      'moop',
      'numPeople',
      'drugs',
      'docs',
      'csr',
      'income',
    ],
  ];

  const newRows = plans?.map((plan, idx) => {
    rows.push(
      rows[0].map((row, i) =>
        ['drugs', 'docs'].includes(row)
          ? plan[row]
              .map(e => e.name)
              ?.join(';')
              .toString()
          : plan[row]?.toString()?.replace(/,/g, ''),
      ),
    );
  });

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  //window.open(encodedUri);

  return encodedUri;
};

export const PHASES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const FFM_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',

  LA: 'Louisiana',

  MI: 'Michigan',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NH: 'New Hampshire',

  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const OSCAR_STATES = {
  CA: 'California',
  CO: 'Colorado',
  NJ: 'New Jersey',
  PA: 'Pennsylvania',
  TX: 'Texas',
  FL: 'Florida',
};

export const STATE_EXCHANGES = {
  CA: {
    name: 'Covered California',
    url: 'https://www.coveredca.com/',
  },
  CO: {
    name: 'Connect for Health Colorado',
    url: 'https://www.connectforhealthco.com/',
  },
  CT: {
    name: 'Access Health CT',
    url: 'https://www.accesshealthct.com/',
  },
  DC: {
    name: 'DC Health Link',
    url: 'https://dchealthlink.com/',
  },
  ID: {
    name: 'Your Health Idaho',
    url: 'https://www.yourhealthidaho.org/',
  },
  MD: {
    name: 'Maryland Health Connection',
    url: 'https://www.marylandhealthconnection.gov/',
  },
  MA: {
    name: 'Massachusetts Health Connector',
    url: 'https://www.mahealthconnector.org/',
  },
  MN: {
    name: 'MNsure',
    url: 'https://www.mn.gov/hix/',
  },
  NV: {
    name: 'Nevada Health Link',
    url: 'https://www.nevadahealthlink.com/',
  },
  NY: {
    name: 'New York State of Health',
    url: 'https://healthbenefitexchange.ny.gov/',
  },
  RI: {
    name: 'HealthSource RI',
    url: 'https://www.healthsourceri.com/',
  },
  VT: {
    name: 'Vermont Health Connect',
    url: 'https://healthconnect.vermont.gov/',
  },
  WA: {
    name: 'Washington Healthplanfinder',
    url: 'https://www.wahealthplanfinder.org/',
  },
};

export const LICENSES = [
  {
    npn: 18986211,
    state_name: 'NPN',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 19315780,
    state_name: 'NPN',
    holder_name: 'Kristen Tyrrell',
  },
  {
    npn: 18946575,
    state_name: 'NPN',
    holder_name: 'Andrew Ambrosino',
  },
  { npn: 20655395,
    state_name: "NPN",
    holder_name: "Alexa Irish"
  },
  {
    npn: 19637718,
    state_name: 'NPN',
    holder_name: 'Samuel Thayer',
  },
  {
    npn: 19334389,
    state_name: 'NPN',
    holder_name: 'Cathleen Turner',
  },
  {
    npn: 19659794,
    state_name: 'NPN',
    holder_name: 'Olivia Granat',
  },

  {
    npn: 19308924,
    state_name: 'NPN',
    holder_name: 'Daniel Tenner',
  },
  {
    npn: 19308925,
    state_name: 'NPN',
    holder_name: 'Caitlin Eilenberg',
  },
  {
    npn: 19320005,
    state_name: 'NPN',
    holder_name: 'Jessica Little',
  },
  {
    npn: 19321269,
    state_name: 'NPN',
    holder_name: 'Edward Ma',
  },

  {
    npn: 19353546,
    state_name: 'NPN',
    holder_name: 'Soutthavone Baccam',
  },

  {
    npn: 3000473658,
    state_name: 'Alabama',
    state_abbr: 'AL',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 100170614,
    state_name: 'Alaska',
    state_abbr: 'AK',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449485,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000464396,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    lic_type: 'Accident, Health & Sickness; Exchange Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 6003058,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident and Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 4070687,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident and Health',
    holder_name: 'Kristen Tyrrell',
  },
  {
    npn: 4075606,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident and Health',
    holder_name: 'Samuel Thayer',
  },
  {
    npn: 591426,
    state_name: 'Colorado',
    state_abbr: 'CO',
    lic_type: 'Accident and Health',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 3000461813,
    state_name: 'Delaware',
    state_abbr: 'DE',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 'L106813',
    state_name: 'Florida',
    state_abbr: 'FL',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 203291,
    state_name: 'Georgia',
    state_abbr: 'GA',
    lic_type: 'Accident and Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 485633,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 3000449487,
    state_name: 'Illinois',
    state_abbr: 'IL',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3402086,
    state_name: 'Indiana',
    state_abbr: 'IN',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1002332547,
    state_name: 'Iowa',
    state_abbr: 'IA',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 832072595,
    state_name: 'Kansas',
    state_abbr: 'KS',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 'DOI-1022498',
    state_name: 'Kentucky',
    state_abbr: 'KY',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 792652,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 'AGN324322',
    state_name: 'Maine',
    state_abbr: 'ME',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449443,
    state_name: 'Maryland',
    state_abbr: 'MD',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2074019,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 118199,
    state_name: 'Michigan',
    state_abbr: 'MI',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 15034951,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 8483108,
    state_name: 'Missouri',
    state_abbr: 'MO',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449620,
    state_name: 'Montana',
    state_abbr: 'MT',
    lic_type: 'Disability (Health)',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 100304427,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    lic_type: 'Sickness, Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3401566,
    state_name: 'Nevada',
    state_abbr: 'NV',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2423226,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1691414,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000453710,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    lic_type: 'Accident and Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1000619825,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449446,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1225414,
    state_name: 'Ohio',
    state_abbr: 'OH',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449449,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000482035,
    state_name: 'Oregon',
    state_abbr: 'OR',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 881171,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 3000461852,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 10021184,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2427299,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2432938,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 703383,
    state_name: 'Utah',
    state_abbr: 'UT',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 145089,
    state_name: 'Virginia',
    state_abbr: 'VA',
    lic_type: 'Health',
    holder_name: 'Catch Insurance',
  },

  {
    npn: 100291276,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    lic_type: 'Accident and Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000461916,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 387946,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000472565,
    state_name: 'Alabama',
    state_abbr: 'AL',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 100169579,
    state_name: 'Alaska',
    state_abbr: 'AK',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    lic_type: 'Accident, Health & Sickness; Exchange Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 591243,
    state_name: 'Colorado',
    state_abbr: 'CO',
    lic_type: 'Accident and Health',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 3000447765,
    state_name: 'Delaware',
    state_abbr: 'DE',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 'W593035',
    state_name: 'Florida',
    state_abbr: 'FL',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3244494,
    state_name: 'Georgia',
    state_abbr: 'GA',
    lic_type: 'Accident and Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 484488,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 18946575,
    state_name: 'Illinois',
    state_abbr: 'IL',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3401124,
    state_name: 'Indiana',
    state_abbr: 'IN',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Iowa',
    state_abbr: 'IA',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Kansas',
    state_abbr: 'KS',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 'DOI-1022179',
    state_name: 'Kentucky',
    state_abbr: 'KY',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 813773,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 'PRN323027',
    state_name: 'Maine',
    state_abbr: 'ME',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3000447786,
    state_name: 'Maryland',
    state_abbr: 'MD',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2072895,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2101040,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Caitlin Eilenberg',
  },
  {
    npn: 2101123,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Daniel Tenner',
  },
  {
    npn: 2101886,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Kristen Tyrrell',
  },
  {
    npn: 892988,
    state_name: 'Michigan',
    state_abbr: 'MI',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 10587754,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 8463045,
    state_name: 'Missouri',
    state_abbr: 'MO',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3000447773,
    state_name: 'Montana',
    state_abbr: 'MT',
    lic_type: 'Disability (Health)',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    lic_type: 'Sickness, Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3400925,
    state_name: 'Nevada',
    state_abbr: 'NV',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2422948,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 1691242,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    lic_type: 'Accident and Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 'LA-1530655',
    state_name: 'New York',
    state_abbr: 'NY',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 1221998,
    state_name: 'Ohio',
    state_abbr: 'OH',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3000447753,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Oregon',
    state_abbr: 'OR',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 880040,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3000447816,
    state_name: 'Puerto Rico',
    state_abbr: 'PR',
    lic_type: 'Health Services, Disability',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 18946575,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 40503962,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2425122,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2364731,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2459992,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Caitlin Eilenberg',
  },
  {
    npn: 2459433,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Daniel Tenner',
  },
  {
    npn: 702705,
    state_name: 'Utah',
    state_abbr: 'UT',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 3401390,
    state_name: 'Vermont',
    state_abbr: 'VT',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 1105288,
    state_name: 'Virginia',
    state_abbr: 'VA',
    lic_type: 'Health',
    holder_name: 'Andrew Ambrosino',
  },

  {
    npn: 18946575,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    lic_type: 'Accident and Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 18946575,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    lic_type: 'Accident & Health',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 387626,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Andrew Ambrosino',
  },
  {
    npn: 2461008,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Kristen Tyrrell',
  },
  {
    npn: 2461117,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'General Lines; Life, Accident, Health and HMO',
    holder_name: 'Jessica Merrell',
  },
];

export const HOLDER_EMAILS = {
  'Andrew Ambrosino': 'andrew@catch.co',
  'Kristen Tyrrell': 'kristen@catch.co',
  'Samuel Thayer': 'samuel@catch.co',
  'Jessica Merrell': 'jess@catch.co',
  'Caitlin Eilenberg': 'caite@catch.co',
  'Daniel Tenner': 'dan@catch.co',
  'Olivia Granat': 'olivia@catch.co',
  'Cathleen Turner': 'cat@catch.co',
  'Jessica Little': 'jess@catch.co',
  'Edward Ma': 'ed@catch.co',
  'Soutthavone Baccam': 'sam@catch.co',
};

export const getSEP = person => {
  if (person?.isLostCoverageLast60Days) return { name: 'Lost Coverage', date: person?.whenLostCoverage };
  if (person?.isWillLoseCoverageNext60Days)
    return { name: 'Will Lose Coverage', date: person?.whenLostCoverage };
  if (person?.isReleasedFromIncarceration)
    return { name: 'Released from Incarceration', date: person?.whenReleasedFromIncarceration };
  if (person?.isMoved) return { name: 'Moved', date: person?.whenRelocated };
  if (person?.isGainDependent) return { name: 'Gained Dependent', date: person?.whenBecomeADependent };
};

export const OSCAR_ACTION_APP_STATUS = {
  CANCELED: 'Canceled',
  DELETED: 'Deleted',
  IN_PROGRESS: 'User Applying',
  EXPLORER: 'User Exploring',
  ENROLLED: 'Complete',
  PENDING_ENROLLMENT: 'Submitted to Carrier',
  ENROLLMENT_STUCK: 'Carrier Issue',
};

export const OSCAR_ACTION_TASK_STATUS = {
  TODO: 'Todo',
  IN_PROGRESS: 'In Progress',
  PENDING: 'Submitted to Carrier',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled',
};

export const parterHealthStatus = ({ taskStatus, appStatus, payStatus }) => {
  if (!!OSCAR_ACTION_APP_STATUS[appStatus]) return OSCAR_ACTION_APP_STATUS[appStatus];
  else if (!!OSCAR_ACTION_TASK_STATUS[taskStatus]) return OSCAR_ACTION_TASK_STATUS[taskStatus];
};

export const OSCAR_ACTION_STATUS = [
  {
    name: 'Todo',
    app: 'SUBMITTED',
    task: 'TODO',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'In Progress',
    app: 'SUBMITTED',
    task: 'IN_PROGRESS',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Submitted to Carrier',
    app: 'PENDING_ENROLLMENT',
    task: 'PENDING',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Carrier Issue',
    app: 'ENROLLMENT_STUCK',
    task: 'PENDING',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Complete',
    app: 'ENROLLED',
    task: 'COMPLETE',
    payment: 'PENDING_FIRST_PAYMENT',
  },
  {
    name: 'Effectuated',
    app: 'ENROLLED',
    task: 'COMPLETE',
    payment: 'EFFECTUATED',
  },
  '–',
  {
    name: 'Canceled',
    app: 'CANCELED',
    task: 'CANCELED',
  },
];

export const Q_MAIN_VARS = {
  pagination: {
    pageNumber: 1,
    pageSize: 50,
  },
};

export const Q_MAIN_CONFIG = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
  pollInterval: 30000,
};

export const PAGINATION = Q_MAIN_VARS;
export const FETCH_CONFIG = Q_MAIN_CONFIG;

export const EDE_ROUTES_RENEW = ['intro', 'agreements', 'identity', 'triage', 'results'];

export const EDE_ROUTES = [
  'intro',
  'coverage-info',
  'privacy',
  'agreements',
  'household',
  'screening',
  '_',
  'pro-assistance',
  'identity',
  'id-info',
  'id-questions',
  'id-verify',
  'id-denied',
  '_',
  'application-import',
  'import',
  'triage',
  'confirm-applicants',
  'member-info',
  'member-questions',
  'immigration-details',
  'medicaid-denial',
  'member-income-list',
  'member-deductions-list',
  'member-income-details',
  'member-income-confirm',
  'member-income-discrepancy',
  'sep',
  'outside-household',
  'dependents-current-coverage',
  'member-medical-bills',
  'member-work-hours',
  'other-coverage',
  'details',
  'review',
  'agreements',
  '_',
  'results',
  'dmi',
  'split-eligibility',
  'plans',
  'plan',
  'aptc-agreement',
  'resubmit',
  'enroll',
  'success',
  'sep-out-of-date',
];

export const EDE_ROUTE_APP_START = 'application-import';
export const EDE_ROUTE_APP_START_IDX = EDE_ROUTES.indexOf(EDE_ROUTE_APP_START);
export const EDE_ROUTES_STARTING = EDE_ROUTES.slice(0, EDE_ROUTE_APP_START_IDX);
export const EDE_ROUTES_APPLYING = EDE_ROUTES.slice(EDE_ROUTE_APP_START_IDX, EDE_ROUTES.length);
export const PARTNER_ROUTES = [
  'partner',
  'partner-applicants',
  'partner-household',
  'risk',
  'usage',
  'drugs',
  'prescriptions',
  'plan',
  'partner-application',
  'partner-confirm',
];

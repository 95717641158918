import React from 'react';
import { useLazyQuery, useQuery, gql } from '@apollo/client';

import { UploadTile, RowGroup, Scroll, Spinner, Banner } from 'src/web/components';
import { formatDate, formatDateOnly, groupBy } from 'src/web/utility';

// BILLING_REPORT
// MONTHLY_STATEMENT
// TRADE_CONFIRMATION
// TAX_STATEMENT_5498
// TAX_1099_DOCUMENT
// TAX_STATEMENT_1099R

// 1040 (tax payment)
// 1099 (retirement)
// 5498 (retirement)
// 1095 (health tax form)

const STATEMENTS = gql`
  query Statements($id: ID) {
    viewerTwo(id: $id) {
      id
      documents {
        taxDocuments {
          id
          date
          providerId
          createdOn
          ext
          documentType
          type
          detail
          month
          vertical
          planType
        }
        statements {
          id
          date
          providerId
          documentType
          type
          detail
          month
          vertical
          planType
        }
      }
    }
  }
`;

const DOCUMENT = gql`
  query Docu($id: ID!, $userId: ID) {
    viewerTwo(id: $userId) {
      id
      document(id: $id) {
        id
        url
      }
    }
  }
`;

const getStatementVendor = item => {
  if (item?.type === 'IRA') return 'IRA';
  if (/ST-/.test(item?.id)) return 'BBVA';
  if (item?.providerId?.length <= 8 || item?.description?.split(new RegExp('statement for', 'i'))?.[0])
    return 'Unit';
  else return 'Bond';
};

const getStatementPeriod = item => {
  return item?.description?.split(new RegExp('statement for the period ending on', 'i'))?.[1] || item.date;
};

export function StatementsPage({ userId }) {
  const [getStatement] = useLazyQuery(DOCUMENT, {
    variables: { language: 'english' },
    onCompleted: data => window.open(data?.viewerTwo?.document?.url, '_self'),
    onError: err => alert(err),
  });

  const { data, loading } = useQuery(STATEMENTS, { variables: { id: userId } });

  const statements = data?.viewerTwo?.documents?.statements;
  const forms = data?.viewerTwo?.documents?.taxDocuments;

  const _statements = (statements || [])?.map(s => ({
    vendor: s?.type,
    _name: s?.detail || 'Bank Statement',
    _date: getStatementPeriod(s),
    _subtitle: s?.description?.split(new RegExp('statement for the period ending on', 'i'))?.[0],
    _docCode: getStatementVendor(s)?.toUpperCase(),
    _onPress: () => getStatement({ variables: { id: s?.id, userId } }),
    dateBucket: s?.month,
    ...s,
  }));

  const taxDocs = (forms || [])
    ?.map(s => ({
      vendor: s?.planType?.replace('PLANTYPE_', ''),
      _name: s?.documentType?.replace('TAX_', 'Form '),
      _date: s?.month.length === 4 ? (parseInt(s?.month) + 1).toString() : s?.month,
      _subtitle: s?.detail === '' || !s?.detail ? s?.month : s?.detail,
      _docCode: s?.documentType
        ?.replace('TAX_', '')
        ?.replace('_ES', '')
        ?.replace('_A', '')
        ?.replace('_R', ''),
      _onPress: () => getStatement({ variables: { id: s?.id, userId } }),
      dateBucket: formatDateOnly({
        date: s?.month,
        length: 'MONTH',
      }),
      ...s,
    }))
    ?.sort((a, b) => a.dateBucket - b.dateBucket);

  const all = groupBy([..._statements], 'dateBucket');
  const months = Object.keys(all)?.sort((a, b) => (b > a ? 1 : -1));

  return loading ? (
    <div className="full">
      <Spinner />
    </div>
  ) : (
    <Scroll loading={loading}>
      <Banner background="var(--c-medium-1)" title="All downloads are supported except 1095-A" />
      <br />
      <RowGroup borderless title="Tax Documents" subtitle="1099-R, 1095-A, 1040-ES, 5498">
        {taxDocs?.length === 0 ? (
          <div className="full">No tax docs (yet)</div>
        ) : (
          taxDocs?.map(item => (
            <UploadTile
              id={item?.id}
              key={item?.id}
              createdOn={item?._date}
              name={item._name}
              subtitle={item._subtitle}
              fileType={item._docCode}
              small
              source={item?.vendor}
              onPress={item?._onPress}
            />
          ))
        )}
      </RowGroup>
      <br />
      <br />
      <RowGroup title="Monthly Statements" subtitle="DDA, IRA">
        {months?.map((month, idx) => (
          <RowGroup
            subtle
            key={month}
            title={formatDate({ date: all[month]?.[0]?._date, length: 'MONTH_HUMAN' })}
            close
          >
            {all[month]?.map(item => (
              <UploadTile
                id={item?.id}
                key={item?.id}
                createdOn={item?._date}
                name={item._name}
                subtitle={item._subtitle}
                fileType={item._docCode}
                small
                source={item?.vendor}
                onPress={item?._onPress}
              />
            ))}
          </RowGroup>
        ))}
      </RowGroup>
    </Scroll>
  );
};
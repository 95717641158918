import { Spinner, Scroll, RowGroup, Status, TableRow } from 'src/web/components';
import { GOAL_NAMES, goalIcon, goalColor, formatPercentage, formatCurrency } from 'src/web/utility';
import { useMediaQuery } from 'src/web/hooks';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { GET_GOALS, GoalStatus, GoalType, useFintechQuery } from 'src/data';

export function GoalsPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();
  const narrow = useMediaQuery('(max-width: 1024px)');

  const { loading, data } = useFintechQuery(GET_GOALS, {
    variables: { customerID: userId },
  });

  const goals = data?.customer?.goals || [];

  return (
    <Scroll transparent={inInspector} loading={loading}>
      {loading ? (
        <div className="full">
          <Spinner loading />
        </div>
      ) : goals?.length === 0 ? (
        <div className="full">No goals</div>
      ) : (
        <RowGroup title="Goals">
          {goals?.map((goal, idx) => (
            <TableRow
              selected={selectedId === goal.slug}
              onPress={() => setSelected(goal.slug, { type: SelectedType.GOAL })}
              title={goal.name || GOAL_NAMES[goal.slug]}
              status={<Status hideText={narrow} status={goal.status} />}
              accessory1={formatCurrency({ value: goal.totalBalance })}
              accessory2={formatPercentage({ number: goal.withholdingPercentage, numDecimals: 0 })}
              key={goal.slug}
              faded={goal.status === GoalStatus.Deleted || goal.status === GoalStatus.Draft}
              color={goalColor({ type: goal.type })}
              subtitle={goal.type === GoalType.Custom ? 'Custom' : undefined}
              logo={goalIcon({ type: goal.type, alt: true })}
            />
          ))}
        </RowGroup>
      )}
    </Scroll>
  );
}

import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { initials, makeName } from 'src/web/utility';
import { PaymentDebugView, TaxPaymentNode } from 'src/web/inspectors';
import { TASK, UPSERT_TASK } from 'src/web/data';
import {
  Scroll,
  Header,
  Button,
  IRow,
  StyledBottomBar,
  RowGroup,
  Modal,
  IActionButton,
  IActionButtons,
  Input,
  InputLabel,
} from 'src/web/components';
import { InspectorPaneProps } from '../../types';
import { BankAccountNode } from 'src/components/nodes';

export function TaxPaymentTaskPane({ id, userId }: InspectorPaneProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [taskStatus, setTaskStatus] = useState();
  const [debug, setDebug] = useState();

  const { data } = useQuery(TASK, {
    variables: { typename: 'TaxPaymentTask', userId: userId, input: { id: id } },
    skip: !id,
  });

  const payment = data?.task?.taxPayment;

  const person = data?.task?.taxPayment?.person;
  const address = payment?.addressOfLastCompletedFiling?.street1
    ? payment?.addressOfLastCompletedFiling
    : person?.legalAddress;
  const [code, setCode] = useState(payment?.confirmationCode || data?.task?.confirmationCode);
  const [notes, setNotes] = useState(payment?.notes || data?.task?.notes);

  const [upsertTask, { data: task }] = useMutation(UPSERT_TASK, {
    refetchQueries: ['Tasks'],
    variables: {
      input: {
        id: data?.task?.id,
        notes: notes,
        confirmationCode: code,
      },
    },
  });

  return !id ? (
    <div className="full">No Selection</div>
  ) : (
    <>
      <Scroll inspector>
        <Header pii title={makeName(person)} subtitle="Payment">
          <IRow
            id={data?.task?.id}
            pii
            label="Assignee"
            value={makeName(data?.task?.assignee)}
            avatar={data?.task?.assignee?.avatarImage}
            initials={initials(data?.task?.assignee)}
            actionType="menu"
            type="assignee"
          />

          <IRow
            label="Status"
            type="status"
            actionType="menu"
            value={data?.task?.status}
            onPress={() => setIsOpen(true)}
          />
        </Header>

        <>
          <IActionButtons>
            <IActionButton icon="Edit" onPress={() => setIsOpen(true)} title="Update" />
          </IActionButtons>

          <TaxPaymentNode paymentId={payment?.id} uid={userId} />

          <BankAccountNode customerID={userId} />
          <br />

          {!!payment && !!userId && (
            <>
              <RowGroup title="Payment Entry" close subtle borderless>
                <IRow pii hideUnset label="Filing Name" value={payment?.filingName} />{' '}
                <IRow pii hideUnset label="Filing Name" value={data?.viewer?.taxGoal?.FilingName} />{' '}
                <IRow pii label="Filing Status" value={payment?.filingStatus} />
              </RowGroup>

              <RowGroup title="Personal" close subtle>
                <IRow pii label="First Name" value={`${person?.givenName}`} />
                <IRow pii label="Last Name" value={`${person?.familyName}`} />
                <IRow pii type="ssn" label="SSN" value={person?.fullSSN} />
                <IRow pii label="Email" value={payment?.confirmationEmailAddress || person?.email} />
                <IRow pii label="DOB" value={person?.dob} />{' '}
                <IRow pii label="Phone" value={person?.phoneNumber} />
                <IRow hideUnset label="Last Tax Year" value={payment?.yearOfLastCompletedFiling} />
                <IRow
                  hideUnset
                  label="Last Tax Year"
                  value={data?.viewer?.taxGoal?.YearOfLastCompletedFiling}
                />
              </RowGroup>

              <RowGroup title="Address" subtitle={'(of last filing)'} close subtle>
                <IRow
                  pii
                  label="Line 1"
                  value={data?.viewer?.taxGoal?.AddressOfLastCompletedFiling?.street1}
                />
                <IRow
                  pii
                  label="Line 2"
                  value={data?.viewer?.taxGoal?.AddressOfLastCompletedFiling?.street2}
                />
                <IRow pii label="City" value={data?.viewer?.taxGoal?.AddressOfLastCompletedFiling?.city} />
                <IRow pii label="State" value={data?.viewer?.taxGoal?.AddressOfLastCompletedFiling?.state} />
                <IRow pii label="ZIP" value={data?.viewer?.taxGoal?.AddressOfLastCompletedFiling?.zip} />
              </RowGroup>
              <RowGroup title="Address" subtitle={'(provided by user)'} close subtle>
                <IRow pii label="Line 1" value={address?.street1} />
                <IRow pii label="Line 2" value={address?.street2} />
                <IRow pii label="City" value={address?.city} />
                <IRow pii label="State" value={address?.state} />
                <IRow pii label="ZIP" value={address?.zip} />
              </RowGroup>
            </>
          )}
        </>
      </Scroll>

      {!!isOpen && (
        <Modal close presentation="formSheet" onRequestClose={() => setIsOpen(false)}>
          <Scroll>
            <Header
              pii
              title="Update Tax Payment"
              subtitle={person?.email}
              avatar={person?.avatarImage}
              initials={initials(person)}
            />
            <IRow
              id={data?.task?.id}
              label="Task Status"
              type="status"
              value={taskStatus || data?.task?.status}
              actionType="menu"
            />

            <br />
            <InputLabel>Confirmation Code</InputLabel>
            <Input
              onChange={event => setCode(event.target.value)}
              type="text"
              placeholder="Confirmation #"
              value={code}
            />
            <InputLabel>Notes</InputLabel>
            <Input
              onChange={event => setNotes(event.target.value)}
              type="text"
              placeholder="Notes"
              value={notes}
            />
          </Scroll>
          <StyledBottomBar bottom flexible>
            <Button onPress={() => upsertTask()} shadow fullWidth>
              Confirm
            </Button>
          </StyledBottomBar>
        </Modal>
      )}
      {!!debug && <PaymentDebugView id={id} u={userId} onClose={() => setDebug(false)} />}
    </>
  );
}

import { useState, useCallback } from 'react';

export const useToggle = () => {
  const [isOpen, setOpen] = useState(false);
  
  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
  }
}
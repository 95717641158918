import { gql } from '../generated';

gql(`
  fragment Policy on HealthPolicy {
    id
    coverageYear
    coverageState
    carrier
    planName
    premium
    premiumEffective
    aptcAmount
    policyStatus
    commissionStatus
    lastPaidCommission
    insuranceSource
    startDate
    endDate
    isPendingDocUpload
    exchangePolicyNumber
    paymentStatus
    agentID
    isDeleted
    phoneNumber
    notes
    providerPlanID
    subscriberID
    exchangeSubscriberID
    providerPlan {
      id
      name
      market
      metalLevel
      type
      premium
      premiumWithCredit
      productDivision
      issuer {
        id
        name
      }
        
    }
  }
`);

export const USER_POLICIES = gql(`
  query UserPolicies($id: ID) {
    viewerTwo(id: $id) {
      id
      health {
        policies(input: {}) {
          ...Policy
        }
      }
    }
  }
`);

export const USER_POLICY = gql(`
  query UserPolicy($personID: ID, $policyID: ID!) {
    viewerTwo(id: $personID) {
      id
      health {
        policy(id: $policyID) {
          ...Policy
        }
      }
    }
  }
`);

export const CREATE_POLICY = gql(`
  mutation CreatePolicy($input: CreatePolicyInput!, $personID: ID!) {
    createPolicy(input: $input, personID: $personID) {
        ...Policy
    }
  }
`);

export const UPDATE_POLICY = gql(`
  mutation UpdatePolicy($input: UpdatePolicyInput!, $personID: ID!) {
    updatePolicy(input: $input, personID: $personID) {
        ...Policy
    }
  }
`);

export function UPDATE_POLICIES(cache, result, personId) {
  const policy = result?.data.createPolicy;

  const { viewerTwo } = cache.readQuery({
    query: USER_POLICIES,
    variables: { id: personId },
  });

  const existingPolicies = viewerTwo?.health?.policies || [];
  const statusIndex = existingPolicies.findIndex(status => status.id === policy.id);

  if (statusIndex < 0) {
    cache.writeQuery({
      query: USER_POLICIES,
      variables: { id: personId },
      data: {
        viewerTwo: {
          ...viewerTwo,
          health: {
            ...viewerTwo.health,
            policies: [...existingPolicies, policy],
          },
        },
      },
    });
  }
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppSection } from 'src/web/components';
import { ExplorerLookupPage } from './ExplorerLookupPage';
import { ExplorerSessionPage } from './ExplorerSessionPage';

export function ExplorerView() {
  return (
    <AppSection>
      <Routes>
        <Route path="/" element={<ExplorerLookupPage />} />
        <Route path=":explorerId" element={<ExplorerSessionPage />} />
      </Routes>
    </AppSection>
  );
};

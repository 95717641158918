import React from 'react';
import styled from 'styled-components';

import { Avatar, I, Icon, IconType, Redact } from '.';

const StyledHeader = styled.div`
  transition: var(--transition);
  min-width: 100%;
  margin-top: 0px;
  margin-bottom: 24px;
`;

const StyledMainHeader = styled.div`
  padding: calc(0.5 * var(--padding)) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled.h5`
  font-size: var(--h5);
  font-weight: 600;
  color: var(--c-fg-0);
  margin-bottom: 0;
`;

const StyledSubtitle = styled.div`
  font-size: var(--fp);
  font-weight: 500;
  color: var(--c-fg-1);
`;

export interface HeaderProps {
  loading?: boolean;
  pii?: boolean;
  title: string | React.ReactElement;
  subtitle?: string;
  logo?: string;
  icon?: IconType;
  iconColor?: string;
  squareIcon?: boolean;
  avatar?: string;
  initials?: string;
  children?: React.ReactNode;
}

const Header = ({
  title,
  children,
  pii,
  usePlaceholder,
  subtitle,
  loading,
  avatar,
  initials,
  inInspector,
  logo,
  icon,
  squareIcon,
  iconColor = 'var(--c-fg-1)',
}: HeaderProps) => {
  return (
    <StyledHeader>
      <StyledMainHeader>
        <div>
          {!!subtitle && <StyledSubtitle inInspector={inInspector}>{subtitle}</StyledSubtitle>}
          <Redact inline disable={!pii && !loading} loading={loading}>
            <StyledTitle inInspector={inInspector}>
              {title || (!!usePlaceholder && 'No Name') || (!!loading && ' ')}
            </StyledTitle>
          </Redact>
        </div>
        {(!!avatar || !!initials) && <Avatar image={avatar} initials={initials} size={36} />}
        {!!logo ? (
          <Icon logo={logo} square={squareIcon} size={36} />
        ) : !!icon ? (
          <I name={icon} color={iconColor} size={32} />
        ) : (
          <></>
        )}
      </StyledMainHeader>

      {children}
    </StyledHeader>
  );
};

export default Header;

import { gql } from '../generated';

export const GET_USER = gql(`
  query GetUser($id: ID!) {
    viewer(id: $id) {
      id
      user {
        id
        email
        givenName
        familyName
      }
    }
  }
`);

export const cleanTaskName = name => {
  return name?.toProperCase();
};

export const taskIcon = (name, dark) => {
  return `/img/tasks/${name}${dark ? '_dark' : ''}.png`;
};

export const tasks = ['HEALTH_ENROLLMENT', 'PARTNER_HEALTH', 'TAX_PAYMENT', 'EXPERT_SETUP'];

const EDE_ORIGINS = {
  IMPORT: 'Import',
  LINK_STRICT: 'Link',
  HEALTH_SHERPA: 'HS',
};

const EDE_STATUS = {
  RENEWAL: 'Express',
  ENROLLMENT_STUCK: 'Stuck',
  ACTIVE: 'Active',
};

const SOEP = '2021-02-15';
const ARP = '2021-04-01';

// CustomTask, TaxPaymentTask, HealthInsuranceEnrollment, ExpertSetupTask, PartnerHealthTask

const makeHealthEnrollmentLabels = (e, options) => {
  const a = e?.healthApplication;
  var labels = [];
  const hasActions = e?.actionsNeeded && e?.actionsNeeded?.length > 0;
  const origin = EDE_ORIGINS[a?.applicationOrigin];
  const status = EDE_STATUS[a?.status];

  if (e?.idProofResult === 'PENDING_MANUAL_REVIEW') labels.push('Needs Manual IDP');
  if (e?.applicationStatus === 'ENROLLMENT_STUCK') labels.push('Stuck');
  if (e?.applicationStatus === 'EXPLORER') labels.push('_exp');

  if (!e?.isOpenEnrollment) labels.push('SEP');

  if (hasActions) {
    const a = [...e?.actionsNeeded]?.map(action =>
      action !== 'ACTION_PAYMENT' ? labels.push(action?.replace('ACTION_', '')?.toProperCase()) : null,
    );
  }

  // if (e?.applicationContext && e?.applicationContext !== 'FULL_APPLICATION')
  //   labels.push(e?.applicationContext);
  if (origin) labels.push(origin);
  if (status) labels.push(status);

  return labels;
};

const y = new Date().getFullYear();

const makeHealthEnrollmentIcons = (e, options) => {
  var icons = [];

  if (e?.enrollmentType === 'DENTAL_INSURANCE') {
    icons.push({ icon: 'Dental', fade: true, text: 'Dental' });
  }

  if (e?.idProofResult === 'PENDING_MANUAL_REVIEW') {
    icons.push({ icon: 'Fingerprint', color: 'var(--c-medium-0)', text: 'Pending Manual Review' });
  }

  if (e?.idProofResult === 'DENIED') {
    icons.push({ icon: 'Fingerprint', color: 'var(--c-bad-0)', text: 'IDP Denied' });
  }

  if (e?.applicationStatus === 'ENROLLMENT_STUCK') {
    icons.push({ icon: 'Warning', color: 'var(--c-bad-0)', text: 'Stuck' });
  }

  if (e?.actionsNeeded?.filter(a => a !== 'ACTION_PAYMENT').length > 0) {
    icons.push({ icon: 'Warning', color: 'var(--c-medium-0)', text: 'Outstanding DMI/SVI' });
  }

  if (e?.applicationStatus === 'ENROLLED' && !/INACTIVE/.test(e?.status)) {
    if (e?.isCatchNPN)
      icons.push({ icon: 'Dollar', color: 'var(--c-good-0)', fade: true, text: 'Catch NPN' });
    else icons.push({ icon: 'Dollar', color: 'var(--c-bad-0)', text: 'Not Catch NPN' });
  }

  if (
    e?.applicationStatus === 'IN_PROGRESS' ||
    e?.applicationStatus === 'SUBMITTED' ||
    e?.applicationStatus === 'ENROLLED' ||
    e?.applicationStatus === 'ENROLLMENT_STUCK'
  ) {
    if (e?.hasSubmittedPermissions)
      icons.push({ icon: 'Bank', color: 'var(--c-good-0)', fade: true, text: 'Connected to EDE' });
    else
      icons.push({
        icon: 'Bank',
        color: e?.applicationStatus === 'IN_PROGRESS' ? 'var(--c-border)' : 'var(--c-bad-0)',
        text: 'Disconnected from EDE',
      });
  }

  if (/INACTIVE/.test(e?.status) && e.year === y) {
    icons.push({ icon: 'Cancel', color: 'var(--c-bad-0)', text: 'Terminated' });
  }

  return icons;
};

const makeBankLinkIcons = (bl, options) => {
  var icons = [];

  if (bl?.latestIdCheck) {
    if (bl?.identityMismatch) icons.push({ icon: 'Fingerprint', color: 'var(--c-bad-0)', text: 'Mismatch' });
    else icons.push({ icon: 'Fingerprint', color: 'var(--c-good-0)', text: 'Match' });
  } else icons.push({ icon: 'Fingerprint', color: 'var(--c-border)', text: 'Not checked' });

  return icons;
};

export const isAfter = (datestring, { year = '22', isOpen, fetched, updated }) => {
  const lastFetched = Date.parse(fetched);
  const lastUpdated = Date.parse(updated);
  const date = !!fetched ? lastFetched : lastUpdated;
  return year === '22' && !isOpen && date >= Date.parse(datestring);
};

export const isSOEP = ({ year, isOpen, fetched, updated } = { year: '22' }) => {
  return isAfter(SOEP, { year, isOpen, fetched, updated });
};

export const isARP = ({ year, isOpen, fetched, updated } = { year: '22' }) => {
  return isAfter(ARP, { year, isOpen, fetched, updated });
};

const makeTaxLabels = (task, options) => {
  var labels = [];
  if (task?.isAuto || task?.taxPayment?.isAuto) labels.push('AUTO');
  else labels.push('manual');
  return labels;
};

const makUserLabels = (person, options) => {
  var labels = [];

  if (person?.payrollProductTier) labels.push(person?.payrollProductTier);
  if (person?.signupContext) labels.push(person?.signupContext);

  return labels;
};

export const makeLabels = (entry, options) => {
  const rowType = entry?.__typename;

  if (rowType === 'HealthEnrollment') return makeHealthEnrollmentLabels(entry, options);
  else if (rowType === 'TaxPaymentTask') return makeTaxLabels(entry, options);
  else if (rowType === 'PersonReference') return makUserLabels(entry, options);
  else return [];
};

export const makeLabelIcons = (entry, options) => {
  const rowType = entry?.__typename;

  if (rowType === 'HealthEnrollment') return makeHealthEnrollmentIcons(entry, options);
  if (rowType === 'BankLink') return makeBankLinkIcons(entry, options);
  else return [];
};

import { gql } from '../generated';

export const COUNTIES = gql(`
  query Counties($zipcode: ZipCode!) {
    reference {
      counties(zipcode: $zipcode) {
        fips
        name
        state
        zipcode
      }
      health {
        ffmStates {
          code
        }
      }
    }
  }
`);

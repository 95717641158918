import { gql } from '@apollo/client';

export const IDENTITY = gql`
  query IdentityQuery($id: ID) {
    viewer(id: $id) {
      id
      sessionInformation {
        loginHistory {
          deviceType
          loginTimeStamp
          version
        }
      }
    }
    viewerTwo(id: $id) {
      id
      unitIdentity: identity(providerType: UNIT) {
        id
        providerType
        transactionID
        sessionID
        confirmationID
        isTransactionIDExpired
        finalDecisionCode
        status
      }
      edeIdentity: identity(providerType: CMS) {
        id
        providerType
        transactionID
        sessionID
        confirmationID
        isTransactionIDExpired
        finalDecisionCode
        status
      }
      existingApplicationInfo {
        existingApplications {
          id
          familyName
          givenName
          state
          matchesWithAnotherUser
          coverageYearNumber
          applicationStatus
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { getCarrierLogo } from '@catch-co/health-utils';
import { formatDateOnly, enrollmentName } from 'src/web/utility';
import { GoalCard, Banner, CostRow } from 'src/web/components';
import { STATES } from 'src/_utils';

const SContainer = styled.div`
  position: relative;
  opacity: ${p => (p.isInactive && !p.selected ? 0.35 : !p.isInactive ? 1 : 0.65)};
  cursor: var(--cursor);
`;

const SInner = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: ${p => (p.hasContent ? '24px' : 0)};
`;

const SEnrollment = styled.div`
  padding: 0 12px;
  background: var(--cCard2);
  border: 1px solid var(--c-border);
  border-radius: var(--radiusLarge);
`;

export default ({
  planYear,
  type,
  status,
  startDate,
  endDate,
  issues,
  children,
  onPress,
  selected,
  isImport,
  isLink,
  compact,
  pathwayType,
  isOEP,
  isSOEP,
  origin,
  route,
  labels = [],
  labelIcons = [],
  issuer,
  grossPremium,
  netPremium,
  name,
  state,
  isDeleted,
  aptc,
  isApplication,
}) => {
  const numIssues = issues?.actionNeeded?.length;
  const numPending = issues?.pending?.length;
  const isPrefill = status === 'PREFILL';
  const isDone = status === 'INACTIVE' || status === 'CANCELLED' || status === 'EXPIRED';
  const hasIssues = numIssues > 0 || numPending > 0;
  const hasChildren = !!children && children?.props && !!Object.keys(children?.props).length > 0;

  return (
    <SContainer
      compact={compact}
      onClick={onPress}
      selected={selected}
      isInactive={
        isDeleted ||
        isDone ||
        isPrefill ||
        status === 'EXPLORER' ||
        status === 'RENEWAL' ||
        status === 'TERMINATED'
      }
    >
      <GoalCard
        small={compact}
        labels={[...labels]}
        labelIcons={labelIcons}
        selected={selected}
        name={name || `${planYear || '–'} ${enrollmentName(type)}`}
        status={status}
        icon={!state ? null : `/img/states/dark/${STATES[state]?.toUpperCase()}.svg`}
        logo={!state ? `https://s.catch.co/img/carriers/${getCarrierLogo(issuer)}.png` : null}
        iconColor={name ? undefined : 'var(--c-red-1)'}
        onPress={onPress}
        subtitle={
          <>
            {startDate
              ? `${formatDateOnly({ date: startDate, length: 'MEDIUM' })}
        ${!!endDate ? ' – ' : ''}
        ${formatDateOnly({ date: endDate, length: 'MEDIUM' })}`
              : `${
                  !!isLink
                    ? 'Linked'
                    : !!isImport
                    ? 'Imported'
                    : origin === 'CATCH'
                    ? 'Originated'
                    : origin?.toProperCase() || ''
                } ${pathwayType?.toProperCase()} 
                ${!isOEP ? (!!isSOEP ? 'SOEP' : '') : !!isOEP ? 'OEP' : ''}`}
            <span style={{ opacity: 0.5 }}>{route ? `(${route.toLowerCase()})` : ``}</span>
          </>
        }
      >
        {(!!grossPremium || !!isApplication) && (
          <CostRow
            compact={compact}
            value={isApplication ? aptc : netPremium}
            prevValue={grossPremium}
            isSavings={isApplication}
          />
        )}
      </GoalCard>

      <SInner hasContent={hasIssues || hasChildren}>
        {numIssues > 0 && (
          <Banner tiny background="var(--c-red-1)" title={`${numIssues} issues need action`} compact />
        )}
        {numPending > 0 && (
          <Banner tiny background="var(--c-orange-1)" title={`${numPending} issues in review`} compact />
        )}

        {hasChildren && <SEnrollment>{children}</SEnrollment>}
      </SInner>
    </SContainer>
  );
};

import { gql } from '../generated';

export const UPSERT_PUBLIC_EXPLORER = gql(`
  mutation UpsertPublicExplorer($input: PublicHealthExplorerDataInput!) {
    upsertPublicHealthExplorerData(input: $input) {
      id
      zipcode
      countyfips
      coverageYear
      state
      income
      providerPlanID
      pathwayType
      email
      financialPreference
      plannedUsage
      drugsWanted {
        ...Drug
      }
      providersWanted {
        ...Provider
      }
      deps {
        ...PublicExplorerMember
      }
      dependents {
        age
      }
      providerPlanID
      healthPlans {
        plans {
          healthPlan {
            planID: id
            name
            type
            metalLevel
            premium
            premiumWithCredit
            benefitsURL
            issuer {
              id
              name
            }
          }
        }
      }
    }
  }
`);

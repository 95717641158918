import { Entity, IRow, RowGroup } from 'src/web/components';
import { CompactCard } from 'src/web/components/health/ApplicationIssue';
import { DateFormat } from 'src/utils';
import { GET_BANK_LINKS, useFintechQuery } from 'src/data';

const openPlaid = bank => {
  window.open(`https://dashboard.plaid.com/activity/status/institution/${bank?.plaidBank?.id}`, '_blank');
};

export interface BankLinkNodeProps {
  customerID: string;
  bankLinkID: string;
  expanded?: boolean;
}

export function BankLinkNode({ customerID, bankLinkID, expanded }: BankLinkNodeProps) {
  const { loading, data } = useFintechQuery(GET_BANK_LINKS, {
    variables: { customerID },
    skip: !customerID,
  });

  const bankLink = data?.customer?.bankLink?.find(link => link.id == bankLinkID);

  // @todo: allow admins to check plaid identity
  const refreshPlaidIdentityInformation = () => {};

  // const stid = !!bank?.identityErrorCode
  //   ? 'Error'
  //   : !bank?.latestIdCheck
  //   ? 'Owner Unverified'
  //   : !!bank?.identityMismatch
  //   ? 'Owner Mismatch'
  //   : 'Owner Match';

  return (
    <Entity
      nodeType="Bank Link"
      title={bankLink?.institution?.name || 'Institution'}
      expanded={expanded}
      status={bankLink?.status}
      logo="/img/plaid.png"
      debugData={bankLink}
      id={bankLinkID}
      actions={[
        { pretitle: 'go to', text: 'Plaid', logo: '/img/plaid.png', onPress: () => openPlaid(bankLink) },
      ]}
    >
      <IRow label="Plaid Institution ID" type="mono" value={bankLink?.institution?.id} />
      <IRow label="First Linked At" type="mono" value={bankLink?.dateAdded} />
      <IRow label="Last Synced At" format={DateFormat.LONG} type="date" value={bankLink?.lastGoodSync} />
      {/* <IRow
        label="Consent expires"
        type="date"
        format={DateFormat.MEDIUM}
        value={bank?.consentExpirationTime}
      /> */}

      {/* {!!bank?.latestIdCheck && (
        <RowGroup title="Owner">
          <>
            <IRow
              label="Status"
              value={stid}
              displayValue={stid === 'Error' ? bank?.identityErrorCode?.toProperCase() : stid}
              type="status"
            />
            <IRow label="Checked" value={bank?.latestIdCheck} type="date" />
            <RowGroup title="Owner Info" subtle close borderless>
              {bank?.identityPayload?.map((item, idx) => (
                <CompactCard
                  key={idx}
                  title={item?.names?.[0]}
                  subtitle={bank?.bankName}
                  detail1={item?.emails?.[0]}
                  detail3={item?.phoneNumbers?.[0]}
                  detail2={item?.addresses?.[0]}
                  status={
                    item?.identityError ? 'Unable to verify' : item?.identityMismatch ? 'Mismatch' : 'Match'
                  }
                />
              ))}
            </RowGroup>
          </>
        </RowGroup>
      )} */}
    </Entity>
  );
}

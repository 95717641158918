type EnvConfig = {
  [key: string]: string | undefined;
};

export const env = {
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL as string,
  FINTECH_URL: process.env.REACT_APP_FINTECH_URL as string,
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL as string,
  HYGRAPH_URL: process.env.REACT_APP_HYGRAPH_URL as string,
};

// throws warning if anything is undefined
function checkEnv(config: EnvConfig): void {
  Object.entries(config).forEach(([key, value]) => {
    if (!value) {
      console.warn(`Warning: Environment variable ${key} is not set.`);
    }
  });
}

// run the environment check
checkEnv(env);

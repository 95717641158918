import React, { useState } from 'react';
import styled from 'styled-components';
import { size } from 'src/web/styles';
import { EDE_ROUTES, PARTNER_ROUTES, EDE_ROUTES_RENEW } from 'src/web/utility';

import { useMutation } from '@apollo/client';
import { UPSERT_LAST_ROUTE } from '../data';

const StyledRoutes = styled.div`
  padding: 4px 0;
`;

const StyledRoute2 = styled.div`
  padding: 6px 24px 6px ${size.iconLg + size.iconLgMargin}px;
  display: flex;
  align-items: center;
  font-size: var(--fp);
  font-weight: ${p => (p.active ? 600 : p.visited ? 500 : 500)};
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  transition: 0.25s var(--transitionCurve);
  cursor: ${p => (p.visited ? 'var(--cursor)' : 'default')};

  color: ${p => (p.active ? 'var(--c-fg-0)' : p.visited ? 'var(--c-good-0)' : 'var(--c-fg-2)')} !important;
  &:hover {
    color: ${p => (p.visited ? 'var(--c-fg-0)' : 'var(--c-fg-2)')} !important;
    .dot {
      opacity: ${p => (p.visited ? 1 : '')};
      background: ${p => (p.visited ? 'var(--c-fg-0)' : '')};
      transform: ${p => (p.visited ? 'scaleX(0.75) scaleY(0.75)' : '')};
    }
  }
`;

const StyledDot2 = styled.div`
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 1px;
  left: ${size.iconLg / 2 + 1}px;
  border-radius: 1px;
  transform: scaleX(0.75);
  transform-origin: center;
  opacity: ${p => (p.active ? 1 : p.visited ? 0.75 : 0.25)};
  transition: 0.25s var(--transitionCurve);
  background: ${p => (p.active ? 'var(--c-fg-0)' : p.visited ? 'var(--c-good-0)' : 'var(--c-fg-1)')};
`;

const Blank = styled.div`
  height: 8px;
`;

export const PathPane = ({ routeName = '', partner, onPress, appId, uid, rowProps, renew }) => {
  const routes = !!partner ? PARTNER_ROUTES : renew ? EDE_ROUTES_RENEW : EDE_ROUTES;
  const selectedIdx = routes?.indexOf(routeName);

  const [upsert, { data, loading, error }] = useMutation(UPSERT_LAST_ROUTE, {
    refetchQueries: ['HealthEnrollment', 'HealthInspectorNew', 'HealthEnrollmentBoardSingleFast'],
  });

  return (
    <Internal2
      routeName={routeName}
      routes={routes}
      selectedIdx={selectedIdx}
      onPress={route => {
        upsert({
          variables: {
            personId: uid,
            input: {
              id: appId,
              lastUsedRoute: route,
            },
          },
        });
      }}
    />
  );
};

const Internal2 = ({ routeName, routes, selectedIdx, onPress }) => {
  const [hRoute, setHRoute] = useState(selectedIdx);

  return (
    <StyledRoutes onMouseLeave={() => setHRoute(selectedIdx)}>
      {routes?.map((route, idx) =>
        route === '_' ? (
          <Blank />
        ) : (
          <StyledRoute2
            onMouseEnter={e => {
              setHRoute(idx <= selectedIdx ? idx : selectedIdx);
            }}
            onClick={() => onPress(route)}
            active={route === routeName}
            visited={idx <= selectedIdx}
            key={route}
            open={true}
          >
            <StyledDot2
              className="dot"
              active={route === routeName && idx <= hRoute}
              visited={idx <= hRoute}
            />
            {route?.replaceAll('-', ' ')?.toProperCase()}
          </StyledRoute2>
        ),
      )}
    </StyledRoutes>
  );
};

export default PathPane;

import React, { useState } from 'react';
import styled from 'styled-components';

import { FontIcon } from 'src/web/components';

const StyledAccordion = styled.div`
  font-size: var(--fp);
  opacity: ${p => (p.disabled ? 0.25 : 1)};
  font-weight: 500;
  transform-origin: top;
  margin-bottom: 4px;
  padding: 8px;
  background: ${p => (p.open ? 'var(--cCard3)' : 'transparent')};
  border-radius: var(--radius);
  overflow: hidden;
  &:hover {
    color: var(--cCard);
  }
`;

const StyledFilterHeader = styled.div`
  font-size: var(--fp);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0;
  cursor: var(--cursor);
  color: ${p => (p.open ? 'var(--fg)' : 'var(--cTextSubtle)')};
  .b-size-collapse {
    transition: transform var(--transition);
    transform: scaleX(0.4) scaleY(0.5) rotate(${p => (p.open ? 90 : 0)}deg);
    opacity: 0.5;
    margin-right: 4px;
  }
  > div {
    transition: transform 250ms;
  }
`;

const StyledFilterBody = styled.div`
  padding: ${p => (p.open ? 4 : 0)}px 8px ${p => (p.open ? 4 : 0)}px 20px;
  max-height: ${p => (p.open ? p.num * 22 + 12 : 0)}px;
  overflow: hidden;
  transition: ${p => p.num * 0.01 + 0.15}s var(--transitionCurve);
  box-sizing: border-box;
`;

const Accordion = ({ title, children, disabled }) => {
  const [open, setOpen] = useState();

  return (
    <StyledAccordion disabled={disabled} open={open}>
      <StyledFilterHeader onClick={() => setOpen(!open)} open={!!open}>
        <FontIcon icon="play" className="b-size-collapse" />
        <div>{title}</div>
      </StyledFilterHeader>
      <StyledFilterBody open={!!open} num={children?.length}>
        {children}
      </StyledFilterBody>
    </StyledAccordion>
  );
};

export default Accordion;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserView } from './UserView';
import { UserListView } from './UserListView';
import { AppSection } from 'src/web/components';

export function UsersView() {
  return (
    <AppSection>
      <Routes>
        <Route path="/:userId/*" element={<UserView />} />
        <Route path="/" element={<UserListView />} />
      </Routes>
    </AppSection>
  );
};

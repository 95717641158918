import React from 'react';
import JSONTree from 'react-json-tree';
import { FullModal, IRow, Modal, RowGroup } from 'src/web/components';

export interface DebugViewProps {
  id?: string;
  type?: string;
  data?: object;
  onClose: () => void;
  isOpen: boolean;
}

const DebugView = ({ data = {}, onClose, isOpen, type, id }: DebugViewProps) => {
  return (
    <FullModal
      tall
      presentation="largeSheet"
      onRequestClose={onClose}
      isOpen={isOpen}
      title="Debug"
      subtitle={type}
      sidebarItem={
        <RowGroup borderless>
          <IRow mono hideUnset label="Type" value={type} />
          <IRow mono hideUnset label="ID" value={id} />
        </RowGroup>
      }
    >
      <div className="tree">
        <DebugTree data={data} />
      </div>
    </FullModal>
  );
};

export const DebugTree = ({ data = {}, modal }: { data?: object; modal?: boolean }) => {
  const colors = {
    base00: 'var(--cCard)',
    base01: 'var(--c-fg-2)',
    base02: 'var(--c-fg-2)',
    base03: 'var(--c-fg-2)',
    base04: 'gray',
    base05: 'var(--c-cerise-0)',
    base06: 'blue',
    base07: 'var(--c-orange-0)',
    base08: 'var(--c-fg-2)',
    base09: 'var(--c-cerise-0)',
    base0A: 'var(--c-cerise-0)',
    base0B: 'var(--c-good-0)',
    base0C: 'yellow',
    base0D: 'var(--c-fg-1)',
    base0E: 'red',
    base0F: 'purple',
  };

  return (
    <div className="treeraw" style={!modal && { margin: '0 -16px' }}>
      <JSONTree
        shouldExpandNode={(keyPath, data, level) => level < 2}
        hideRoot
        sortObjectKeys
        className="tree"
        invertTheme={false}
        theme={colors}
        data={data}
      ></JSONTree>
    </div>
  );
};

export default DebugView;

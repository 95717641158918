import React from 'react';
import { useQuery } from '@apollo/client';

import { Scroll, Spinner, UploadTile } from 'src/web/components';
import { UPLOADS } from 'src/web/data';
import { useInspector, SelectedType } from 'src/hooks/useInspector';

export function UploadsPage({ userId }) {
  const { selectedId, setSelected } = useInspector();

  const { data, error, loading } = useQuery(UPLOADS, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  const docs = data?.viewerTwo?.documentUploads;
  if (error || !data) return null;

  return (
    <Scroll loading={loading}>
      {docs?.length > 0 ? (
        docs?.map((item, idx) => (
          <UploadTile
            key={item.id}
            id={item.id}
            fileType={item.ext}
            createdOn={item.createdOn}
            name={item.documentType}
            selected={selectedId === item.id}
            imageUrl={item.url}
            onPress={() => setSelected(item.id, { type: SelectedType.UPLOAD })}
          />
        ))
      ) : (
        <div className="full">{loading ? <Spinner /> : 'No uploads'}</div>
      )}
    </Scroll>
  );
};
import React from 'react';
import { useQuery } from '@apollo/client';

import { makeName } from 'src/web/utility';

import { Spinner, IRow, RowGroup, Scroll, IdentityCard } from 'src/web/components';
import { IDENTITY } from 'src/web/data/queries';
import { useUserSummary } from 'src/web/hooks';
import { CompactCard } from 'src/web/components/health/ApplicationIssue';
import { IdentityFinding } from 'src/web/types/identity';

export function IdentityPage({ userId, inInspector }) {
  const {
    email,
    phone,
    dob,
    ssn,
    kycStatus,
    legalAddress,
    familyName,
    givenName,
    nickname,
    middleName,
  } = useUserSummary({
    id: userId,
  });

  const { data: idData, loading } = useQuery(IDENTITY, {
    variables: { id: userId },
    skip: !userId,
  });

  const banks = idData?.viewer?.bankLinks;

  // new
  const _unit = idData?.viewerTwo?.unitIdentity;
  const _ede = idData?.viewerTwo?.edeIdentity;
  const _edeApps = idData?.viewerTwo?.existingApplicationInfo;

  const hasPlaidResult = banks?.filter((bank, idx) => !!bank?.identityPayload?.[0]?.names?.[0]);

  const kyc_map = {
    KYC_GOOD: 'verified',
    KYC_REVIEW: 'in_review',
    KYC_PENDING: 'in_review',
    KYC_DENIED: 'rejected',
  };

  var tidbits: Array<IdentityFinding> = [];
  if (nickname)
    tidbits.push({
      value: nickname,
      type: 'Nickname',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (givenName)
    tidbits.push({
      value: givenName,
      type: 'First Name',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (middleName)
    tidbits.push({
      value: middleName,
      type: 'Middle Name',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (givenName)
    tidbits.push({
      value: makeName({ familyName, givenName, middleName }),
      type: 'Full Name',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (familyName)
    tidbits.push({
      value: familyName,
      type: 'Last Name',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (email)
    tidbits.push({
      value: email,
      type: 'Email',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (phone)
    tidbits.push({
      value: phone?.replace(/-/g, '')?.replace('+1', ''),
      type: 'Phone',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (legalAddress?.city)
    tidbits.push({
      value: legalAddress,
      type: 'Address',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (ssn && ssn !== '•••-••-null')
    tidbits.push({
      value: ssn,
      type: 'SSN',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });
  if (dob)
    tidbits.push({
      value: dob,
      type: 'DOB',
      source: 'provided',
      verification: [{ status: kyc_map[kycStatus], provider: 'unit' }],
    });

  // todo matches with another user

  (_edeApps?.existingApplications || [])
    ?.sort((a, b) => b.coverageYearNumber - a.coverageYearNumber)
    ?.forEach(app => {
      tidbits.push({
        value: app?.givenName,
        type: 'First Name',
        source: 'ede',
        verification: [{ status: 'third_party', provider: 'ede' }],
        note: `${app.coverageYearNumber} ${app?.state} application`,
      });
      tidbits.push({
        value: app?.familyName,
        type: 'Last Name',
        source: 'ede',
        verification: [{ status: 'third_party', provider: 'ede' }],
        note: `${app.coverageYearNumber} ${app?.state} application`,
      });
      tidbits.push({
        value: makeName({ familyName: app?.familyName, givenName: app?.givenName }),
        type: 'Full Name',
        source: 'ede',
        verification: [{ status: 'third_party', provider: 'ede' }],
        note: `${app.coverageYearNumber} ${app?.state} application`,
      });
      tidbits.push({
        value: { state: app?.state },
        type: 'Address',
        source: 'ede',
        verification: [{ status: 'third_party', provider: 'ede' }],
        note: `${app.coverageYearNumber} ${app?.state} application`,
      });
    });

  banks
    ?.filter(b => !!b?.identityPayload)
    ?.forEach((bank, idx) => {
      const pl = bank.identityPayload?.[0];
      pl?.names?.map(name =>
        tidbits.push({
          value: name,
          type: 'Full Name',
          source: 'plaid',
          verification: [{ status: 'third_party', provider: 'plaid' }],
          note: `${bank.bankName} account owner`,
          logo: bank?.plaidBank?.logo,
          systemMismatch: !!bank?.identityMismatch,
        }),
      );
      pl?.emails?.map(email =>
        tidbits.push({
          value: email,
          type: 'Email',
          source: 'plaid',
          verification: [{ status: 'third_party', provider: 'plaid' }],
          note: `${bank.bankName} account owner`,
          logo: bank?.plaidBank?.logo,
          match: !bank?.identityMismatch,
          systemMismatch: !!bank?.identityMismatch,
        }),
      );
      pl?.phoneNumbers?.map(phone =>
        tidbits.push({
          value: phone?.replace(/-/g, '')?.replace('+1', ''),
          type: 'Phone',
          source: 'plaid',
          verification: [{ status: 'third_party', provider: 'plaid' }],
          note: `${bank.bankName} account owner`,
          logo: bank?.plaidBank?.logo,
          systemMismatch: !!bank?.identityMismatch,
        }),
      );
      pl?.addresses?.map(address =>
        tidbits.push({
          value: address,
          type: 'Address',
          source: 'plaid',
          verification: [{ status: 'third_party', provider: 'plaid' }],
          note: `${bank.bankName} account owner`,
          logo: bank?.plaidBank?.logo,
          systemMismatch: !!bank?.identityMismatch,
        }),
      );
    });

  return (
    <>
      <Scroll transparent={inInspector} loading={loading}>
        {!!loading && !idData ? (
          <div className="full">
            <Spinner />
          </div>
        ) : (
          <div>
            <RowGroup borderless title="Provided Info">
              <IRow noMax label="Preferred Name" value={nickname} />
              <IRow noMax label="First Name" value={givenName} />
              {/* <IRow right label="Middle Name" value={middleName} /> */}
              <IRow noMax label="Last Name" value={familyName} />
              {/* <IRow right label="Email" value={email} />
              <IRow right type="Phone" label="Phone" value={phone} />
              <IRow right label="Address" value={makeAddress(legalAddress)} />
              <IRow right mono label="SSN" value={ssn} />
              <IRow right type="dateOnly" mono label="DOB" value={dob} /> */}
              {/* <IRow right mono label="IP Address" value={ipAddress} /> */}
              <br />
              <IRow noMax label="KYC" type="status" value={kycStatus} />
              <IRow
                noMax
                type="status"
                label="Owner Checks"
                value={
                  hasPlaidResult?.length > 0
                    ? 'Able to Check'
                    : banks?.length > 0
                    ? 'Unable to Verify'
                    : 'Nothing Linked'
                }
              />
              <IRow
                noMax
                label="Experian IDP"
                type="status"
                displayValue={
                  _ede?.status?.toProperCase() +
                  (_ede?.finalDecisionCode ? ` (${_ede?.finalDecisionCode})` : '')
                }
                value={_ede?.status}
              />
              <br />
              <IdentityCard
                inInspector={inInspector}
                type={'Full Name'}
                primary={tidbits?.filter(t => t.type === 'Full Name' && t.source === 'provided')?.[0]}
                searches={tidbits?.filter(t => /Name/.test(t.type) && t.source === 'provided')}
                findings={tidbits?.filter(t => t.type === 'Full Name')}
              />
              <IdentityCard
                inInspector={inInspector}
                type={'Email'}
                primary={tidbits?.filter(t => t.type === 'Email' && t.source === 'provided')?.[0]}
                findings={tidbits?.filter(t => t.type === 'Email')}
              />
              <IdentityCard
                inInspector={inInspector}
                type={'Phone'}
                primary={tidbits?.filter(t => t.type === 'Phone' && t.source === 'provided')?.[0]}
                findings={tidbits?.filter(t => t.type === 'Phone')}
              />
              <IdentityCard
                inInspector={inInspector}
                type={'Address'}
                primary={tidbits?.filter(t => t.type === 'Address' && t.source === 'provided')?.[0]}
                findings={tidbits?.filter(t => t.type === 'Address')}
              />
              <IdentityCard
                inInspector={inInspector}
                type={'SSN'}
                primary={tidbits?.filter(t => t.type === 'SSN' && t.source === 'provided')?.[0]}
                findings={tidbits?.filter(t => t.type === 'SSN')}
              />
              <IdentityCard
                inInspector={inInspector}
                type={'DOB'}
                primary={tidbits?.filter(t => t.type === 'DOB' && t.source === 'provided')?.[0]}
                findings={tidbits?.filter(t => t.type === 'DOB')}
              />
            </RowGroup>

            <RowGroup title="Records" close>
              <RowGroup title="Apps" borderless subtle>
                {_edeApps?.existingApplications?.map(app => (
                  <div style={{ marginLeft: inInspector ? 0 : 185 }} key={app?.id}>
                    <CompactCard
                      title={makeName(app)}
                      subtitle="Health Lookup"
                      status={app.applicationStatus}
                      detail1={app.coverageYearNumber}
                      detail2={
                        app.matchesWithAnotherUser ? 'Note: this was also found on another Catch user' : null
                      }
                      detail3={app.state}
                    />
                  </div>
                ))}
              </RowGroup>
              <RowGroup title="Banks" borderless subtle>
                {banks
                  ?.filter(b => !!b?.identityPayload)
                  ?.map((bank, idx) => (
                    <div style={{ marginLeft: inInspector ? 0 : 185 }} key={bank?.id}>
                      <CompactCard
                        title={bank?.identityPayload?.[0]?.names?.[0]}
                        subtitle={bank?.bankName}
                        detail1={bank?.identityPayload?.[0]?.emails?.[0]}
                        detail3={bank?.identityPayload?.[0]?.phoneNumbers?.[0]}
                        detail2={bank?.identityPayload?.[0]?.addresses?.[0]}
                        status={
                          bank?.identityErrorCode
                            ? 'Unable to verify'
                            : bank?.identityMismatch
                            ? 'Mismatch'
                            : 'Match'
                        }
                      />
                    </div>
                  ))}
              </RowGroup>
            </RowGroup>
          </div>
        )}
      </Scroll>
    </>
  );
};

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { groupBy, STATES } from 'src/web/utility';
import { HealthEnrollment, RowGroup } from 'src/web/components';
import { makeLabelIcons } from 'src/web/utility/tasks';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { USER_APPLICATIONS } from 'src/data';
import { Scroll } from 'src/kit';

const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017];

export function ApplicationsPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();

  const { loading, data } = useQuery(USER_APPLICATIONS, {
    variables: {
      id: userId,
      input: { includeDeleted: true },
    },
    skip: !userId,
  })

  const apps = useMemo(() => {
    return groupBy(data?.viewerTwo?.health?.applications || [], 'coverageYearNumber');
  }, [data]);

  return (
    <Scroll loading={loading} transparent={inInspector}>
      {Object.keys(apps).length === 0 && (
        <div className="full">No policies</div>
      )}

      {years.map(year => {
        if (!apps[year] || apps[year].length === 0) {
          return null;
        }

        return (
          <RowGroup key={year} title={year} close={year < 2021}>
            {apps[year].map(app => (
              <HealthEnrollment
                key={app.id}
                isApplication
                labelIcons={makeLabelIcons(app)}
                compact={inInspector}
                id={app?.insuranceApplicationIdentifier}
                type={`${STATES[app.coverageState]}`}
                state={app.coverageState}
                isDeleted={app?.isDeleted}
                labels={[
                  app?.isDeleted && 'DELETED',
                  app.applicationContext || undefined,
                  app.insuranceApplicationIdentifier &&
                    `${app.insuranceApplicationIdentifier}${
                      app.applicationVersionNum ? ' v' + app.applicationVersionNum : ''
                    }`,
                ]}
                aptc={app?.isEligibilityDetermined ? app?.aptcHouseholdAmount : '––.––'}
                selected={selectedId === app?.id}
                status={app.status}
                onPress={() => setSelected(app?.id, { type: SelectedType.APP })}
                planYear={app?.coverageYearNumber}
                pathwayType={app?.pathwayType}
                origin={app?.applicationOrigin}
                route={app?.lastUsedRoute}
                isImport={app?.applicationOrigin === 'IMPORT'}
                isLink={app?.applicationOrigin === 'LINK_STRICT'}
              />
            ))}
          </RowGroup>
        );

      })}
    </Scroll>
  );
};

import { FieldProps } from 'src/forms';
import { create } from 'zustand';

export interface EditState {
  // toggles the modal open/close
  isOpen: boolean;

  // stored values for edit instance
  title?: string;
  initialValues: object;
  fields: Array<FieldProps>;
  onSubmit: (values: object) => void;

  edit: ({
    title,
    initialValues,
    fields,
    onSubmit,
  }: {
    initialValues: object;
    title?: string;
    fields: Array<FieldProps>;
    onSubmit: (values: object) => void;
  }) => void;

  close: () => void;
}

export const useEdit = create<EditState>((set, get) => ({
  isOpen: false,
  title: '',
  initialValues: {},
  fields: [],
  onSubmit: () => {},

  // opens the modal
  edit: ({ title, initialValues, fields, onSubmit }) => {
    set({ title, initialValues, fields, onSubmit, isOpen: true });
  },

  // closes the modal
  close: () => {
    set({
      isOpen: false,
      initialValues: {},
      fields: [],
      onSubmit: () => {},
    });
  },
}));

import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  background: ${p => (p.selected ? 'var(--c-border-light)' : 'transparent')};
  padding: 16px 12px;
  margin: 0 ${p => (p.small ? 0 : -10)}px 1px;
  min-width: 350px;
  cursor: var(--cursor);
  border-radius: var(--radiusLarge);
  font-size: var(--fp);
  &:hover {
    background: ${p => (p.selected ? 'var(--c-border-light)' : 'var(--c-border-light)')};
    z-index: 999;
  }
`;


export interface CardProps {
  selected?: boolean;
  onPress?: () => void;
  children: React.ReactNode;
}

export function Card({
  selected,
  onPress,
  children,
}: CardProps) {
  return (
    <StyledCard
      className={`goalCard fadeInSubtle flex-h ${!!selected && 'selected'}`}
      selected={selected}
      onClick={e => {
        e.stopPropagation();
        if (onPress) onPress();
      }}
    >
      <div className="grow">
        <div className="flex-h">
          {children}
        </div>
      </div>
    </StyledCard>
  );
};
import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.div`
  border-radius: ${p => (p.square ? 'var(--radiusLarge)' : '999px')};
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  transition: box-shadow 500ms, background-color 500ms;
  z-index:99999;
  overflow-x: hidden;
  cursor:${p => (p.onClick ? 'default' : 'default')};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${p => p.logo || p.icon}");
  background-size: ${p =>
    !!p.logo
      ? p.fill
        ? '100%'
        : p.contain
        ? '50%'
        : p.containLg
        ? '75%'
        : 'cover'
      : p.containLg
      ? '75%'
      : '60% '};
`;

const Icon = ({
  logo,
  icon,
  contain,
  border,
  size = 60,
  invert,
  noShadow,
  style,
  iconColor,
  iconBorderColor,
  onPress,
  containLg,
  loading,
  render,
  fill,
  square,
}) => {
  const n = icon?.lastIndexOf('.');
  const url =
    icon?.substring(0, n) +
    ((!render && 'var(--c-fg-0)' === '#FFFFFF') || !!invert ? '_dark' : '') +
    icon?.substring(n);

  return (
    <StyledLogo
      className="c-icon"
      logo={!!loading ? null : logo}
      icon={!!loading ? null : !!icon && url}
      contain={contain}
      containLg={containLg}
      border={border}
      onClick={onPress}
      size={size}
      noShadow={noShadow}
      square={square}
      fill={fill}
      style={{
        ...style,
        backgroundColor: !!loading ? 'var(--c-border-light)' : iconColor || 'transparent',
        boxShadow: !!loading
          ? 'none'
          : !!iconBorderColor
          ? `0 0 0px 2px ${'var(--c-bg-0)'}, 0 0 0px 4px ${iconBorderColor}`
          : ``,
      }}
    />
  );
};

export default Icon;

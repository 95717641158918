import { useMemo } from 'react';
import styled from 'styled-components';
import { copyToClipboard } from 'src/web/utility';
import { Env } from 'src/_utils';
import { ToolbarButton, I, IconType } from 'src/web/components';
import { Priority, useKBar, useRegisterActions, VisualState } from 'kbar';
import { KACTIONS } from '../kbar';
import { useSelection } from 'src/util/selection';
import { useModal } from 'src/util/modals';
import { CreateUserModalContent } from 'src/components/users/CreateUserModalContent';
import { useToken } from 'src/global';

const StyledToolbar = styled.div`
  z-index: 9999999;
  height: var(--navHeight);
  -webkit-app-region: drag;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0px;
  right: ${p => (p.overInspector ? 'calc(0 * var(--inspector))' : 0)};
  backdrop-filter: blur(25px);
  top: 0px;
  padding: 0 16px 0 var(--padding);
  z-index: 999999;
  font-size: var(--p);
  font-weight: 500;
  border-bottom: 1px solid ${p => (p.borderless ? 'transparent' : 'var(--c-border)')};
  border-left: ${p => (p.inspector && !p.borderless ? '1px solid var(--c-border)' : '')};
  background: ${p =>
    p.hasAlert
      ? 'var(--cCredit)'
      : p.hasChange
      ? 'var(--cYellow)'
      : Env.isDesktop
      ? 'var(--bga)'
      : 'var(--cCard2)'};
  > div {
    width: 100%;
    height: var(--navHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
  }
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: calc(var(--fp));
  align-items: center;
  display: flex;
  line-height: 1;
  margin-right: 8px;
  color: ${p => (p.hasAlert ? 'var(--bg)' : 'var(--fg)')};
`;

const StyledSubtitle = styled.div`
  font-weight: 400;
  font-size: calc(var(--fp));
  line-height: 1;
  color: ${p => (p.hasAlert ? 'var(--c-bg-1)' : 'var(--c-fg-1)')};
`;

export const StyledBottomBar = styled.div`
  padding: 24px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: var(--cCard2);
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledTitleBlock = styled.div`
  display: flex;
`;

const StyledContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledActions = styled.div`
  flex: 0;
`;

interface PageAction {
  fill?: string;
  icon?: IconType;
  tooltip?: string;
  keyCombo?: string;
  disabled?: boolean;
  active?: boolean;
  onPress?: () => void;
}

export interface ToolbarProps {
  icon?: IconType;
  title?: string;
  subtitle?: string;
  pageActions?: Array<PageAction>;
  hideDefaultActions?: boolean;
  hasAlert?: boolean;
  hasChange?: boolean;
  borderless?: boolean;
  overInspector?: boolean;
  children?: React.ReactNode;
}

function Toolbar({
  icon,
  title,
  subtitle,
  pageActions = [],
  hideDefaultActions,
  hasAlert,
  hasChange,
  borderless,
  overInspector,
  children,
}: ToolbarProps) {
  const { privacyMode, togglePrivacyMode, toggleNavbar } = useSelection();
  const { query } = useKBar();
  const { openModal } = useModal();
  const fillColor = hasAlert ? 'var(--c-bg-0)' : 'var(--c-fg-0)';
  const { token } = useToken();

  const actions = useMemo(() => {
    const defaultActions: Array<PageAction> = [
      {
        fill: 'var(--cAdminAccent)',
        icon: 'Command',
        tooltip: 'Action Menu',
        keyCombo: '⌘K',
        onPress: () => query.setVisualState(VisualState.animatingIn),
      },
      {
        tooltip: 'Copy link',
        icon: 'Link',
        onPress: () => copyToClipboard(document.location),
        keyCombo: 'C+L',
      },
      {
        tooltip: privacyMode ? 'Show PII' : 'Hide PII',
        icon: 'Hide',
        onPress: togglePrivacyMode,
        active: privacyMode,
        keyCombo: 'P',
      },
    ];

    return hideDefaultActions ? pageActions : [...pageActions, ...defaultActions];
  }, [hideDefaultActions, pageActions]);

  useRegisterActions(
    [
      {
        ...KACTIONS.COPY_PAGE_LINK,
        perform: () => copyToClipboard(document.location),
      },
      {
        ...KACTIONS.COPY_TOKEN,
        subtitle: 'dev only',
        perform: () => copyToClipboard(token),
      },
      {
        ...KACTIONS.TOGGLE_PII,
        name: privacyMode ? 'Show PII' : 'Hide PII',
        subtitle: 'privacy mode',
        perform: togglePrivacyMode,
        priority: Priority.LOW,
      },
      {
        ...KACTIONS.CREATE_USER,
        perform: () => openModal(<CreateUserModalContent />),
      },
    ],
    [privacyMode],
  );

  return (
    <StyledToolbar
      hasAlert={hasAlert}
      hasChange={hasChange}
      borderless={borderless}
      overInspector={overInspector}
    >
      <StyledLeft>
        <ToolbarButton
          className="mobile-only"
          fill={fillColor}
          icon="Menu"
          onPress={toggleNavbar}
          style={{ marginLeft: -12, marginRight: 12 }}
        />
        {!!icon && (
          <I className="no-mobile" name={icon} color={fillColor} size={20} style={{ marginRight: 8 }} />
        )}
        <StyledTitleBlock>
          <StyledTitle hasAlert={hasAlert}>{title}</StyledTitle>
          {!!subtitle && (
            <StyledSubtitle className="mono no-mobile" hasAlert={hasAlert}>
              {subtitle}
            </StyledSubtitle>
          )}
        </StyledTitleBlock>
        <StyledContent>{children}</StyledContent>
      </StyledLeft>
      <StyledActions>
        {actions.map((action, idx) => (
          <ToolbarButton
            key={idx}
            disabled={action?.disabled}
            fill={hasAlert ? 'var(--c-bg-0)' : action?.fill || 'var(--c-fg-0)'}
            active={action?.active}
            icon={action?.icon}
            tooltip={action?.tooltip}
            onPress={action?.onPress}
            keyCombo={action?.keyCombo}
          />
        ))}
      </StyledActions>
    </StyledToolbar>
  );
}

export default Toolbar;

import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import { initials } from 'src/web/utility';
import { DebugView } from 'src/web/inspectors';
import { useUserSummary } from 'src/web/hooks';

import { Scroll, Header, IRow, RowGroup, IAction } from 'src/web/components';
import { SYNC_HEALTH } from 'src/web/data';
import { KACTIONS } from 'src/web/kbar';
import { useRegisterActions } from 'kbar';
import { InspectorPaneProps } from '../../types';
import { useModal, ModalType } from 'src/hooks/useModal';
import { useQuery, USER_POLICY } from 'src/data';

export function HealthPolicyPane({ userId, id }: InspectorPaneProps) {
  const { openModal } = useModal();
  const [debug, setDebug] = useState();
  const { givenName, familyName, avatarImage } = useUserSummary({ id: userId });

  const { loading, data } = useQuery(USER_POLICY, {
    skip: !id,
    variables: { personID: userId, policyID: id },
  });

  const [syncHealth, { loading: syncing }] = useMutation(SYNC_HEALTH, {
    variables: {
      personId: userId,
    },
  });

  const policy = useMemo(() => {
    return data?.viewerTwo?.health?.policy;
  }, [data]);

  useRegisterActions([{ ...KACTIONS.DEBUG, perform: () => setDebug(true) }]);

  return (
    <>
      <Scroll inspector loading={loading}>
        <Header
          pii
          avatar={avatarImage}
          title="Policy"
          initials={initials({
            givenName: givenName,
            familyName: familyName,
          })}
        >
          <IAction status={policy?.policyStatus} />
          <IAction icon="Edit" title="Edit" onPress={() => openModal(ModalType.UPDATE_POLICY, { userId, policy })} />
          <IAction {...KACTIONS.DEBUG} onPress={() => setDebug(true)} />


          <IAction
            {...KACTIONS.HEALTH_SYNC}
            onPress={syncHealth}
            loading={syncing}
            disabled
          
          />
        </Header>
        <RowGroup borderless>
          <IRow label="Start" value={policy?.startDate} type="dateOnly" />
          <IRow label="End" value={policy?.endDate} type="dateOnly" />
          <IRow label="Gross Prem" type="currency" value={policy?.premium} />
          <IRow label="APTC" type="currency" value={policy?.aptcAmount} />
          <IRow label="Net Prem" type="currency" value={policy?.premiumEffective} />
        </RowGroup>
        <RowGroup title="Commissions">
          <IRow label="Status" type="status" value={policy?.commissionStatus} />
          <IRow label="Last Paid" value={policy?.lastPaidCommission} type="dateOnly" />
        </RowGroup>
        <RowGroup title="Identifiers">
          <IRow
            mono
            label="CMS Policy "
            helpText="exchangePolicyNumber"
            value={policy?.exchangePolicyNumber}
          />
          <IRow
            mono
            label="CMS Subscriber"
            helpText="exchangeSubscriberID"
            value={policy?.exchangeSubscriberID}
          />
          <IRow
            mono
            label="Issuer Policy"
            helpText="Issuer-assigned. Not always available."
            value={policy?.policyNumber}
          />
          <IRow
            mono
            label="Issuer Subscriber"
            helpText="Issuer-assigned. Not always available."
            value={policy?.subscriberID}
          />
          <IRow mono label="NPN" helpText="(broker)" value={policy?.agentID} />
          <IRow mono label="Catch ID" helpText="Catch internal ID" value={policy?.id} />
        </RowGroup>
        <RowGroup title="Plan Details">
          <IRow mono label="Plan ID" value={policy?.providerPlan?.id} />
          <IRow label="Carrier" value={policy?.carrier} />
          <IRow label="Name" value={policy?.planName} />
          <IRow label="Metal" value={policy?.providerPlan?.metalLevel} />
          <IRow label="Network" value={policy?.providerPlan?.type} />
        </RowGroup>
      </Scroll>
      {!!debug && <DebugView data={policy} isOpen={debug} onClose={() => setDebug(false)} />}
    </>
  );
};
import { Scroll } from 'src/web/components';
import { BankLinkNode } from 'src/components/nodes';
import { InspectorPaneProps } from '../../types';

export function BankLinkPane({ id, userId }: InspectorPaneProps) {
  return (
    <Scroll inspector>
      <BankLinkNode customerID={userId} bankLinkID={id} expanded />
    </Scroll>
  );
}

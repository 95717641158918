import { gql } from '../generated';

export const UPDATE_USER = gql(`
  mutation UpdateUser($input: UpdateUserInput!, $personID: ID!) {
    updateUserNew(input: $input, personID: $personID) {
      user {
        id
        givenName
        familyName
        dob
        filingStatus
        signupCode
        signupContext
      }
    }
  }
`);

import React, { useState } from 'react';
import styled from 'styled-components';
import I, { IconType } from '../I';

const SSRow = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .leading,
  .trailing,
  .kb {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leading {
    height: 24px;
    width: 24px;
    margin-right: 12px;
    background: var(--c-border-light);
    border-radius: var(--radius);
  }
  .text {
    flex-grow: 1;
  }
  .kb {
    height: 20px;
    width: 20px;
    margin-left: 8px;
    color: var(--c-fg-2);
    text-align: center;
    font-size: 12px;
  }
`;

export interface SRowProps {
  text: string;
  icon: IconType;
  accessory?: React.ReactElement;
}


const SRow = ({ value = false, text, accessory, kb, icon, destructive, color }: SRowProps) => {
  const [on, setOn] = useState(value);

  return (
    <SSRow on={on} onClick={() => setOn(!on)}>
      <div className="leading">
        <I name={icon} size={20} color={destructive ? 'var(--c-red-0)' : color || 'var(--fg)'} />
      </div>
      <div className="text">{text}</div>
      <div className="trailing">{accessory}</div>
      <div className="kb">{kb}</div>
    </SSRow>
  );
};

export default SRow;

import { gql } from '@apollo/client';

export const BLOG_POSTS = gql`
  query Posts {
    posts(orderBy: postDate_DESC) {
      id
      title
      slug
      teaserTldr
      postDate
      displayApp
      displayBlog
      image {
        id
        url(transformation: { image: { resize: { width: 1000, height: 800 } } })
      }
    }
  }
`;

export const AGREEMENTS = gql`
  query Agreements {
    agreements {
      id
      title
      slug
      order
    }
  }
`;

export const PARTNERS = gql`
  query Partners {
    partners(orderBy: name_ASC) {
      id
      name
      slug
      code
      incomeSource
      vertical
      personas
      heroText
      heroSubtitle
      color {
        hex
      }
      heroImage {
        url(transformation: { image: { resize: { width: 1000, height: 1000 } } })
      }
      logo {
        url(transformation: { image: { resize: { width: 200, height: 200 } } })
      }
      agreement {
        id
        slug
      }
    }
  }
`;

import React from 'react';
import { differenceInMonths } from 'date-fns';
import { useQuery } from '@apollo/client';

import { Scroll, GoalCard, IRow, ActivityRow, RowGroup, Status, Banner } from 'src/web/components';
import { DEFAULT_URL, formatDate, ROLL_COPY } from 'src/web/utility';
import { useUserSummary } from 'src/web/hooks';
import { formatCurrency } from 'src/web/utility';
import { SelectedType, useInspector } from 'src/hooks/useInspector';

const score = source => {
  if (source?.IncomeAutomationRule?.automationType === 'ALWAYS') {
    return 1;
  } else if (source?.IncomeAutomationRule?.automationType === 'NEVER') {
    return -1;
  } else {
    return 0;
  }
};

export function IncomePage({ userId }) {
  const { selectedId, setSelected } = useInspector();

  const { joinDate, incomeApp, incomeAlt, incomeSeen, incomeProjected } = useUserSummary({
    id: userId,
  });

  const loading = false;
  const data = {};
  const _transactions = {};

  const rules = data?.viewer?.incomeSources?.map((source, idx) => {
    return {
      ...source,
      automationType: ROLL_COPY[source?.IncomeAutomationRule?.automationType || 'SOMETIMES'],
    };
  });

  const transactions = _transactions?.viewer?.incomeTransactionsPaged?.edges;

  return (
    <Scroll loading={loading}>
      <RowGroup title="Income Summary" borderless>
        <IRow type="currency" noMax label="User Estimate" value={incomeAlt} />
        <IRow type="currency" noMax label="User Estimate (Health App)" value={incomeApp} />
        <IRow
          type="currency"
          noMax
          label={`Observed last ${differenceInMonths(Date.now(), Date.parse(joinDate))} months`}
          value={incomeSeen}
        />
        <IRow type="currency" noMax label="Catch Projected Income" value={incomeProjected} />
        <IRow
          noMax
          label="Transfer Delay"
          value={data?.viewer?.incomePreferences?.isDelayAutoIncome ? 'On' : 'Off'}
        />
      </RowGroup>
      <RowGroup title="Income Sources" borderless>
        {!!rules &&
          !loading &&
          (rules || [])
            ?.sort((a, b) => score(b) - score(a))
            ?.map((source, idx) => (
              <GoalCard
                index={idx}
                fillLogo
                selected={source.id === selectedId}
                logo={source?.taggedSource?.logo?.url || DEFAULT_URL}
                key={source?.id}
                name={source?.text}
                subtitle={source?.sourceDetail}
                status={ROLL_COPY[source?.IncomeAutomationRule?.automationType || 'SOMETIMES']}
                onPress={() => setSelected(source?.id, { type: SelectedType.INCOME_SOURCE })}
              />
            ))}
      </RowGroup>
      <RowGroup title="Recent">
        {transactions?.map((tr, idx) => (
          <ActivityRow
            key={tr?.id}
            label={tr?.source?.text}
            label2={tr?.memo}
            status={tr?.status}
            icon={`/img/activity/Deposit.svg`}
            logo={tr?.source?.taggedSource?.logo?.url}
            date={formatDate({ date: tr.actedOn, length: 'SHORT' })}
            timestamp={tr?.actedOn}
            auto={tr.isAutopilot}
            large
            value={
              <div className="row flex-h tabular">
                {formatCurrency({ value: tr.amount })}

                <Status
                  status={tr?.status}
                  statusText={tr?.transfer?.returnCode}
                  reverse
                  style={{ marginLeft: 8, opacity: tr?.status ? 1 : 0 }}
                >
                  {tr?.transfer?.returnCode}
                </Status>
              </div>
            }
          />
        ))}
      </RowGroup>
    </Scroll>
  );
}

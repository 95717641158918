import styled from 'styled-components';
import { useSelection } from 'src/util/selection';

const StyledRedact = styled.div`
  display: ${p => (p.inline ? 'inline' : 'inline-block')};
  background: ${p => (p.silent ? 'transparent' : p.hide ? p.theme.skeletonColor : 'transparent')};
  border-radius: ${p => (p.hide ? '6px' : 'inherit')};
  > div {
    transition: opacity 0.2s var(--transitionCurve);
    display: ${p => (p.inline ? 'inline' : 'inline-block')};
    opacity: ${p => (p.hide ? 0 : 'inherit')} !important;
  }
`;

const Redact = ({ children, disable, fallback, blur, inline, silent, style, alwaysHide, loading }) => {
  const { privacyMode } = useSelection();
  return disable ? (
    children
  ) : (
    <StyledRedact
      blur={blur}
      hide={privacyMode || alwaysHide || loading}
      inline={inline}
      silent={silent}
      style={style}
    >
      <div>{privacyMode || alwaysHide ? (fallback ? fallback : children) : children}</div>
    </StyledRedact>
  );
};

export default Redact;

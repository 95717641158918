import { create } from 'zustand';

interface TokenState {
  token?: string;
  setToken: (token: string) => void;
  clearToken: () => void;
}

/**
 * useToken stores the current JWT
 * This needs to sit at a global level because auth needs to use it to set it
 * and other high level libraries need to consume the token (e.g. lib/apollo)
 */
export const useToken = create<TokenState>(set => ({
  token: undefined,
  setToken: token => set({ token }),
  clearToken: () => set({ token: undefined }),
}));

/**
 * Helper function for getting the current token from state
 */
export const getAuthToken = () => useToken.getState().token;
export const setAuthToken = token => useToken.getState().setToken(token);

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeName, makeLabels } from 'src/web/utility';

import { TASKS } from 'src/web/data';
import { TaskRow, Toolbar, Scroll, Carousel, Status } from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';

const pagination = {
  pageNumber: 1,
  pageSize: 100,
};

const _input = {
  pagination,
  sort: { CreatedOn: 'DESCENDING' },
  types: ['TAX_PAYMENT'],
};

const TaxTasksPage = () => {
  const { selectedId, setSelected } = useInspector();

  const { data: wip } = useQuery(TASKS, {
    variables: {
      input: {
        statuses: ['IN_PROGRESS'],
        ..._input,
      },
    },
  });
  const { data: pending } = useQuery(TASKS, {
    variables: {
      input: {
        statuses: ['PENDING'],
        ..._input,
      },
    },
  });
  const { data: complete } = useQuery(TASKS, {
    variables: {
      input: {
        statuses: ['COMPLETE'],
        ..._input,
      },
    },
  });
  const { data: canceled } = useQuery(TASKS, {
    variables: {
      input: {
        statuses: ['CANCELED'],
        ..._input,
      },
    },
  });

  const _wip = wip?.tasks?.edges;
  const _pending = pending?.tasks?.edges;
  const _complete = complete?.tasks?.edges;
  const _canceled = canceled?.tasks?.edges;

  return (
    <>
      <Toolbar icon="Taxes" title="Tax Payments" overInspector />
      <Scroll toolbar>
        <Carousel>
          <div className="kanban-col-wrap">
            <div className="kanban-status flex-h">
              <Status status={'In Progress'} />
            </div>
            <div className="kanban-col">
              {!!_wip &&
                _wip?.map((item, idx) => (
                  <TaskRow
                    labels={makeLabels(item)}
                    standalone
                    small
                    showOwner
                    key={item?.id}
                    showAvatar
                    user={item?.taxPayment?.person}
                    name={makeName(item?.taxPayment?.person)}
                    date={item.createdOn}
                    amount={item?.taxPayment?.amount}
                    selected={item?.id === selectedId}
                    owner={item?.assignee}
                    onPress={() => setSelected(item?.id, { type: SelectedType.TAX_PAYMENT_TASK })}
                  />
                ))}
            </div>
          </div>
          <div className="kanban-col-wrap">
            <div className="kanban-status flex-h">
              <Status status={'Pending Withdrawal'} />
            </div>
            <div className="kanban-col">
              {!!_pending &&
                _pending?.map((item, idx) => (
                  <TaskRow
                    labels={makeLabels(item)}
                    standalone
                    small
                    showOwner
                    key={item?.id}
                    showAvatar
                    user={item?.taxPayment?.person}
                    name={makeName(item?.taxPayment?.person)}
                    date={item.createdOn}
                    amount={item?.taxPayment?.amount}
                    selected={item?.id === selectedId}
                    owner={item?.assignee}
                    onPress={() => setSelected(item?.id, { type: SelectedType.TAX_PAYMENT_TASK })}
                  />
                ))}
            </div>
          </div>
          <div className="kanban-col-wrap">
            <div className="kanban-status flex-h">
              <Status status={'Completed'} />
            </div>
            <div className="kanban-col">
              {!!_complete &&
                _complete?.map((item, idx) => (
                  <TaskRow
                    labels={makeLabels(item)}
                    small
                    standalone
                    showOwner
                    key={item?.id}
                    showAvatar
                    user={item?.taxPayment?.person}
                    name={makeName(item?.taxPayment?.person)}
                    date={item?.lastUpdated}
                    amount={item?.taxPayment?.amount}
                    selected={item?.id === selectedId}
                    owner={item?.assignee}
                    onPress={() => setSelected(item?.id, { type: SelectedType.TAX_PAYMENT_TASK })}
                  />
                ))}
            </div>
          </div>
          <div className="kanban-col-wrap">
            <div className="kanban-status flex-h">
              <Status status={'Canceled'} />
            </div>
            <div className="kanban-col">
              {!!_canceled &&
                _canceled?.map((item, idx) => (
                  <TaskRow
                    labels={makeLabels(item)}
                    small
                    standalone
                    showOwner
                    key={item?.id}
                    showAvatar
                    user={item?.taxPayment?.person}
                    name={makeName(item?.taxPayment?.person)}
                    date={item?.lastUpdated}
                    amount={item?.taxPayment?.amount}
                    selected={item?.id === selectedId}
                    owner={item?.assignee}
                    onPress={() => setSelected(item?.id, { type: SelectedType.TAX_PAYMENT_TASK })}
                  />
                ))}
            </div>
          </div>
        </Carousel>
      </Scroll>
    </>
  );
};

export default TaxTasksPage;

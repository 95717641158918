import React from 'react';
import { Modal } from 'src/web/components';
import { useModal } from './useModal';

export function ModalsProvider() {
  const { isOpen, content, closeModal } = useModal();

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      {React.cloneElement(content, { closeModal })}
    </Modal>
  );

}
import { gql } from '@apollo/client';
import { EXPLORER } from './queries';

export const UPDATE_HEALTH_PREFERENCE = (cache, result) => {
  const updatedPreferences = result?.data?.upsertHealthPreference || {};

  const initial = cache.readQuery({
    query: EXPLORER,
    variables: { id: updatedPreferences.ownerID },
  });

  if (initial) {
    cache.writeQuery({
      query: EXPLORER,
      data: {
        viewer: {
          ...initial.viewer,
        },
        viewerTwo: {
          ...initial.viewerTwo,
          health: {
            ...initial.viewerTwo.health,
            healthPreference: {
              // sets defaults in case health preference is not yet defined
              ownerID: initial?.viewer?.user?.id,
              drugsWanted: [],
              providersWanted: [],
              financialPreference: null,
              plannedUsage: null,

              // then we override with anything already queried
              ...initial?.viewerTwo?.health?.healthPreference,

              // and lastly, set the newly updated prefs
              ...updatedPreferences,
              __typename: 'HealthPreference',
            },
          },
        },
      },
    });
  }
};

export const UPDATE_EXPLORER_APPLICANTS = (cache, result, personID) => {
  // gets the explorer ID from cache
  const data = cache.readFragment({
    id: `Person:${personID}`,
    fragment: gql`
      fragment HealthExplorerID on Person {
        id
        healthExplorerData {
          id
        }
      }
    `,
  });

  cache.modify({
    id: `HealthExplorerData:${data?.healthExplorerData?.id}`,
    fields: {
      dependents(deps) {
        return result?.data?.upsertHealthExplorerDependents || deps;
      },
    },
  });
};

export const REMOVE_EXPLORER_APPLICANT = (cache, result, personID) => {
  // gets the explorer ID from cache
  const data = cache.readFragment({
    id: `Person:${personID}`,
    fragment: gql`
      fragment HealthExplorerID on Person {
        id
        healthExplorerData {
          id
        }
      }
    `,
  });

  const isRemaining = id => {
    return result?.data?.deleteHealthExplorerDependents?.some(m => m.id === id);
  };

  cache.modify({
    id: `HealthExplorerData:${data?.healthExplorerData?.id}`,
    fields: {
      dependents(existingMembers, { readField }) {
        return existingMembers.filter(member => {
          return isRemaining(readField('id', member));
        });
      },
    },
  });
};

export const ADD_CONSENT_REQUEST = (cache, consentRequest, personID) => {
  cache.modify({
    id: `Person:${personID}`,
    fields: {
      consentRequest() {
        return consentRequest;
      },
    },
  });
};

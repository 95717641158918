import { BankApplicationProvider } from 'src/data';

export type ConsolidatedType = BankApplicationProvider;

export const NAMES: Record<ConsolidatedType, string> = {
  [BankApplicationProvider.Unit]: 'Unit',
};

export const LOGOS: Record<ConsolidatedType, string> = {
  [BankApplicationProvider.Unit]: '/img/products/unit.png',
};

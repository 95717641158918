import React from 'react';

import { formatDate } from 'src/web/utility';
import { getStatusColor, getStatusColorLight } from '../Status';
import styled from 'styled-components';

const StyledIssue = styled.div`
  background: ${p => p.color};
  color: var(--c-fg-0);
  border-radius: 8px;
  padding: 12px 12px;
  margin-bottom: 1px;
`;

const ApplicationIssue = ({ status, category, resolveBy, lastReviewed, type, subType }) => {
  const _type =
    !!type &&
    type
      ?.replace('MDCAID', 'MEDICAID')
      ?.replace('NONESCMEC', 'Non-Employer Marketplace Coverage')
      ?.replace('MEC_LOSS', 'Loss of Marketplace Coverage')
      ?.replace('SEP_GRANTED_FOR_MEDICAID_DENIAL', 'Denied Medicaid')
      ?.replace('_', ' ')
      ?.toProperCase();
  return (
    <StyledIssue color={getStatusColorLight(status)}>
      <div
        class="flex-h"
        style={{
          color: 'var(--c-fg-0)',
          fontSize: 'var(--fp)',
          fontWeight: 600,
          justifyContent: 'space-between',
        }}
      >
        <div>{_type}</div>
        <div style={{ opacity: 0.5, fontWeight: 400 }}>{category}</div>
      </div>
      <div class="flex-h" style={{ justifyContent: 'space-between', flex: 1, marginTop: 4 }}>
        <div
          style={{
            fontSize: 'var(--ffp)',
            fontWeight: 500,
            color: getStatusColor(status),
            flex: 1,
          }}
        >
          {status?.toProperCase()}
        </div>
        <div style={{ fontSize: 'var(--ffp)', fontWeight: 500 }}>{subType?.toProperCase()}</div>
      </div>

      <div class="flex-h" style={{ justifyContent: 'space-between', flex: 1 }}>
        <div style={{ fontSize: 'var(--ffp)' }}>Due {formatDate({ date: resolveBy })}</div>
        <div style={{ fontSize: 'var(--ffp)' }}>
          Reviewed {formatDate({ date: lastReviewed, length: 'SHORT' })}
        </div>
      </div>
    </StyledIssue>
  );
};

export const CompactCard = ({ status, title, subtitle, detail1, detail2, detail3 }) => {
  return (
    <StyledIssue color={getStatusColorLight(status)}>
      <div
        class="flex-h"
        style={{
          color: 'var(--c-fg-0)',
          fontSize: 'var(--fp)',
          fontWeight: 600,
          justifyContent: 'space-between',
        }}
      >
        <div>{title}</div>
        <div style={{ opacity: 0.5, fontWeight: 400 }}>{subtitle}</div>
      </div>
      <div class="flex-h" style={{ justifyContent: 'space-between', flex: 1, marginTop: 4 }}>
        <div
          style={{
            fontSize: 'var(--ffp)',
            fontWeight: 500,
            color: getStatusColor(status),
            flex: 1,
          }}
        >
          {status?.toProperCase()}
        </div>
        <div
          style={{
            fontSize: 'var(--ffp)',
            fontWeight: 500,
          }}
        >
          {detail1}
        </div>
      </div>

      <div class="flex-h" style={{ justifyContent: 'space-between', flex: 1 }}>
        <div
          style={{
            fontSize: 'var(--ffp)',
            textOverflow: 'clip',
            whiteSpace: 'nowrap',
            width: 225,
            mask: 'linear-gradient(to right, #000000 85%, #00000000)',
            webkitMask: 'linear-gradient(to right, #000000 85%, #00000000)',
            overflow: 'hidden',
            flexGrow: 1,
          }}
        >
          {detail2}
        </div>
        <div style={{ fontSize: 'var(--ffp)' }}>{detail3}</div>
      </div>
    </StyledIssue>
  );
};

export default ApplicationIssue;

import { gql } from "../generated";

export const EXPLORER = gql(`
  query Explorer($id: ID!) {
    viewerTwo(id: $id) {
      id
      health {
        healthPreference {
          ownerID
          financialPreference
          plannedUsage
          drugsWanted {
            ...Drug
          }
          providersWanted {
            ...Provider
          }
        }
      }
      healthExplorerData {
        id
        zipcode
        countyfips
        coverageYear
        state
        income
        providerPlanID
        pathwayType
        email
        dependents {
          ...ExplorerMember
        }
        providerPlanID
        healthPlans {
          plans {
            healthPlan {
              planID: id
              name
              type
              metalLevel
              premium
              premiumWithCredit
              benefitsURL
              issuer {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);
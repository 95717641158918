import { gql } from '@apollo/client';

export interface StoreIDProofOverrideInput {
  personID: string;
  documentID: string;
  documentNumber: string;
}

export const MANUAL_IDENTITY = gql`
  mutation StoreIDProofOverride($input: StoreIDProofOverrideInput!) {
    storeIDProofOverride(input: $input) {
      id
    }
  }
`;

export const IMPORT_ALL = gql`
  mutation ImportAllExistingApplications($personId: ID!) {
    importAllExistingApplications(personID: $personId) {
      hasActivePolicy
      hasPreviousActivePolicy
      hasSubmittedApplication
      existingApplications {
        id
        familyName
        givenName
        coverageYearNumber
        state
        applicationIdentifier
        applicationVersion
        providerLastUpdatedDate
        applicationStatus
        hasExistingPersonTrackingNumbersTiedToApplication
        policyInfo
        matchesWithAnotherUser
      }
      canIDProof
      idProofOk
      importOk
    }
  }
`;

export const ENSURE_HEALTH = gql`
  mutation EnsureHealthApplication($appId: ID!, $personId: ID!) {
    ensureHealthApplication(id: $appId, personID: $personId) {
      currApplication {
        id
      }
      error {
        code
        field
        message
      }
      errors {
        applicationMemberID
        code
        path
      }
      successWithError
    }
  }
`;

export const HEALTH_LOOKUP = gql`
  mutation EdeApplicationSearch($personId: ID!) {
    edeApplicationSearch(personID: $personId) {
      hasActivePolicy
      hasPreviousActivePolicy
      hasSubmittedApplication
      existingApplications {
        id
        familyName
        givenName
        coverageYearNumber
        state
        applicationIdentifier
        applicationVersion
        providerLastUpdatedDate
        applicationStatus
        hasExistingPersonTrackingNumbersTiedToApplication
        policyInfo
        matchesWithAnotherUser
      }
      canIDProof
      idProofOk
      importOk
    }
  }
`;

export const FETCH_ELIG = gql`
  mutation FetchEligibility($appId: ID!, $personId: ID, $forceNewEnrollmentGroups: Boolean) {
    fetchEligibility(id: $appId, personID: $personId, forceNewEnrollmentGroups: $forceNewEnrollmentGroups) {
      id
      aptcEligibility {
        startDate
        endDate
        amount
        change
      }
      csrEligibility {
        startDate
        endDate
        csrLevel
      }
      exchangeEligibility {
        startDate
        endDate
      }
      sepEligibility {
        startDate
        endDate
        earliestQhpEffective
        latestQhpEffective
        sepReason
        verificationPending
      }
      chipEligibility {
        startDate
        endDate
      }
      medicaidEligibility {
        startDate
        endDate
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';

import { formatHealthPlanNameWithDetail, getCarrierLogo } from '@catch-co/health-utils';
import { GoalCard } from 'src/web/components';

export default ({ name, grossPremium, netPremium, issuer, metal, benefits }) => {
  return (
    <GoalCard
      name={formatHealthPlanNameWithDetail(name)?.name}
      status={metal}
      logo={`https://s.catch.co/img/carriers/${getCarrierLogo(issuer)}.png`}
      subtitle={issuer}
      balance={netPremium}
    />
  );
};

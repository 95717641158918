import styled from 'styled-components';
import Icon from './Icon';
import { useSelection } from 'src/util/selection';

const StyledLogo = styled.div`
  border-radius: ${p => (p.extraSquare || p.square ? 'var(--radius)' : '99px')};
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  background-color: var(--c-border-light);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${p => (p.hide && p.image ? null : p.image)}"); 
  background-size: cover;
  z-index: 99999;
  color: var(--c-fg-1);
  display: inline-flex;
  text-transform: uppercase;
  justify-content: center;
  cursor: ${p => (p.withHover ? 'pointer' : 'default')};
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  font-weight: 600;
  line-height: 1.0;
  box-sizing:border-box;
  transition: box-shadow 0.25s var(--transitionCurve);
  cursor: default;
  .avaini {
    
    transform-origin:center;
    font-size: ${p => 9 * Math.max(1, p.size / 24)}px !important;
    line-height: 1.0;
    font-weight: 400;
    color: var(--c-fg-1);
    padding:0;
  }
 
`;

const Avatar = ({
  image,
  size = 24,
  initials,
  style,
  color,
  withHover,
  invert,
  square,
  extraSquare,
  secondaryLogo,
}) => {
  const { privacyMode } = useSelection();

  return (
    <div style={{ ...style, width: size, height: size, display: 'flex', position: 'relative' }}>
      <StyledLogo
        invert={invert}
        image={!!image ? `data:image/gif;base64,${image}` : undefined}
        size={size}
        background={color}
        square={square}
        hide={privacyMode}
        extraSquare={extraSquare}
        withHover={withHover}
        className="initials"
      >
        <div className="avaini">{!image && (initials?.replace(' ', '') || '')}</div>
      </StyledLogo>
      {!!secondaryLogo && (
        <Icon iconColor="transparent" logo={secondaryLogo} size={20} style={styles.srcLogo} />
      )}
    </div>
  );
};

const styles = {
  srcLogo: { position: 'absolute', bottom: 0, left: 0, zIndex: 999999 },
};

export default Avatar;

import { Scroll } from 'src/kit';
import { Button, FullModal, IRow, RowGroup, StyledBottomBar } from 'src/web/components';
import { Form, Field } from 'src/forms';
import { CommissionStatus, PolicyStatus, UPDATE_POLICY, UpdatePolicyInput, useMutation } from 'src/data';
import { ModalComponentProps, ModalType } from 'src/hooks/useModal';
import toast from 'react-hot-toast';

export type UpdatePolicyModalProps = ModalComponentProps<ModalType.UPDATE_POLICY>

export const UpdatePolicyModal = ({ data, closeModal }: UpdatePolicyModalProps) => {

  const [updatePolicy, { loading }] = useMutation(UPDATE_POLICY, {
    onCompleted: () => {
      toast.success('Policy updated');
      closeModal();
    }
  });

  const handleSubmit = (values: UpdatePolicyInput) => {
    updatePolicy({
      variables: {
        personID: data.userId,
        input: {
          ...values,
          id: data.policy.id,
        }
      }
    })
  }

  return (
    <FullModal onRequestClose={closeModal} title="Update Policy" sidebarItem={
      <RowGroup title="Plan Details" borderless>
        <IRow label="Coverage Year" value={data.policy.coverageYear} />
        <IRow label="Coverage State" value={data.policy.coverageState} />
        <IRow label="Plan Name" value={data.policy.planName} />
        <IRow label="Carrier" value={data.policy.carrier} />
      </RowGroup>
    }>
      <Form onSubmit={handleSubmit} initialValues={{
        status: data.policy.policyStatus,
        commissionStatus: data.policy.commissionStatus,
        lastPaidCommission: data.policy.lastPaidCommission,
      }}>
        <Scroll transparent loading={loading}>
          <Field
            required
            name="status"
            type="dropdown"
            label="Status"
            options={Object.values(PolicyStatus).map(value => ({ label: value, value }))}
          />
          <Field
            required
            name="commissionStatus"
            type="dropdown"
            label="Commission Status"
            options={Object.values(CommissionStatus).map(value => ({ label: value, value }))}
          />
          <Field
            required
            name="lastPaidCommission"
            type="date"
            label="Last Paid Commission"
          />
        </Scroll>
        <StyledBottomBar>
          <Button disabled={loading}>
            Save
          </Button>
        </StyledBottomBar>
      </Form>
    </FullModal>
  );
};

export default UpdatePolicyModal;

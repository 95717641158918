import React, { useState } from 'react';
import styled from 'styled-components';
import {  NavLink } from 'react-router-dom';
import { AppPage, Input, Button } from 'src/web/components';

const Form = styled.form`
  max-width: 350px;
  > * {
    &:not(:first-child) {
      display: block;
      margin-top: 16px;
    }
  }
`;

export function ExplorerLookupPage() {
  const [healthID, setHealthID] = useState('');

  return (
    <AppPage 
      noPanel
      icon="Map"
      title="Lookup PX Information"
      main={() => (
        <Form>
          <Input 
            value={healthID}
            onChange={({ target }) => setHealthID(target.value)}
            placeholder="Enter session ID"
          />
          <NavLink to={`/explorer/${healthID}`}>
            <Button wide>Find</Button>
          </NavLink>
        </Form>
      )}
    />
  );
};

import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import { EDE_ROUTES, PARTNER_ROUTES, EDE_ROUTES_RENEW } from 'src/web/utility';

const UPSERT_LAST_ROUTE = gql`
  mutation UpsertLastRoute($input: UpsertHealthApplicationInput!, $personId: ID!) {
    upsertHealthApplication(input: $input, personID: $personId) {
      id
      lastUsedRoute
      isRequestingFinancialAssistance
    }
  }
`;

const StyledRoute2 = styled.div`
  padding: 6px 24px 6px 32px;
  display: flex;
  align-items: center;
  font-size: var(--fp);
  font-weight: ${p => (p.active ? 600 : 400)};
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  transition: 0.25s var(--transitionCurve);
  cursor: var(--cursor);
  will-change: color font-weight;
  border-radius: 2px;
  color: ${p => (p.active ? 'var(--c-cerise-0)' : p.visited ? 'var(--c-fg-0)' : 'var(--c-fg-1)')} !important;
  &:hover {
    background: ${p => (p.visited ? 'var(--c-border-light)' : '')};
    color: ${p => (p.visited ? 'var(--c-cerise-0)' : '')} !important;
    .dot {
      background: ${p => (p.visited ? 'var(--c-cerise-0)' : '')};
      transform: ${p => (p.visited ? 'scaleY(1)' : '')};
    }
  }
`;

const StyledDot2 = styled.div`
  width: 2px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 1px;
  will-change: transform background;
  transform-origin: center;
  transition: 0.25s var(--transitionCurve);
  background: ${p => (p.active ? 'var(--c-cerise-0)' : p.visited ? 'var(--c-fg-0)' : 'var(--c-border)')};
  transform: ${p =>
    p.active ? 'scaleY(1) scaleX(1)' : p.visited ? 'scaleY(1) ' : 'scaleY(0.75) scaleX(0.5)'};
`;

const Blank = styled.div`
  height: 24px;
`;

export const PathPane = ({ routeName = '', partner, appId, uid, renew, onChange }) => {
  const routes = !!partner ? PARTNER_ROUTES : renew ? EDE_ROUTES_RENEW : EDE_ROUTES;
  const selectedIdx = routes?.indexOf(routeName);

  const [upsert] = useMutation(UPSERT_LAST_ROUTE, {
    refetchQueries: ['HealthApplication'],
    onCompleted: onChange,
  });

  return (
    <PathPaneInternal
      routeName={routeName}
      routes={routes}
      selectedIdx={selectedIdx}
      onPress={route => {
        upsert({
          variables: {
            personId: uid,
            input: {
              id: appId,
              lastUsedRoute: route,
            },
          },
        });
      }}
    />
  );
};

export const PathPaneInternal = ({ routeName, routes, selectedIdx, onPress }) => {
  const [hRoute, setHRoute] = useState(selectedIdx);

  return (
    <div onMouseLeave={() => setHRoute(selectedIdx)}>
      {routes?.map((route, idx) =>
        route === '_' ? (
          <Blank />
        ) : (
          <StyledRoute2
            onMouseEnter={e => {
              setHRoute(idx <= selectedIdx ? idx : selectedIdx);
            }}
            onClick={() => onPress(route)}
            active={route === routeName}
            visited={idx <= selectedIdx}
            key={route}
            open={true}
          >
            <StyledDot2
              className="dot"
              active={route === routeName && idx <= hRoute}
              visited={idx <= hRoute}
            />
            {route?.replaceAll('-', ' ')?.toProperCase()}
          </StyledRoute2>
        ),
      )}
    </div>
  );
};

export default PathPane;

import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

import { Spinner, Redact } from 'src/web/components';
import { formatDate } from 'src/web/utility';

const COLORS = {
  '1040': 'var(--c-orange-1)',
  UNIT: 'var(--c-lime-1)',
  BOND: 'var(--c-lime-1)',
  BBVA: 'var(--c-lime-1)',
  IRA: 'var(--c-teal-1)',
  '1095': 'var(--c-red-1)',
  '5498': 'var(--c-teal-1)',
  '1099': 'var(--c-teal-1)',
};

const SIZE = 72;
const SIZE_SM = 48;
const SIZE_SM_2 = 40;

const StyledTile = styled.div`
  text-align: center;
  border-radius: var(--radiusLarge);
  background: ${p => (p.selected ? 'var(--c-border-light)' : 'transparent')};
  padding: 12px 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: var(--fp);

  cursor: var(--cursor);
  &:hover {
    background-color: var(--c-border-light);
    z-index: 999;
    img {
      transform: scale(1.05);
    }
  }
  .doc-name {
    flex-grow: 1;
    text-align: left;
    text-overflow: clip;
    white-space: nowrap;
    mask: linear-gradient(to right, #000000 85%, #00000000);
  }
`;

const StyledImage = styled.img`
  max-width: ${p => (p.small ? SIZE_SM : SIZE)}px;
  max-height: ${p => (p.small ? SIZE_SM_2 : SIZE)}px;
  background-image: url(${p => p.url || ''});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: var(--transition);
  transform: ${p => (p.selected ? `scale(1.05)` : ``)};
  box-shadow: ${p => (p.selected ? ` 0px 5px 25px rgba(6, 7, 10, 0.15)` : ``)};
  margin: auto;
  border: 1px solid var(--cBorder);
  border-radius: ${p => (p.small ? 4 : 8)}px;
  overflow: hidden;
  margin: auto;
`;

const StyledImgWrap = styled.div`
  border-radius: ${p => (p.small ? 4 : 8)}px;
  height: ${p => (p.small ? SIZE_SM_2 : SIZE)}px;
  width: ${p => (p.small ? SIZE_SM : SIZE)}px;
  align-items: center;
  display: flex;
  margin: auto;
  margin-right: 16px;
`;

const StyledPlaceholder = styled.div`
  width: ${p => (p.small ? SIZE_SM : SIZE)}px;
  height: ${p => (p.small ? SIZE_SM_2 : SIZE)}px;
  background-color: ${p => COLORS[p.fileType] || 'var(--cCard2)'};
  box-shadow: 0px 1px 2px -1px rgba(6, 7, 10, 0.15);
  margin: auto;
  border-radius: calc(var(--radius));
  border: 1px solid var(--cBorder);
  font-size: 11px;
  opacity: 1;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1;
  color: var(--c-fg-1);
`;

const StyledTextCont = styled.div`
  display: ${p => (p.small ? 'flex' : 'block')};
  flex-grow: 1;
  align-items: center;
`;
const UploadTile = ({
  id,
  createdOn,
  name,
  imageUrl,
  fileType,
  onPress,
  selected,
  subtitle,
  small = true,
  source,
  compact,
  dateLength = 'MEDIUM',
  loading,
  documentNumber,
}) => {
  return (
    <StyledTile
      small={small}
      key={id}
      label={!!createdOn ? format(new Date(createdOn), 'MMM dd') : ''}
      value={name}
      selected={selected}
      onClick={onPress}
      compact={compact}
    >
      <Redact blur>
        <StyledImgWrap className={`imgwrp`} small={small} url={imageUrl}>
          {!!loading ? (
            <div style={{ margin: 'auto', transform: 'scale(0.7)' }}>
              <Spinner />
            </div>
          ) : !!imageUrl ? (
            <StyledImage small={small} selected={selected} src={imageUrl} />
          ) : (
            <StyledPlaceholder small={small} fileType={fileType}>
              {documentNumber || fileType}
            </StyledPlaceholder>
          )}
        </StyledImgWrap>
      </Redact>
      <StyledTextCont small={small}>
        <div className="doc-name">
          <div style={{ fontWeight: 500, color: 'var(--c-fg-0)', fontSize: 'var(--p)' }}>
            {name
              ?.replace('PHOTOID', 'Photo ID')
              ?.toProperCase()
              ?.replace('U S ', 'U.S. ')
              ?.replace('Es', 'ES')}{' '}
          </div>
          <div style={{ fontWeight: 400, color: 'var(--c-fg-1)' }}>{subtitle}</div>
        </div>
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <div style={{ fontWeight: 400, color: 'var(--c-fg-1)', fontSize: 'var(--fp)' }}>
            {formatDate({ date: createdOn, length: dateLength })}
          </div>
          <div style={{ fontWeight: 400, color: 'var(--c-fg-1)' }}>{source}</div>
        </div>
      </StyledTextCont>
    </StyledTile>
  );
};

export default UploadTile;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppPage } from 'src/web/components';
import { useUrlParams } from 'src/web/hooks';
import { IncomeSourcesPage, PartnersPage, LinkGeneratorsPage } from '.';

const routes = [
  {
    path: 'sources',
    name: 'Income Sources',
    search: true,
    component: props => <IncomeSourcesPage {...props} />,
  },
  {
    path: 'links',
    name: 'Link Generator',
    search: true,
    component: props => <LinkGeneratorsPage {...props} />,
  },
  {
    path: 'partners',
    name: 'Partner Pages',
    component: props => <PartnersPage {...props} />,
  },
];

const ReferenceView = () => {
  const { uid } = useUrlParams();

  return (
    <Routes>
      {routes.map(route => {
        const page = (
          <AppPage
            canCollapse
            supportsGrid={route.supportsGrid}
            title={route.name}
            main={route.component}
            bgImage={route.bgImage}
            color={route.color}
            userId={uid}
            noPanel
            noInspector
          />
        );

        return (
          <>
            <Route path={route.path} element={page} />
            <Route path={`${route.path}/:id`} element={page} />
          </>
        );
      })}
    </Routes>
  );
};

export default ReferenceView;

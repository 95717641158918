import React from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '.';
import { size } from 'src/web/styles';
import { FontIcon, Avatar, Redact, I } from 'src/web/components';
import { getStatusColor, getStatusIcon, getStatusTextColor } from './Status';

const SActionRow = styled.div`
  display: flex;
  /* background-image: ${p => (p.hasHover ? 'url(/img/next.svg)' : ' ')}; */
  background-position: right 8px center;
  background-size: ${p => (p.small ? 8 : 12)}px;
  background-repeat: no-repeat;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  font-weight: 500;
  font-size: var(--fp);
  padding: var(--overpad);
  ${p => !!p.loading && 'opacity:0.5;'}
  margin: 0 calc(var(--overpad) * -1) 0;
  border-radius: calc(var(--overpad) + var(--radius));
  cursor: ${p => (p.hasHover ? 'pointer' : 'default')};
  transition: transform 0.1s var(--transitionCurve);
  &:hover {
    background-color: ${p => (p.hasHover ? 'var(--fga)' : 'inherit')};
  }
  &:active {
    transform: scale(0.98);
  }
`;

const SBackRow = styled.div`
  margin: 0 calc(var(--overpad) * -1);
  padding: var(--overpad);
  transition: transform var(--transition);
`;

const STitle = styled.div`
  color: ${p => p.color || 'var(--c-fg-0)'};
  margin-right: 8px;
  font-size: var(--fp);
`;

const SBack = styled.div`
  color: var(--cAdminAccent);
  margin-left: 4px;
  font-size: var(--fp);
  font-weight: 600;
`;

const SSubtitle = styled.div`
  color: var(--c-fg-1);
  font-size: var(--fp);
  font-weight: 400;
  margin-right: 4px;
`;

const SBackIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
`;

const SFontICcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--iconLg);
  height: var(--iconLg);

  margin-right: calc(2 * var(--overpad));
  background: ${p => p.color};
  border-radius: var(--radius);
`;

export const IActionBack = ({ onPress, active }) => (
  <SBackRow active={active}>
    <SActionRow circle onClick={onPress} style={{ marginLeft: -24 }}>
      <div className="flex-h">
        <SBackIcon>
          <FontIcon size={14} icon="back" color="adminAccentColor" />
        </SBackIcon>
        <SBack pressable circle color="adminAccentColor">
          Back
        </SBack>
      </div>
    </SActionRow>
  </SBackRow>
);

const SActionButton = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  font-weight: 500;
  flex: 1;
  font-size: var(--fp);
  height: 32px;
  line-height: 1;
  border-radius: var(--radius);
  cursor: var(--cursor);
  transition: transform 0.1s var(--transitionCurve);
  background-color: ${p => p.bg};
  color: ${p => p.color || 'var(--fg)'};
  &:hover {
    opacity: 0.85;
  }
  &:active {
    transform: scale(0.98);
  }
  .abs2 {
    ${p => !!p.loading && 'opacity: 0.5;'}
  }
  .abs {
    ${p => !!p.loading && 'animation: loading infinite 1s linear;'}
    transform-origin: center;
    margin-right: calc(2 * var(--overpad));
    width: var(--iconLg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SActionButtons = styled.div`
  margin-bottom: var(--padding);
  > div {
    margin-bottom: 4px;
  }
`;

const SCaption = styled.div`
  font-size: 12px;
  margin-top: -3px;
  padding-bottom: 8px;
  opacity: ${p => (p.color ? 1 : 0.65)};
  font-weight: 500;
  padding-left: ${p => (p.button ? 36 : 40)}px;
  color: var(${p => '--' + p.color || '--fg'});
`;

export interface IActionProps {
  pretitle?: string;
  title: string;
}

export const IActionButton = ({
  title,
  caption,
  onPress,
  fontIcon,
  icon,
  logo,
  loading,
  bg = 'var(--c-border-light)',
  captionColor,
  color,
  hide,
  destructive,
  pretitle,
  ...rest
}: IActionProps) =>
  !hide ? (
    <>
      <SActionButton
        onClick={onPress}
        bg={destructive ? 'var(--c-red-1)' : bg}
        color={destructive ? 'var(--c-red-0)' : color}
        {...rest}
        loading={loading}
      >
        <div className="abs">
          {logo ? (
            <Icon logo={logo} size={20}></Icon>
          ) : (
            <I name={icon} size={20} color={destructive ? 'var(--c-red-0)' : color || 'var(--fg)'} />
          )}
        </div>
        {pretitle && <div className="abs2">{pretitle}</div>}
        <div className="abs2">{title}</div>
      </SActionButton>
      {caption && (
        <SCaption button color={captionColor}>
          {caption}
        </SCaption>
      )}
    </>
  ) : null;
export const IActionButtons = ({ children }) => <SActionButtons>{children}</SActionButtons>;

export interface IActionProps {
  disabled?: boolean;
  icon: IconType;
  avatar?: string;
  title: string;
  text?: string;
  onPress: () => void;
  iconColor?: string;
}

const IAction = ({
  circle,
  title,
  text,
  text2,
  icon,
  logo,
  fontIcon,
  iconColor,
  accentColor = 'skeletonColor',
  logoSm,
  avatar,
  onPress,
  hasActions,
  status,
  color,
  small,
  loading,
  pii,
  caption,
  captionColor = 'c-fg-2',
  hide,
  destructive,
  logoFilter,
  pulse,
  disabled,
  pretitle,
  shortcut,
  name,
  subtitle,
}: IActionProps) => {
  const pressable = !!onPress || !!hasActions;
  const st = status?.toLowerCase();
  const stc = {
    color: getStatusColor(st),
    textColor: getStatusTextColor(st),
    icon: getStatusIcon(st),
  };
  const sm = !!small || !!circle;

  return !!hide ? null : (
    <>
      <SActionRow
        small={sm}
        circle={circle}
        onClick={onPress}
        hasHover={(onPress || hasActions || !disabled) && !circle}
        accentColor={accentColor}
        loading={loading}
      >
        <div className="flex-h">
          <SFontICcon
            className="ficns"
            pressable={pressable}
            color={!!destructive ? 'var(--c-red-1)' : !!onPress ? 'var(--c-border-light)' : 'transparent'}
            small={sm}
          >
            {!!status ? (
              <I name={stc?.icon} size={20} color={stc?.color} />
            ) : !!avatar ? (
              <Avatar square size={size.iconLg} image={avatar} />
            ) : !!logo ? (
              <Icon
                size={!!logoSm ? 20 : size.iconLg}
                square={!circle}
                containLg={!logo}
                noShadow
                icon={!!status ? stc?.icon : icon}
                logo={logo}
                style={logoFilter && { filter: 'var(--filter)' }}
              />
            ) : (
              <I name={icon} size={20} color={!!destructive ? 'var(--c-red-0)' : iconColor} />
            )}{' '}
          </SFontICcon>
          {pretitle && <SSubtitle small={sm}>{pretitle}</SSubtitle>}
          {(!!title || !!status || !!name) && (
            <STitle
              hasHover={(onPress || hasActions) && !circle}
              small={sm}
              pressable={pressable}
              color={!!destructive ? 'var(--c-red-0)' : !!status ? stc?.textColor : color}
              circle={circle}
            >
              <Redact disable={!pii}>{name || title || status?.toProperCase()}</Redact>
            </STitle>
          )}
          {(!!text || !!subtitle) && <SSubtitle small={sm}>{subtitle || text}</SSubtitle>}
        </div>
        <div className="flex-h">
          {!!text2 && <SSubtitle small={sm}>{text2}</SSubtitle>}
          {!!shortcut && (
            <div style={{ display: 'grid', gridAutoFlow: 'column', gap: '1px', marginLeft: 6 }}>
              {shortcut?.map(sc => (
                <span
                  className="mono"
                  key={sc}
                  style={{
                    padding: '6px 6px 5px',
                    background: 'var(--fga)',
                    color: 'var(--c-fg-1)',
                    borderRadius: '4px',
                    fontSize: 'calc(var(--ffp) - 2px)',
                    fontWeight: 500,
                    lineHeight: 1,
                    marginTop: -1,
                    marginRight: 1,
                    textTransform: /Enter|Backspace/.test(sc) || 'uppercase',
                  }}
                >
                  {sc
                    ?.replace('Command', '⌘')
                    ?.replace('Shift', '⇧')
                    ?.replace('Option', '⌥')}
                </span>
              ))}
            </div>
          )}
        </div>
      </SActionRow>
      {caption && <SCaption color={captionColor}>{caption}</SCaption>}
    </>
  );
};

export default IAction;

import React from 'react';
import { IRow, Entity, Notes } from 'src/web/components';
import { taxPaymentEntity } from 'src/web/utility';

const TaxPaymentNode = ({ paymentId, uid }) => {
  const data = {};
  const payment = data?.viewerTwo?.taxPayment;
  const person = data?.viewer?.user;

  return (
    <Entity
      nodeType="Tax Payment"
      title={!!payment ? `${payment?.timeframe} ${taxPaymentEntity(payment?.jurisdiction)}` : 'Q'}
      status={payment?.providerStatus || payment?.status?.replace('TAX_PAYMENT_ADMIN_', '')}
      icon="Payment"
      logo={`/img/activity/tax_done.svg`}
      debugData={data}
      actions={[
        {
          pretitle: 'go to',
          title: 'Abound',
          logo: '/img/products/abound.png',
          onPress: () => window.open('https://dashboard.withabound.com/login', '_blank'),
        },
      ]}
    >
      <IRow label="Abound ID" type="mono" value={payment?.providerId} />
      <IRow label="Trigger" value={payment?.isAuto === true ? 'Auto' : 'Manual'} />
      <IRow type="currency" label="Amount" value={payment?.amount} />
      <IRow label="User Initiated" type="date" value={payment?.createdOn} />

      <IRow
        label="Conf. Code"
        type="mono"
        hideUnset
        value={payment?.confirmationCode || data?.task?.confirmationCode}
      />
      {(payment?.notes || data?.task?.notes) && <Notes>{payment?.notes || data?.task?.notes}</Notes>}
    </Entity>
  );
};

export default TaxPaymentNode;

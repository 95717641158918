import React from 'react';
import styled from 'styled-components';
import I from './I';

const StyledAnnotationInline = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 0px;
  .ann-title {
    font-weight: 500;
    margin-bottom: 0;
    font-size: var(--fp);
    color: var(--c-fg-0);
  }
  p {
    margin-bottom: 0;
    font-size: var(--ffp);
    color: var(--c-fg-0);
    opacity: 0.75;
  }
`;

const StyledAnnotation = styled.div`
  margin: 0 0 0;
  display: flex;
  align-items: center;
  background: ${p => p.theme[p.background + 'Color']};
  color: ${p => (p.invert ? p.theme.pageColor : p.theme.textColor)};
  border-radius: var(--radius);
  padding: 8px 12px;
  max-width: 600px;
  .ann-title {
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${p => (p.subtitle ? 2 : 0)}px;
    font-size: var(--fp);
    color: ${p => (p.invert ? p.theme.pageColor : p.theme.textColor)};
  }
  p {
    display: none;
    margin-bottom: 2px;
    font-size: var(--fp);
    color: ${p => (p.invert ? p.theme.pageColor : p.theme.textColor)};
    opacity: 0.75;
  }
  &:last-child {
    margin: 0;
  }
`;

function Annotation({
  icon = 'INFO',
  title,
  subtitle,
  background = 'skeleton',
  color = 'text',
  invert,
  inline,
  style,
}) {
  const S = inline ? StyledAnnotationInline : StyledAnnotation;
  return (
    <S background={background} invert={invert} style={style}>
      {!!icon && <I name={icon} />}
      <div>
        <div className="ann-title">{title}</div>
        {!!subtitle && !inline && <p>{subtitle}</p>}
      </div>
    </S>
  );
}

export default Annotation;

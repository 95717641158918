import React from 'react';
import styled from 'styled-components';
import I, { IconType } from './I';
import Icon from './Icon';
import Tooltip from './Tooltip';

const StyledOuter = styled.div`
  position: relative;
  &:hover .tooltip {
    display: flex !important;
  }
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  padding-top: 1px;
  box-sizing: border-box;
  margin: 0 1px;
  width: ${p => (p.wide ? 'var(--toolbarButtonWidthWide)' : 'var(--toolbarButtonWidth)')};
  cursor: var(--cursor);
  height: var(--toolbarButtonHeight);
  border-radius: var(--radius);
  color: var(--c-fg-0);
  background: transparent;
  opacity: ${p => (p.disabled ? 0.35 : 1)};
  &.active {
    color: var(--cAdminAccent);
    background: var(--fga);
  }
  &:hover {
    background: ${p => !p.disabled && 'var(--fga)'};
  }
`;

export interface ToolbarButtonProps {
  icon: IconType;
  onPress: () => void;
  tooltip?: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  fill?: string;
  keyCombo?: string;
  style?: any;
}

export function ToolbarButton({
  icon,
  tooltip,
  onPress,
  active,
  disabled,
  className,
  fill,
  keyCombo,
  style,
}: ToolbarButtonProps) {
  return (
    <Tooltip text={tooltip} keyCombo={keyCombo} side="bottom">
      <StyledOuter className={className} style={style}>
        <StyledButton
          disabled={disabled}
          className={!!active ? 'active' : ''}
          onClick={onPress}
          wide
        >
          <I name={icon} color={fill} size={22} />
        </StyledButton>
      </StyledOuter>
    </Tooltip>
  );
}

export default ToolbarButton;

import { ModalContent } from "src/web/components";
import { Form, Field } from 'src/forms';
import { CREATE_USER, useMutation } from "src/data";
import { useNavigate } from "react-router";

export function CreateUserModalContent({ closeModal }) {
  const navigate = useNavigate();

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted(data) {
      closeModal();
      navigate(`/users/${data?.adminCreateUser?.id}`);
    }
  });

  const handleSubmit = ({ email }) => {
    createUser({ variables: { email } });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ModalContent title="Create new user" onSubmit={() => {}} cta="Create" disabled={loading}>
        <Field 
          name="email"
          type="email"
          label="Email Address"
          autoFocus
        />
      </ModalContent>
    </Form>
  );
}
import React from 'react';
import styled from 'styled-components';
import I from '../I';
import Icon from '../Icon';

const STableRow = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${p => (p.selected ? 'var(--c-border-light)' : 'transparent')};
  margin: 0 -8px;
  border-radius: 16px;
  opacity: ${p => (p.faded && !p.selected ? 0.35 : 1)};
  color: var(--c-fg-0);
  cursor: var(--cursor);
  &:hover {
    background: var(--c-border-light);
    opacity: 1;
  }
  .leading,
  .trailing,
  .kb {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leading {
    height: 48px;
    width: 48px;
    margin-right: 12px;
    background: var(--c-border-light);
    border-radius: calc(2 * var(--radius));
  }
  .trailing {
    margin-left: 12px;
    width: 48px;
    font-size: var(--fp);
    text-align: right;
    justify-content: flex-end;
    &.large {
      width: 108px;
    }
    &.noMax {
      width: auto;
    }
  }
  .text {
    flex-grow: 1;

    ._title {
      font-weight: ${p => (p?.hasTitle ? 500 : 400)};
      line-height: 1;
      color: ${p => (p?.hasTitle ? 'var(--c-fg-0)' : 'var(--c-fg-2)')};
      @media (max-width: 1024px) {
        max-width: 250px;
        white-space: nowrap;
        mask: linear-gradient(to right, #000000 85%, #00000000);
      }
    }
    ._subtitle {
      font-size: var(--ffp);
      line-height: 1;
      color: var(--c-fg-1);
      margin-top: 4px;
    }
  }
`;

const TableRow = ({
  title,
  defaultTitle,
  subtitle,
  labels,
  status,
  accessory1,
  accessory2,
  icon,
  selected,
  onPress,
  faded,
  color,
  logo,
}) => {
  return (
    <STableRow hasTitle={!!title} selected={selected} onClick={onPress} faded={faded}>
      <div className="leading" style={{ background: color }}>
        {logo ? (
          <Icon size={40} noShadow logo={logo} render containLg={!!logo} />
        ) : (
          <I name={icon} size={20} />
        )}
      </div>
      <div className="text">
        <div className="_title">{title || defaultTitle}</div>
        {!!subtitle && (
          <div className="_subtitle">
            {subtitle} {labels}
          </div>
        )}
      </div>
      <div className="trailing mono noMax ">{accessory1}</div>
      <div className="trailing mono small ">{accessory2}</div>
      <div className="trailing large">{status}</div>
    </STableRow>
  );
};

export default TableRow;

import { TIER_NAMES } from './tiers';

export const BANK_LINK_STATUS = ['GOOD', 'LOGIN_ERROR', 'CONFIG_ERROR', 'CHALLENGE_ERROR', 'SYSTEM_ERROR'];

export const WORK_TYPE = ['WORK_TYPE_1099', 'WORK_TYPE_W2', 'WORK_TYPE_DIVERSIFIED'];

export const HEALTH_APPLICATION = [
  'ENROLLED',
  'PENDING_ENROLLMENT',
  'ENROLLMENT_STUCK',
  'SUBMITTED',
  'IN_PROGRESS',
  'CANCELED',
  'DELETED',
];

export const HEALTH_ENROLLMENT = [
  'NOT_ENROLLED',
  'ENROLLED',
  'ACTIVE',
  'TERMINATED',
  'CANCELLED',
  'PENDING_START_DATE',
  'PENDING',
  'ACTIVE_PENDING_PAYMENT',
];

export const HEALTH_POLICY = [
  'ACTIVE',
  'ACTIVE_PENDING_PAYMENT',
  'PENDING_START_DATE',
  'PENDING',
  'EXPIRED',
  'TERMINATED',
  'CANCELLED',
];

export const KYC_STATUS = ['KYC_GOOD', 'KYC_DENIED', 'KYC_REVIEW', 'KYC_PENDING'];

export const CIP_STATUS = ['KYC_GOOD', 'KYC_DENIED', 'KYC_REVIEW', 'KYC_PENDING'];

export const ACCOUNT_STATUS = ['INITIAL', 'READY', 'DELETED', 'LOCKED'];

export const SIGNUP_CONTEXT = [
  'PREMIUM_SLASHER',
  'HEALTH_LINK',
  'HEALTH_APPLICATION',
  'HEALTH_RENEW',
  'TAX',
  'RETIREMENT',
  'SAVINGS',
];

export const ACCOUNT_TIER = [...Object.keys(TIER_NAMES)];

export const FILTER_GROUPS = {
  'Account Status': ACCOUNT_STATUS,
  'Account Tier': ACCOUNT_TIER,
  'Signup Context': SIGNUP_CONTEXT,
  'KYC Status': KYC_STATUS,
  'CIP Status': CIP_STATUS,
  'Health Application': HEALTH_APPLICATION,
  'Health Policy': HEALTH_POLICY,
  'Bank Link': BANK_LINK_STATUS,
};

export const FILTER_NAMES = {};

export const encode = string => {
  if (!string || string === '') return '';
  else return string.replace(' ', '_').toUpperCase();
};

export const decode = string => {
  if (!string || string === '') return '';
  else
    return string
      .replace('_', ' ')
      .toProperCase()
      .replace('Kyc', 'KYC');
};

export const decodeOption = string => {
  if (!string || string === '') return '';
  else
    return decode(string)
      .replace('KYC', '')
      .replace('Work Type', '');
};

export const aggregateFilterGroups = filters => {
  if (!filters || filters.length === 0) return {};
  var obj = {};
  Object.keys(FILTER_GROUPS).map((key, idx) => {
    var g = [];
    filters.forEach((item, idx2) => {
      if (item.group === encode(key)) g.push(item.value);
    });
    if (g?.length > 0 && g[0] !== null) obj[encode(key)] = g;
  });

  return obj;
};

export const createFilterQuery = filters => {
  var params = new URLSearchParams();

  if (!filters || filters?.length === 0) return '';

  filters.map(item => {
    if (item.value) params.append(encode(item.group).toLowerCase(), encode(item.value).toLowerCase());
  });

  return params.toString();
};

import { gql } from '../generated';

export const UPSERT_EXPLORER = gql(`
  mutation UpsertExplorer($input: HealthExplorerDataInput!, $personID: ID!) {
    upsertHealthExplorerData(input: $input, personID: $personID) {
      id
      pathwayType
      zipcode
      countyfips
      state
      coverageYear
      providerPlanID
      income
    }
  }
`);

import React from 'react';
import styled from 'styled-components';

const StyledModalNavDetail = styled.div`
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  left: var(--navPrimary);
  padding: calc(var(--navHeight) / 2) 36px 24px;
`;

export default ({ children }) => {
  return <StyledModalNavDetail>{children}</StyledModalNavDetail>;
};

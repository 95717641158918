
// Capitalize does what it sounds like.
export function capitalize(value = '') {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
}

// Capitalize does what it sounds like.
export function propercase(value = '') {
  if (!value) return '';

  return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}


export function plural(num, single, plural) {
  return num === 1 ? single : plural;
}

export function formatEnum(value = '', ignore = '') {
  const removed = value?.replaceAll(ignore, '');
  return propercase(removed?.replaceAll('_', ' '));
}

// Capitalize does what it sounds like.
export function forceCapitalize(value = '') {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

function onlyNums(value = '') {
  return value.replace(/[^\d]/g, '');
}


export function formatPhone(value) {
  if (!value) {
    return value;
  }

  const nums = onlyNums(value);

  if (nums.length <= 3) {
    return nums;
  }

  if (nums.length <= 7) {
    return `${nums.slice(0, 3)}-${nums.slice(3)}`;
  }

  return `${nums.slice(0, 3)}-${nums.slice(3, 6)}-${nums.slice(6, 10)}`;
}


// formatCurrency takes a value and ensures it's properly formatted to appear
// like currency by including the $ sign and not allowing multiple periods in
// the number.  In its current state it does not handle internationalization and
// if we want to format currencies for other countries we'll have to look into
// this further.
export const formatCurrency = value => {
  if (typeof value === 'undefined' || value === null) {
    return value;
  }
  value = value.toString();

  value = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .
  const sections = value.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  const addCommas = num => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return '$' + addCommas(sections[0]) + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return '$' + addCommas(sections[0]) + '.';
    // Otherwise, return only section
  } else {
    return '$' + addCommas(sections[0]);
  }
};


const ISO_FORMAT = /^\d{4}-\d{2}-\d{2}$/; // tests if matching YYYY-MM-DD
const INPUT_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/;

// shows a formatted value like $ 25,000
export const formatDate = (val) => {
  if (!val) return '';

  if (ISO_FORMAT.test(val)) {
    // convert mm/dd/yyyy to YYYY-MM-DD
    const yyyy = val.substring(0, 4);
    const mm = val.substring(5, 7);
    const dd = val.substring(8, 10);
    return `${mm} / ${dd} / ${yyyy}`;
  }

  const digits = val.replace(/\D+/g, '');
  if (!digits) return '';

  const slashCount = val.split('/').length - 1;

  const month = digits.substring(0, 2);
  const day = digits.substring(2, 4);
  const year = digits.substring(4, 8);

  if (digits.length <= 2) {
    return slashCount > 0 ? `${month} /` : month;
  }

  if (digits.length <= 4) {
    return slashCount > 1 ? `${month} / ${day} /` : `${month} / ${day}`;
  }

  return `${month} / ${day} / ${year}`;
};

// stores the actual value as a base-10 integer
export const parseDate = (d) => {
  // slice the date after the 8th digit
  // e.g. 12/01/19938 should be checked as 12/01/1993
  let num = 0;
  let l = d.length;
  for (let i = 0; i < d.length; i++) {
    if ((d[i], d[i] !== ' ' && !isNaN(d[i]))) {
      num++;
    }

    if (num === 8) {
      l = i;
    }
  }

  const val = d.slice(0, l + 1);

  // check here at beginning and again at end
  if (INPUT_FORMAT.test(val)) {
    // store the data in the requested format YYYY-MM-DD
    const MM = val.substring(0, 2);
    const DD = val.substring(3, 5);
    const YYYY = val.substring(6, 10);
    return `${YYYY}-${MM}-${DD}`;
  }

  // strip down to just digits and /
  const dateCharacters = val.replace(/[^0-9/]+/g, '').replace(' ', '');

  const pieces = dateCharacters.split('/');

  // if there are NO slashes, we just deduce as best as we can..
  if (pieces.length === 1) {
    // auto split instead
    const digits = val.replace(/\D+/g, '');
    if (!digits) return '';

    const month = digits.substring(0, 2);
    const day = digits.substring(2, 4);
    const year = digits.substring(4, 8);

    if (digits.length <= 2) {
      if (month === '01') return '01';
      const number = parseInt(month, 10);
      if (number === 0) return '0';
      return number < 10 && number > 1 ? `0${number}` : `${number}`;
    }

    if (digits.length <= 4) {
      return `${month}/${day}`;
    }

    return `${month}/${day}/${year}`;
  }

  const parsedValue = pieces.reduce((date, piece, index) => {
    const number = piece ? parseInt(piece, 10) : '';
    let parsed = piece;

    const hasAnotherSlash = index < pieces.length - 1;

    // if number is 2 -> 9 we can prepend a 0
    if (index === 0 && number < 10 && number > 0) {
      parsed = `0${number}`;
    }

    // parsing the day
    if (index === 1 && ((number <= 3 && hasAnotherSlash) || (number > 3 && number < 10))) {
      parsed = `0${number}`;
    }

    // if year is two digits and NOT 19 or 20, we can deduce the full year
    if (index === 2 && number !== 19 && number !== 20 && number > 9 && number < 100) {
      parsed = number < 20 ? `20${number}` : `19${number}`;
    }

    // append the newly parsed section, plus add a / if there are more pieces
    return `${date}${parsed}${hasAnotherSlash ? '/' : ''}`;
  }, '');

  if (INPUT_FORMAT.test(parsedValue)) {
    // store the data in the requested format YYYY-MM-DD
    // convert mm/dd/yyyy to YYYY-MM-DD
    const MM = parsedValue.substring(0, 2);
    const DD = parsedValue.substring(3, 5);
    const YYYY = parsedValue.substring(6, 10);
    return `${YYYY}-${MM}-${DD}`;
  }

  return parsedValue;
};
import React from 'react';
import styled from 'styled-components';
import { ApplicationIssue, UploadTile } from 'src/web/components';

const StyledLabel = styled.div`
  padding: 2px 0;
  color: ${p => p.theme.subtleColor};
  font-size: var(--ffp);
`;

const IssuesPane = ({ app }) => {
  return (
    <>
      {app?.dmis?.map(issue => (
        <div key={issue?.id}>
          <ApplicationIssue
            key={issue?.id}
            members={[issue?.member]}
            status={issue?.status}
            type={issue?.verificationType}
            subType={issue?.subType}
            resolveBy={issue?.resolveBy}
            lastReviewed={issue?.lastReviewed}
            category="DMI"
          />
          {issue?.generatedPhysicalDocuments?.length > 0 && <StyledLabel>Notices</StyledLabel>}
          {issue?.generatedPhysicalDocuments?.map(doc => (
            <UploadTile
              small
              id={doc?.id}
              name={doc?.type}
              fileType="PDF"
              onPress={() => window.open(doc?.url, '_blank')}
            />
          ))}
          {app?.dmiDocumentUploads?.length > 0 && <StyledLabel>User uploads</StyledLabel>}
          {app?.dmiDocumentUploads
            ?.filter(doc => doc.issueID === issue?.id)
            ?.map(doc => (
              <UploadTile
                small
                id={doc?.id}
                name={doc?.type}
                fileType="PDF"
                onPress={() => window.open(doc?.url, '_blank')}
              />
            ))}
        </div>
      ))}
      {app?.dmiNotices?.length > 0 && <StyledLabel>Notices</StyledLabel>}
      {app?.dmiNotices?.map(doc => (
        <UploadTile
          small
          id={doc?.id}
          name={doc?.type}
          fileType="PDF"
          onPress={() => window.open(doc?.url, '_blank')}
        />
      ))}{' '}
      {app?.svis?.map(issue => (
        <div key={issue?.id}>
          <ApplicationIssue
            key={issue?.id}
            members={issue?.members}
            status={issue?.status}
            type={issue?.statusCode}
            resolveBy={issue?.resolveBy}
            lastReviewed={issue?.lastReviewed}
            category="SVI"
          />
          {issue?.generatedPhysicalDocuments?.length > 0 && <StyledLabel>Notices</StyledLabel>}
          {issue?.generatedPhysicalDocuments?.map(doc => (
            <UploadTile
              small
              id={doc?.id}
              name={doc?.name}
              fileType="PDF"
              onPress={() => window.open(doc?.url, '_blank')}
            />
          ))}
          {app?.sviDocumentUploads?.length > 0 && <StyledLabel>User uploads</StyledLabel>}
          {app?.sviDocumentUploads
            ?.filter(doc => doc.issueID === issue?.id)
            ?.map(doc => (
              <UploadTile
                small
                id={doc?.id}
                name={doc?.type}
                fileType="PDF"
                onPress={() => window.open(doc?.url, '_blank')}
              />
            ))}
        </div>
      ))}
    </>
  );
};

export default IssuesPane;

import React, { useState } from 'react';
import {
  Scroll,
  Button,
  StyledBottomBar,
  Banner,
  FullModal,
  IRow,
  RowGroup,
  OptionRow,
} from 'src/web/components';
import { TIER_NAMES } from 'src/web/utility/tiers';
import { makeAddress, makeName } from 'src/web/utility';

export const UpdateTierModal = ({ userId, tier, eligibleTiers, onRequestClose }) => {
  const [newTier, setNewTier] = useState(tier);

  // @todo: lookup IDV information
  const data = {};

  const viewer = { ...(data?.viewer || {}) };
  const user = { ...(viewer?.user || {}) };
  const banks = viewer?.bankLinks;

  // @todo: add back later
  const updateTier = () => {};
  const loading = false;

  return (
    <FullModal
      title="Change Tier"
      onRequestClose={onRequestClose}
      sidebarItem={
        <>
          <RowGroup borderless>
            <IRow pretext="DDA" label="Legal Name" pii value={makeName(user)} />

            {banks?.map((bank, idx) => (
              <IRow
                pretext="Plaid"
                label="Legal Name"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.names?.[0]}
              />
            ))}

            <IRow pretext="Email" label="Email" pii value={user?.email} />
            {banks?.map((bank, idx) => (
              <IRow
                pretext="Plaid"
                label="Plaid Email"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.emails?.[0]}
              />
            ))}
            <IRow pretext="DDA" label="Address" value={makeAddress(user?.legalAddress)} />

            {banks?.map((bank, idx) => (
              <IRow
                pretext="Plaid"
                label="Plaid Address"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.addresses?.[0]}
              />
            ))}
          </RowGroup>
        </>
      }
    >
      <Scroll transparent>
        <Banner
          title="Early feature preview. Use care and diligence."
          subtitle={
            <>
              Make sure you have scanned this user's identity page and read the{' '}
              <a
                href="https://www.notion.so/catchco/Deposit-Products-acaf22e206b8488486c022765300a32c"
                target="_blank"
                style={{ color: 'var(--c-fg-0)', textDecoration: 'underline', lineHeight: 1 }}
                rel="noreferrer"
              >
                Deposit Product details
              </a>
            </>
          }
        />
        <br />

        {Object.keys(TIER_NAMES).map(t => (
          <OptionRow
            title={TIER_NAMES[t]}
            pretitle={t?.replace('TIER_', '')}
            selected={newTier === t}
            onPress={() => setNewTier(t)}
            subtitle={
              <span
                style={{
                  color: eligibleTiers.includes(t) ? 'var(--c-good-0)' : 'var(--c-bad-0)',
                }}
              >
                {eligibleTiers.includes(t) ? 'Qualified' : 'Unqualified'}
              </span>
            }
          />
        ))}
      </Scroll>
      <StyledBottomBar>
        <Button loading={loading} onPress={updateTier}>
          <span style={{ fontWeight: 400 }}>Set to</span>{' '}
          <span style={{ fontWeight: 600 }}>{TIER_NAMES[newTier]}</span>{' '}
          <span style={{ fontWeight: 500, opacity: 0.5 }}>(Tier {newTier?.replace('TIER_', '')})</span>
        </Button>
      </StyledBottomBar>
    </FullModal>
  );
};

export default UpdateTierModal;

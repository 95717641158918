import React from 'react';

import { ReactComponent as Star } from 'src/web/svg/star.svg';
import { ReactComponent as Home } from 'src/web/svg/home.svg';
import { ReactComponent as Bank } from 'src/web/svg/bank.svg';
import { ReactComponent as Passport } from 'src/web/svg/passport.svg';
import { ReactComponent as Clock } from 'src/web/svg/clock.svg';
import { ReactComponent as Shield } from 'src/web/svg/shield.svg';
import { ReactComponent as CoverageCheck } from 'src/web/svg/shield-tick.svg';
import { ReactComponent as Dollar } from 'src/web/svg/dollar.svg';
import { ReactComponent as Income } from 'src/web/svg/arrow-down-right-circle.svg';
import { ReactComponent as Fingerprint } from 'src/web/svg/fingerprint.svg';
import { ReactComponent as Statements } from 'src/web/svg/file-text-2.svg';
import { ReactComponent as Close } from 'src/web/svg/close.svg';
import { ReactComponent as Search } from 'src/web/svg/search.svg';
import { ReactComponent as Chart } from 'src/web/svg/chart.svg';
import { ReactComponent as App } from 'src/web/svg/app-store.svg';
import { ReactComponent as User } from 'src/web/svg/user.svg';
import { ReactComponent as Bookmark } from 'src/web/svg/bookmark.svg';
import { ReactComponent as Inbox } from 'src/web/svg/inbox.svg';
import { ReactComponent as Health } from 'src/web/svg/health.svg';
import { ReactComponent as Code } from 'src/web/svg/code.svg';
import { ReactComponent as Grid } from 'src/web/svg/grid-masonry-2.svg';
import { ReactComponent as Megaphone } from 'src/web/svg/announcement.svg';
import { ReactComponent as Linked } from 'src/web/svg/linked.svg';
import { ReactComponent as Lock } from 'src/web/svg/lock.svg';
import { ReactComponent as Unlock } from 'src/web/svg/lock-unlocked.svg';
import { ReactComponent as Command } from 'src/web/svg/command.svg';
import { ReactComponent as Link } from 'src/web/svg/link.svg';
import { ReactComponent as Eye } from 'src/web/svg/eye.svg';
import { ReactComponent as Hide } from 'src/web/svg/eye-off.svg';
import { ReactComponent as Info } from 'src/web/svg/information.svg';
import { ReactComponent as Pause } from 'src/web/svg/pause.svg';
import { ReactComponent as Trash } from 'src/web/svg/trash.svg';
import { ReactComponent as Barcode } from 'src/web/svg/barcode.svg';
import { ReactComponent as Caret } from 'src/web/svg/chevron-right.svg';
import { ReactComponent as Down } from 'src/web/svg/chevron-down.svg';
import { ReactComponent as Settings } from 'src/web/svg/settings.svg';
import { ReactComponent as Pin } from 'src/web/svg/pin.svg';
import { ReactComponent as Refresh } from 'src/web/svg/refresh.svg';
import { ReactComponent as Send } from 'src/web/svg/send.svg';
import { ReactComponent as Debug } from 'src/web/svg/indent-left.svg';
import { ReactComponent as Apps } from 'src/web/svg/apps.svg';
import { ReactComponent as Edit } from 'src/web/svg/pen.svg';
import { ReactComponent as Cancel } from 'src/web/svg/minus-circle.svg';
import { ReactComponent as All } from 'src/web/svg/grid-masonry-2.svg';
import { ReactComponent as Map } from 'src/web/svg/map.svg';
import { ReactComponent as Right } from 'src/web/svg/arrow-right.svg';
import { ReactComponent as Rows } from 'src/web/svg/rows.svg';
import { ReactComponent as Swap } from 'src/web/svg/swap.svg';
import { ReactComponent as Deposit } from 'src/web/svg/arrow-down-right.svg';
import { ReactComponent as Import } from 'src/web/svg/arrow-down-left.svg';
import { ReactComponent as Withdrawal } from 'src/web/svg/arrow-up-left.svg';
import { ReactComponent as Payment } from 'src/web/svg/arrow-up-right.svg';
import { ReactComponent as Love } from 'src/web/svg/heart.svg';
import { ReactComponent as Help } from 'src/web/svg/help-circle.svg';
import { ReactComponent as Loading } from 'src/web/svg/spinner.svg';
import { ReactComponent as Done } from 'src/web/svg/check-circle.svg';
import { ReactComponent as Target } from 'src/web/svg/target.svg';
import { ReactComponent as Crosshair } from 'src/web/svg/crosshair.svg';
import { ReactComponent as Initial } from 'src/web/svg/square.svg';
import { ReactComponent as Download } from 'src/web/svg/download.svg';
import { ReactComponent as Auto } from 'src/web/svg/bolt.svg';
import { ReactComponent as Press } from 'src/web/svg/cursor.svg';
import { ReactComponent as Tag } from 'src/web/svg/tag.svg';
import { ReactComponent as Question } from 'src/web/svg/help-circle.svg';
import { ReactComponent as Circle } from 'src/web/svg/circle.svg';
import { ReactComponent as Menu } from 'src/web/svg/menu.svg';
import { ReactComponent as Unit } from 'src/web/svg/unit.svg';
import { ReactComponent as EDE } from 'src/web/svg/ede.svg';
import { ReactComponent as Plaid } from 'src/web/svg/plaid.svg';
import { ReactComponent as Warning } from 'src/web/svg/warning-triangle.svg';
import { ReactComponent as RotateLeft } from 'src/web/svg/rotate-left.svg';
import { ReactComponent as RotateRight } from 'src/web/svg/rotate-right.svg';
import { ReactComponent as Task } from 'src/web/svg/clipboard.svg';
import { ReactComponent as FileDownload } from 'src/web/svg/file-download-2.svg';
import { ReactComponent as Open } from 'src/web/svg/maximize-2.svg';
import { ReactComponent as Dental } from 'src/web/svg/tooth.svg';
import { ReactComponent as Medical } from 'src/web/svg/stethoscope.svg';
import { ReactComponent as Unlink } from 'src/web/svg/link-break.svg';
import { ReactComponent as Add } from 'src/web/svg/plus.svg';
import { ReactComponent as Play } from 'src/web/svg/play.svg';
import { ReactComponent as Ensure } from 'src/web/svg/floppy-disc.svg';
import { ReactComponent as Refetch } from 'src/web/svg/rotate-2.svg';
import { ReactComponent as FaceCheck } from 'src/web/svg/face-check.svg';
import { ReactComponent as Dollar2 } from 'src/web/svg/dollar-2.svg';
import { ReactComponent as Sign } from 'src/web/svg/sign.svg';
import { ReactComponent as External } from 'src/web/svg/share.svg';
import { ReactComponent as Cake } from 'src/web/svg/cake.svg';
import { ReactComponent as Phone } from 'src/web/svg/phone.svg';
import { ReactComponent as Triangle } from 'src/web/svg/triangle.svg';
import { ReactComponent as Activity } from 'src/web/svg/activity.svg';
import { ReactComponent as Slash } from 'src/web/svg/slash.svg';
import { ReactComponent as File } from 'src/web/svg/file-text-2.svg';
import { ReactComponent as UserPlus } from 'src/web/svg/user-plus.svg';
import { ReactComponent as DocumentShield } from 'src/web/svg/document-shield.svg';
import { ReactComponent as ShieldTick } from 'src/web/svg/shield-tick.svg';
import { ReactComponent as Flag } from 'src/web/svg/flag.svg';
import { ReactComponent as Piggybank } from 'src/web/svg/piggybank.svg';

export type IconType = keyof typeof ICONS;

const ICONS = {
  Slash,
  Home,
  Eye,
  File,
  UserPlus,
  Activity,
  Crosshair,
  Inbox,
  Search,
  Close,
  Health,
  Taxes: Bank,
  User,
  Bookmark,
  Pin: Bookmark,
  Grid,
  Code,
  Fingerprint,
  Clock,
  DocumentShield,
  ShieldTick,
  Download,
  Doc: Statements,
  Plan: Dollar,
  Coverage: Shield,
  CoverageCheck,
  Income,
  Bank: Linked,
  Linked,
  Plus: Star,
  Upload: Passport,
  Passport,
  Megaphone,
  Info,
  Link,
  Hide,
  Command,
  Delete: Trash,
  ID: Barcode,
  Pause,
  Lock,
  Unlock,
  Caret,
  Down,
  Settings,
  Location: Pin,
  Refresh,
  Send,
  Debug,
  Apps,
  Edit,
  Cancel,
  All,
  Map,
  Right,
  Rows,
  Deposit,
  Withdrawal,
  Payment,
  Swap,
  Love,
  Help,
  Loading,
  Done,
  Target,
  Initial,
  Auto,
  Press,
  Tag,
  Question,
  Circle,
  Unit,
  EDE,
  Plaid,
  RotateLeft,
  RotateRight,
  FileDownload,
  Open,
  Dental,
  Medical,
  Menu,
  Unlink,
  Add,
  Play,
  Warning,
  Task,
  Ensure,
  Refetch,
  Import,
  FaceCheck,
  Dollar: Dollar2,
  Sign,
  App,
  External,
  Cake,
  Phone,
  Chart,
  Triangle,
  Flag,
  Piggybank,
};

interface Props {
  name: IconType;
  size?: number;
  color?: string;
  style?: any;
  className?: string;
}

export const I = ({ name = 'Loading', size = 75, color = 'var(--fg)', style, className, hasFill }: Props) => {
  const C = ICONS[name] ? ICONS[name] : Loading;
  return (
    <C
      style={style}
      width={size}
      height={size}
      stroke={color}
      className={className}
      fill={hasFill ? color : 'transparent'}
    />
  );
};

export default I;

import { GoalCard, Spinner, Scroll, RowGroup } from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { GET_BANK_ACCOUNT, useFintechQuery } from 'src/data';

export function BankingPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();

  const { loading, data } = useFintechQuery(GET_BANK_ACCOUNT, {
    variables: { customerID: userId },
    skip: !userId,
  });

  const bankAccount = data?.customer?.bankAccount;
  const bankApplication = data?.customer?.bankApplication;

  return (
    <Scroll transparent={inInspector} loading={loading}>
      {loading ? (
        <div className="full">
          <Spinner loading />
        </div>
      ) : (
        <>
          <RowGroup title="Bank Account" borderless close={!bankAccount}>
            {bankAccount ? (
              <GoalCard
                selected={selectedId === bankAccount.id}
                onPress={() => setSelected(bankAccount.id, { type: SelectedType.BANK_ACCOUNT })}
                fillLogo
                squareLogo
                logo={`/img/products/unit.png`}
                name="DDA"
                subtitle={bankAccount.accountMask}
                balance={bankAccount.totalBalance}
                status={bankApplication.status}
              />
            ) : (
              <div className="full">No bank account</div>
            )}
            {/* {!!bbva && (
  
            )} */}
          </RowGroup>
          <RowGroup title="Bank Application" close={!!bankAccount}>
            {bankApplication ? (
              <GoalCard
                selected={selectedId === bankApplication.id}
                onPress={() => setSelected(bankApplication.id, { type: SelectedType.BANK_APPLICATION })}
                fillLogo
                squareLogo
                logo={`/img/products/unit.png`}
                name="Application"
                subtitle={bankApplication.id}
                status={bankApplication.status}
              />
            ) : (
              <div className="full">No bank application</div>
            )}
          </RowGroup>
        </>
      )}
    </Scroll>
  );
}

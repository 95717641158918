import { ConversionStatusFragment } from "src/data";
import { SelectedType, useInspector } from "src/hooks/useInspector";
import { Card, Status, Text } from "src/kit";
import { formatDate } from "src/utils";

export function ConversionCard({ conversion }: { conversion: ConversionStatusFragment }) {
  const { selectedId, setSelected } = useInspector();

  const select = () => {
    setSelected(conversion.id, { type: SelectedType.CONVERSION });
  }

  return (
    <Card onPress={select} selected={selectedId === conversion.id}>
      <div>
        <Text size="title">{conversion.label}</Text>
        <Text size="subtitle">Last updated {formatDate(conversion.lastUpdated)}</Text>
      </div>
      <Status status={conversion.status} />
    </Card>
  );
}
import { formatEnum } from 'src/_utils/format';
import { ActivityEventType, ConversionStatusEnum } from 'src/data';
import { formatStatus } from 'src/kit';

interface EventConfig {
  getLabel?: (event) => string;
  getSublabel?: (event) => string;
}

export const eventConfig: Partial<Record<ActivityEventType, EventConfig>> = {
  [ActivityEventType.ConversionStatusUpdated]: {
    getSublabel: event => {
      if (event.metadata?.status === ConversionStatusEnum.Closed) {
        return `Closed for ${formatStatus(event.metadata?.closed_reason)}`;
      }
      return formatStatus(event.metadata?.status);
    },
  },

  [ActivityEventType.AgreementSigned]: {
    getSublabel: event => {
      return formatEnum(event.metadata?.agreement_type);
    },
  },
};

import { useQuery, GET_EVENT } from 'src/data';
import { formatStatus, Scroll, statusTypes } from 'src/kit';
import { Header, IRow } from 'src/web/components';
import { InspectorPaneProps } from '../../types';
import { DateFormat } from 'src/utils';
import { RowDataType } from 'src/web/types';
import { initials, makeName } from 'src/web/utility';

const getType = (val: any): RowDataType => {
  if (typeof val === 'boolean') return 'bool';
  if (typeof val === 'number') return 'mono';
  if (val in statusTypes) return 'status';
  return undefined;
}

export function EventPane({ id }: InspectorPaneProps) {
  const { loading, data } = useQuery(GET_EVENT, {
    variables: { id },
    skip: !id,
  });

  if (!id) {
    return <div className="full">No selection</div>;
  }

  const metadata = Object.keys(data?.event?.metadata || {});

  return (
    <Scroll inspector loading={loading}>
      <Header icon="Clock" subtitle="Event" title={data?.event?.label}>
        <IRow
          label="User"
          type="assignee" 
          value={makeName(data?.event?.subject)}
          avatar={data?.event.subject?.avatarImage}
          initials={initials(data?.event?.subject)}
        />
        {metadata.map(field => (
          <IRow 
            key={field}
            label={formatStatus(field)}
            type={getType(data?.event?.metadata[field])}
            value={data?.event?.metadata[field]}
          />
        ))}
        <IRow
          label="Created On"
          type="date"
          format={DateFormat.FULL}
          value={data?.event?.createdOn}
        />
        <IRow
          label="Performed By"
          type="assignee" 
          value={makeName(data?.event?.triggeredBy)}
          avatar={data?.event.triggeredBy?.avatarImage}
          initials={initials(data?.event?.triggeredBy)}
        />
      </Header>
    </Scroll>
  );
};

export const any = (arr, key) => {
  var a = arr?.map((el, i) => el[key]) || [];
  return a.some(e => !!e);
};

export const uniq = arr => {
  return Array.from(new Set(arr));
};

const useEDEResults = ({ application, members }) => {
  const isReady = application?.isEligibilityDetermined;
  const _mExchange = members?.filter(a => !!a?.exchangeEligibility);

  const aptc = {
    householdMax: application?.eligibility?.totalAptc,
    household: members.reduce(function(a, b) {
      return a + (b?.aptcEligibility?.amount || 0);
    }, 0),
  };

  const exchange = { household: _mExchange?.length > 0, members: _mExchange };

  return {
    isDetermined: !!isReady,
    wantsDiscount: application?.isRequestingFinancialAssistance,
    canEnroll: !isReady ? null : !!exchange?.household,
    aptc,
    hasSEP: !isReady ? null : application?.applicant?.sepEligibility?.earliestQhpEffective,
  };
};

export default useEDEResults;

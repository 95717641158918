import React from 'react';
import { useQuery } from '@apollo/client';

import { initials, makeName } from 'src/web/utility';
import { EDE_ENROLLMENT, EDE_ENROLLMENT_FAST } from 'src/web/data';
import { Scroll, Header } from 'src/web/components';
import { EdeSharedInspector } from 'src/web/inspectors';
import { InspectorPaneProps } from '../../types';
import { USER_POLICY } from 'src/data';

/**
 *
 * @todo:
 * - idp
 * - plan app link
 * - move into shared file
 * - explorer
 * - actions:
 *    - submit
 *    - delete
 *    - debug
 *    - goto one/all
 * - technicals
 *    - catch app id
 *    - exchange app id
 *
 */
export function EDEPane({ id, userId }: InspectorPaneProps) {
  const { data: enrollmentLite, loading: _loading } = useQuery(EDE_ENROLLMENT_FAST, {
    skip: !id || !userId,
    variables: {
      id,
    },
  });

  const { data: polData } = useQuery(USER_POLICY, {
    skip: !id || !enrollmentLite?.healthEnrollment?.policyID,
    variables: {
      personID: userId,
      policyID: enrollmentLite?.healthEnrollment?.policyID,
    },
  });

  const { data, loading } = useQuery(EDE_ENROLLMENT, {
    skip: !id || !userId,
    variables: {
      input: {
        id,
      },
      userId,
    },
  });

  const e = {
    ...enrollmentLite?.healthEnrollment,
  };
  const app = data?.viewerTwo?.health?.application;
  const pol = e?.policy || polData?.viewerTwo?.health?.policy;
  const needsLoad = !!loading && (!data || id !== e?.id);
  const user = e?.id === id && data?.viewer?.user;

  return (
    <Scroll inspector>
      {(!!e?.id || !!loading) && (
        <>
          <Header
            pii
            avatar={user?.avatarImage}
            loading={needsLoad}
            initials={initials({
              givenName: e?.givenName,
              familyName: e?.familyName,
            })}
            title={makeName({
              givenName: e?.givenName,
              familyName: e?.familyName,
            })}
          />
          <EdeSharedInspector
            pathway={e?.pathwayType}
            exit={data?.viewerTwo?.health?.information?.exitStage}
            loading={loading}
            user={user || { id: userId }}
            enrollment={e}
            application={app}
            policy={pol}
          />
        </>
      )}
    </Scroll>
  );
};
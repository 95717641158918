import React from 'react';
import { Form, Field } from 'react-final-form';
import { useMutation } from '@apollo/client';

import { UPDATE_USER } from 'src/web/data';
import { Scroll, Button, Modal, StyledBottomBar, Header, Input, InputLabel } from 'src/web/components';

export const UpdateUserModal = ({ onRequestClose, userId, user }) => {
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onRequestClose();
    },

    onError: e => {
      alert(`ERROR: ${e}`);
    },
  });

  const F = ({ name, label, ...rest }) => (
    <Field name={name}>
      {props => (
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <InputLabel style={{ width: 200 }}>{label}</InputLabel>
          <Input
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            {...rest}
          />
        </div>
      )}
    </Field>
  );

  return (
    <Modal close onRequestClose={onRequestClose}>
      <Form
        onSubmit={vals =>
          updateUser({
            variables: {
              personId: userId,
              input: {
                ...vals,
              },
            },
          })
        }
        initialValues={{
          givenName: user?.givenName,
          middleName: user?.middleName,
          familyName: user?.familyName,
          nickname: user?.nickname,
          dba: user?.dba,
          ein: user?.ein,
        }}
        render={({ handleSubmit }) => (
          <>
            <Scroll transparent>
              <Header title="Update User" />
              <F name="nickname" label="Preferred Name" autoFocus />
              <F name="givenName" label="First Name" />
              <F name="middleName" label="Middle Name" />
              <F name="familyName" label="Last Name" />
              <F name="dba" label="DBA" />
              <F name="ein" label="EIN" />
            </Scroll>
            <StyledBottomBar>
              <Button loading={loading} onPress={handleSubmit}>
                Update {userId}
              </Button>
            </StyledBottomBar>
          </>
        )}
      />
    </Modal>
  );
};

export default UpdateUserModal;

import React from 'react';
import { Env } from 'src/_utils';
import { size } from 'src/web/styles';
import styled from 'styled-components';

const StyledPanel = styled.div`
  overflow: ${p => (p.overflows ? 'visible' : 'hidden')};
  box-sizing: border-box;
  position: absolute;
  top: ${p => (p.toolbar ? 'var(--navheight)' : 0)}px;
  transition: var(--transition);
  left: ${p => p.left}px;
  right: ${p => p.right}px;
  width: ${p => p.width}px;
  bottom: 0px;
  min-width: ${p => p.minWidth}px
  background-repeat: no-repeat;
  background-position: right -25% bottom -25%;
  background-size: 533px;
  border-bottom: none;
  background-color: ${p =>
    !!p.background ? p.background : Env.isDesktop ? 'transparent' : 'var(--pageLight)'};
  border-left: ${p =>
    !!p.lBorder ? (p.borderColor ? `1px solid ${p.borderColor}` : `1px solid ${p.borderColor}`) : 'none'};
  border-right: ${p =>
    !!p.rBorder ? (p.borderColor ? `1px solid ${p.borderColor}` : `1px solid ${p.borderColor}`) : 'none'};
      @media (max-width: ${size.breakpoint}px) {
        left: ${p => p.leftCompact}px;
        width: ${p => p.widthMobile}px
      }
`;

function Panel({
  left,
  leftCompact,
  widthMobile,
  right,
  width,
  lBorder = false,
  rBorder = false,
  children,
  background,
  borderColor,
  onDeselect,
  styles,
  toolbar,
  collapseRight,
  overflow,
  minWidth,
}) {
  return (
    <StyledPanel
      onClick={onDeselect}
      left={left}
      leftCompact={leftCompact}
      right={right}
      width={width}
      widthMobile={widthMobile}
      lBorder={lBorder}
      rBorder={rBorder}
      overflows={overflow}
      minWidth={minWidth}
      collapseRight={collapseRight}
      background={!!background ? background : !!Env.isDesktop ? 'transparent' : 'var(--pageLight)'}
      borderColor={!!borderColor ? borderColor : 'var(--c-border)'}
      toolbar={toolbar}
      style={styles}
    >
      {children}
    </StyledPanel>
  );
}

export default Panel;

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { I } from 'src/web/components';
import { useMediaQuery } from '../hooks';
import { useSelection } from 'src/util/selection';
import { IconType } from './I';

const StyledNavLink = styled.div`
  font-weight: 500;
  font-size: var(--fp);
  text-align: left;
  display: block;
  position: relative;
  cursor: var(--cursor);
  .the-link {
    text-overflow: clip;
    width: 160px;
    white-space: nowrap;
    mask: linear-gradient(to right, #000000 85%, #00000000);
    line-height: 1;
  }
  > a {
    height: ${p => (p.mobile ? 40 : 32)}px;
    padding: 0 8px 0;
    margin: 0 -8px;
    border-radius: var(--radius);
    font-weight: ${p => (p.bold ? 550 : 450)};
    text-decoration: none;
    line-height: 1;
    display: flex;
    overflow: hidden;
    opacity: 0.8;
    cursor: var(--cursor);
    color: 'var(--c-fg-0)';
    .icon-opacity {
      opacity: 0.65;
    }
    * {
      cursor: var(--cursor);
      color: 'var(--c-fg-0)';
    }
  }
  &:hover > a,
  > a:hover {
    opacity: 1;
    color: 'var(--c-fg-0)';
    background: var(--cSkeleton);
    * {
      opacity: 1;
      color: 'var(--c-fg-0)';
    }
  }
  &:active > a,
  > a.active {
    opacity: 1;

    background: ${p => (p.bold ? 'var(--c-bg-0)' : 'var(--c-border)')};
    .icon-opacity {
      opacity: 1;
    }
  }
  .bk-close {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    position: absolute;
    right: -8px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &:hover .bk-close {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`;

const StyledOverflow = styled.div`
  height: ${p => (p.mobile ? 40 : 32)}px;
  display: flex;
  align-items: center;
  flex-shink: 1;
  &:hover {
    opacity: 1;
  }
`;

const gradients = {
  brand: [ '#9D11D9', '#7F09DB'],
  coverage: ['#EE4B7B', '#D83177'],
  gray: ['#A9A5AD', '#615D65'],
};

const StyledIcon = styled.div<{ bg?: 'brand' | 'coverage' | 'gray' }>`

  ${(p) => p.bg && `
    background: linear-gradient(to bottom, ${gradients[p.bg][0]}, ${gradients[p.bg][1]});
    svg {
      stroke: white;
    }
  `}

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  width: 22px;
  height: 22px;
`;

const StyledBadge = styled.div`
  align-self: center;
  font-size: var(--ffp);
  position: absolute;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  color: var(--c-fg-1) !important;
  line-height: 1;
  letter-spacing: -0.5px;
`;

export interface TabLinkProps {
  exact?: boolean;
  to: string;
  onClick?: () => void;
  onRemove?: () => void;
  children: React.ReactNode;
  badge?: number;
  bold?: boolean;
  secondary?: boolean;
  icon?: IconType;
  customIcon?: React.ReactNode;
  bg?: string;
}

export function TabLink({
  exact,
  to,
  onClick,
  onRemove,
  children,
  secondary,
  bold,
  badge,
  icon,
  customIcon,
  bg,
}: TabLinkProps) {
  const mobile = useMediaQuery('(max-width: 1024px)');
  const { setNavbarOpen } = useSelection();
  const justIcon = !!secondary && !!mobile;

  return (
    <StyledNavLink
      mobile={mobile}
      secondary={secondary}
      bold={bold}
      onClick={() => setNavbarOpen(false)}
    >
      <NavLink end={exact} to={to ? to : '/'} onClick={onClick}>
        <StyledOverflow mobile={mobile}>
          {!!customIcon ? (
            <div style={{ marginRight: 12 - 4, width: 24 }}>{customIcon}</div>
          ) : (
            !!icon && (
              <StyledIcon bg={bg}>
                <I
                  name={icon}
                  size={justIcon ? 24 : 20}
                  color="var(--fg)"
                  className={bg ? '' : 'icon-opacity'}
                />
              </StyledIcon>
            )
          )}
          {!justIcon && <span className="the-link">{children}</span>}
        </StyledOverflow>

        {!!badge && <StyledBadge className="mono">{badge}</StyledBadge>}
        {!!onRemove && (
          <div
            className="bk-close"
            onClick={e => {
              e.preventDefault();
              onRemove();
            }}
          >
            <I name="Close" size={16} />
          </div>
        )}
      </NavLink>
    </StyledNavLink>
  );
}
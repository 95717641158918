import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
  useMutation,
  DefaultContext,
  ApolloCache,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { contentClient, fintechClient, publicClient } from 'src/lib/apollo';

export { useQuery, useMutation } from '@apollo/client';

/**
 * Hooks for data for fintech client:
 * - useFintechQuery and useFintechMutation
 * - Identical to the root useQuery/useMutation but points at fintech client
 */
export function useFintechQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: fintechClient });
}

export function useFintechMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: fintechClient });
}

/**
 * Hooks for data for public clients:
 * - usePublicQuery and usePublicMutation
 * - Identical to the root useQuery/useMutation but points at public client
 */
export function usePublicQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: publicClient });
}

export function usePublicMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: publicClient });
}

/**
 * Hooks for data for content clients:
 * - useContentQuery and useContentMutation
 * - Identical to the root useQuery/useMutation but points at content client
 */
export function useContentQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: contentClient });
}

export function useContentMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: contentClient });
}

import React, { useEffect, useState } from 'react';

import { Scroll, Header, ManualIdpModal, IAction } from 'src/web/components';
import { useUserSummary } from 'src/web/hooks';
import { InspectorPaneProps } from '../../types';

export function IdReviewPane({ id }: InspectorPaneProps) {
  const [open, setIsOpen] = useState();
  const { givenName, familyName, ssn, dob, state } = useUserSummary({ id });

  useEffect(() => {
    setIsOpen(true);
  }, [id]);

  return !id ? (
    <div className="full">No Selection</div>
  ) : (
    <>
      <Scroll inspector>
        <Header subtitle="EDE" title="Manual ID Proof">
          <IAction icon="Open" text="Reopen" onPress={() => setIsOpen(true)} />
        </Header>
      </Scroll>
      {!!open && (
        <ManualIdpModal
          userId={id}
          onRequestClose={() => setIsOpen(false)}
          info={{ givenName, familyName, ssn, dob, coverageState: state }}
        />
      )}
    </>
  );
};
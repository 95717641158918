import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { ClosedReasonEnum, ConversionStatusEnum, UPDATE_CONVERSION_STATUS, UPSERT_CONVERSION_STATUS, useMutation } from 'src/data';
import { formatStatus, Scroll, Steps } from 'src/kit';
import { Button, FullModal, Header, IAction, Modal, StyledBottomBar } from 'src/web/components';
import { conversionStatusGroups } from './config';
import { ModalComponentProps, ModalType } from 'src/hooks/useModal';
import { useToggle } from 'src/hooks/useToggle';
import { propercase } from 'src/_utils/format';

export type ConversionModalProps = ModalComponentProps<ModalType.EDIT_CONVERSION>

export const ConversionModal = ({ data, closeModal }: ConversionModalProps) => {
  const closeReasonModal = useToggle();

  const [status, setStatus] = useState(data.status);
  const [closedReason, setClosedReason] = useState(data.closedReason);

  // must have status in order to update
  const canUpdate = !!status;

  // when setting a new status, unset the closed reason
  const updateStatus = (status) => {
    setStatus(status);
    setClosedReason(null);
  }

  const updateClosedReason = (closedReason) => {
    setClosedReason(closedReason);
    closeReasonModal.close();
  }

  const [update, { loading }] = useMutation(UPSERT_CONVERSION_STATUS, {
    update: (cache, result) => UPDATE_CONVERSION_STATUS(cache, result, data.userId),
    onCompleted: () => {
      toast.success('Conversion status updated');
      
      closeModal();
    },
  });

  const handleUpdate = () => {
    const input = { year: data.year, type: data.type, status, closedReason };
    update({ variables: { personID: data.userId, input } });
  }
  
  return (
    <>
      <FullModal
        onRequestClose={closeModal}
        title="Change Conversion Status"
        subtitle={`${data.year} ${propercase(data.type)}`}
      >
        <Scroll loading={loading}>
          <Steps<ConversionStatusEnum>
            current={status}
            groups={conversionStatusGroups}
            onSelect={(status) => updateStatus(status)}
        />
        <IAction
          icon="Caret"
          title={closedReason ? `Closed for ${closedReason}` : 'Add closed reason'}
          onPress={closeReasonModal.open}
        />
        </Scroll>
        <StyledBottomBar>
          <Button onPress={handleUpdate} disabled={loading || !canUpdate}>
            Update conversion status
          </Button>
        </StyledBottomBar>
      </FullModal>
      <Modal presentation="formSheet" isOpen={closeReasonModal.isOpen} onRequestClose={closeReasonModal.close}>
        <Scroll>
          <Header title="Close Reason" />
          {Object.values(ClosedReasonEnum).map(reason => (
            <IAction
              key={reason}
              icon="Caret"
              title={formatStatus(reason)}
              onPress={() => updateClosedReason(reason)}
            />
          ))}
        </Scroll>
      </Modal>
    </>
  );
};

export default ConversionModal;

import { gql } from '../generated';

export const CREATE_CONSENT_REQUEST = gql(`
  mutation CreateConsentRequest($input: CreateConsentRequestInput!) {
    createConsentRequest(input: $input) {
      id
      consentType
      token
      expiresAt
    }
  }
`);

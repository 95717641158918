import styled from 'styled-components';

export enum BadgeType {
  Info = 'Info',
  Good = 'Good',
  Mid = 'Mid',
  Bad = 'Bad',
  Draft = 'Draft',
  Light = 'Light',
};

export interface BadgeProps {
  type: BadgeType;
  label: string;
}

const colors: Record<BadgeType, { bg: string; color: string }> = {
  [BadgeType.Good]: {
    bg: 'var(--c-good-1)',
    color: 'var(--c-good-0)',
  },
  [BadgeType.Bad]: {
    color: 'var(--c-bad-0)',
    bg: 'var(--c-bad-1)',
  },
  [BadgeType.Info]: {
    color: 'var(--c-done-0)',
    bg: 'var(--c-done-1)',
  },
  [BadgeType.Mid]: {
    color: 'var(--c-medium-0)',
    bg: 'var(--c-medium-1)',
  },
  [BadgeType.Draft]: {
    color: 'var(--c-fg-1)',
    bg: 'var(--c-border)',
  },
  [BadgeType.Light]: {
    color: 'var(--c-border)',
    bg: 'transparent',
  },
};


const StyledOuter = styled.div<{ bg: string; color: string }>`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  border-radius: 999px;
  background: ${p => p.bg};
  box-sizing: border-box;
  line-height: 1;
  height: ${p => (p.small ? 16 : 24)}px;
  font-size: var(--fp);
  flex-shrink: 0;

  .dot {
    height: ${p => (p.small ? 16 : 24)}px;
    width: ${p => (p.small ? 16 : 24)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      height: 8px;
      width: 8px;
      border-radius: 999px;
      background: ${p => p.color};
    }
  }
`;

const StyledText = styled.div`
  font-weight: 500;
  font-size: ${p => (p.small ? 'calc(var(--ffp) - 0.5px)' : 'calc(var(--fp) - 0.5px)')};
  line-height: 1;
  transition: none;
  padding-right: ${p => (p.small ? 8 : 12)}px;
  text-transform: ${p => (p.noCap ? '' : 'capitalize')};
  color: var(--c-fg-0);
  blend: var(--blend);
`;

export function Badge({ type, label }: BadgeProps) {
  const { bg, color } = colors[type];

  return (
    <StyledOuter bg={bg} color={color}>
      <div className="dot">
        <div></div>
      </div>
      <StyledText>
        {label}
      </StyledText>
    </StyledOuter>
  );
}
import styled from 'styled-components';
import { ConversionStatusEnum, ConversionStatusFragment } from 'src/data';
import { IAction } from 'src/web/components';
import { formatRelativeDateStrict } from 'src/web/utility';
import { formatStatus } from 'src/kit';
import { conversionStatusOrder } from './config';
import { useModal, ModalType } from 'src/hooks/useModal';

export interface ConversionProgressProps {
  userId: string;
  conversionStatus: ConversionStatusFragment;
}

const SBar = styled.div<{ bg: string; color: string }>`
  width: 100%;
  height: 3px;
  background:  ${p => p.bg};
  border-radius: 999px;
  overflow: hidden;
  margin: 12px auto;

  > div {
    height: 3px;
    width: ${p => p.width * 100}%;
    background: ${p => p.color};
    will-change: width;
    transition: 0.5s var(--transitionCurve);
  }
`;


/**
 * Special cases for closed + enrolled,
 * otherwise, refer to the conversion status order
 */
const getConversionProgress = (status: ConversionStatusEnum) => {
  if (status === ConversionStatusEnum.Closed || status === ConversionStatusEnum.Effectuated) {
    return 1;
  }

  const index = conversionStatusOrder.indexOf(status);
  return index / conversionStatusOrder.length;
};

/**
 * Helper function to get color for progress bar
 * - Special cases for closed and enrolled
 * - Otherwise, use cerise
 */
const getConversionColor = (status: ConversionStatusEnum): { color: string, bg: string } => {
  if (status === ConversionStatusEnum.Closed) {
    return { bg: 'var(--c-bad-1)', color: 'var(--c-bad-0)' };
  }

    if (status === ConversionStatusEnum.Effectuated) {
    return { bg: 'var(--c-good-1)', color: 'var(--c-good-0)' };
  }

  return { bg: 'var(--c-cerise-2)', color: 'var(--c-cerise-0)' };
};

/**
 * Displays progress of conversion status
 */
export function ConversionProgress({ userId, conversionStatus }: ConversionProgressProps) {
  const { openModal } = useModal();
  const { bg, color } = getConversionColor(conversionStatus.status);

  const handleEdit = () => {
    openModal(ModalType.EDIT_CONVERSION, { 
      userId,
      year: conversionStatus.year,
      type: conversionStatus.type,
      status: conversionStatus.status,
      closedReason: conversionStatus.closedReason,
    });
  }

  return (
    <>
      <IAction
        icon="Crosshair"
        title={formatStatus(conversionStatus.status)}
        text={formatRelativeDateStrict({ date: conversionStatus.lastUpdated })}
        onPress={handleEdit}
      />
      <SBar width={getConversionProgress(conversionStatus.status)} bg={bg} color={color}>
        <div />
      </SBar>
    </>
  );
}
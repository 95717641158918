import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import { UsersView, ReferenceView, DocsView, TasksView, ExplorerView } from 'src/web/views';

// feature pages
import { LoginPage } from 'src/features/auth';
import { AuthenticatedLayout } from 'src/web/views/App/AuthenticatedLayout';

const RequireGuest = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

const RequireAuth = () => {
  const { isAuthenticated, token } = useAuth();

  if (isAuthenticated) {
    return token ? <Outlet /> : null;
  }

  return <Navigate to="/auth" />;
};

export const AppRoutes = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/auth" element={<RequireGuest />}>
        <Route path="/auth" element={<LoginPage />} />
      </Route>
      <Route path="/" element={<RequireAuth />}>
        <Route path="/" element={<AuthenticatedLayout />}>
          <Route path="/users/*" element={<UsersView />} />
          <Route path="/explorer/*" element={<ExplorerView />} />
          <Route path="/reference/*" element={<ReferenceView />} />
          <Route path="/docs/*" element={<DocsView />} />
          <Route path="/tasks/*" element={<TasksView />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/tasks" />} />
    </Routes>
  );
};

import { Toaster as HotToaster } from 'react-hot-toast';

export function Toaster() {
  return (
    <HotToaster
      position="bottom-right"
      reverseOrder={false}
      gutter={2}
      containerClassName=""
      containerStyle={{ zIndex: '99999999999999999999999999' }}
      toastOptions={{
        // Define default options
        className: '',
        duration: 2500,
        style: {
          background: 'var(--c-bg-gray)',
          color: 'var(--c-fg-0)',
          padding: '0px 1px',
          maxWidth: 'var(--inspector)',
          fontWeight: 500,
          fontSize: 'calc(var(--ffp) - 1px)',
          backdropFilter: 'blur(100px) ',
          WebkitBackdropFilter: 'blur(100px)',
          boxShadow: '0 5px 8px -2px transparent',
        },

        // Default options for specific types
        success: {
          icon: null,
          style: {
            color: `var(--c-good-0)`,
            background: `var(--c-good-1)`,
          },
        },
        loading: {
          style: {
            color: `var(--c-medium-0)`,
            background: `var(--c-medium-1)`,
          },
          iconTheme: {
            primary: 'var(--c-medium-0)',
            secondary: 'var(--c-medium-1)',
          },
        },
        error: {
          icon: null,
          duration: 10000,
          style: {
            color: `var(--c-bad-0)`,
            background: `var(--c-bad-1)`,
          },
        },
      }}
    />
  );
}

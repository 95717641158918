import { goalIcon } from 'src/web/utility';

import { Scroll, Header, IRow, RowGroup } from 'src/web/components';
import { BankAccountNode } from 'src/components/nodes';
import { GET_GOALS, useFintechQuery } from 'src/data';
import { InspectorPaneProps } from '../../types';

export function GoalPane({ id: slug, userId }: InspectorPaneProps) {
  const { loading, data } = useFintechQuery(GET_GOALS, {
    variables: { customerID: userId },
  });

  const goal = (data?.customer?.goals || []).find(g => g.slug === slug) || {};

  return (
    <>
      <Scroll loading={loading} inspector>
        <Header title={goal.name} logo={goalIcon({ type: goal.type, alt: false })}>
          <IRow label="Status" type="status" right value={goal.status} hideUnset />
          <IRow
            mono
            hideUnset
            right
            type="percentage"
            label="Withholding"
            value={goal.withholdingPercentage}
          />
        </Header>

        <RowGroup title="Balances">
          <IRow
            mono
            right
            type="currency"
            label="Balance (Total)"
            value={goal.totalBalance}
            helpText="Includes all funds, even if they're currently in transit to Catch"
          />
          <IRow mono right type="currency" label="Pending" value={goal.pendingBalance} />
          <IRow mono right type="currency" label="Available" value={goal.availableBalance} />
        </RowGroup>

        <RowGroup title="Accounts" close>
          <BankAccountNode customerID={userId} />
        </RowGroup>
      </Scroll>
    </>
  );
}

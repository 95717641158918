import { gql } from '../generated';

gql(`
  fragment Application on HealthApplication {
    id
    applicationContext
    applicationOrigin
    applicationVersionNum
    asyncStatus
    coverageState
    coverageYearNumber
    createdOn
    eligibilityLastFetched
    hasSubmittedPermissions
    householdIncome
    insuranceApplicationIdentifier
    lastUpdated
    lastUsedRoute
    lastUserUpdate
    pathwayType
    planPremium
    planPremiumEffective
    status
    isDeleted
    aptcHouseholdAmount
    isEligibilityDetermined
  }
`);

export const CREATE_APPLICATION = gql(`
  mutation CreateApplication($input: UpsertHealthApplicationInput!, $personID: ID!) {
    upsertHealthApplicationV2(input: $input, personID: $personID) {
      ...Application
    }
  }
`);

export const USER_APPLICATIONS = gql(`
  query UserApplications($id: ID!, $input: ApplicationsInput!) {
    viewerTwo(id: $id) {
      id
      health {
        applications(input: $input) {
          ...Application
        }
      }
    }
  }
`);

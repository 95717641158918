import { useMemo } from 'react';
import { Scroll } from 'src/kit';
import { SRow, RowGroup, Switch } from 'src/web/components';
import { useQuery, GET_FLAGS, useMutation, UPDATE_FLAGS, UpdateFlagsInput } from 'src/data';



export function FlagsPage({ userId, inInspector }) {
  const { loading, data } = useQuery(GET_FLAGS, {
    skip: !userId,
    variables: { personID: userId },
  });

  const [updateFlags, { loading: updating }] = useMutation(UPDATE_FLAGS);

  const flags = useMemo(() => data?.person?.flags, [data]);

  const updateFlag = (input: UpdateFlagsInput) => {
    updateFlags({ variables: { personID: userId, input } });
  }

  return (
    <Scroll loading={loading || updating} transparent={inInspector}>
      <RowGroup title="Flags">
        <SRow
          text="Access to Fintech"
          icon="Piggybank"
          accessory={
            <Switch
              value={flags?.hasAccessToFintech}
              onChange={(val: boolean) => updateFlag({ hasAccessToFintech: val })}
            />
          }
        />
      </RowGroup>
    </Scroll>
  );
};

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Scroll, Header, IRow, IAction } from 'src/web/components';
import { DebugView } from 'src/web/inspectors';
import { DEFAULT_URL, ROLL_COPY } from 'src/web/utility';
import { KACTIONS } from 'src/web/kbar';
import { InspectorPaneProps } from '../../types';

const RULE = gql`
  query Rule($id: ID!, $userId: ID) {
    viewer(id: $userId) {
      id
      incomeSource(id: $id) {
        id
        id
        text
        sourceDetail
        taggedSource {
          id
          name
          logo {
            url
          }
        }
        IncomeAutomationRule {
          id
          automationType
          workType
        }
      }
    }
  }
`;

export function IncomeSourcePane({ id, userId }: InspectorPaneProps) {
  const [debug, setDebug] = useState();
  const { data, loading } = useQuery(RULE, {
    variables: { id, userId: userId },
    skip: !id || !userId,
  });

  const source = data?.viewer?.incomeSource;
  const rule = source?.IncomeAutomationRule;

  return (
    <>
      <Scroll inspector>
        <Header
          loading={loading}
          squareIcon
          logo={source?.taggedSource?.logo?.url || DEFAULT_URL}
          title={source?.text}
          subtitle="Income Source"
        >
          <IRow type="status" label="Rule" value={ROLL_COPY[rule?.automationType]} />
          <IRow type="enum" label="Work Type" value={rule?.workType} />
        </Header>
        <IAction onPress={() => setDebug(true)} {...KACTIONS.DEBUG} />
      </Scroll>
      {!!debug && (
        <DebugView
          data={data?.viewer?.incomeSource}
          onClose={() => setDebug(false)}
          type={data?.viewer?.incomeSource?.__typename}
          id={id}
        />
      )}
    </>
  );
};
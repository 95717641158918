import React from 'react';
import FlatList from 'flatlist-react';
import { useQuery, gql } from '@apollo/client';

import { Spinner, Tile } from 'src/web/components';
import { DEFAULT_URL } from 'src/web/utility';

const SOURCES = gql`
  query IncomeSources($pagination: Pagination!) {
    reference {
      catchTransactionTags(pagination: $pagination) {
        pageInfo {
          hasNextPage
          currentPageNumber
          pageSize
          totalItems
        }
        edges {
          id
          name
          logo {
            url
          }
        }
      }
    }
  }
`;

const IncomeSourcesPage = ({ searchTerm }) => {
  const { data, loading } = useQuery(SOURCES, {
    fetchPolicy: 'cache-first',
    variables: {
      pagination: {
        pageNumber: 1,
        pageSize: 750,
      },
    },
  });

  const tags = [...(data?.reference?.catchTransactionTags?.edges || [])];

  return !tags ? (
    <div className="full">{!!loading ? <Spinner /> : <></>}</div>
  ) : (
    <FlatList
      list={tags}
      searchTerm={searchTerm}
      searchCaseInsensitive
      searchMinCharactersCount={2}
      searchBy={['name']}
      renderWhenEmpty={() => <></>}
      minColumnWidth="124px"
      gridGap="0px"
      renderItem={(source, idx) => (
        <Tile
          key={source?.id}
          idx={idx}
          name={source?.name}
          logo={source?.logo?.url || DEFAULT_URL}
        />
      )}
    />
  );
};

export default IncomeSourcesPage;

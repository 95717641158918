import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { EDE_ROUTES, PARTNER_ROUTES } from 'src/web/utility';

const TRACK_HEIGHT = 12;
const TRACK_WIDTH = 400;
const TRACK_PAD = 16;

const StyledProgressFilter = styled.div`
  position: relative;
  cursor: default;
  * {
    cursor: ${p => (p.drag ? 'grabbing' : ' ')} !important;
    user-select: none;
  }
`;

const StyledProgressReceiver = styled.div`
  position: relative;
  padding: ${TRACK_PAD / 2}px ${TRACK_PAD}px;
`;

const StyledTrack = styled.div`
  width: ${TRACK_WIDTH}px;
  margin: auto;
  height: ${TRACK_HEIGHT * 3}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
`;

const StyledDot = styled.div`
  height: ${TRACK_HEIGHT}px;
  flex: 1;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  cursor: var(--cursor);
  justify-content: center;
  pointer-events: ${p => (p.drag ? 'none' : '')};
  border-radius: ${p => (p.first ? 99 : 0)}px ${p => (p.last ? 99 : 0)}px ${p => (p.last ? 99 : 0)}px
    ${p => (p.first ? 99 : 0)}px;
  > div {
    height: 2px;
    width: 2px;
    border-radius: 999px;
    background: var(--c-cerise-0);
    pointer-events: none;
  }
  &:hover {
    transform: scale(1);
    background: var(--c-cerise-1);
    > div {
      transform: scale(2);
    }
  }
`;

const StyledActiveTrack = styled.div`
  height: 2px;
  transition: 0.05s;
  border-radius: 999px;
  pointer-events: none;
  position: absolute;
  background: var(--c-cerise-0);
  transform: scale(${p => (p.active && p.drag ? 1.25 : 1)});
  z-index: 1;
  cursor: grab;
  left: ${p => (p.startX ? p.startX + 'px' : (p.start / p.total) * 100 + '%')};
  right: ${p => (p.endX ? TRACK_WIDTH - p.endX + 'px' : (1 - (p.end + 1) / p.total) * 100 + '%')};
`;

const StyledDragDot = styled.div`
  height: ${TRACK_HEIGHT}px;
  width: ${TRACK_HEIGHT}px;
  border-radius: 999px;
  z-index: 2;
  transition: ${p => (p.drag ? 'none' : 'var(--transition')};
  position: absolute;
  left: ${p => (p.active && p.drag ? p.x + 'px' : (p.idx / p.total) * 100 + '%')};
  background: white;
  pointer-events: ${p => (p.drag ? 'none' : '')};
  cursor: grab;
  background: white;
  transform-origin: center;
  transform: scale(${p => (p.active && p.drag ? 1.25 : p.active ? 1.25 : 1)});
  box-shadow: ${p => (p.active ? '0 5px 25px  #000000' : 'none')};
`;

const StyledRouteName = styled.div`
  font-size: var(--p);
  font-weight: 500;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  width: ${TRACK_WIDTH}px;
  margin: auto;
  > .to {
    margin: 0 4px;
    font-weight: 500;
    opacity: 0.5;
    font-size: var(--fp);
  }
`;

const StyledHoverName = styled.div`
  font-size: var(--ffp);
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: ${TRACK_WIDTH}px;
  margin: auto;
  height: ${TRACK_HEIGHT}px;
  margin-bottom: 0px;
  opacity: 0.5;
`;

const StyledFacet = styled.div`
  background: ${p => (p.active ? 'var(--c-cerise-0)' : 'transparent')};
  color: ${p => (p.color ? p.color : p.active ? 'var(--c-cerise-0)' : 'var(--c-fg-0)')} !important;
  font-weight: 600;
  font-size: var(--fp);
  border-radius: 4px;
  padding: 2px 4px 1px;
  display: inline-flex;
  align-items: center;
  cursor: var(--cursor);
`;

const formatRoute = r => r?.replaceAll('-', ' ')?.toProperCase();

const ProgressFilter = ({ partner, onSetFilters }) => {
  const rectRef = useRef();
  const offsetLeft = rectRef?.current?.getBoundingClientRect().left;

  const routes = !!partner ? PARTNER_ROUTES?.filter(a => a !== '_') : EDE_ROUTES?.filter(a => a !== '_');
  const [hover, setHover] = useState();
  const [step, setStep] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(routes?.length - 1);
  const [drag, setDrag] = useState(false);
  const [x, setX] = useState(0);
  const [l, setL] = useState(0);

  useEffect(() => {
    setL(offsetLeft);
  }, [offsetLeft]);

  const setRoute = (route, advance) => {
    if (step === 0) {
      setStart(route);
      onSetFilters(routes?.slice(route, end + 1));
    } else {
      setEnd(route);
      onSetFilters(routes?.slice(start, route + 1));
    }
  };

  return (
    <StyledProgressFilter
      onMouseUp={() => {
        setDrag(false);
        setHover();
      }}
      drag={drag}
    >
      <StyledProgressReceiver>
        <StyledHoverName>{formatRoute(routes[hover])}</StyledHoverName>
        <StyledTrack
          ref={rectRef}
          onMouseUp={() => {
            if (!drag) return;
            setRoute(Math.round((x / TRACK_WIDTH) * (routes.length - 1)));
            setDrag(false);
            setX();
          }}
          onMouseMove={e => {
            if (!drag) return;
            let mx = Math.round(e.clientX - l - 5);
            if (x !== mx) {
              setX(mx);
              setHover(Math.round((mx / TRACK_WIDTH) * (routes.length - 1)));
            }
          }}
        >
          <StyledActiveTrack
            start={start}
            end={end}
            startX={drag && step === 0 ? x : null}
            endX={drag && step === 1 ? x : null}
            total={routes.length}
          />
          <StyledDragDot
            active={step === 0}
            drag={drag}
            onMouseDown={e => {
              let mx = Math.round(e.clientX - l);

              setStep(0);
              setX(mx);
              setDrag(true);
            }}
            x={x}
            idx={start}
            total={routes?.length}
          />
          <StyledDragDot
            active={step === 1}
            drag={drag}
            onMouseDown={e => {
              let mx = Math.round(e.clientX - l);

              setStep(1);
              setX(mx);
              setDrag(true);
            }}
            x={x}
            idx={end}
            total={routes?.length}
          />

          {routes?.map((route, idx) => (
            <StyledDot
              first={idx === 0}
              last={idx === routes?.length - 1}
              drag={drag}
              active={idx === start || idx === end}
              key={route}
              onMouseOver={() => {
                if (!drag) setHover(idx);
              }}
              onClick={() => {
                if (!drag) setRoute(idx, true);
              }}
            >
              <div />
            </StyledDot>
          ))}
        </StyledTrack>

        <StyledRouteName>
          <StyledFacet
            color="creditColor"
            onClick={() => {
              setStart(0);
              setEnd(routes.length - 1);
              onSetFilters([]);
            }}
          >
            Clear
          </StyledFacet>
          <div style={{ flex: 1 }}> </div>
          <StyledFacet active={step === 0} onClick={() => setStep(0)}>
            {formatRoute(routes[start])}
          </StyledFacet>
          <div className="to">to</div>
          <StyledFacet active={step === 1} onClick={() => setStep(1)}>
            {formatRoute(routes[end])}
          </StyledFacet>
        </StyledRouteName>
      </StyledProgressReceiver>
    </StyledProgressFilter>
  );
};

export default ProgressFilter;

import React from 'react';
import styled from 'styled-components';

const types = {
  default: {
    background: _ => 'var(--c-fg-0)',
    hoverBackground: _ => 'var(--c-fg-0)',
    activeBackground: _ => 'var(--c-fg-0)',
    color: _ => 'var(--c-bg-0)',
    borderColor: _ => 'transparent',
    hoverBorderColor: _ => 'transparent',
  },
  outline: {
    background: _ => 'transparent',
    hoverBackground: _ => 'transparent',
    activeBackground: _ => 'transparent',
    color: _ => 'var(--c-bg-0)',
    borderColor: _ => 'transparent',
    hoverBorderColor: _ => 'transparent',
  },
  subtle: {
    background: _ => 'var(--c-fg-gray)',
    hoverBackground: _ => 'transparent',
    activeBackground: _ => 'transparent',
    color: _ => 'var(--c-fg-0)',
    borderColor: _ => 'transparent',
    hoverBorderColor: _ => 'transparent',
  },
};

const StyledButton = styled.button`
  appearance: none;
  background: ${p => p.background};
  opacity: ${p => (p.disabled ? 0.15 : 1)};
  color: ${p => p.color};
  border-radius: var(--radiusLarge);
  padding: ${p => (p.small ? '6px 12px' : 'calc(0.5 * var(--padding))')};
  font-size: ${p => (p.small ? 'var(--ffp)' : 'var(--p)')};
  outline: none;
  font-weight: 500;
  border: none;
  cursor: ${p => (p.disabled ? 'no-drop' : 'pointer')};
  box-shadow: ${p => (p.shadow ? 'rgba(0, 0, 0, 0.35) 0px 3px 16px -6px' : '')};
  transition: var(--transition);

  width: ${p => (p.fullWidth ? '100%' : p.wide ? '200px' : '')};
  * {
    color: ${p => p.color};
  }
  .copyRow {
    opacity: 0;
  }
  &:hover {
    box-shadow: var(--box-shadow);
    transition: var(--transition);
    opacity: 0.9;
    .copyRow {
      opacity: 1;
    }
  }
  &:active {
  }
`;


export interface ButtonProps {
  disabled?: boolean;
  onPress?: () => void;
  children: React.ReactNode;
  buttonType?: 'submit';
}

function Button({
  children,
  type = 'default',
  color,
  background,
  loading,
  small,
  disabled,
  onPress,
  fullWidth,
  wide,
  shadow,
  buttonType,
  ...rest
}: ButtonProps) {
  return (
    <StyledButton
      fullWidth={fullWidth}
      small={small}
      type={buttonType}
      wide={wide}
      shadow={shadow}
      disabled={disabled}
      background={background ? background : color ? color : types[type].background()}
      color={color ? color : types[type].color()}
      onClick={e => {
        if (!loading && !disabled && !!onPress) onPress(e);
      }}
      {...rest}
    >
      <div style={{ opacity: loading ? 0.5 : 1 }}>{children}</div>
    </StyledButton>
  );
}

export default Button;

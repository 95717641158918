import { useQuery, USER_CONVERSION } from 'src/data';
import { Scroll } from 'src/kit';
import { Header, IRow, RowGroup } from 'src/web/components';
import { ConversionCard, ConversionProgress } from '../../../conversion';
import { InspectorPaneProps } from '../../types';
import { useMemo } from 'react';
import { DateFormat } from 'src/utils';
import { propercase } from 'src/_utils/format';
import { EventFeed } from 'src/components/events/EventFeed';

export function ConversionPane({ userId, id }: InspectorPaneProps) {
  const { loading, data } = useQuery(USER_CONVERSION, {
    variables: { id: userId },
    skip: !userId,
  });

  const { conversion, other } = useMemo(() => {
    const conversions = data?.person?.conversionStatuses || [];
    const conversion = conversions.find(c => c.id === id);
    const other = conversions.filter(c => c.id !== id);
    return { conversion, other };
  }, [id, data]);

  if (!id || !conversion) {
    return <div className="full">No selection</div>;
  }

  return (
    <Scroll loading={loading} inspector>
      <Header title={`${conversion.year} ${propercase(conversion.type)}`} subtitle="Conversion Status">
        <IRow label="Year" value={conversion.year} />
        <IRow label="Status" type="status" value={conversion.status} />
        <IRow label="Closed Reason" type="enum" value={conversion.closedReason} hideUnset />
        <IRow label="Last Updated" type="date" format={DateFormat.FULL} value={conversion.lastUpdated} />
      </Header>

      <ConversionProgress userId={userId} conversionStatus={conversion} />

      <RowGroup title="History" borderless close>
        <EventFeed events={conversion.events} />
      </RowGroup>

      {other.length > 0 && (
        <RowGroup title="Other Years" borderless close>
          {other.map(conversion => <ConversionCard key={conversion.id} conversion={conversion} />)}
        </RowGroup>
      )}
    </Scroll>
  );
};
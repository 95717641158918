import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--veil);
  z-index: 99999999999;
`;

const Modal = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 90%;
  max-width: 90%;
  border-radius: 16px;
  z-index: 99999999999;
`;

type ModalPresentation = 
  | 'formSheet'
  | 'squareSheet'
  | 'pageSheet'
  | 'actionSheet'
  | 'largeSheet'
  | 'applicationSheet'
  | 'dialogSheet'
  | 'fullScreen';

export interface ModalProps {
  isOpen?: boolean;
  presentation?: ModalPresentation;
  onRequestClose: () => void;
  children: React.ReactNode;
}

export default ({ isOpen = true, presentation = 'pageSheet', onRequestClose, children }: ModalProps) => {
  useHotkeys('esc', onRequestClose);

  if (!isOpen) return null;

  const el = (
    <ModalOverlay className="modal-overlay" onClick={onRequestClose}>
      <Modal
        className="modal modal-content"
        style={styles[presentation]}
        onClick={e => {
          e.stopPropagation();
          return false;
        }}
      >
        {children}
      </Modal>
    </ModalOverlay>
  );

  return ReactDOM.createPortal(el, document.getElementById('modal-root'));
};

const styles: Record<ModalPresentation, object> = {
  formSheet: {
    width: 440,
    height: 480,
    maxHeight: '90%',
  },
  squareSheet: {
    width: 420,
    height: 300,
    maxHeight: '90%',
  },
  pageSheet: {
    width: 560,
    height: 680,
    maxHeight: '90%',
  },
  actionSheet: {
    width: 375,
    height: 270,
    maxHeight: '90%',
  },
  largeSheet: {
    width: 640,
    height: 1080,
    maxHeight: '90%',
  },
  applicationSheet: {
    width: 920,
    height: 840,
    maxHeight: '90%',
    maxWidth: '90%',
  },
  dialogSheet: {
    width: 440,
    height: 312,
    maxHeight: '90%',
  },
  fullScreen: {
    maxHeight: '90%',
    height: '100%',
    width: '100%',
  },
};

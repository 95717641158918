import { gql } from '../generated';

gql(`
  fragment Goal on Goal {
    id
    icon
    name
    slug
    type
    status
    withholdingPercentage
    pendingBalance
    availableBalance
    totalBalance
  }
`);

export const GET_GOALS = gql(`
  query GetGoals($customerID: ID) {
    customer(id: $customerID) {
      id
      goals {
        ...Goal
      }
    }
  }
`);

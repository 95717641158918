import React from 'react';
import * as RUITooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

const TTContent = styled(RUITooltip.Content)`
  background: var(--c-bg-inset);
  // backdrop-filter: blur(25px) saturate(400%);
  //   border: 0.5px solid var(--c-border-light);
  font-size: var(--ffp);
  font-weight: 450;
  color: var(--c-fg-0);
  padding: 6px 10px;
  line-height: 1;
  border-radius: var(--radius);
  box-shadow: 0 0 15px 5px #ffffff22;
  @media (prefers-color-scheme: dark) {
    box-shadow: 0 0 15px 5px var(--c-bg-1);
  }
  display: flex;
  align-items: center;
  .key {
    display: inline-block;
    padding: 0 6px;
    border-radius: 4px;
    font-weight: 500;
    background: var(--c-menu);
    color: var(--c-fg-1);
    font-size: calc(var(--ffp) - 1px);
    letter-spacing: 0.5px;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin: -2px -6px -2px 8px;
    line-height: 1;
  }
`;

const TTArrow = styled(RUITooltip.Arrow)`
  fill: var(--c-bg-inset);
`;

export interface TooltipProps {
  text: string;
  keyCombo?: string;
  side?: RUITooltip.TooltipContentProps['side'];
  align?: RUITooltip.TooltipContentProps['align'];
  children: React.ReactNode;
}

export const Tooltip = ({ text, keyCombo, side, align, children }: TooltipProps) => (
  <RUITooltip.Provider delayDuration={0}>
    <RUITooltip.Root>
      <RUITooltip.Trigger asChild>{children}</RUITooltip.Trigger>
      <RUITooltip.Portal container={document.getElementById('tooltip-root')}>
        <TTContent side={side} align={align} alignOffset={-6} className="TooltipContent">
          <span style={{ fontWeight: !!keyCombo ? 500 : 450 }}>{text}</span>
          {!!keyCombo?.replace && (
            <span className="key mono">
              {keyCombo
                ?.replace('Command', '⌘')
                ?.replace('Shift', '⇧')
                ?.replace('Option', '⌥')}
            </span>
          )}
          <TTArrow />
        </TTContent>
      </RUITooltip.Portal>
    </RUITooltip.Root>
  </RUITooltip.Provider>
);

export default Tooltip;

import styled from 'styled-components';
import { Routes, Route, useResolvedPath } from 'react-router-dom';
import { AppPage, Toolbar, TabLink, SectionNav } from 'src/web/components';
import { DocsPage } from '.';

const EDE_DOCS = [
  {
    link: `ses_app`,
    html: `ses.html`,
    name: `Application (SES)`,
  },
  {
    link: `permission`,
    html: `permission.html`,
    name: `Consumer Permission`,
  },
  {
    link: `consumer_search`,
    html: `consumer_search.html`,
    name: `Consumer Search`,
  },
  {
    link: `doc_upload`,
    html: `doc_upload.html`,
    name: `Document Upload`,
  },
  {
    link: `event_proc`,
    html: `event_proc.html`,
    name: `Event Processing`,
  },
  {
    link: `get_enrollment`,
    html: `get_enrollment.html`,
    name: `Get Enrollment`,
  },
  {
    link: `id_proofing_pdf`,
    html: `ridp.pdf`,
    name: `ID Proofing (RIDP)`,
    external: true,
  },
  {
    link: `id_proofing`,
    html: `id_proofing.html`,
    name: `ID Proofing (Store)`,
  },
  {
    link: `dmi`,
    html: `dmi.html`,
    name: `Issues – DMI`,
  },
  {
    link: `svi`,
    html: `svi.html`,
    name: `Issues – SVI`,
  },
  {
    link: `metadata_search`,
    html: `metadata_search.html`,
    name: `Metadata Search`,
  },
  {
    link: `notice_retrieval`,
    html: `notice_retrieval.html`,
    name: `Notice Retrieval`,
  },
  {
    link: `notice_retrieval_flow`,
    html: `notice_retrieval_flow.pdf`,
    name: `Notice Retrieval Process Flow`,
    external: true,
  },
  {
    link: `payment_redirect`,
    html: `payment_redirect.html`,
    name: `Payment Redirect`,
  },
  {
    link: `ses_get_states`,
    html: `ses_get_states.html`,
    name: `Reference (SES)`,
  },
  {
    link: `submit_enrollment`,
    html: `submit_enrollment.pdf`,
    name: `Submit Enrollment`,
    external: true,
  },
  {
    link: `update_policy`,
    html: `update_policy.html`,
    name: `Update Policy`,
  },
];

const StyledWindowInner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: var(--navHeight);
  background: var(--bg);
`;

const StyledWindow = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: calc(var(--navPrimary));
  display: block;
`;

const DocsView = () => {
  const url = useResolvedPath("").pathname;


  return (
    <StyledWindow>
      <Toolbar title="EDE Documentation" />
      <StyledWindowInner>
        <SectionNav>
          {EDE_DOCS.map((route, idx) => (
            <TabLink key={route.link} to={`/docs/ede/${route.link}?v=EDE`}>
              {route.name}
            </TabLink>
          ))}
        </SectionNav>
        <AppPage
          username
          left={0}
          noPanel
          raw={true}
          title="Docs"
          main={() => (
            <Routes>
              <Route key="ede" path="/ede/:id" element={<DocsPage />} />
              <Route path="/ede" element={<div className="full">No doc selected</div>} />
            </Routes>
          )}
        />
      </StyledWindowInner>
    </StyledWindow>
  );
};

export default DocsView;

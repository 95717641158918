import React from 'react';
import styled from 'styled-components';
import { Menu, MenuItem, Avatar } from 'src/web/components';

import { useQuery, gql } from '@apollo/client';
import { useAuth } from '../../auth/useAuth';
import { initials } from 'src/web/utility';
import { Env } from 'src/_utils';

const StyledToolbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--navPrimary);
  height: var(--navHeight);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px 0 16px;
  z-index: 99999999999999;
  color: var(--cCard);

  -webkit-app-region: drag;
`;

const ADMIN = gql`
  query AdminBasicQuery {
    viewer {
      id
      user {
        id
        givenName
        familyName
        nickname
        avatarImage
      }
    }
  }
`;

const AppToolbar = ({ ...rest }) => {
  const { isAuthenticated, logout } = useAuth();
  const { data } = useQuery(ADMIN, { skip: !isAuthenticated });
  const admin = data?.viewer?.user;

  return (
    <StyledToolbar {...rest}>
      <div
        className="mono"
        style={{
          flex: 1,
          lineHeight: 1,
          paddingLeft: 32,
          color: 'var(--c-fg-1)',
          fontSize: 9,
          fontWeight: 500,
        }}
      >
        <span style={{ color: 'var(--c-fg-2)', fontWeight: 400 }}>{Env.version}</span>
        <br />
        {Env.buildConfig.number || 'LOCAL'}
      </div>
      <Menu
        placement="bottomRight"
        noDismiss
        content={
          <>
            <MenuItem fontIcon="unavailable" text="Sign Out" onPress={logout} />
          </>
        }
      >
        <div style={{ border: '1px solid var(--fga)', borderRadius: 99 }}>
          <Avatar image={admin?.avatarImage} initials={initials(admin)} withHover size={24} />
        </div>
      </Menu>
    </StyledToolbar>
  );
};

export default AppToolbar;

import React, { useMemo, useContext } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { colors } from 'src/web/styles';
import { UPSERT_TASK } from 'src/web/data';
import { rowFormatters, formatCurrency, makeName, copyToClipboard, formatPercentage } from 'src/web/utility';
import {
  Icon,
  Redact,
  Menu,
  MenuItem,
  MenuDivider,
  Avatar,
  RowLabel,
  Tooltip,
} from 'src/web/components';
import { DateFormat, formatDate } from 'src/utils';
import { RowDataType } from '../types';
import { Status, formatStatus } from 'src/kit';
import { Trinary } from 'src/data';
import { STATES } from 'src/_utils';
import { useAdmins } from '../hooks';

const BG = 'transparent';
const ICONS = {
  edit: 'edit',
  menu: 'menu',
  link: 'caret',
  remove: 'remove',
  plus: 'plus'
};

const StyledRow = styled.div`
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  background: ${p => colors[p.background] || p.background || BG};
  height: 32px;
  margin: 0;
  margin-bottom: 0;
  background-image: url('/img/rows/${p => ICONS[p.actionType]}${p => (p.dark ? '_dark' : '')}.svg');
  background-repeat: no-repeat;
  background-position: center right 8px;
  background-size: ${p => ['remove', 'plus'].includes(p.actionType) ? '16px' : '10px'};
  box-sizing: border-box;
  font-size: var(--fp);
  line-height: 1;
 
  cursor: ${p => 
    ['edit', 'remove', 'plus'].includes(p.actionType) ? 'pointer' : p.actionType === 'menu' ? 'default' : p.value ? (p.copyable ? 'grab' : 'default') : 'not-allowed'};
   
  *:not(.initials *) {
    line-height: 1;
  }
  &:hover {
    background-color: ${p => (p.horizontal ? 'transparent' : p.value ? 'var(--cAdminAccentLight)' : BG)};
  }
  &:active {
    transition:250ms;
    transform: scale(${p => (p.value && p.copyable && !p.actionType === 'menu' ? 0.98 : 1)}) ;
    cursor: ${p =>
      p.actionType === 'menu'
        ? 'default'
        : p.value
        ? p.copyable
          ? 'grabbing'
          : 'default'
        : 'not-allowed'} !important;
  }
`;

const StyledRowValue = styled.div`
  border-radius: ${p => (p.horizontal ? ' var(--radius)' : 0)};
  text-overflow: clip;
  white-space: nowrap;
  mask: ${p => (p.mask && !p.right ? ' linear-gradient(to left, #00000000 0, #000000 16px)' : '')};
  color: ${p => (p.value ? 'var(--c-fg-0)' : 'var(--cTextSubtle)')};
  padding: 0 8px;
  height: 32px;
  flex-grow: 1;
  text-align: ${p => (p.right ? 'right' : 'left')};
  justify-content: ${p => (p.right ? 'flex-end' : 'flex-start')};
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  line-height: 1;
  font-weight: 600;
  height: ${p => (p.type === 'signature' ? '30px' : '')};
  font-size: var(--fp);
  cursor: ${p =>
    ['edit', 'remove', 'plus'].includes(p.actionType) ? 'pointer' :
    p.actionType === 'menu' ? 'var(--cursor)' : p.value ? (p.copyable ? 'grab' : 'default') : 'not-allowed'};
  &:hover {
    background-color: ${p =>
      p.horizontal && (p.value || p.actionType === 'menu') ? 'var(--c-border-light)' : 'transparent'};
    mask: none;
  }
  &:active {
    cursor: ${p =>
      (p.actionType === 'edit' || p.actionType === 'remove') ? 'pointer' :
      p.actionType === 'menu'
        ? 'var(--cursor)'
        : p.value
        ? p.copyable
          ? 'grabbing'
          : 'default'
        : 'not-allowed'};
  }
`;

const StyledLabelContainerWrapper = styled.div`
  cursor: var(--cursor);
  text-decoration: underline dotted var(--c-cerise-0);
  text-decoration-line: underline;
  text-decoration-skip: ink;
  text-underline-offset: 3px;
  text-underline-thickness: 1px;
  &:hover {
    color: var(--c-cerise-0);
  }
`;

const StyledLabelOuter = styled.div`
  text-overflow: clip;
  white-space: nowrap;
  mask: linear-gradient(to right, #000000 200px, #00000000);
  font-size: var(--fp);
  font-weight: 450;
  color: var(--c-fg-0);
  height: var(--iconLg);
  display: flex;
  align-items: center;
`;

interface IRowProps {
  loading?: boolean;
  id?: string;
  type?: RowDataType;
  pretext?: string; // content before label
  label: string;
  value: string | number | boolean;
  displayValue?: string;
  placeholder?: string;
  actionType?: 'remove' | 'plus' | 'edit' | 'menu' | 'caret' | 'plus' | 'link';
  icon?: string;
  avatar?: string;
  initials?: string;
  onPress?: () => void;
  onChange?: (val) => void;
  mono?: boolean;
  hideUnset?: boolean;
  right?: boolean;
  noMax?: boolean;
  helpText?: string;
  pii?: boolean;
  hideIcon?: boolean;
  format?: DateFormat;
  linkText?: string;
  menuPlacement?: string;
  menu?: React.ReactNode;
}

// helper function to check if a value is not set.
// we specifically do not want to include boolean false here
const isUnset = (val) => {
  return val === null || val === undefined || val === '';
}

const formatValue = (val, { type, format, linkText = 'View' }: { type: RowDataType, format?: DateFormat, linkText?: string }) => {
  if (type === 'date' || type === 'dateOnly' ) {
    return formatDate(val, format);
  }

  if (type === 'link') {
    return <a href={val} target="_blank">{linkText}</a>
  }

  if (type === 'enum') {
    return formatStatus(val);
  }

  if (type === 'status' || type === 'goalStatus') {
    return <Status status={val} />;
  }

  if (type === 'bool') {
    if (val === true || val === false) {
      return <Status status={val === true ? Trinary.Yes : Trinary.No} />;
    }
  }

  if (type === 'state') {
    return STATES[val] || val;
  }

  if (type === 'currency') {
    return formatCurrency({ value: val });
  }

  if (type === 'percentage') {
    return formatPercentage({ number: val });
  }

  if (type === 'mono') {
    return <div className="mono">{val}</div>;
  }

  return val;
}

export const IRow = ({
  id,
  type,
  label,
  value,
  placeholder,
  displayValue,
  icon,
  format,
  linkText,
  actionType,
  hideUnset,
  right,
  noMax,
  pii,
  avatar,
  initials,
  menu,
  menuPlacement,
  loading,
  pretext,
  helpText,
  mono,
  onPress,
  onChange,
}: IRowProps) => {

  const formattedValue = formatValue(value, {
    type,
    format,
    linkText,
  });


  const LabelContainer = useMemo(
    () => ({ children }) =>
      helpText ? (
        <StyledLabelContainerWrapper>
          <Tooltip text={helpText} side="left">
            <div>{children}</div>
          </Tooltip>
        </StyledLabelContainerWrapper>
      ) : (
        <>{children}</>
      ),
    [helpText],
  );

  const { admins, admin, adminUser } = useAdmins();
  const [upsertTask, { data: task }] = useMutation(UPSERT_TASK, {
    refetchQueries: ['Tasks', 'HealthEnrollments', 'HealthEnrollmentBoardSingleFast'],
  });

  const modifyTask = d => {
    upsertTask({
      variables: {
        input: {
          id,
          ...d,
        },
      },
    });
  };

  const Container = ({ children }) =>
    actionType === 'menu' ? (
      <Menu
        placement={menuPlacement}
        content={
          <>
            {type === 'assignee' && (
              <>
                <MenuItem
                  type="person"
                  text={makeName(adminUser?.user) || adminUser?.email || 'No Name'}
                  el={adminUser?.user}
                  onPress={() => modifyTask({ assignee: admin })}
                />
                <MenuDivider />
                {admins?.map(
                  (el, i) =>
                    el?.id !== admin && (
                      <MenuItem
                        key={el?.id}
                        type="person"
                        text={makeName(el) || el?.email || 'No Name'}
                        el={el}
                        onPress={() => modifyTask({ assignee: el.id })}
                      />
                    ),
                )}
              </>
            )}
            {type === 'status' &&
              !menu &&
              ['TODO', 'IN_PROGRESS', 'PENDING', 'COMPLETE', '', 'CANCELED'].map(status =>
                status === '' ? (
                  <MenuDivider />
                ) : (
                  <MenuItem
                    key={status}
                    text={<Status status={status} />}
                    onPress={() => modifyTask({ status })}
                  />
                ),
              )}
            {type === 'goalStatus' &&
              !menu &&
              ['NOT_STARTED', 'DRAFT', 'ACTIVE', 'PAUSED', 'DELETED'].map(status =>
                status === '' ? (
                  <MenuDivider />
                ) : (
                  <MenuItem key={status} text={<Status status={status} />} onPress={() => onChange(status)} />
                ),
              )}

            {type === 'priority' &&
              ['URGENT', 'HIGH', 'MEDIUM', 'LOW'].map(priority => (
                <MenuItem
                  key={priority}
                  text={priority?.toProperCase()}
                  icon={`/img/rows/${priority}.svg`}
                  onPress={() => modifyTask({ priority: 'PRIORITY_' + priority })}
                />
              ))}
            {menu}
          </>
        }
      >
        {children}
      </Menu>
    ) : (
      <>{children}</>
    );

  if (hideUnset && isUnset(value)) {
    return null;
  }

  return (
    <div>
      <StyledRow
        type={type}
        right={right}
        noMax={noMax}
        actionType={actionType}
        copyable={!onPress}
        noPadding
        horizontal
        lbl={!!label}
        value={!!value}
        style={{
          justifyContent: right ? 'space-between' : 'flex-start',
        }}
      >
        {!!pretext && (
          <div style={{ marginRight: 12 }}>
            <RowLabel
              style={{ width: 35, textAlign: 'center', fontSize: 11, color: 'var(--c-fg-2)' }}
              text={pretext}
            />
          </div>
        )}
        {!!label && (
          <StyledLabelOuter
            style={{
              opacity: actionType === 'menu' ? 1 : 0.65,
              flexShrink: 0,
              width: !!pretext ? 125 : (noMax ? (right ? '' : 200) : (right ? 200 : 125)),
            }}
          >
            <LabelContainer>
              <Redact inline>
                {label}
              </Redact>
            </LabelContainer>
          </StyledLabelOuter>
        )}
        <Container>
          <StyledRowValue
            className={mono || type === 'enum' || type === 'percentage' || type === 'mono' ? 'mono' : ''}
            type={type}
            right={right}
            horizontal
            actionType={actionType}
            copyable={!onPress}
            onClick={() =>
                !!onPress
                ? onPress()
                : actionType === 'menu'
                ? null
                : copyToClipboard(value)
            }
            value={!!value}
            style={{
              textAlign: right ? 'right' : 'left',
              fontWeight: !!value ? 500 : 400,
            }}
          >
            {!!icon && (
              <Icon
                loading={loading}
                style={{
                  flexShrink: 0,
                  marginRight: 8,
                  alignItems: 'center',
                }}
                logo={icon}
                size={14}
              />
            )}
            {type === 'state' && (
              <Icon
                loading={loading}
                square
                style={{
                  flexShrink: 0,
                  marginRight: 8,
                }}
                logo={`/img/states/dark/${formattedValue?.toUpperCase()}.svg`}
                size={14}
              />
            )}
            {(!!avatar || !!initials) && (
              <Avatar
                style={{
                  flexShrink: 0,
                  marginRight: 8,
                }}
                loading={loading}
                image={avatar}
                initials={initials}
                size={16}
              />
            )}

            <div
              className="the-text"
              style={{ opacity: !!value || !!loading ? 1 : 0.5, minWidth: 100, flex: 1 }}
            >
              {(type === 'status' || type === 'goalStatus') && !!loading ? (
                <Status hideIcon status={'status loading'} loading>
                  {displayValue || value}
                </Status>
              ) : !!value ? (
                <Redact
                  disable={(!pii && !loading) || type === 'status' || type === 'goalStatus'}
                  inline
                  loading={loading}
                >
                  {formattedValue}
                </Redact>
              ) : type === 'currency' ? (
                formatCurrency({ value: 0 })
              ) : (
                placeholder || '–'
              )}
            </div>
          </StyledRowValue>
        </Container>
      </StyledRow>
    </div>
  );
};

export default IRow;

import React from 'react';

import { Scroll, RowGroup, IRow, GoalCard } from 'src/web/components';
import { formatDate, taxPaymentEntity } from 'src/web/utility';
import { SelectedType, useInspector } from 'src/hooks/useInspector';

export function TaxPage({ userId }) {
  const { selectedId, setSelected } = useInspector();

  // @todo add back taxes query
  const loading = false;
  const data = {};
  const error = false;

  if (error || !data) return null;

  const viewer = data?.viewer;
  const user = viewer?.user;
  const goal = viewer?.goal;
  const pmts = data?.viewerTwo?.taxPayments;

  return (
    <Scroll loading={loading}>
      <RowGroup title="Tax Goal" borderless>
        <IRow type="currency" noMax label="Total Balance" value={goal?.totalBalance} />
        <IRow type="currency" noMax label="Available Balance" value={goal?.availableBalance} />
        <IRow type="currency" noMax label="Pending Balance" value={goal?.pendingBalance} />
        <IRow type="percentage" noMax label="Paycheck %" value={goal?.paycheckPercentage} />
        <IRow noMax label="Payment mode" value={!!goal?.autoTax ? 'Auto' : 'Manual'} />
        <IRow
          noMax
          label="EFTPS Authorized"
          value={!!data?.viewerTwo?.agreements?.authorizedAboundEftpsEnrollment ? 'Yes' : 'No'}
        />
        <IRow noMax label="Files w/Business" value={!!goal?.usesBusinessForFiling ? 'Yes' : 'No'} />
        <IRow noMax label="DBA" value={user?.dba} />
        <IRow noMax label="EIN" value={user?.ein} />
      </RowGroup>
      <RowGroup title="Tax Payments">
        {pmts?.map((pmt, idx) => (
          <GoalCard
            key={pmt?.id}
            name={formatDate({ date: pmt.createdOn, length: 'SHORT' })}
            subtitle={taxPaymentEntity(pmt?.jurisdiction)}
            status={pmt?.providerStatus || pmt?.status?.replace('TAX_PAYMENT_ADMIN_', '')}
            balance={pmt?.amount}
            logo={`/img/activity/tax_done.svg`}
            onPress={() => setSelected(pmt?.id, { type: SelectedType.TAX_PAYMENT })}
            selected={pmt?.id === selectedId}
            squareLogo
          />
        ))}
      </RowGroup>
    </Scroll>
  );
}

import React from 'react';
import { AppPage } from 'src/web/components';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

import { useUrlParams } from 'src/web/hooks';
import {
  TaxPaymentsPage,
  EdePage,
  PoliciesPage,
  HomePage,
  MyTasksPage,
  IdReviewPage,
  HealthLinkPage,
} from '.';
import { UserInspector } from 'src/components/inspectors';

const routes = [
  {
    path: 'tax',
    name: 'Tax Payments',
    component: props => <TaxPaymentsPage {...props} />,
  },
  {
    path: 'health',
    name: 'EDE Applications',
    supportsGrid: true,
    component: props => <EdePage {...props} />,
  },
  {
    path: 'policies',
    name: 'Policies',
    supportsGrid: true,
    component: props => <PoliciesPage {...props} />,
  },
  {
    path: 'id-review',
    name: 'ID Review',
    supportsGrid: true,
    component: props => <IdReviewPage {...props} />,
  },
  {
    path: 'health-link',
    name: 'Health Link',
    supportsGrid: true,
    component: props => <HealthLinkPage {...props} />,
  },
  {
    path: 'home',
    component: props => <HomePage {...props} />,
  },
  {
    path: 'my',
    component: props => <MyTasksPage {...props} />,
  },
];

const TasksView = () => {
  const { uid } = useUrlParams();

  return (
    <Routes>
      {routes.map(route => {
        const page = (
          <AppPage
            raw
            canCollapse
            supportsGrid={route.supportsGrid}
            title={route.name}
            main={route.component}
            bgImage={route.bgImage}
            color={route.color}
            userId={uid}
            noPanel
            inspector={<UserInspector userId={uid} />}
          />
        );

        return (
          <>
            <Route path={`${route.path}/:id`} element={page} />
            <Route path={route.path} element={page} />
          </>
        );
      })}

      <Route path="/" element={<Navigate replace to="home" />} />
    </Routes>
  );

};

export default TasksView;

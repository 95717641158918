import React from 'react';
import {
  format,
  formatDistanceToNow,
  differenceInMinutes,
  formatDistanceToNowStrict,
  differenceInDays,
  addMinutes,
} from 'date-fns';
import { formatCurrency as fCurrency, STATES } from 'src/_utils';
import { Status } from 'src/web/components';
import { TIER_NAMES } from './tiers';

export const CODES = {
  'The Cut': {
    filterable: true,
    codes: ['thecut2020', 'thecut'],
    icon: 'thecut',
  },
  'Design Details': {
    filterable: false,
    codes: ['designdetails'],
    icon: 'designdetails',
  },
  Doordash: {
    filterable: true,
    codes: ['doordash', 'doordash-health', 'oe-doordash'],
    icon: 'doordash',
  },
  Email: {
    filterable: false,
    codes: ['customerio', 'hs_email', 'customer.io'],
    icon: 'email',
  },
  Facebook: {
    filterable: true,
    codes: ['fb', 'facebook'],
    icon: 'facebook',
  },
  'Freelancing School': {
    filterable: true,
    codes: ['freelancingschool'],
    icon: 'freelancingschool',
  },
  'Georgia Access': {
    filterable: true,
    codes: ['georgiaaccess', 'georgia'],
    icon: 'georgiaaccess',
  },
  Google: {
    filterable: true,
    codes: ['google'],
    icon: 'google',
  },
  Hyer: {
    filterable: true,
    codes: ['hyer'],
    icon: 'hyer',
  },
  Lili: {
    filterable: false,
    codes: ['lili', 'lilli', 'autolili'],
    icon: 'lili',
  },
  'Man Up': {
    filterable: true,
    codes: ['manup'],
    icon: 'manup',
  },
  Nana: {
    filterable: true,
    codes: ['nana'],
    icon: 'nana',
  },
  Nearside: {
    filterable: true,
    codes: ['nearside'],
    icon: 'nearside',
  },
  Paro: {
    filterable: true,
    codes: ['autoparo'],
    icon: 'autoparo',
  },
  Providers: {
    filterable: true,
    codes: ['providers'],
    icon: 'providers',
  },
  'Right Side Up': {
    filterable: true,
    codes: ['rightside', 'rightsideup'],
    icon: 'rightsideup',
  },
  SimplrFlex: {
    filterable: true,
    codes: ['simplrflex', 'autosimplrflex'],
    icon: 'simplrflex',
  },
  Sittercity: {
    filterable: true,
    codes: ['sittercity'],
    icon: 'sittercity',
  },
  Square: {
    filterable: true,
    codes: [
      'square',
      'square-health',
      'sqid',
      'autosquare',
      'sqidp',
      'sqidpr',
      'sqidph',
      'sqidpt',
      'sqidtt',
      'sqidth',
      'sqidtr',
    ],
    icon: 'square',
  },
  Toptal: {
    filterable: true,
    codes: ['toptal'],
    icon: 'toptal',
  },
  Turo: {
    filterable: true,
    codes: ['turo', 'autoturo'],
    icon: 'turo',
  },
  Upwork: {
    filterable: true,
    codes: ['upwork', 'braze'],
    icon: 'upwork',
  },
  Veryable: {
    filterable: true,
    codes: ['veryable'],
    icon: 'veryable',
  },
  Wethos: {
    filterable: true,
    codes: ['wethos'],
    icon: 'wethos',
  },
};

export const getSourceName = signupCode => {
  if (/doordash/.test(signupCode?.toLowerCase())) return 'Doordash';
  if (/square|sqid|squp/.test(signupCode?.toLowerCase())) return 'Square';

  const found = Object.entries(CODES)?.find(([key, value]) =>
    value?.codes?.includes(signupCode?.toLowerCase()),
  );

  return found?.[0] || signupCode?.replaceAll('-', ' ')?.toProperCase();
};

export const getSourceLogo = signupCode => {
  const found = Object.entries(CODES)?.find(([key, value]) =>
    value?.codes?.includes(signupCode?.toLowerCase()),
  );

  return CODES[found?.[0]]?.icon
    ? `https://s.catch.co/img/sources/${CODES[found?.[0]]?.icon || signupCode}.png`
    : undefined;
};

export const groupBy = (array, property) => {
  if (!array) return [];
  var hash = {};
  for (var i = 0; i < array.length; i++) {
    if (!hash[array[i][property]]) hash[array[i][property]] = [];
    hash[array[i][property]].push(array[i]);
  }
  return hash;
};

export const formatPercentage = ({ number, numDecimals = 1 }: { number: number; numDecimals?: number }) => {
  if (!number) return null;
  else if (number > 1 && number < 100) return `${number.toFixed(numDecimals)}%`;
  else return `${(number * 100).toFixed(numDecimals)}%`;
};

export const formatCurrency = ({ value, omitCents }: { value: number; omitCents?: boolean }) => {
  const dollars = `${fCurrency(Math.floor(Math.abs(value || 0)))}`;
  const cents = `${(Math.abs(value || 0) % 1).toFixed(2).slice(1)}`;
  const sign = value < 0 ? `–` : ``;
  return `${sign}${dollars}${!omitCents ? cents : ''}`;
};

const DATE_FORMATS = {
  TIME: 'hh:mmaaaaa',
  SHORTER: 'MMM d',
  SHORT: 'MMM dd',
  MEDIUM: 'MMM dd yyyy',
  LONG: 'MMM dd, yyyy  hh:mmaaaaa',
  FULL: 'MMM dd yyyy  hh:mm aa',
  ISO: 'yyyy-MM-dd',
  HUMAN: 'eeee, MMMM dd, yyyy',
  MONTH_HUMAN: 'MMMM yyyy',
  MONTH: 'yyyy-MM',
};

export const makeAddress = address => {
  return (
    !address ||
    Object.entries(address)
      ?.map(([k, v]) => v)
      ?.filter(v => !!v && v !== 'Address')
      ?.join(', ')
  );
};

export const isActiveNow = (date, extended) => {
  const n = differenceInMinutes(new Date(date), new Date());
  return n >= -5 ? 'Now' : n > -60 ? -n + 'm' : extended ? -120 : null;
};

export const shouldSync = date => {
  const n = differenceInDays(new Date(date), new Date());
  console.log({ n });
  return n <= -3 ? true : false;
};

export const makeSSN = user => {
  return /catch/.test(user?.email)
    ? !!user?.ssn
      ? `•••–••–${user?.ssn}`
      : null
    : user?.fullSSN || (!!user?.ssn ? `•••–••–${user?.ssn}` : null);
};

export const formatDate = ({ date, length = 'MEDIUM' }) => {
  if (!date) return null;
  else return format(new Date(date), DATE_FORMATS[length]);
};

export const formatDateOnly = ({ date, length = 'MEDIUM' }) => {
  const d = new Date(date);
  if (!date) return null;
  else return format(addMinutes(d, d.getTimezoneOffset()), DATE_FORMATS[length]);
};

export const formatRelativeDatePlain = ({ date }) => {
  if (!date) return null;
  else return formatDistanceToNow(new Date(date), { addSuffix: false });
};

export const formatRelativeDate = ({ date }) => {
  if (!date) return null;
  else return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export const formatRelativeDateStrict = ({ date }) => {
  if (!date) return null;
  else
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true })
      ?.replace(' seconds', 's')
      ?.replace(' second', 's')
      ?.replace(' minutes', 'm')
      ?.replace(' minute', 'm')
      ?.replace(' months', 'mo')
      ?.replace(' month', 'mo')
      ?.replace(' hours', 'h')
      ?.replace(' hour', 'h')
      ?.replace(' days', 'd')
      ?.replace(' day', 'd')
      ?.replace(' years', 'y')
      ?.replace(' year', 'y');
};

export const getBankLogo = ({ logo, provider }) => {
  return logo ? `data:image/gif;base64,${logo}` : `/img/products/${provider?.toLowerCase()}.png`;
};

export const rowFormatters = {
  percentage: (value, options) => formatPercentage({ number: value, ...options }),
  currency: (value, options) => formatCurrency({ value, ...options }),
  tier: value => (!value ? 'No tier' : `${TIER_NAMES[value]} (${value?.toProperCase()})`),
  enum: str =>
    str === 'IRA'
      ? 'Traditional IRA'
      : !!str && str !== ''
      ? str
          .replace('REC_', ' ')
          .replace('_', ' ')
          .toProperCase()
          .replace('Ira', 'IRA')
          .replace('Llc', 'LLC')
          .replace('Work Type', '')
          .replace('Fee', '')
          .replace('_fee', '')
          .replace('Pto', 'Time Off')
      : '',

  // do not use this for DOB or other things where time zone doesn't matter
  date: (value, options) => formatDate({ date: value, ...options }),

  // use dateOnly to avoid time zone issues aka for DOB
  dateOnly: (value, options) => formatDateOnly({ date: value, ...options }),
  status: (value, options) => (
    <Status
      status={value}
      hideText={options?.smallValue}
      hideIcon={options?.hideIcon}
      loading={options?.loading}
      compact={options?.compact}
      iconOverride={!!value ? options?.iconTrue : options?.iconFalse}
    >
      {options?.valueLabel || options?.statusValue}
    </Status>
  ),
  mono: value => <div className="mono">{value}</div>,
  trinary: (value, options) => (
    <>
      {value === null || value === undefined ? (
        <></>
      ) : (
        <Status
          iconOverride={!value || value === 'false' ? options.iconFalse : options?.iconTrue}
          status={value === 'false' ? 'false' : !!value ? 'true' : 'false'}
          compact={options?.compact}
        >
          {options?.valueLabel || options?.statusValue}
        </Status>
      )}
    </>
  ),
  bool: (value, options) => (
    <>
      {
        <Status
          iconOverride={!!value ? options?.iconTrue : options?.iconFalse}
          status={!!value ? 'true' : 'false'}
          compact={options?.compact}
        >
          {options?.valueLabel || options?.statusValue}
        </Status>
      }
    </>
  ),
  state: value => STATES[value] || value,
};

export const initials = person => {
  if (!person) return '';
  const first = person?.nickname || person?.givenName || person?.email;
  return `${first?.slice(0, 1) || ' '} ${person?.familyName?.slice(0, 1) || ''}`;
};

export const makeCSV = pmts => {
  var rows = [
    [
      'payment_amount',
      'payment_date',
      'payment_status',
      'first',
      'last',
      'filing_status',
      'dob',
      'city',
      'state',
      'id',
    ],
  ];

  const newRows = pmts?.map((pmt, idx) => {
    rows.push([
      pmt?.amount,
      pmt?.createdOn,
      pmt?.status?.replace('TAX_PAYMENT_ADMIN_', ''),
      pmt?.person?.givenName,
      pmt?.person?.familyName,
      pmt?.person?.filingStatus,
      pmt?.person?.dob,
      pmt?.person?.legalAddress?.city,
      pmt?.person?.legalAddress?.state,
      pmt?.person?.id,
    ]);
  });

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

export const makeName = user => {
  const last = user?.familyName || '';
  const first = user?.nickname
    ? user?.familyName
      ? user?.nickname?.replace(user?.familyName, '')
      : user?.nickname
    : user?.givenName;

  return !first && !last ? null : `${first} ${last}`;
};

export const userSummary = ({ job, state, joinDate, joinCode }) => (
  <div>
    {(!!job || !!state) && (
      <b>
        {job}
        {!!state && ' in '}
        {state}
        <br />
      </b>
    )}
    <span style={{ fontWeight: 500 }}>Joined {formatRelativeDate({ date: joinDate })} </span>

    <span style={{ fontWeight: 400, opacity: 0.5 }}>{!!joinCode && 'via '}</span>
    {!!joinCode && (
      <span className="mono" style={{ fontWeight: 600 }}>
        {joinCode}
      </span>
    )}
  </div>
);

export const externalLinks = (id, intercomID) => {
  return {
    intercom: `https://app.intercom.com/a/apps/pniw40fg/users/${intercomID}/all-conversations`,
    customer: `https://fly.customer.io/env/91186/people/${id}`,
    logrocket: `https://app.logrocket.com/6an89b/catch-production?filters=%5B%7B%22type%22%3A%22userID%22%2C%22operator%22%3A%7B%22name%22%3A%22is%22%2C%22type%22%3A%22IS%22%2C%22hasStrings%22%3Atrue%2C%22autocompleteEnabled%22%3Atrue%7D%2C%22strings%22%3A%5B%22${id}%22%5D%7D%5D`,
  };
};

export const enrollmentName = eType => eType?.replace('_INSURANCE', '')?.toProperCase();

export const taxPaymentEntity = code => {
  if (code === 'US') return 'Federal';
  else return STATES[code] || code;
};

import React from 'react';

import styled from 'styled-components';

const SRow = styled.div`
  padding: 16px 12px;
  border: 1px solid ${p => (p.selected ? 'var(--c-fg-1)' : 'var(--c-border)')};
  box-shadow: ${p => (p.selected ? '0 0 0 1px var(--c-fg-1)' : 'none')};
  margin-bottom: 8px;
  border-radius: var(--radiusLarge);
  cursor: pointer;
  &:hover {
    border: 1px solid ${p => (p.selected ? 'var(--c-fg-1)' : 'var(--c-fg-2)')};
  }
  > div {
    line-height: 1;
    * {
      line-height: 1;
    }
    &._title {
      font-size: var(--fp);
      color: var(--c-fg-0);
      font-weight: 500;
      margin: 0;
    }
    &._subtitle {
      margin-top: 6px;
      font-size: var(--ffp);
      color: var(--c-fg-1);
      font-weight: 400;
    }
  }
`;

export const OptionRow = ({ pretitle, title, subtitle, selected, onPress }) => {
  return (
    <SRow selected={selected} onClick={onPress}>
      {!!title && (
        <div className="_title">
          {!!pretitle && (
            <span
              style={{
                fontWeight: 400,
                opacity: 0.5,
                fontVariantNumeric: 'tabular-nums',
                marginRight: 8,
                width: 24,
              }}
            >
              {pretitle}
            </span>
          )}
          {title}
        </div>
      )}
      {!!subtitle && <div className="_subtitle">{subtitle}</div>}
    </SRow>
  );
};

export default OptionRow;

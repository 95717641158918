import { Scroll } from 'src/kit';
import { useQuery, GET_USER_EVENTS } from 'src/data';
import { RowGroup } from 'src/web/components';
import { EventFeed } from 'src/components/events/EventFeed';

export function ActivityPage({ userId, inInspector }) {
  const { loading, data } = useQuery(GET_USER_EVENTS, {
    variables: { userId },
    skip: !userId,
  })

  return (
    <Scroll transparent={inInspector} loading={loading}>
      <RowGroup title="Events">
        <EventFeed events={data?.person?.events} />
      </RowGroup>
    </Scroll>
  );
};
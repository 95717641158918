import React, { useState } from 'react';

import { FullModal } from 'src/web/components';
import { PathPane } from '../health/Path';

export const PathModal = ({ userId, appId, lastRoute, isRenew, onRequestClose, task }) => {
  return (
    <FullModal
      task={task}
      onRequestClose={onRequestClose}
      title="Change Application Step"
      subtitle="EDE"
      sidebarItem={<></>}
    >
      <PathPane uid={userId} appId={appId} routeName={lastRoute} renew={isRenew} onChange={onRequestClose} />
    </FullModal>
  );
};

export default PathModal;

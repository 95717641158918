/**
 *
 * @deprecated
 */
export const colors = {
  'ink-1': '#06070A',
  ink: '#1F2533',
  'ink+1': '#70747D',
  'ink+2': '#A6A8AD',
  'ink+3': '#CDCED1',
  'ink+4': '#E6E8EB',
  'ink+5': '#F1F3F5',
  'ink+6': '#F8F9FA',
  'flare-2': '#00368C',
  'flare-1': '#0048BD',
  flare: '#1169F5',
  'flare+1': '#5C9AFF',
  'flare+2': '#A8C9FF',
  'flare+3': '#E6EFFF',
  'flare+4': '#F5F7FF',
  'algae-2': '#004D48',
  'algae-1': '#00706A',
  algae: '#03948C',
  'algae+1': '#4FBDB7',
  'algae+2': '#C0EBEA',
  'algae+3': '#E3F7F7',
  'coral-3a': '#FEE0E0',
  'coral-2': '#9C0511',
  'coral-1': '#C71A28',
  coral: '#ED3E4C',
  'coral+1': '#FF8086',
  'coral+2': '#FFC2C5',
  'coral+3': '#FFEBED',
  'coral+4': '#FFF5F5',
  'peach-3': '#B04900',
  'peach-2': '#E5792C',
  'peach-1': '#FFB06B',
  peach: '#B04900',
  'peach+1': '#FFDFC9',
  'peach+1.5': '#FFF3EB',
  'peach+2': '#FFF3EB',
  'peach+3': '#FFF5F0',
  'sage-3': '#5E6F73',
  'sage-2': '#7A8D91',
  'sage-1': '#A9C0C4',
  sage: '#CEE1E5',
  'sage+1': '#E6F0F0',
  'sage+2': '#F2FAFA',
  'sage+dark': '#183738',
  'sage+1+dark': '#163233',
  snow: '#FFFFFF',
  'jazz+4': '#8BE0DC',

  'quartz-2': '#2C1691',
  'quartz-1': '#5036C2',
  quartz: '#7254F7',
  'quartz+1': '#B19CFF',
  'quartz+2': '#D5CAFC',
  'quartz+3': '#EFEBFF',

  'midnight-1': '#050506',
  midnight: '#121213',
  'midnight+1': '#1C1C1E',
  'midnight+2': '#303032',
  'midnight+3': '#313133',
  'midnight+4': '#464649',

  // helper function to convert hex -> rgb
  rgb: hex => ({
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  }),
};

/**
 *
 * @deprecated
 */
export const size = {
  padding: 24,
  summaryHeight: 64,
  maxWidth: 980,
  breakpoint: 980,
  iconSm: 16,
  iconLg: 28,
  iconLgMargin: 12,
  overpad: 4,
  timelineInset: 85,
};

/**
 *
 * @deprecated
 */
export const fontSize = {
  h1: 28,
  h2: 24,
  h3: 20,
  h4: 18,
  h5: 16,
  h6: 12,
  p: 15,
  fp: 14,
  ffp: 12.5,
  lp: 18,
  l: 16,
  nav: 16,
};

/**
 *
 * @deprecated
 */
export const shadow = {
  tiny: '0 1px 3px -1px #00000011',
  chip: '0 3px 12px -1px #0000001A, 0 1px 3px 0px #00000011',
  small: '0 5px 15px -5px #00000022',
};

/**
 *
 * @deprecated
 */
export const z = {
  navbar: 75,
  navbar2: 75,
  sticky: 100,
};

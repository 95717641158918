import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'src/web/utility';

const Row = styled.div`
  align-items: center;
  text-align: right;
  overflow: hidden;
  color: ${p => (p.isSavings ? 'var(--c-cerise-0)' : 'var(--c-fg-0)')};
  font-size: ${p => (p.compact ? 'var(--fp)' : 'var(--p)')};
  margin-top: ${p => (p.compact ? -24 : 0)}px;
  margin-right: ${p => (p.compact ? 6 : 0)}px;
  font-weight: 500;
  line-height: 1;
  &:last-child {
    border: none;
  }

  .prev {
    color: var(--c-fg-0);
    opacity: 0.5;
    margin-right: ${p => (p.compact ? 6 : 12)}px;
    font-weight: 300;
    font-size: ${p => (p.compact ? 'calc(var(--ffp) - 1px)' : 'var(--p)')};
  }
  .extra {
    font-size: ${p => (p.compact ? 'calc(var(--ffp) - 1.5px)' : 'calc(var(--ffp) - 1px)')};
    color: ${p => (p.isSavings ? 'var(--c-cerise-0)' : 'var(--c-good-0)')};
    line-height: 1;
    font-weight: 500;
    > span {
      font-weight: 400;
      margin-right: 3px;
      line-height: ${p => (p.compact ? 1 : undefined)};
    }
  }
`;

const CostRow = ({ label, unit, value, prevValue, compact, rawValue, isSavings }) => {
  const sticker = !!prevValue && value !== prevValue;

  const prev = prevValue ? prevValue : value;

  const extra = (
    <div className="extra mono">
      <span>{isSavings ? '' : '–'}</span>
      {isSavings ? 'APTC' : formatCurrency({ value: prev - value })}
    </div>
  );

  const prevc = !!prevValue && prevValue !== value && (
    <span className="prev" style={{ textDecoration: 'line-through' }}>
      {!!sticker && formatCurrency({ value: prevValue })}
    </span>
  );

  return (
    <Row compact={compact} isSavings={isSavings}>
      <div className="mono">
        {compact ? extra : prevc}

        <span className="new">{value === '––.––' ? '––.––' : formatCurrency({ value: value })}</span>
        {!!unit && <span> /{unit}</span>}
      </div>
      {!compact && extra}
    </Row>
  );
};

export default CostRow;

import { gql } from '../generated';

export const CREATE_USER = gql(`
  mutation CreateUser($email: String!) {
    adminCreateUser(input: { email: $email }) {
      id
      email
      createdOn
    }
  }
`);

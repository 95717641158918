import { gql } from '../generated';

export const REMOVE_EXPLORER_MEMBER = gql(`
  mutation RemoveExplorerMember($memberID: ID!, $personID: ID!) {
    deleteHealthExplorerDependents(ids: [$memberID], personID: $personID) {
      id
    }
  }
`);


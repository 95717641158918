import { useState } from 'react';
import { SelectionContext } from './SelectionContext';

export const SelectionProvider = ({ children }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [privacyMode, setPrivacyMode] = useState(localStorage.privacyMode === 'true');
  const [inspectorClosed, setInspectorClosed] = useState(false);
  const [sidebarSecondaryOpen, setSidebarSecondaryOpen] = useState(
    !localStorage.sidebarSecondary ? true : localStorage.sidebarSecondary === 'open' ? true : false,
  );

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const togglePrivacyMode = () => {
    localStorage.privacyMode = !privacyMode;
    setPrivacyMode(!privacyMode);
  };

  return (
    <SelectionContext.Provider
      value={{
        inspectorClosed,
        sidebarSecondaryOpen,
        privacyMode,
        togglePrivacyMode,
        navbarOpen,
        setInspectorClosed,
        toggleNavbar,
        setNavbarOpen,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

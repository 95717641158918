import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { formatRelativeDate, makeName } from 'src/web/utility';
import { UploadTile, ExportModal } from 'src/web/components';

const REQUEST_CSV = gql`
  mutation RequestCSV {
    generateHealthReport {
      generating
    }
  }
`;

const GET_CSV = gql`
  query GetCSV {
    getHealthReport {
      isReady
      link
      lastGeneratedAt
      lastGeneratedBy {
        id
        givenName
        familyName
        nickname
        avatarImage
      }
    }
  }
`;

export const EdeExportModal = ({ onCompleted }) => {
  const [waiting, setWaiting] = useState(false);
  const [last, setLast] = useState();

  const { data: d, loading } = useQuery(GET_CSV, {
    pollInterval: 15000,
  });

  const [generate, { data }] = useMutation(REQUEST_CSV, {
    fetchPolicy: 'no-cache',
    onCompleted: d => setWaiting(true),
    onError: d => setWaiting(true),
  });

  useEffect(() => {
    if (d?.getHealthReport?.link && d?.getHealthReport?.lastGeneratedAt) setLast(d?.getHealthReport);
    if (d?.getHealthReport?.isReady === true) {
      if (!!waiting && !loading) {
        setWaiting(false);
      }
    } else setWaiting(true);
  }, [d, loading, waiting]);

  return (
    <ExportModal onCompleted={onCompleted}>
      {!!last?.lastGeneratedAt && (
        <UploadTile
          small
          fileType="CSV"
          name={formatRelativeDate({ date: last?.lastGeneratedAt })}
          subtitle={makeName(last?.lastGeneratedBy)}
          onPress={() => window.open(last?.link)}
        />
      )}
      <UploadTile
        small
        loading={!!waiting || !!loading}
        placeholder={!!waiting || !!loading}
        color={!loading && !waiting ? 'linkColor' : 'textColor'}
        fileType={!loading && !waiting ? '+' : '•••'}
        name={!!loading ? 'Checking...' : !!waiting ? 'Making new version...' : 'Get updated version'}
        onPress={() => {
          if (!waiting) {
            setWaiting(true);
            generate();
          } else alert('puneet is already generating a new one, cool off brah');
        }}
      />
    </ExportModal>
  );
};

import React, { useState } from 'react';
import { Priority, useRegisterActions } from 'kbar';
import { Routes, Route, useParams, useNavigate, Navigate, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { useUserSummary } from 'src/web/hooks';
import { copyToClipboard } from 'src/web/utility';
import { TaxPage } from './TaxPage';

import { AppPage, SectionNav, Toolbar, Icon, UnlockModal } from 'src/web/components';
import { FETCH_DISABLE_USER_STATUS, BG_ENROLLMENTS } from 'src/web/data';
import {
  GoalsPage,
  StatementsPage,
  BankingPage,
  ActivityPage,
  HealthPage,
  IncomePage,
  UploadsPage,
  HomePage,
  UserNavigation,
  IdentityPage,
  ExplorerPage,
  ConversionPage,
  ApplicationsPage,
  PoliciesPage,
  LinkedAccountsPage,
} from '.';
import { UserInspector } from 'src/components/inspectors';
import UpdateUserModal from 'src/web/components/modal/UpdateUserModal';
import CloseAccountModal from 'src/web/components/modal/CloseAccountModal';
import UpdateTierModal from 'src/web/components/modal/UpdateTierModal';

import { lookupApplications, syncHealth } from 'src/web/data/actions/user';
import { KACTIONS } from 'src/web/kbar';
import { useBookmarks } from 'src/util/bookmarks';
import { FlagsPage } from './FlagsPage';

const StyledWindowInner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: var(--navHeight);
  background: var(--bg);
`;

const StyledWindow = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: var(--navPrimary);
  display: block;
  @media (max-width: 1024px) {
    left: 0;
  }
`;

interface UserRoute {
  path: string;
  name: string;
  component: React.ComponentType<any>;
}

const routes: Array<UserRoute> = [
  {
    path: 'home',
    name: 'Home',
    component: props => <HomePage {...props} />,
  },
  {
    path: 'activity',
    name: 'Activity',
    component: props => <ActivityPage {...props} />,
  },
  {
    path: 'conversion',
    name: 'Conversion',
    component: props => <ConversionPage userId={props.userId} />,
  },

  {
    path: 'identity',
    name: 'Identity',
    component: props => <IdentityPage {...props} />,
  },
  {
    path: 'taxes',
    name: 'Taxes',
    component: props => <TaxPage {...props} />,
  },
  {
    path: 'insurance',
    name: 'Health Insurance',
    component: props => <HealthPage {...props} />,
  },
  {
    path: 'applications',
    name: 'Applications',
    component: props => <ApplicationsPage {...props} />,
  },
  {
    path: 'policies',
    name: 'Policies',
    component: props => <PoliciesPage {...props} />,
  },
  {
    path: 'goals',
    name: 'Goals',
    component: props => <GoalsPage {...props} />,
  },
  {
    path: 'banking',
    name: 'Banking',
    component: props => <BankingPage {...props} />,
  },
  {
    path: 'income',
    name: 'Income',
    component: props => <IncomePage {...props} />,
  },
  {
    path: 'linked-accounts',
    name: 'Linked Accounts',
    component: props => <LinkedAccountsPage {...props} />,
  },
  {
    path: 'explorer',
    name: 'HX',
    component: props => <ExplorerPage {...props} />,
  },
  {
    path: 'uploads',
    name: 'Uploads',
    component: props => <UploadsPage {...props} />,
  },
  {
    path: 'statements',
    name: 'Statements',
    component: props => <StatementsPage {...props} />,
  },
  {
    path: 'flags',
    name: 'Flags',
    component: props => <FlagsPage {...props} />,
  },
];

export function UserView() {
  const url = useResolvedPath('').pathname;
  let { userId } = useParams();

  const [isTiersOpen, setTiersOpen] = useState(false);
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const [isLockOpen, setLockIsOpen] = useState(false);
  const [isDisableOpen, setDisableIsOpen] = useState(false);
  const [notes, setNotes] = useState('');

  const navigate = useNavigate();
  const { bookmarks, addBookmark, removeBookmark } = useBookmarks();

  const {
    firstName,
    name,
    lastName,
    nickname,
    middleName,
    id,
    isLocked,
    isDeleted,
    givenName,
    familyName,
    isTradingPaused,
    notes: userNotes,
    links,
    avatar,
    email,
    phoneNumber,
    urlCustomer,
    urlIntercom,
    urlLogrocket,
    urlOpenphone,
    urlUnit,
    urlMixpanel,
    signupContext,
    joinDate,
    joinCode,
    tier,
    eligibleTiers,
    urlAbound,
    urlCastle,
  } = useUserSummary({
    id: userId,
  });

  useQuery(BG_ENROLLMENTS, {
    skip: !userId,
    variables: { id: userId },
  });

  const { data: status_data } = useQuery(FETCH_DISABLE_USER_STATUS, {
    skip: !userId,
    variables: { personId: userId },
  });
  const disable_status = status_data?.disableUserStatus?.status;
  const isPendingDisable = !!disable_status;

  // @todo add back bank links data
  const bankData = {};
  const bankLinks = bankData?.viewer?.bankLinks;

  // @todo allow admins to refresh plaid identity info
  const refreshPlaidIdentityInformation = () => {};

  const bookmark = () => {
    !!bookmarks[id]
      ? removeBookmark(id)
      : addBookmark({
          id,
        });
  };

  const externalTools = [
    {
      id: 'unit',
      name: 'Unit',
      icon: '/img/products/unit.png',
      action: () => window.open(urlUnit, '_blank'),
      key: 'u',
    },
    {
      id: 'intercom',
      name: 'Intercom',
      icon: '/img/products/inter.png',
      action: () => window.open(urlIntercom, '_blank'),
      key: 'i',
    },
    {
      id: 'mixpanel',
      name: 'Mixpanel',
      icon: '/img/products/mixpanel.png',
      action: () => window.open(urlMixpanel, '_blank'),
      key: 'm',
    },
    {
      id: 'customer',
      name: 'Customer.io',
      icon: '/img/products/customer.png',
      action: () => window.open(urlCustomer, '_blank'),
      key: 'c',
    },
    {
      id: 'logrocket',
      name: 'LogRocket',
      icon: '/img/products/logrocket.png',
      action: () => window.open(urlLogrocket, '_blank'),
      key: 'l',
    },
    {
      id: 'openphone',
      name: 'OpenPhone',
      icon: '/img/products/openphone.png',
      action: () => window.open(urlOpenphone, '_blank'),
      key: 'p',
    },
    {
      id: 'abound',
      name: 'Abound',
      icon: '/img/products/abound.png',
      action: () => window.open(urlAbound, '_blank'),
      key: 'a',
    },
    {
      id: 'castle',
      name: 'Castle',
      icon: '/img/products/castle.png',
      action: () => window.open(urlCastle, '_blank'),
      key: 'k',
    },
  ];

  useRegisterActions(
    [
      {
        ...KACTIONS.CHANGE_TIER,
        perform: () => setTiersOpen(true),
      },
      {
        ...KACTIONS.UPDATE_USER_NAME,
        perform: () => setUpdateOpen(true),
      },
      {
        ...(isLocked ? KACTIONS.UNLOCK_USER : KACTIONS.LOCK_USER),
        perform: () => setLockIsOpen(true),
      },
      {
        ...KACTIONS.DELETE_USER,
        perform: () => setDisableIsOpen(true),
      },
      {
        ...KACTIONS.HEALTH_SYNC,
        perform: () => syncHealth({ userId: userId, forceLink: true, forcePermissions: true }),
        priority: Priority.LOW,
      },
      {
        ...KACTIONS.HEALTH_IMPORT,
        perform: () => lookupApplications({ userId: userId }),
        priority: Priority.LOW,
      },
      {
        ...KACTIONS.COPY_USER_ID,
        perform: () => copyToClipboard(userId),
        priority: Priority.LOW,
      },
      ...externalTools
        .filter(tool => !!tool?.id)
        ?.map(tool => {
          return !!tool
            ? {
                id: tool?.id,
                name: tool?.name,
                icon: <Icon fill style={{ borderRadius: 8 }} logo={tool?.icon} size={24} />,
                perform: tool?.action,
                pretitle: 'open',
                section: 'Open',
                keywords: `goto, ${tool?.name}`,
                shortcut: ['o', tool?.key],
              }
            : {};
        }),
    ],
    [userId, externalTools, isLocked],
  );

  return (
    <StyledWindow>
      <StyledWindowInner>
        <SectionNav
          back="/users"
          color="usersColor"
          hasUserActions
          userId={userId}
          intercomID={links?.intercomID}
        >
          <UserNavigation
            userId={id}
            image={avatar}
            name={name}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phoneNumber}
            notes={userNotes}
            signupContext={signupContext}
            joinDate={joinDate}
            joinCode={joinCode}
            tier={tier}
          />
        </SectionNav>
        <div>
          <Routes>
            {routes.map(route => {
              const element = (
                <AppPage
                  secondary
                  icon="User"
                  noPanel
                  raw={true}
                  bgImage={route.bgImage}
                  username={name}
                  title={name}
                  userId={userId}
                  path={`${url}/${route.path}`}
                  main={route.component}
                  locked={isLocked}
                  topColor={route.topColor}
                  color={route.color}
                  onRename={() => setUpdateOpen(true)}
                  onChangeTier={() => setTiersOpen(true)}
                  inspector={<UserInspector userId={userId} fullHeight />}
                />
              );

              return (
                <React.Fragment key={route.path}>
                  <Route path={route.path} element={element} />
                  <Route path={`${route.path}/:id`} element={element} />
                </React.Fragment>
              );
            })}

            <Route key="*" path="*" element={<Navigate replace to="home" />} />
          </Routes>
        </div>
        {!!isUpdateOpen && (
          <UpdateUserModal
            userId={userId}
            onRequestClose={() => setUpdateOpen(false)}
            user={{
              givenName,
              middleName,
              familyName,
              nickname,
            }}
          />
        )}
        {!!isTiersOpen && (
          <UpdateTierModal
            eligibleTiers={eligibleTiers}
            userId={userId}
            tier={tier}
            onRequestClose={() => setTiersOpen(false)}
          />
        )}
        {!!isLockOpen && (
          <UnlockModal
            setNotes={setNotes}
            notes={notes}
            userId={userId}
            setLockIsOpen={setLockIsOpen}
            onRequestClose={() => setLockIsOpen(false)}
          />
        )}
        {!!isDisableOpen && (
          <CloseAccountModal
            bankLinks={bankLinks}
            userId={userId}
            onRequestClose={() => setDisableIsOpen(false)}
          />
        )}
      </StyledWindowInner>

      <Toolbar
        icon="User"
        title={name}
        hasAlert={isLocked || isDeleted || isPendingDisable}
        subtitle={`${isLocked ? 'Locked' : ''} ${isDeleted ? 'Deleted' : ''} ${
          isPendingDisable ? 'deletion in progress' : ''
        } ${!!isTradingPaused ? 'Trading paused' : ''}`}
        pageActions={[
          {
            icon: 'Bookmark',
            tooltip: !!bookmarks[id] ? 'Remove Pin' : 'Pin',
            onPress: bookmark,
            active: !!bookmarks[id],
          },
        ]}
      />
    </StyledWindow>
  );
}

import React from 'react';
import styled from 'styled-components';

import { Redact, Icon, Avatar, Status, RowLabel, I } from 'src/web/components';
import { formatCurrency, formatDate, initials } from 'src/web/utility';
import Tooltip from './Tooltip';
import { differenceInDays } from 'date-fns';

const ICON_SIZE = 22;
const DEBUG = false;
const COLOR = DEBUG ? '#0055FF33' : 'transparent';

const SBaseRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--c-fg-0);
  align-items: center;
  cursor: var(--cursor);
  border: none;
  line-height: 0.9;
  overflow: hidden;
  position: relative;
  flex-grow: 0;
  background: ${p => (p.accent ? p.accent : 'var(--cCard2)')};
  margin-left: ${p => -p.negset}px;
  margin-right: ${p => -p.negset}px;
  padding-left: ${p => p.negset}px !important;
  padding-right: ${p => p.negset}px !important;
  &:last-child {
    margin-bottom: none;
  }
  .reference-color {
    color: var(--c-cerise-0);
  }
  .gray-color {
    color: var(--c-fg-1);
  }
  .taskrowbg {
    position: absolute;
    background: var(--cCard);
    z-index: -1;
    right: 0px;
    left: 0px;
  }
`;

const SGridRow = styled(SBaseRow)`
  padding: 12px 12px 12px 12px;
  min-height: 64px;
  font-size: var(--fp);
  transform: translateZ(0);
  background: ${p => (p.accent ? p.accent : 'var(--cCard2)')};
  position: relative;
  border: 1px solid transparent;
  margin-bottom: 2px;
  border-radius: 8px;
  opacity: ${p => (p.placeholder ? 0.5 : undefined)};
  &.live {
    background: ${p => (p.accent ? p.accent : 'var(--c-cerise-2)')};
    &.now {
      background: ${p => (p.accent ? p.accent : 'var(--c-cerise-1)')};
    }
  }
  &:hover {
    border-color: var(--c-border);
  }
  &.selected {
    border-color: var(--c-fg-3);
  }
  .taskrowbg {
    opacity: 0.15;
    background: ${p => (p.accent ? p.accent : 'transparent')};
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 4px;
    width: 2px;
    border-radius: 4px;
    z-index: 0;
    &.live {
      background: ${p => (p.accent ? p.accent : 'var(--c-fg-0)')};
    }
  }
`;

const SRow = styled(SBaseRow)`
  padding: ${p => (p.compact ? 8 : 12)}px 0;
  height: ${p => (p.large ? 65 : 54)}px;
  font-size: ${p => (p.compact ? 'var(--ffp)' : 'var(--fp)')};
  opacity: ${p => (p.placeholder ? 0.5 : undefined)};
  .taskrowbg {
    top: 0px;
    bottom: 0px;
    border-bottom: 1px solid var(--c-border-light);
    background: transparent;
    &.selected {
      background: var(--cAdminAccentLight) !important;
    }
  }
  &:hover .taskrowbg {
    background: var(--c-border-light);
  }
`;

const SName = styled.div`
  text-overflow: clip;
  white-space: nowrap;
  mask: ${p => (!!p.standalone ? 'linear-gradient(to right, #000000 85%, #00000000)' : '')};
  flex-shrink: 2;
  flex-grow: 1;
  max-width: ${p => (!!p.standalone ? 180 : 360)}px;
  line-height: var(--fp);
  background: ${COLOR};
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: flex-end;
  font-size: var(--fp);
  font-weight: 550;
  > div {
    background: ${COLOR};
  }
`;

const STestCase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.transparent ? 'var(--c-border)' : 'var(--c-fg-0)')};
  background: ${p => (p.transparent ? 'transparent' : 'var(--c-border-light)')};
  height: ${ICON_SIZE - 2}px;
  border-radius: 4px;
  font-size: 11px !important;
  white-space: nowrap;
  line-height: 1;
  font-weight: 500;
  margin: 1px 0 1px 1px;
  padding: 0 4px;
`;

const SLabels = styled.div`
  background: ${COLOR};
  flex-shrink: 0;
  display: flex;
  height: 16px;
  margin-top: 1px;
  font-size: ${p => (p.large ? 'var(--fp)' : 'var(--ffp)')}px;
`;

const SMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${COLOR};
  height: 20px;
  margin-left: 8px;
`;

const Currency = styled.span`
  font-size: var(--fp);
  font-weight: 600;
  margin-right: 8px;
  color: ${p => p.theme.debitColor};
`;

const STop = styled.span`
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  background: ${COLOR};
`;

const SBottom = styled.span`
  display: flex;
  height: 16px;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  background: ${COLOR};
  margin-top: 1px;
`;

const StyledInner = styled.div`
  flex-grow: 1;
`;

const StyledInner2 = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SFlex = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const TaskRow = ({
  id,
  name,
  defaultName,
  amount,
  owner,
  selected,
  onPress,
  date,
  inlineDate,
  active,
  showAvatar,
  user,
  defaultUser,
  detail,
  datePrefix,
  stuck,
  small,
  status,
  background,
  icon,
  labels,
  labelIcons,
  large,
  page,
  dateLength = 'SHORTER',
  live,
  standalone,
  rightIcon,
  phase,
  needsManualAction,
  negset,
  placeholder,
}) => {
  const testcase = defaultName
    ?.match(/async|hle|hl|(tc_(\w{0,2}))|(ga_(\w{1})_(\w{1}))/)?.[0]
    ?.replaceAll('_', '')
    ?.replaceAll('tc', '')
    ?.replaceAll('ga', 'ga ')
    ?.replaceAll('hle', 'hl ')
    ?.toUpperCase();

  const Container = !!standalone ? SGridRow : SRow;

  const Name = () => (
    <SName standalone={standalone}>
      <div className="m" style={!name ? styles.namePlaceholder : styles.name}>
        <Redact blur inline>
          {name || defaultName || ''}
        </Redact>
      </div>
    </SName>
  );

  const Meta = () => (
    <SMeta>
      {!!amount && <Currency>{formatCurrency({ value: amount })}</Currency>}
      {!!date && !!inlineDate && (
        <div
          className={`${live ? 'reference-color' : 'gray-color'} ${live === 'Now' && 'pulse'}`}
          style={styles.inlineDate}
        >
          {live ? live : formatDate({ date })}
        </div>
      )}

      {!!icon && <Icon icon={icon} size={ICON_SIZE} square noShadow containLg style={styles.mr2} />}
      {!standalone && !!status && <Status small hideText={!!small} status={status} />}

      {!!phase && (
        <STestCase className="mono" transparent>
          {phase}
        </STestCase>
      )}
      {!!testcase && <STestCase className="mono">{testcase}</STestCase>}
      {!!owner && (
        <Avatar
          style={{ marginLeft: 4, marginTop: -1, alignSelf: 'flex-start' }}
          initials={initials(owner)}
          image={owner?.avatarImage}
          size={20}
        />
      )}
    </SMeta>
  );

  const Detail = () =>
    (!!detail || (!!date && !inlineDate)) && (
      <SLabels>
        <Redact blur disable={!detail} inline>
          <div
            className={`${live ? 'reference-color' : 'gray-color'} ${live === 'Now' && 'pulse-text'} `}
            style={!!large ? styles.redact : live ? styles.redactSmBold : styles.redactSm}
          >
            {!!detail && <>{detail}</>}
            <span className={live === 'Now' && 'pulse'} style={{ fontWeight: 500, opacity: live ? 0.5 : 1 }}>
              {!!date && !detail && !inlineDate && !!datePrefix
                ? `${datePrefix} ${formatDate({ date })}`
                : live
                ? live
                : formatDate({
                    date,
                    length: differenceInDays(new Date(date), new Date()) < -180 ? 'MEDIUM' : dateLength,
                  })}
            </span>
            {!!page && (
              <span style={{ fontWeight: 500, marginLeft: 4, opacity: live ? 1 : 0.5 }}>{page}</span>
            )}
          </div>
        </Redact>
      </SLabels>
    );

  const Labels = () => (
    <SLabels>
      {!!labels && labels?.map(label => <RowLabel key={label} text={label} />)}
      {labelIcons?.map(icon => (
        <div key={icon.icon}>
          <Tooltip text={icon.text} key={icon.icon} align="end">
            <div>
              <I
                style={{ opacity: icon.fade ? 0.65 : 1, marginLeft: 2 }}
                size={16}
                name={icon.icon}
                color={icon.color}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </SLabels>
  );

  return (
    <Container
      placeholder={placeholder}
      negset={negset}
      accent={stuck ? 'var(--c-red-1)' : needsManualAction ? 'var(--c-orange-1)' : undefined}
      standalone={standalone}
      id={`Task-${id}`}
      small={small}
      large={large}
      onClick={onPress}
      active={active}
      selected={selected}
      background={background}
      className={`taskrow   ${!!selected && 'selected'} ${!!live && 'live'} ${live === 'Now' && 'now'} ${
        !!placeholder ? 'pulse-slow' : 'fadeInSubtle'
      }`}
    >
      <div
        className={`taskrowbg ${!!selected && 'selected'} ${!!live && 'live'}  ${live === 'Now' && 'pulse'} `}
      ></div>
      {!!rightIcon && <Icon iconColor="transparent" logo={rightIcon} size={20} style={styles.srcLogo} />}
      {!!showAvatar && (
        <Avatar
          style={{ ...styles.mr12, alignSelf: 'flex-start' }}
          initials={initials(user?.person || user) || initials(defaultUser)}
          image={user?.person?.avatarImage || user?.avatarImage}
          size={32}
        />
      )}
      {!!standalone ? (
        <StyledInner>
          <STop>
            <Name />
            <Meta />
          </STop>
          <SBottom>
            <div>
              <Detail />
            </div>
            <div>
              <Labels />
            </div>
          </SBottom>
        </StyledInner>
      ) : (
        <StyledInner2>
          <div>
            <Name />
            <Detail />
          </div>
          <SFlex>
            <Labels />
            <Meta />
          </SFlex>
        </StyledInner2>
      )}
    </Container>
  );
};

const styles = {
  redact: {
    fontSize: 'var(--ffp)',
    fontWeight: 500,
  },
  redactSm: {
    fontSize: 'var(--ffp)',
    fontWeight: 500,
  },
  redactSmBold: {
    fontSize: 'var(--ffp)',
    fontWeight: 500,
  },
  inlineDate: {
    fontSize: 'var(--ffp)',
    lineHeight: 1,
    fontWeight: 500,
    width: 60,
    textAlign: 'center',
  },
  mr2: { marginLeft: 2 },
  mr12: { marginRight: 12 },
  mr8: { marginRight: 8 },
  avatar2: { marginRight: 0, marginLeft: 0, flexShrink: 0 },
  srcLogo: { position: 'absolute', bottom: 12, left: 12, zIndex: 999999 },
  namePlaceholder: {
    fontWeight: 500,
    opacity: 0.5,
    minWidth: 150,
    maxWidth: 250,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  name: { minWidth: 150, maxWidth: 250, height: 20, display: 'flex', alignItems: 'center' },
};

export default TaskRow;

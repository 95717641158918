import { Scroll, Button, StyledBottomBar, Banner, FullModal, IRow, Filter, Filters } from 'src/web/components';
import { Input, InputLabel } from 'src/web/components';
import { SearchItem, useMarketplace } from 'src/hooks/useMarketplace';
import { SearchResults } from './Search';
import { formatMedicalName } from '@catch-co/health-utils';
import { plural,  } from 'src/_utils/format';
import { SearchResultCard } from './Search/SearchResultsCard';
import { DrugFragment } from 'src/data';

export interface RXModalProps{
  isOpen: boolean;
  onRequestClose: () => void;
  zip?: string;
  drugs: Array<DrugFragment>;
  editDrugs: (selected: Array<SearchItem>) => void;
}

export function RXModal({ isOpen, onRequestClose, drugs, zip, editDrugs }: RXModalProps) {
  const { searchTerm, handleSearch, results, searching, selected, addWildcard, toggleItem, removeItem, isSelected } = useMarketplace({
    type: 'drugs',
    zip,
    initial: drugs
  });
  
  if (!isOpen) return null;

  const handleSubmit = () => {
    editDrugs(selected);
    onRequestClose();
  }


  return (
    <FullModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Add Drugs"
      subtitle={zip ? `Searching in ${zip}` : ''}
      sidebarItem={
      <>
        {!zip && (<Banner tiny title="Zipcode not set, search may not function as expected" />)}

        {selected.map((item, index) => (
          <IRow 
            label="Drug"
            value={item.name}
            actionType="remove"
            onPress={() => removeItem(item)}
          />
        ))}
      </>
    }>
        <Scroll transparent bottomBar>
          <InputLabel>Search drugs</InputLabel>
          <Input placeholder="Search" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
          <br />
          <SearchResults 
            searching={false} 
            results={results} 
            searchTerm={searchTerm}
            addWildcard={addWildcard}
            renderItem={(result) => (
              <SearchResultCard
                title={formatMedicalName(result.name)} 
                subtitle={result.route}
                detail={`${result.strengths.length} ${plural(result.strengths.length, 'strength', 'strengths')}`}
              >
                <Filters spacing="options">
                  {result.strengths.map(option => (
                    <Filter 
                      key={option.rxcui}
                      text={option.strength}
                      active={isSelected(option.rxcui)}
                      onPress={() => toggleItem({
                        name: formatMedicalName(`${option?.name} ${option?.strength}`),
                        id: option.rxcui,
                      })}
                    />
                  ))}
                </Filters>
              </SearchResultCard>
            )}
          />
        </Scroll>
        <StyledBottomBar>
          <Button onPress={handleSubmit}>
            Update
          </Button>
        </StyledBottomBar>
    </FullModal>
  );
}
import { Scroll } from 'src/kit';
import { Button, FullModal, StyledBottomBar } from 'src/web/components';
import { Form, Field } from 'src/forms';
import { CREATE_POLICY, CreatePolicyInput, InsuranceSource, PolicyStatus, UPDATE_POLICIES, useMutation } from 'src/data';
import { ModalComponentProps, ModalType } from 'src/hooks/useModal';
import { DEFAULT_COVERAGE_YEAR, stateCodeOptions, SUPPORTED_COVERAGE_YEARS } from 'src/config';
import toast from 'react-hot-toast';

export type CreatePolicyModalProps = ModalComponentProps<ModalType.CREATE_POLICY>

export const CreatePolicyModal = ({ data, closeModal }: CreatePolicyModalProps) => {
  const [createPolicy, { loading }] = useMutation(CREATE_POLICY, {
    update: (cache, result) => UPDATE_POLICIES(cache, result, data.userId),
    onCompleted: () => {
      toast.success('Policy created');
      closeModal();
    }
  });

  const handleSubmit = (values: CreatePolicyInput) => {
    createPolicy({
      variables: {
        personID: data.userId,
        input: values,
      }
    })
  }

  return (
    <FullModal onRequestClose={closeModal} title="Create Policy">
      <Form onSubmit={handleSubmit} initialValues={{
        coverageYear: DEFAULT_COVERAGE_YEAR,
      }}>
        <Scroll transparent loading={loading}>
          <Field
            required
            name="coverageYear"
            type="dropdown"
            label="Coverage Year"
            options={SUPPORTED_COVERAGE_YEARS.map(year => ({
              label: `${year}`, value: year
            }))}
          />

          <Field
            required
            name="coverageState"
            type="dropdown"
            label="Coverage State"
            options={stateCodeOptions}
          />

          <Field required name="planName" type="text" label="Plan Name" />
          <Field required name="carrier" type="text" label="Carrier Name" />
          <Field required name="startDate" type="date" label="Policy Start Date" />
          <Field required name="endDate" type="date" label="Policy End Date" />
          <Field
            required
            name="insuranceSource"
            type="dropdown"
            label="Source"
            options={Object.values(InsuranceSource).map(value => ({ label: value, value }))}
          />
          <Field
            required
            name="status"
            type="dropdown"
            label="Status"
            options={Object.values(PolicyStatus).map(value => ({ label: value, value }))}
          />
          
        </Scroll>
        <StyledBottomBar>
          <Button >
            Create
          </Button>
        </StyledBottomBar>
      </Form>
    </FullModal>
  );
};

export default CreatePolicyModal;

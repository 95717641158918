import React from 'react';
import {
  useMatches,
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
} from 'kbar';
import { SearchBarResult } from './SearchBarResult';

const actions = [];

const Results = () => {
  const { results } = useMatches();
  return (
    <KBarResults
      style={resultsStyle}
      className="k-results"
      items={results?.filter(item => !item.hide)}
      onRender={({ item, active }) => <SearchBarResult action={item} active={active} />}
    />
  );
};

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <KBarProvider
      actions={actions}
      options={{
        animations: {
          enterMs: 200,
          exitMs: 100,
        },
      }}
    >
      <KBarPortal className="k-portal">
        <KBarPositioner className="k-positioner" style={{ zIndex: 99999999999 }}>
          <KBarAnimator className="k-animator" style={animatorStyle}>
            <KBarSearch className="k-input" style={searchStyle} placeholder="Type a command or search…" />
            <Results />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};


const searchStyle = {
  padding: '12px 16px',

  width: '100%',
  boxSizing: 'border-box',
  outline: 'none',
  border: 'none',
  background: 'var(--cCard2)',
  color: 'var(--fg)',
};

const resultsStyle = {
  maxHeight: 400,
  overflow: 'auto',
};

const animatorStyle = {
  maxWidth: '500px',
  width: '100%',
  overflow: 'hidden',
};

import { ApolloProvider } from '@apollo/client';
import { SelectionProvider, BookmarkProvider, SearchProvider, Toaster } from 'src/util';
import client from 'src/lib/apollo';
import { BrowserRouter } from 'react-router-dom';
import { EditModal } from 'src/components/EditModal';
import { ModalsProvider } from 'src/util/modals';
import { Modals } from 'src/components/modals';
import { AuthProvider } from 'src/auth';

export function AppProvider({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <BookmarkProvider>
            <SelectionProvider>
              <SearchProvider>
                <div id="app-container" style={{ flex: 1, background: 'var(--bg)' }}>
                  {children}
                </div>
              </SearchProvider>
              <Toaster />
              <EditModal />
              <ModalsProvider />
              <Modals />
              <div id="tooltip-root" />
            </SelectionProvider>
          </BookmarkProvider>
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  );
}

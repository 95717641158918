import debug from 'debug';

const BASE = 'catch';
const COLORS = {
  debug: '#1FBD81',
  info: '#4983FF',
  warn: '#FF7878',
  error: '#FF3D3D',
};

class Log {
  constructor(source) {
    this.source = source;
  }

  info(msg, source) {
    this.write(msg, 'info', source);
  }

  debug(msg, source) {
    this.write(msg, 'debug', source);
  }

  warn(msg, source) {
    this.write(msg, 'warn', source);
  }

  /**
   * @NOTE: we keep Sentry in error method
   * to avoid logging too much for now, we can increase breadcrumbs as we go
   */
  error(msg, source) {
    this.write(msg, 'error', source);
  }

  write(msg, level, source = '') {
    const namespace = `${BASE}:${level}:${this.source || source}`;
    const logger = debug(namespace);
    logger.color = COLORS[level];

    /* debugger doesn't work in native so we can still use Logger */

    // pretty print objects
    if (typeof msg === 'object') {
      logger('%o', msg);
    } else {
      logger(msg);
    }
  }
}

const LOGGER = new Log();

// init can be used to set up the logger when an app mounts.  By default all
// messages will get logged.  You can use the shorthand of
// "covered:info,covered:warn" to show just warning/info etc.
export function init() {}

// createLogger let's us continue using the singleton Logger but pin a source
// for a series of log statements.
export function createLogger(source) {
  if (!LOGGER) {
    return {
      debug: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    };
  }

  return {
    debug: msg => LOGGER.debug(msg, source),
    info: msg => LOGGER.info(msg, source),
    warn: msg => LOGGER.warn(msg, source),
    error: (msg, bullets = [], shouldThrow = true) => {
      let message = `${msg}\n`;
      bullets.forEach(bullet => {
        message += `•    ${bullet}\n`;
      });
      LOGGER.error(message, source);
      if (shouldThrow) throw new Error(message);
    },
  };
}

export default LOGGER;

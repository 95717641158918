import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { DebugView } from 'src/web/inspectors';
import { GOAL_NAMES, QUERIES, NAMES, rowFormatters } from 'src/web/utility';

import { TIER_NAMES } from 'src/web/utility/tiers';
import {
  Scroll,
  Header,
  IRow,
  RowGroup,
  IAction,
  RetirementTransferModal,
  WithdrawModal,
  IActionButtons,
} from 'src/web/components';
import { KACTIONS } from 'src/web/kbar';
import { useRegisterActions } from 'kbar';
import { InspectorPaneProps } from '../../types';
import { GET_BANK_ACCOUNT, useFintechQuery } from 'src/data';

export function BankAccountPane({ id, userId }: InspectorPaneProps) {
  const [debug, setDebug] = useState(false);

  const { loading, data } = useFintechQuery(GET_BANK_ACCOUNT, {
    variables: { customerID: userId },
    skip: !userId,
  });

  const bankAccount = data?.customer?.bankAccount;
  const bankApplication = data?.customer?.bankApplication;

  useRegisterActions([
    {
      ...KACTIONS.DEBUG,
      perform: () => setDebug(true),
    },
  ]);

  return (
    <>
      <Scroll inspector loading={loading}>
        <Header title="Bank Account" subtitle="Unit" squareIcon logo={`/img/products/unit.png`}>
          <IRow label="Account Number" type="mono" right value={bankAccount?.accountMask} hideUnset />
        </Header>

        <RowGroup borderless>
          <IActionButtons>
            <IAction status={bankApplication?.status} hide={!bankApplication?.status} />
            <IAction onPress={() => {}} {...KACTIONS.CHANGE_TIER} />
            <IAction onPress={() => setDebug(true)} {...KACTIONS.DEBUG} />
          </IActionButtons>
        </RowGroup>

        <RowGroup title="Balances">
          <IRow
            mono
            right
            type="currency"
            label="Balance (Total)"
            value={bankAccount?.totalBalance}
            helpText="Includes all funds, even if they're currently in transit to Catch"
          />
          <IRow
            mono
            hideUnset
            right
            type="currency"
            label="Available"
            value={bankAccount?.availableBalance}
          />
          <IRow mono hideUnset right type="currency" label="Pending" value={bankAccount?.pendingBalance} />
        </RowGroup>
      </Scroll>

      <DebugView isOpen={debug} data={data} onClose={() => setDebug(false)} />
    </>
  );
}

import React, { useState } from 'react';

import { DebugView } from 'src/web/inspectors';

import { Scroll, Header, IRow, RowGroup, IAction, IActionButtons } from 'src/web/components';
import { KACTIONS } from 'src/web/kbar';
import { useRegisterActions } from 'kbar';
import { InspectorPaneProps } from '../../types';
import { GET_BANK_APPLICATION, useFintechQuery } from 'src/data';
import { LOGOS, NAMES } from 'src/constants';

export function BankApplicationPane({ id, userId }: InspectorPaneProps) {
  const [debug, setDebug] = useState(false);

  const { loading, data } = useFintechQuery(GET_BANK_APPLICATION, {
    variables: { customerID: userId },
    skip: !userId,
  });

  const bankApplication = data?.customer?.bankApplication;

  useRegisterActions([
    {
      ...KACTIONS.DEBUG,
      perform: () => setDebug(true),
    },
  ]);

  return (
    <>
      <Scroll inspector loading={loading}>
        <Header
          squareIcon
          title="Bank Application"
          subtitle={NAMES[bankApplication?.provider]}
          logo={LOGOS[bankApplication?.provider]}
        >
          <IRow
            type="mono"
            right
            label={`${NAMES[bankApplication?.provider]} ID`}
            value={bankApplication?.internalID}
          />
          <IRow type="date" right label="Created At" value={bankApplication?.createdAt} />
          <IRow type="date" right label="Last Updated" value={bankApplication?.approvedOn} />
        </Header>

        <RowGroup borderless>
          <IActionButtons>
            <IAction status={bankApplication?.status} hide={!bankApplication?.status} />
            <IAction onPress={() => setDebug(true)} {...KACTIONS.DEBUG} />
          </IActionButtons>
        </RowGroup>

        <RowGroup title="Application Details" close>
          <IRow right label="Name" value={bankApplication?.name} />
          <IRow right label="Phone #" value={bankApplication?.phoneNumber} />
          <IRow right label="DOB" type="date" value={bankApplication?.dob} />
          <IRow right label="Address" value={bankApplication?.addressId} />
          <IRow right label="SSN" value={bankApplication?.ssn} />
        </RowGroup>
      </Scroll>

      <DebugView isOpen={debug} data={data} onClose={() => setDebug(false)} />
    </>
  );
}

import { createRoot } from 'react-dom/client';
import { init as initLogger, Log, Env } from 'src/_utils';
import { AppProvider } from 'src/providers';
import { App } from 'src/App';
import 'src/web/index.css';

String.prototype.toProperCase = function() {
  return `${this}`?.toLowerCase() === 'identity'
    ? this.charAt(0).toUpperCase() + this.substr(1).toLowerCase()
    : this.toUpperCase()
        .replaceAll('_', ' ')
        .replaceAll(
          /\b(?!(?:KYC|BAR|TBD|CIP|IRA|DDA|DMI|SVI|OEP|SEP|MEC|APTC|CSR|CSRS|EDE|ID|MSCI|ETF|SIPC|FDIC|NONESCMEC|ACC|RF1|RF2|RF3|RF4)\b)\b\w+\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          },
        );
};

String.prototype.toNonTechnical = function() {
  return this.replaceAll(/([A-Z])/g, ' $1').toProperCase();
};

Array.prototype.sortBy = (function() {
  var sorters = {
    string: function(a, b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    },

    number: function(a, b) {
      return a - b;
    },
  };

  return function(prop) {
    var type = !!this[0] ? typeof this[0][prop] || 'string' : 'string';

    return this.sort(function(a, b) {
      return sorters[type](a[prop], b[prop]);
    });
  };
})();

const MOUNT_NODE = document.getElementById('app');

// Logger will log messages in dev and prod or send
// them to Sentry in production
initLogger();
Log.info(Env, 'env');

function setup() {
  const root = createRoot(MOUNT_NODE); // createRoot(container!) if you use TypeScript
  root.render(
    <AppProvider>
      <App />
    </AppProvider>,
  );
}

setup();

import { useState } from 'react';
import { BookmarkContext } from './BookmarkContext';
import { Bookmark, BookmarkType } from './types';

export const BookmarkProvider = ({ children }) => {
  const [bookmarks, setBookmarks] = useState(
    localStorage.bookmarks2 ? JSON.parse(localStorage.bookmarks2) : {},
  );

  const addBookmark = ({ id, type = BookmarkType.User }: Bookmark) => {
    const arr = { ...bookmarks };
    arr[id] = { id, type, lastViewDate: Date.now() };
    setBookmarks(arr);
    localStorage.bookmarks2 = JSON.stringify(arr);
  };

  const removeBookmark = id => {
    const arr = { ...bookmarks };
    delete arr[id];

    setBookmarks(arr);
    localStorage.bookmarks2 = JSON.stringify(arr);
  };

  return (
    <BookmarkContext.Provider value={{ bookmarks, addBookmark, removeBookmark }}>
      {children}
    </BookmarkContext.Provider>
  );
};
import React, { useContext } from 'react';
import { useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';

import { TabLink, Avatar, Redact, Notes } from 'src/web/components';
import { initials, rowFormatters, formatDate, getSourceLogo } from 'src/web/utility';
import { TIER_NAMES } from 'src/web/utility/tiers';
import { useMediaQuery } from 'src/web/hooks';
import { useSelection } from 'src/util/selection';

const _C = {
  PREMIUM_SLASHER: 'var(--c-red-0)',
  HEALTH_LINK: 'var(--c-red-0)',
  HEALTH_APPLICATION: 'var(--c-red-0)',
  TAX: 'var(--c-orange-0)',
  RETIREMENT: 'var(--c-teal-0)',
  SAVINGS: 'var(--c-green-0)',
  ONBOARDING: 'var(--c-cerise-0)',
};

const SNote = styled.div`
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
`;

export function UserNavigation({
  name,
  firstName,
  lastName,
  signupContext,
  joinDate,
  joinCode,
  image,
  notes,
  tier,
  email,
  phone,
}) {
  const mobile = useMediaQuery('(max-width: 1024px)');
  const { sidebarSecondaryOpen } = useSelection();

  const url = useResolvedPath('').pathname;

  const shouldCollapse = !sidebarSecondaryOpen;
  return (
    <div
      className="flex-v"
      style={{
        alignItems: 'stretch',
        width: '100%',
      }}
    >
      <Avatar
        secondaryLogo={getSourceLogo(joinCode)}
        image={image}
        initials={initials({ givenName: firstName, familyName: lastName })}
        size={mobile ? 32 : 60}
        style={{
          marginBottom: 12,
          marginLeft: mobile ? -2 : 0,
          transition: `transform var(--transition)`,
          transform: shouldCollapse && 'translateX(-16px) scale(0.5)',
          transformOrigin: 'left',
        }}
      />

      <div
        className="no-mobile"
        style={{
          height: 160,
          overflow: 'hidden',
        }}
      >
        <Redact hide style={{ marginBottom: 0, minWidth: '75%', maxWidth: '90%' }}>
          <h3
            style={{
              marginTop: 0,
              fontWeight: 600,
              lineHeight: 1.25,
              color: 'var(--c-fg-0)',
            }}
          >
            {name || email || phone}
          </h3>
        </Redact>
        <div
          style={{
            color: 'var(--c-fg-1)',
            fontSize: 'var(--ffp)',

            fontWeight: 300,
          }}
        >
          <div>joined {formatDate({ date: joinDate, length: 'MEDIUM' })}</div>
          <div>
            from <span style={{ fontWeight: 400, color: 'var(--c-fg-0)' }}>{joinCode || 'Direct'}</span>
          </div>
          <div>
            via{' '}
            <span style={{ fontWeight: 400, color: _C[signupContext] }}>
              {signupContext ? rowFormatters.enum(signupContext) : 'Onboarding'}
            </span>
          </div>
          <div>
            <span style={{ fontWeight: 400, color: _C[signupContext] }}>
              {tier ? `${rowFormatters.enum(tier)} (${TIER_NAMES[tier]})` : ' '}
            </span>
          </div>
        </div>
      </div>

      <TabLink secondary exact icon="Home" to={`${url}/home`}>
        Home
      </TabLink>
      <TabLink secondary icon="Crosshair" to={`${url}/conversion`}>
        Conversion
      </TabLink>
      <TabLink secondary icon="Map" to={`${url}/explorer`}>
        HX
      </TabLink>
      <TabLink secondary icon="Coverage" to={`${url}/insurance`}>
        Coverage
      </TabLink>
      <TabLink secondary icon="DocumentShield" to={`${url}/applications`}>
        Applications
      </TabLink>
      <TabLink secondary icon="ShieldTick" to={`${url}/policies`}>
        Policies
      </TabLink>
      <br />
      <TabLink secondary icon="Target" to={`${url}/goals`}>
        Goals
      </TabLink>
      <TabLink secondary icon="Plan" to={`${url}/banking`}>
        Banking
      </TabLink>
      {/* <TabLink secondary icon="Dollar" to={`${url}/income`}>
        Income
      </TabLink> */}
      <TabLink secondary icon="Linked" to={`${url}/linked-accounts`}>
        Linked Accounts
      </TabLink>

      <br />

      <TabLink secondary icon="Clock" to={`${url}/activity`}>
        Activity
      </TabLink>
      <TabLink secondary exact icon="Fingerprint" to={`${url}/identity`}>
        Identity
      </TabLink>

      <TabLink secondary icon="Upload" to={`${url}/uploads`}>
        Uploads
      </TabLink>
      <TabLink secondary icon="Doc" to={`${url}/statements`}>
        Statements
      </TabLink>
      <TabLink secondary icon="Flag" to={`${url}/flags`}>
        Flags
      </TabLink>
      {!!notes && (
        <SNote className="no-mobile">
          <Notes>{notes}</Notes>
        </SNote>
      )}
    </div>
  );
}

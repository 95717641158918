import { Scroll, Button, StyledBottomBar, Header } from 'src/web/components';

export interface ModalContentProps {
  title: string;
  children: React.ReactNode;
  onSubmit?: () => void;
  cta?: string;
  disabled?: boolean;
}

export function ModalContent({ title, onSubmit, cta, disabled, children }: ModalContentProps) {
  return (
    <>
      <Scroll transparent>
        <Header title={title} />
        {children}
      </Scroll>
      {onSubmit && (
        <StyledBottomBar>
          <Button onPress={onSubmit} disabled={disabled}>
            {cta || 'Submit'}
          </Button>
        </StyledBottomBar>
      )}
    </>
  );
}
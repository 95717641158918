import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ADMINS } from 'src/web/data';

export const useAdmins = () => {
  const { data, loading } = useQuery(ADMINS, {
    fetchPolicy: 'cache-first',
  });

  const admins = useMemo(() => {
    const adminsList = data?.admins ? [...data?.admins] : [];

    return adminsList.sort((a, b) => {
      return a.email < b.email ? -1 : 1;
    });
  }, [data]);

  const adminUser = useMemo(() => {
    return data?.viewer;
  }, [data]);

  return {
    loading,
    admins,
    adminUser,
    admin: adminUser?.id,
  };
};
import { Scroll, Button, StyledBottomBar, Banner, FullModal, IRow } from 'src/web/components';
import { Input, InputLabel } from 'src/web/components';
import { SearchItem, useMarketplace } from 'src/hooks/useMarketplace';
import { SearchResults } from './Search';
import { formatMedicalName } from '@catch-co/health-utils';
import { propercase } from 'src/_utils/format';
import { SearchResultCard } from './Search/SearchResultsCard';
import { ProviderFragment } from 'src/data';

export interface ProvidersModalProps{
  isOpen: boolean;
  onRequestClose: () => void;
  providers: Array<ProviderFragment>;
  zip?: string;
  editProviders: (selected: Array<SearchItem>) => void;
}

export function ProvidersModal({ isOpen, onRequestClose, providers, zip, editProviders }: ProvidersModalProps) {
  const { searchTerm, handleSearch, results, searching, selected, addWildcard, toggleItem, removeItem, isSelected } = useMarketplace({
    type: 'providers',
    zip,
    initial: providers
  });
  
  if (!isOpen) return null;

  const handleSubmit = () => {
    editProviders(selected);
    onRequestClose();
  }

  return (
    <FullModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Add Providers"
      subtitle={zip ? `Searching in ${zip}` : ''}
      sidebarItem={
      <>
        {!zip && (<Banner tiny title="Zipcode not set, search may not function as expected" />)}

        {selected.map((item, index) => (
          <IRow 
            label="Provider"
            value={item.name}
            actionType="remove"
            onPress={() => removeItem(item)}
          />
        ))}
      </>
    }>
        <Scroll transparent bottomBar>
          <InputLabel>Search providers</InputLabel>
          <Input placeholder="Search" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
          <br />
          <SearchResults 
            searching={false} 
            results={results} 
            searchTerm={searchTerm}
            addWildcard={addWildcard}
            renderItem={(result) => (
              <SearchResultCard
                active={isSelected(result.npi)}
                title={formatMedicalName(result.name)} 
                subtitle={result.taxonomy}
                detail={`${propercase(result.address?.city)}, ${result?.address?.state}`}
                onPress={() => toggleItem({ name: formatMedicalName(result.name), id: result.npi })}
              />
            )}
          />
        </Scroll>
        <StyledBottomBar>
          <Button onPress={handleSubmit}>
            Update
          </Button>
        </StyledBottomBar>
    </FullModal>
  );
}
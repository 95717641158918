export const COUNTRIES = {
  AFG: {
    iso2CountryCode: 'AF',
    countryName: 'Afghanistan',
    isoNumericCountryCode: '4',
  },
  ALA: {
    iso2CountryCode: 'AX',
    countryName: 'Aland Islands',
    isoNumericCountryCode: '248',
  },
  ALB: {
    iso2CountryCode: 'AL',
    countryName: 'Albania',
    isoNumericCountryCode: '8',
  },
  DZA: {
    iso2CountryCode: 'DZ',
    countryName: 'Algeria',
    isoNumericCountryCode: '12',
  },
  ASM: {
    iso2CountryCode: 'AS',
    countryName: 'American Samoa',
    isoNumericCountryCode: '16',
  },
  AND: {
    iso2CountryCode: 'AD',
    countryName: 'Andorra',
    isoNumericCountryCode: '20',
  },
  AGO: {
    iso2CountryCode: 'AO',
    countryName: 'Angola',
    isoNumericCountryCode: '24',
  },
  AIA: {
    iso2CountryCode: 'AI',
    countryName: 'Anguilla',
    isoNumericCountryCode: '660',
  },
  ATA: {
    iso2CountryCode: 'AQ',
    countryName: 'Antarctica',
    isoNumericCountryCode: '10',
  },
  ATG: {
    iso2CountryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    isoNumericCountryCode: '28',
  },
  ARG: {
    iso2CountryCode: 'AR',
    countryName: 'Argentina',
    isoNumericCountryCode: '32',
  },
  ARM: {
    iso2CountryCode: 'AM',
    countryName: 'Armenia',
    isoNumericCountryCode: '51',
  },
  ABW: {
    iso2CountryCode: 'AW',
    countryName: 'Aruba',
    isoNumericCountryCode: '533',
  },
  AUS: {
    iso2CountryCode: 'AU',
    countryName: 'Australia',
    isoNumericCountryCode: '36',
  },
  AUT: {
    iso2CountryCode: 'AT',
    countryName: 'Austria',
    isoNumericCountryCode: '40',
  },
  AZE: {
    iso2CountryCode: 'AZ',
    countryName: 'Azerbaijan',
    isoNumericCountryCode: '31',
  },
  BHS: {
    iso2CountryCode: 'BS',
    countryName: 'Bahamas',
    isoNumericCountryCode: '44',
  },
  BHR: {
    iso2CountryCode: 'BH',
    countryName: 'Bahrain',
    isoNumericCountryCode: '48',
  },
  BGD: {
    iso2CountryCode: 'BD',
    countryName: 'Bangladesh',
    isoNumericCountryCode: '50',
  },
  BRB: {
    iso2CountryCode: 'BB',
    countryName: 'Barbados',
    isoNumericCountryCode: '52',
  },
  BLR: {
    iso2CountryCode: 'BY',
    countryName: 'Belarus',
    isoNumericCountryCode: '112',
  },
  BEL: {
    iso2CountryCode: 'BE',
    countryName: 'Belgium',
    isoNumericCountryCode: '56',
  },
  BLZ: {
    iso2CountryCode: 'BZ',
    countryName: 'Belize',
    isoNumericCountryCode: '84',
  },
  BEN: {
    iso2CountryCode: 'BJ',
    countryName: 'Benin',
    isoNumericCountryCode: '204',
  },
  BMU: {
    iso2CountryCode: 'BM',
    countryName: 'Bermuda',
    isoNumericCountryCode: '60',
  },
  BTN: {
    iso2CountryCode: 'BT',
    countryName: 'Bhutan',
    isoNumericCountryCode: '64',
  },
  BOL: {
    iso2CountryCode: 'BO',
    countryName: 'Bolivia',
    isoNumericCountryCode: '68',
  },
  BIH: {
    iso2CountryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    isoNumericCountryCode: '70',
  },
  BWA: {
    iso2CountryCode: 'BW',
    countryName: 'Botswana',
    isoNumericCountryCode: '72',
  },
  BVT: {
    iso2CountryCode: 'BV',
    countryName: 'Bouvet Island',
    isoNumericCountryCode: '74',
  },
  BRA: {
    iso2CountryCode: 'BR',
    countryName: 'Brazil',
    isoNumericCountryCode: '76',
  },
  VGB: {
    iso2CountryCode: 'VG',
    countryName: 'British Virgin Islands',
    isoNumericCountryCode: '92',
  },
  IOT: {
    iso2CountryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    isoNumericCountryCode: '86',
  },
  BRN: {
    iso2CountryCode: 'BN',
    countryName: 'Brunei Darussalam',
    isoNumericCountryCode: '96',
  },
  BGR: {
    iso2CountryCode: 'BG',
    countryName: 'Bulgaria',
    isoNumericCountryCode: '100',
  },
  BFA: {
    iso2CountryCode: 'BF',
    countryName: 'Burkina Faso',
    isoNumericCountryCode: '854',
  },
  BDI: {
    iso2CountryCode: 'BI',
    countryName: 'Burundi',
    isoNumericCountryCode: '108',
  },
  KHM: {
    iso2CountryCode: 'KH',
    countryName: 'Cambodia',
    isoNumericCountryCode: '116',
  },
  CMR: {
    iso2CountryCode: 'CM',
    countryName: 'Cameroon',
    isoNumericCountryCode: '120',
  },
  CAN: {
    iso2CountryCode: 'CA',
    countryName: 'Canada',
    isoNumericCountryCode: '124',
  },
  CPV: {
    iso2CountryCode: 'CV',
    countryName: 'Cape Verde',
    isoNumericCountryCode: '132',
  },
  CYM: {
    iso2CountryCode: 'KY',
    countryName: 'Cayman Islands',
    isoNumericCountryCode: '136',
  },
  CAF: {
    iso2CountryCode: 'CF',
    countryName: 'Central African Republic',
    isoNumericCountryCode: '140',
  },
  TCD: {
    iso2CountryCode: 'TD',
    countryName: 'Chad',
    isoNumericCountryCode: '148',
  },
  CHL: {
    iso2CountryCode: 'CL',
    countryName: 'Chile',
    isoNumericCountryCode: '152',
  },
  CHN: {
    iso2CountryCode: 'CN',
    countryName: 'China',
    isoNumericCountryCode: '156',
  },
  HKG: {
    iso2CountryCode: 'HK',
    countryName: 'Hong Kong, Special Administrative Region of China',
    isoNumericCountryCode: '344',
  },
  MAC: {
    iso2CountryCode: 'MO',
    countryName: 'Macao, Special Administrative Region of China',
    isoNumericCountryCode: '446',
  },
  CXR: {
    iso2CountryCode: 'CX',
    countryName: 'Christmas Island',
    isoNumericCountryCode: '162',
  },
  CCK: {
    iso2CountryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    isoNumericCountryCode: '166',
  },
  COL: {
    iso2CountryCode: 'CO',
    countryName: 'Colombia',
    isoNumericCountryCode: '170',
  },
  COM: {
    iso2CountryCode: 'KM',
    countryName: 'Comoros',
    isoNumericCountryCode: '174',
  },
  COG: {
    iso2CountryCode: 'CG',
    countryName: 'Congo (Brazzaville)',
    isoNumericCountryCode: '178',
  },
  COD: {
    iso2CountryCode: 'CD',
    countryName: 'Congo, Democratic Republic of the',
    isoNumericCountryCode: '180',
  },
  COK: {
    iso2CountryCode: 'CK',
    countryName: 'Cook Islands',
    isoNumericCountryCode: '184',
  },
  CRI: {
    iso2CountryCode: 'CR',
    countryName: 'Costa Rica',
    isoNumericCountryCode: '188',
  },
  CIV: {
    iso2CountryCode: 'CI',
    countryName: "Côte d'Ivoire",
    isoNumericCountryCode: '384',
  },
  HRV: {
    iso2CountryCode: 'HR',
    countryName: 'Croatia',
    isoNumericCountryCode: '191',
  },
  CUB: {
    iso2CountryCode: 'CU',
    countryName: 'Cuba',
    isoNumericCountryCode: '192',
  },
  CYP: {
    iso2CountryCode: 'CY',
    countryName: 'Cyprus',
    isoNumericCountryCode: '196',
  },
  CZE: {
    iso2CountryCode: 'CZ',
    countryName: 'Czech Republic',
    isoNumericCountryCode: '203',
  },
  DNK: {
    iso2CountryCode: 'DK',
    countryName: 'Denmark',
    isoNumericCountryCode: '208',
  },
  DJI: {
    iso2CountryCode: 'DJ',
    countryName: 'Djibouti',
    isoNumericCountryCode: '262',
  },
  DMA: {
    iso2CountryCode: 'DM',
    countryName: 'Dominica',
    isoNumericCountryCode: '212',
  },
  DOM: {
    iso2CountryCode: 'DO',
    countryName: 'Dominican Republic',
    isoNumericCountryCode: '214',
  },
  ECU: {
    iso2CountryCode: 'EC',
    countryName: 'Ecuador',
    isoNumericCountryCode: '218',
  },
  EGY: {
    iso2CountryCode: 'EG',
    countryName: 'Egypt',
    isoNumericCountryCode: '818',
  },
  SLV: {
    iso2CountryCode: 'SV',
    countryName: 'El Salvador',
    isoNumericCountryCode: '222',
  },
  GNQ: {
    iso2CountryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    isoNumericCountryCode: '226',
  },
  ERI: {
    iso2CountryCode: 'ER',
    countryName: 'Eritrea',
    isoNumericCountryCode: '232',
  },
  EST: {
    iso2CountryCode: 'EE',
    countryName: 'Estonia',
    isoNumericCountryCode: '233',
  },
  ETH: {
    iso2CountryCode: 'ET',
    countryName: 'Ethiopia',
    isoNumericCountryCode: '231',
  },
  FLK: {
    iso2CountryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
    isoNumericCountryCode: '238',
  },
  FRO: {
    iso2CountryCode: 'FO',
    countryName: 'Faroe Islands',
    isoNumericCountryCode: '234',
  },
  FJI: {
    iso2CountryCode: 'FJ',
    countryName: 'Fiji',
    isoNumericCountryCode: '242',
  },
  FIN: {
    iso2CountryCode: 'FI',
    countryName: 'Finland',
    isoNumericCountryCode: '246',
  },
  FRA: {
    iso2CountryCode: 'FR',
    countryName: 'France',
    isoNumericCountryCode: '250',
  },
  GUF: {
    iso2CountryCode: 'GF',
    countryName: 'French Guiana',
    isoNumericCountryCode: '254',
  },
  PYF: {
    iso2CountryCode: 'PF',
    countryName: 'French Polynesia',
    isoNumericCountryCode: '258',
  },
  ATF: {
    iso2CountryCode: 'TF',
    countryName: 'French Southern Territories',
    isoNumericCountryCode: '260',
  },
  GAB: {
    iso2CountryCode: 'GA',
    countryName: 'Gabon',
    isoNumericCountryCode: '266',
  },
  GMB: {
    iso2CountryCode: 'GM',
    countryName: 'Gambia',
    isoNumericCountryCode: '270',
  },
  GEO: {
    iso2CountryCode: 'GE',
    countryName: 'Georgia',
    isoNumericCountryCode: '268',
  },
  DEU: {
    iso2CountryCode: 'DE',
    countryName: 'Germany',
    isoNumericCountryCode: '276',
  },
  GHA: {
    iso2CountryCode: 'GH',
    countryName: 'Ghana',
    isoNumericCountryCode: '288',
  },
  GIB: {
    iso2CountryCode: 'GI',
    countryName: 'Gibraltar',
    isoNumericCountryCode: '292',
  },
  GRC: {
    iso2CountryCode: 'GR',
    countryName: 'Greece',
    isoNumericCountryCode: '300',
  },
  GRL: {
    iso2CountryCode: 'GL',
    countryName: 'Greenland',
    isoNumericCountryCode: '304',
  },
  GRD: {
    iso2CountryCode: 'GD',
    countryName: 'Grenada',
    isoNumericCountryCode: '308',
  },
  GLP: {
    iso2CountryCode: 'GP',
    countryName: 'Guadeloupe',
    isoNumericCountryCode: '312',
  },
  GUM: {
    iso2CountryCode: 'GU',
    countryName: 'Guam',
    isoNumericCountryCode: '316',
  },
  GTM: {
    iso2CountryCode: 'GT',
    countryName: 'Guatemala',
    isoNumericCountryCode: '320',
  },
  GGY: {
    iso2CountryCode: 'GG',
    countryName: 'Guernsey',
    isoNumericCountryCode: '831',
  },
  GIN: {
    iso2CountryCode: 'GN',
    countryName: 'Guinea',
    isoNumericCountryCode: '324',
  },
  GNB: {
    iso2CountryCode: 'GW',
    countryName: 'Guinea-Bissau',
    isoNumericCountryCode: '624',
  },
  GUY: {
    iso2CountryCode: 'GY',
    countryName: 'Guyana',
    isoNumericCountryCode: '328',
  },
  HTI: {
    iso2CountryCode: 'HT',
    countryName: 'Haiti',
    isoNumericCountryCode: '332',
  },
  HMD: {
    iso2CountryCode: 'HM',
    countryName: 'Heard Island and Mcdonald Islands',
    isoNumericCountryCode: '334',
  },
  VAT: {
    iso2CountryCode: 'VA',
    countryName: 'Holy See (Vatican City State)',
    isoNumericCountryCode: '336',
  },
  HND: {
    iso2CountryCode: 'HN',
    countryName: 'Honduras',
    isoNumericCountryCode: '340',
  },
  HUN: {
    iso2CountryCode: 'HU',
    countryName: 'Hungary',
    isoNumericCountryCode: '348',
  },
  ISL: {
    iso2CountryCode: 'IS',
    countryName: 'Iceland',
    isoNumericCountryCode: '352',
  },
  IND: {
    iso2CountryCode: 'IN',
    countryName: 'India',
    isoNumericCountryCode: '356',
  },
  IDN: {
    iso2CountryCode: 'ID',
    countryName: 'Indonesia',
    isoNumericCountryCode: '360',
  },
  IRN: {
    iso2CountryCode: 'IR',
    countryName: 'Iran, Islamic Republic of',
    isoNumericCountryCode: '364',
  },
  IRQ: {
    iso2CountryCode: 'IQ',
    countryName: 'Iraq',
    isoNumericCountryCode: '368',
  },
  IRL: {
    iso2CountryCode: 'IE',
    countryName: 'Ireland',
    isoNumericCountryCode: '372',
  },
  IMN: {
    iso2CountryCode: 'IM',
    countryName: 'Isle of Man',
    isoNumericCountryCode: '833',
  },
  ISR: {
    iso2CountryCode: 'IL',
    countryName: 'Israel',
    isoNumericCountryCode: '376',
  },
  ITA: {
    iso2CountryCode: 'IT',
    countryName: 'Italy',
    isoNumericCountryCode: '380',
  },
  JAM: {
    iso2CountryCode: 'JM',
    countryName: 'Jamaica',
    isoNumericCountryCode: '388',
  },
  JPN: {
    iso2CountryCode: 'JP',
    countryName: 'Japan',
    isoNumericCountryCode: '392',
  },
  JEY: {
    iso2CountryCode: 'JE',
    countryName: 'Jersey',
    isoNumericCountryCode: '832',
  },
  JOR: {
    iso2CountryCode: 'JO',
    countryName: 'Jordan',
    isoNumericCountryCode: '400',
  },
  KAZ: {
    iso2CountryCode: 'KZ',
    countryName: 'Kazakhstan',
    isoNumericCountryCode: '398',
  },
  KEN: {
    iso2CountryCode: 'KE',
    countryName: 'Kenya',
    isoNumericCountryCode: '404',
  },
  KIR: {
    iso2CountryCode: 'KI',
    countryName: 'Kiribati',
    isoNumericCountryCode: '296',
  },
  PRK: {
    iso2CountryCode: 'KP',
    countryName: "Korea, Democratic People's Republic of",
    isoNumericCountryCode: '408',
  },
  KOR: {
    iso2CountryCode: 'KR',
    countryName: 'Korea, Republic of',
    isoNumericCountryCode: '410',
  },
  KWT: {
    iso2CountryCode: 'KW',
    countryName: 'Kuwait',
    isoNumericCountryCode: '414',
  },
  KGZ: {
    iso2CountryCode: 'KG',
    countryName: 'Kyrgyzstan',
    isoNumericCountryCode: '417',
  },
  LAO: {
    iso2CountryCode: 'LA',
    countryName: 'Lao PDR',
    isoNumericCountryCode: '418',
  },
  LVA: {
    iso2CountryCode: 'LV',
    countryName: 'Latvia',
    isoNumericCountryCode: '428',
  },
  LBN: {
    iso2CountryCode: 'LB',
    countryName: 'Lebanon',
    isoNumericCountryCode: '422',
  },
  LSO: {
    iso2CountryCode: 'LS',
    countryName: 'Lesotho',
    isoNumericCountryCode: '426',
  },
  LBR: {
    iso2CountryCode: 'LR',
    countryName: 'Liberia',
    isoNumericCountryCode: '430',
  },
  LBY: {
    iso2CountryCode: 'LY',
    countryName: 'Libya',
    isoNumericCountryCode: '434',
  },
  LIE: {
    iso2CountryCode: 'LI',
    countryName: 'Liechtenstein',
    isoNumericCountryCode: '438',
  },
  LTU: {
    iso2CountryCode: 'LT',
    countryName: 'Lithuania',
    isoNumericCountryCode: '440',
  },
  LUX: {
    iso2CountryCode: 'LU',
    countryName: 'Luxembourg',
    isoNumericCountryCode: '442',
  },
  MKD: {
    iso2CountryCode: 'MK',
    countryName: 'Macedonia, Republic of',
    isoNumericCountryCode: '807',
  },
  MDG: {
    iso2CountryCode: 'MG',
    countryName: 'Madagascar',
    isoNumericCountryCode: '450',
  },
  MWI: {
    iso2CountryCode: 'MW',
    countryName: 'Malawi',
    isoNumericCountryCode: '454',
  },
  MYS: {
    iso2CountryCode: 'MY',
    countryName: 'Malaysia',
    isoNumericCountryCode: '458',
  },
  MDV: {
    iso2CountryCode: 'MV',
    countryName: 'Maldives',
    isoNumericCountryCode: '462',
  },
  MLI: {
    iso2CountryCode: 'ML',
    countryName: 'Mali',
    isoNumericCountryCode: '466',
  },
  MLT: {
    iso2CountryCode: 'MT',
    countryName: 'Malta',
    isoNumericCountryCode: '470',
  },
  MHL: {
    iso2CountryCode: 'MH',
    countryName: 'Marshall Islands',
    isoNumericCountryCode: '584',
  },
  MTQ: {
    iso2CountryCode: 'MQ',
    countryName: 'Martinique',
    isoNumericCountryCode: '474',
  },
  MRT: {
    iso2CountryCode: 'MR',
    countryName: 'Mauritania',
    isoNumericCountryCode: '478',
  },
  MUS: {
    iso2CountryCode: 'MU',
    countryName: 'Mauritius',
    isoNumericCountryCode: '480',
  },
  MYT: {
    iso2CountryCode: 'YT',
    countryName: 'Mayotte',
    isoNumericCountryCode: '175',
  },
  MEX: {
    iso2CountryCode: 'MX',
    countryName: 'Mexico',
    isoNumericCountryCode: '484',
  },
  FSM: {
    iso2CountryCode: 'FM',
    countryName: 'Micronesia, Federated States of',
    isoNumericCountryCode: '583',
  },
  MDA: {
    iso2CountryCode: 'MD',
    countryName: 'Moldova',
    isoNumericCountryCode: '498',
  },
  MCO: {
    iso2CountryCode: 'MC',
    countryName: 'Monaco',
    isoNumericCountryCode: '492',
  },
  MNG: {
    iso2CountryCode: 'MN',
    countryName: 'Mongolia',
    isoNumericCountryCode: '496',
  },
  MNE: {
    iso2CountryCode: 'ME',
    countryName: 'Montenegro',
    isoNumericCountryCode: '499',
  },
  MSR: {
    iso2CountryCode: 'MS',
    countryName: 'Montserrat',
    isoNumericCountryCode: '500',
  },
  MAR: {
    iso2CountryCode: 'MA',
    countryName: 'Morocco',
    isoNumericCountryCode: '504',
  },
  MOZ: {
    iso2CountryCode: 'MZ',
    countryName: 'Mozambique',
    isoNumericCountryCode: '508',
  },
  MMR: {
    iso2CountryCode: 'MM',
    countryName: 'Myanmar',
    isoNumericCountryCode: '104',
  },
  NAM: {
    iso2CountryCode: 'NA',
    countryName: 'Namibia',
    isoNumericCountryCode: '516',
  },
  NRU: {
    iso2CountryCode: 'NR',
    countryName: 'Nauru',
    isoNumericCountryCode: '520',
  },
  NPL: {
    iso2CountryCode: 'NP',
    countryName: 'Nepal',
    isoNumericCountryCode: '524',
  },
  NLD: {
    iso2CountryCode: 'NL',
    countryName: 'Netherlands',
    isoNumericCountryCode: '528',
  },
  ANT: {
    iso2CountryCode: 'AN',
    countryName: 'Netherlands Antilles',
    isoNumericCountryCode: '530',
  },
  NCL: {
    iso2CountryCode: 'NC',
    countryName: 'New Caledonia',
    isoNumericCountryCode: '540',
  },
  NZL: {
    iso2CountryCode: 'NZ',
    countryName: 'New Zealand',
    isoNumericCountryCode: '554',
  },
  NIC: {
    iso2CountryCode: 'NI',
    countryName: 'Nicaragua',
    isoNumericCountryCode: '558',
  },
  NER: {
    iso2CountryCode: 'NE',
    countryName: 'Niger',
    isoNumericCountryCode: '562',
  },
  NGA: {
    iso2CountryCode: 'NG',
    countryName: 'Nigeria',
    isoNumericCountryCode: '566',
  },
  NIU: {
    iso2CountryCode: 'NU',
    countryName: 'Niue',
    isoNumericCountryCode: '570',
  },
  NFK: {
    iso2CountryCode: 'NF',
    countryName: 'Norfolk Island',
    isoNumericCountryCode: '574',
  },
  MNP: {
    iso2CountryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    isoNumericCountryCode: '580',
  },
  NOR: {
    iso2CountryCode: 'NO',
    countryName: 'Norway',
    isoNumericCountryCode: '578',
  },
  OMN: {
    iso2CountryCode: 'OM',
    countryName: 'Oman',
    isoNumericCountryCode: '512',
  },
  PAK: {
    iso2CountryCode: 'PK',
    countryName: 'Pakistan',
    isoNumericCountryCode: '586',
  },
  PLW: {
    iso2CountryCode: 'PW',
    countryName: 'Palau',
    isoNumericCountryCode: '585',
  },
  PSE: {
    iso2CountryCode: 'PS',
    countryName: 'Palestinian Territory, Occupied',
    isoNumericCountryCode: '275',
  },
  PAN: {
    iso2CountryCode: 'PA',
    countryName: 'Panama',
    isoNumericCountryCode: '591',
  },
  PNG: {
    iso2CountryCode: 'PG',
    countryName: 'Papua New Guinea',
    isoNumericCountryCode: '598',
  },
  PRY: {
    iso2CountryCode: 'PY',
    countryName: 'Paraguay',
    isoNumericCountryCode: '600',
  },
  PER: {
    iso2CountryCode: 'PE',
    countryName: 'Peru',
    isoNumericCountryCode: '604',
  },
  PHL: {
    iso2CountryCode: 'PH',
    countryName: 'Philippines',
    isoNumericCountryCode: '608',
  },
  PCN: {
    iso2CountryCode: 'PN',
    countryName: 'Pitcairn',
    isoNumericCountryCode: '612',
  },
  POL: {
    iso2CountryCode: 'PL',
    countryName: 'Poland',
    isoNumericCountryCode: '616',
  },
  PRT: {
    iso2CountryCode: 'PT',
    countryName: 'Portugal',
    isoNumericCountryCode: '620',
  },
  PRI: {
    iso2CountryCode: 'PR',
    countryName: 'Puerto Rico',
    isoNumericCountryCode: '630',
  },
  QAT: {
    iso2CountryCode: 'QA',
    countryName: 'Qatar',
    isoNumericCountryCode: '634',
  },
  REU: {
    iso2CountryCode: 'RE',
    countryName: 'Réunion',
    isoNumericCountryCode: '638',
  },
  ROU: {
    iso2CountryCode: 'RO',
    countryName: 'Romania',
    isoNumericCountryCode: '642',
  },
  RUS: {
    iso2CountryCode: 'RU',
    countryName: 'Russian Federation',
    isoNumericCountryCode: '643',
  },
  RWA: {
    iso2CountryCode: 'RW',
    countryName: 'Rwanda',
    isoNumericCountryCode: '646',
  },
  BLM: {
    iso2CountryCode: 'BL',
    countryName: 'Saint-Barthélemy',
    isoNumericCountryCode: '652',
  },
  SHN: {
    iso2CountryCode: 'SH',
    countryName: 'Saint Helena',
    isoNumericCountryCode: '654',
  },
  KNA: {
    iso2CountryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    isoNumericCountryCode: '659',
  },
  LCA: {
    iso2CountryCode: 'LC',
    countryName: 'Saint Lucia',
    isoNumericCountryCode: '662',
  },
  MAF: {
    iso2CountryCode: 'MF',
    countryName: 'Saint-Martin (French part)',
    isoNumericCountryCode: '663',
  },
  SPM: {
    iso2CountryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    isoNumericCountryCode: '666',
  },
  VCT: {
    iso2CountryCode: 'VC',
    countryName: 'Saint Vincent and Grenadines',
    isoNumericCountryCode: '670',
  },
  WSM: {
    iso2CountryCode: 'WS',
    countryName: 'Samoa',
    isoNumericCountryCode: '882',
  },
  SMR: {
    iso2CountryCode: 'SM',
    countryName: 'San Marino',
    isoNumericCountryCode: '674',
  },
  STP: {
    iso2CountryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    isoNumericCountryCode: '678',
  },
  SAU: {
    iso2CountryCode: 'SA',
    countryName: 'Saudi Arabia',
    isoNumericCountryCode: '682',
  },
  SEN: {
    iso2CountryCode: 'SN',
    countryName: 'Senegal',
    isoNumericCountryCode: '686',
  },
  SRB: {
    iso2CountryCode: 'RS',
    countryName: 'Serbia',
    isoNumericCountryCode: '688',
  },
  SYC: {
    iso2CountryCode: 'SC',
    countryName: 'Seychelles',
    isoNumericCountryCode: '690',
  },
  SLE: {
    iso2CountryCode: 'SL',
    countryName: 'Sierra Leone',
    isoNumericCountryCode: '694',
  },
  SGP: {
    iso2CountryCode: 'SG',
    countryName: 'Singapore',
    isoNumericCountryCode: '702',
  },
  SVK: {
    iso2CountryCode: 'SK',
    countryName: 'Slovakia',
    isoNumericCountryCode: '703',
  },
  SVN: {
    iso2CountryCode: 'SI',
    countryName: 'Slovenia',
    isoNumericCountryCode: '705',
  },
  SLB: {
    iso2CountryCode: 'SB',
    countryName: 'Solomon Islands',
    isoNumericCountryCode: '90',
  },
  SOM: {
    iso2CountryCode: 'SO',
    countryName: 'Somalia',
    isoNumericCountryCode: '706',
  },
  ZAF: {
    iso2CountryCode: 'ZA',
    countryName: 'South Africa',
    isoNumericCountryCode: '710',
  },
  SGS: {
    iso2CountryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    isoNumericCountryCode: '239',
  },
  SSD: {
    iso2CountryCode: 'SS',
    countryName: 'South Sudan',
    isoNumericCountryCode: '728',
  },
  ESP: {
    iso2CountryCode: 'ES',
    countryName: 'Spain',
    isoNumericCountryCode: '724',
  },
  LKA: {
    iso2CountryCode: 'LK',
    countryName: 'Sri Lanka',
    isoNumericCountryCode: '144',
  },
  SDN: {
    iso2CountryCode: 'SD',
    countryName: 'Sudan',
    isoNumericCountryCode: '736',
  },
  SUR: {
    iso2CountryCode: 'SR',
    countryName: 'Suriname *',
    isoNumericCountryCode: '740',
  },
  SJM: {
    iso2CountryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen Islands',
    isoNumericCountryCode: '744',
  },
  SWZ: {
    iso2CountryCode: 'SZ',
    countryName: 'Swaziland',
    isoNumericCountryCode: '748',
  },
  SWE: {
    iso2CountryCode: 'SE',
    countryName: 'Sweden',
    isoNumericCountryCode: '752',
  },
  CHE: {
    iso2CountryCode: 'CH',
    countryName: 'Switzerland',
    isoNumericCountryCode: '756',
  },
  SYR: {
    iso2CountryCode: 'SY',
    countryName: 'Syrian Arab Republic (Syria)',
    isoNumericCountryCode: '760',
  },
  TWN: {
    iso2CountryCode: 'TW',
    countryName: 'Taiwan, Republic of China',
    isoNumericCountryCode: '158',
  },
  TJK: {
    iso2CountryCode: 'TJ',
    countryName: 'Tajikistan',
    isoNumericCountryCode: '762',
  },
  TZA: {
    iso2CountryCode: 'TZ',
    countryName: 'Tanzania *, United Republic of',
    isoNumericCountryCode: '834',
  },
  THA: {
    iso2CountryCode: 'TH',
    countryName: 'Thailand',
    isoNumericCountryCode: '764',
  },
  TLS: {
    iso2CountryCode: 'TL',
    countryName: 'Timor-Leste',
    isoNumericCountryCode: '626',
  },
  TGO: {
    iso2CountryCode: 'TG',
    countryName: 'Togo',
    isoNumericCountryCode: '768',
  },
  TKL: {
    iso2CountryCode: 'TK',
    countryName: 'Tokelau',
    isoNumericCountryCode: '772',
  },
  TON: {
    iso2CountryCode: 'TO',
    countryName: 'Tonga',
    isoNumericCountryCode: '776',
  },
  TTO: {
    iso2CountryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    isoNumericCountryCode: '780',
  },
  TUN: {
    iso2CountryCode: 'TN',
    countryName: 'Tunisia',
    isoNumericCountryCode: '788',
  },
  TUR: {
    iso2CountryCode: 'TR',
    countryName: 'Turkey',
    isoNumericCountryCode: '792',
  },
  TKM: {
    iso2CountryCode: 'TM',
    countryName: 'Turkmenistan',
    isoNumericCountryCode: '795',
  },
  TCA: {
    iso2CountryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    isoNumericCountryCode: '796',
  },
  TUV: {
    iso2CountryCode: 'TV',
    countryName: 'Tuvalu',
    isoNumericCountryCode: '798',
  },
  UGA: {
    iso2CountryCode: 'UG',
    countryName: 'Uganda',
    isoNumericCountryCode: '800',
  },
  UKR: {
    iso2CountryCode: 'UA',
    countryName: 'Ukraine',
    isoNumericCountryCode: '804',
  },
  ARE: {
    iso2CountryCode: 'AE',
    countryName: 'United Arab Emirates',
    isoNumericCountryCode: '784',
  },
  GBR: {
    iso2CountryCode: 'GB',
    countryName: 'United Kingdom',
    isoNumericCountryCode: '826',
  },
  USA: {
    iso2CountryCode: 'US',
    countryName: 'United States of America',
    isoNumericCountryCode: '840',
  },
  UMI: {
    iso2CountryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    isoNumericCountryCode: '581',
  },
  URY: {
    iso2CountryCode: 'UY',
    countryName: 'Uruguay',
    isoNumericCountryCode: '858',
  },
  UZB: {
    iso2CountryCode: 'UZ',
    countryName: 'Uzbekistan',
    isoNumericCountryCode: '860',
  },
  VUT: {
    iso2CountryCode: 'VU',
    countryName: 'Vanuatu',
    isoNumericCountryCode: '548',
  },
  VEN: {
    iso2CountryCode: 'VE',
    countryName: 'Venezuela (Bolivarian Republic of)',
    isoNumericCountryCode: '862',
  },
  VNM: {
    iso2CountryCode: 'VN',
    countryName: 'Viet Nam',
    isoNumericCountryCode: '704',
  },
  VIR: {
    iso2CountryCode: 'VI',
    countryName: 'Virgin Islands, US',
    isoNumericCountryCode: '850',
  },
  WLF: {
    iso2CountryCode: 'WF',
    countryName: 'Wallis and Futuna Islands',
    isoNumericCountryCode: '876',
  },
  ESH: {
    iso2CountryCode: 'EH',
    countryName: 'Western Sahara',
    isoNumericCountryCode: '732',
  },
  YEM: {
    iso2CountryCode: 'YE',
    countryName: 'Yemen',
    isoNumericCountryCode: '887',
  },
  ZMB: {
    iso2CountryCode: 'ZM',
    countryName: 'Zambia',
    isoNumericCountryCode: '894',
  },
  ZWE: {
    iso2CountryCode: 'ZW',
    countryName: 'Zimbabwe',
    isoNumericCountryCode: '716',
  },
};

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

import { SelectedType } from 'src/hooks/useInspector';
import { InspectorPane } from './types';
import {
  UploadPane,
  EventPane,
  BankLinkPane,
  BankApplicationPane,
  HealthPolicyPane,
  HealthAppPane,
  HealthLinkPane,
  TaxPaymentPane,
  IncomeSourcePane,
  GoalPane,
  BankAccountPane,
  TaxPaymentTaskPane,
  IdReviewPane,
  EDEPane,
  ConversionPane,
} from './panes';

export const panesConfig: Record<SelectedType, InspectorPane> = {
  [SelectedType.UPLOAD]: UploadPane,
  [SelectedType.BANK_LINK]: BankLinkPane,
  [SelectedType.EVENT]: EventPane,
  [SelectedType.POLICY]: HealthPolicyPane,
  [SelectedType.APP]: HealthAppPane,
  [SelectedType.LINK]: HealthLinkPane,
  [SelectedType.TAX_PAYMENT]: TaxPaymentPane,
  [SelectedType.TAX_PAYMENT_TASK]: TaxPaymentTaskPane,
  [SelectedType.BANK_ACCOUNT]: BankAccountPane,
  [SelectedType.BANK_APPLICATION]: BankApplicationPane,
  [SelectedType.GOAL]: GoalPane,
  [SelectedType.INCOME_SOURCE]: IncomeSourcePane,
  [SelectedType.ID_REVIEW]: IdReviewPane,
  [SelectedType.EDE]: EDEPane,
  [SelectedType.CONVERSION]: ConversionPane,
};

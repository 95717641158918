import React from 'react';
import styled from 'styled-components';
import I from './I';

export const Input = styled.input`
  appearance: none;
  border: 1px solid transparent;
  border-radius: var(--radiusLarge);
  padding: calc(0.5 * var(--padding));
  font-size: var(--p);
  color: var(--c-fg-0);
  background: var(--c-border-light);
  &:focus {
    box-shadow: 0 0 0.5px 1.5px var(--c-fg-1) !important;
    background: var(--c-bg-0) !important;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    right: calc(0.5 * var(--padding));
    height: 100%;
  }
`;


const SSelect = styled.select`
  appearance: none;
  border: 1px solid transparent;
  border-radius: var(--radiusLarge);
  padding: calc(0.5 * var(--padding));
  font-size: var(--p);
  color: ${({ value }) => !!value ? 'var(--c-fg-0)' : 'var(--c-fg-1)'};
  background: var(--c-border-light);
  width: 100%;

  &:focus {
    box-shadow: 0 0 0.5px 1.5px var(--c-fg-1) !important;
    background: var(--c-bg-0);
  }
`;


export const Select = ({ options, placeholder = '', ...props }) => {
  return (
    <SelectContainer>
      <SSelect {...props}>
        <option value="">{placeholder || 'Select'}</option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </SSelect>
      <I
        name="Down"
        size={24}
        className="b-size-collapse"
      />
    </SelectContainer>
  );
}


export const SInputLabel = styled.div`
  font-size: var(--fp);
  font-weight: 500;
  color: var(--c-fg-1);
  padding-bottom: 4px;
  padding-top: 16px;
`;

export const InputLabel = ({ children, required, ...rest }) => {
  return (
    <SInputLabel {...rest}>
      {children}{' '}
      {required && <span style={{ color: 'var(--c-bad-0)', opacity: 0.5, fontWeight: 400 }}>required</span>}
    </SInputLabel>
  );
};
import React, { useState, useEffect } from 'react';
import FlatList from 'flatlist-react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { useSearchQuery } from 'src/web/hooks';
import {
  Scroll,
  Button,
  TaskRow,
  AppPage,
  Filter,
  Filters,
  MenuItem,
  SecondaryToolbar,
  ModalContent,
} from 'src/web/components';
import {
  FILTER_GROUPS,
  decodeOption,
  encode,
  createFilterQuery,
  aggregateFilterGroups,
  makeLabels,
  makeName,
  getSourceLogo,
  formatDate,
} from 'src/web/utility';
import { useModal } from 'src/util/modals';
import { CreateUserModalContent } from 'src/components/users/CreateUserModalContent';

const NAME = gql`
  query UserListQuery($input: SearchPersonReferenceRequest!) {
    searchPersonReference(input: $input) {
      pageInfo {
        hasNextPage
        currentPageNumber
        pageSize
        totalItems
      }
      edges {
        id
        personReference {
          id
          workType
          healthApplicantEmail
          healthApplicantGivenName
          healthApplicantFamilyName
          givenName
          familyName
          phoneNumber
          email
          avatarImage
          signupCode
          signupContext
          isDeleted
        }
      }
    }
  }
`;

export function UserListView() {
  var filters = [];
  const url = useResolvedPath('').pathname;

  const { openModal } = useModal();

  const query = useSearchQuery();
  const navigate = useNavigate();
  const [draftSearch, setDraftSearch] = useState(query.get('search'));
  const [search, setSearch] = useState(query.get('search'));

  for (var pair of query.entries()) {
    if (pair[0] !== 'search') filters.push({ group: encode(pair[0]), value: encode(pair[1]) });
  }

  const addFilter = (group, value) => {
    filters = filters.filter((item, idx) => item.group !== encode(group));
    filters.push({ group: encode(group), value: encode(value) });
    setSearchTerm(search, filters);
  };
  const removeFilter = (group, value) => {
    filters = filters.filter((item, idx) => item.group !== encode(group) || item.value !== encode(value));
    setSearchTerm(search, filters);
  };
  const goToUser = id => {
    navigate(`${url}/${id}`);
  };
  const setSearchTerm = (searchTerm, filterSet) => {
    navigate(`${url}?${createFilterQuery([{ group: 'search', value: searchTerm }, ...filters])}`);
  };
  const isActiveFilter = (group, value) => {
    return (
      filters.filter((item, idx) => {
        return item.group === encode(group) && item.value === encode(value);
      }).length > 0
    );
  };
  const activeFilterForGroup = group => {
    const hello = filters.filter((item, idx) => {
      return item.group === encode(group);
    })?.[0];
    return encode(hello?.value);
  };

  useEffect(() => {
    if (!!query && search !== query.get('search')) {
      setSearch(query.get('search'));
      setDraftSearch(query.get('search'));
    }
  }, [query, search]);

  const groupedFilters = aggregateFilterGroups(filters);
  const { data, loading, error, fetchMore } = useQuery(NAME, {
    variables: {
      id: null,
      input: {
        genericQuery: !!search && search !== '' ? search?.replaceAll(' ', '')?.toLowerCase() : null,
        userAccountStatuses: groupedFilters['ACCOUNT_STATUS'],
        kycStatuses: groupedFilters['KYC_STATUS'],
        cipStatuses: groupedFilters['CIP_STATUS'],
        workTypes: groupedFilters['WORK_TYPE'],
        healthApplicationStatuses: groupedFilters['HEALTH_APPLICATION'],
        healthPolicyStatuses: groupedFilters['HEALTH_POLICY'],
        signupContexts: groupedFilters['SIGNUP_CONTEXT'],
        pagination: {
          pageNumber: 1,
          pageSize: 50,
        },
      },
    },
  });
  const results = data?.searchPersonReference?.edges;

  return (
    <>
      <AppPage
        noPanel
        icon="Search"
        title={`Users`}
        subtitle={data?.searchPersonReference?.pageInfo?.totalItems}
        pageActions={[
          {
            keyCombo: 'C+U',
            icon: 'UserPlus',
            tooltip: 'Create User',
            onPress: () => openModal(<CreateUserModalContent />),
            loading: false,
          },
        ]}
        main={() => (
          <>
            <SecondaryToolbar>
              <Filters>
                {Object.keys(FILTER_GROUPS).map((key, gidx) => (
                  <Filter
                    key={key}
                    menuPlacement="bottomLeft"
                    text={decodeOption(activeFilterForGroup(key)) || key}
                    active={!!activeFilterForGroup(key)}
                    menuContent={FILTER_GROUPS[key].map((item, idx) => (
                      <MenuItem
                        statusText={isActiveFilter(key, item) ? 'credit' : 'debit'}
                        key={item}
                        color={isActiveFilter(key, item) ? 'text' : 'subtle'}
                        text={isActiveFilter(key, item) ? '•    ' + decodeOption(item) : decodeOption(item)}
                        onPress={() =>
                          isActiveFilter(key, item) ? removeFilter(key, item) : addFilter(key, item)
                        }
                      />
                    ))}
                  />
                ))}
              </Filters>
            </SecondaryToolbar>
            <Scroll toolbar inspector>
              {!!error || !results || results?.length === 0 ? (
                <div className="full light faded">{!!loading ? 'Loading...' : 'No results'}</div>
              ) : (
                <FlatList
                  renderWhenEmpty={() => <></>}
                  list={results}
                  renderItem={(person, idx) => {
                    const p = {
                      ...person?.personReference,
                      ...person?.person,
                      __typename: 'PersonReference',
                    };
                    return (
                      <TaskRow
                        large
                        negset={24}
                        defaultName={p?.phoneNumber || 'No Name'}
                        showAvatar
                        onPress={() => goToUser(person?.id)}
                        labels={makeLabels(p)}
                        user={p}
                        key={person?.id}
                        detail={p?.email}
                        status={p?.isLocked ? 'LOCKED' : p?.userAccountStatus || p?.savingsKYC}
                        rightIcon={getSourceLogo(p?.signupCode)}
                        name={makeName(p)}
                        page={formatDate({ data: p.createdOn })}
                      />
                    );
                  }}
                />
              )}
              {!!data?.searchPersonReference?.pageInfo?.hasNextPage && (
                <Button
                  alt
                  small
                  type="subtle"
                  color="adminAccent"
                  background="adminAccentLight"
                  disabled={!!loading}
                  onPress={() =>
                    fetchMore({
                      variables: {
                        input: {
                          genericQuery: !!search && search !== '' ? search.toLowerCase() : null,
                          userAccountStatuses: groupedFilters['ACCOUNT_STATUS'],
                          kycStatuses: groupedFilters['KYC_STATUS'],
                          workTypes: groupedFilters['WORK_TYPE'],
                          healthApplicationStatuses: groupedFilters['HEALTH_APPLICATION'],
                          healthPolicyStatuses: groupedFilters['HEALTH_POLICY'],
                          pagination: {
                            pageNumber: data?.searchPersonReference?.pageInfo?.currentPageNumber + 1,
                            pageSize: 50,
                          },
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return Object.assign(data.searchPersonReference, prev, {
                          edges: [
                            ...prev.searchPersonReference.edges,
                            ...fetchMoreResult.searchPersonReference.edges,
                          ],
                          pageInfo: {
                            ...fetchMoreResult.searchPersonReference.pageInfo,
                          },
                        });
                      },
                    })
                  }
                >
                  {!!loading ? 'Loading...' : 'Load More'}
                </Button>
              )}
            </Scroll>
          </>
        )}
      />
    </>
  );
}

import Sentry from './Sentry';

const SENTRY_ADMIN_DSN = 'https://544bcd6dd99e47f6850faab2612b93d8@o219279.ingest.sentry.io/5368713';

export function initSentry() {
  Sentry.init({ dsn: SENTRY_ADMIN_DSN });
}

export function logSentryError(exception, extra) {
  try {
    Sentry.configureScope(scope => {
      if (extra && typeof extra === 'object') {
        Object.keys(extra).forEach(key => {
          scope.setExtra(key, extra[key]);
        });
      }
      Sentry.captureException(exception);
    });
  } catch (e) {}
}

// tags, extra, contexts, user, level, fingerprint
export function logGQLError(err, operation) {
  try {
    // Add scoped report details and send to Sentry
    Sentry.configureScope(scope => {
      // // Annotate whether failing operation was query/mutation/subscription
      scope.setTag('kind', operation.operationName);
      scope.setTag('client', 'admin');
      scope.setTransactionName(operation.operationName);

      // Log query and variables as extras
      scope.setExtra('variables', operation.variables);

      const apiErrorCategory = err.message?.match(/(rpc|gQL)(?=\s+error:)/g)?.[0];
      const apiErrorCode = err.message?.match(/(?:code = )(.+)(?= desc =)/g)?.[0]?.replace('code = ', '');
      const apiErrorMessage = err.message
        ?.match(/(?:desc = )((\w| )+)(?=(\n|\r|))/g)?.[0]
        ?.replace('desc = ', '');

      if (err.path) {
        // We can also add the path as breadcrumb
        scope.addBreadcrumb({
          category: 'query-path',
          message: err.path.join('.'),
        });
      }

      if (apiErrorCategory) {
        scope.setTag('api.category', apiErrorCategory);
        scope.setTag('api.code', apiErrorCode);
        scope.setTag('api.message', apiErrorMessage);
      }

      scope.setExtra('fullMessage', err.message);
      Sentry.captureMessage(apiErrorMessage || err.message);
    });
  } catch (e) {}
}

export function setSentryUser(user) {
  Sentry.configureScope(scope => {
    scope.setUser(user);
  });
}

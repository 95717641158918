import React from 'react';
import styled from 'styled-components';

export const SBanner = styled.div`
  padding: ${p => (p.tiny || p.compact ? 8 : 16)}px ${p => (p.tiny ? 12 : 24)}px;
  color: ${p => p.color};
  background: ${p => p.background};
  border-radius: ${p => (p.tiny ? 'var(--radius)' : 'var(--radiusLarge)')};
  transition: 0.5s var(--transitionCurve);
  cursor: ${p => (p?.hasAction ? 'var(--cursor)' : 'default')};
  font-weight: 400;
  z-index: 99999999999999999999;
  backdrop-filter: blur(25px);
  box-shadow: ${p => (p.tiny || p.compact ? 'none' : 'var(--box-shadow)')};
  margin: ${p => (p.inset ? '12px' : '0px')};
  div.title {
    font-size: ${p => (p.tiny ? 'var(--ffp)' : p.compact ? 'var(--p)' : 'var(--h5)')};
    color: ${p => p.color};
    margin: 0;
    animation: ${p => (p.pulse ? 'pulse 2s infinite alternate' : '')};
    font-weight: 500;
  }
  div.subtitle {
    font-size: ${p => (p.tiny ? 'var(--ffp)' : p.compact ? 'var(--fp)' : 'var(--fp)')};
    color: var(--c-fg-1);
  }
`;

export const Banner = ({
  title,
  subtitle,
  inset,
  color = 'var(--c-fg-0)',
  background = 'var(--c-medium-1)',
  pulse,
  onPress,
  compact,
  tiny,
  ...rest
}) => {
  return (
    <SBanner
      className="fadeInSubtle"
      inset={inset}
      color={color}
      background={background}
      pulse={pulse}
      hasAction={!!onPress}
      onClick={onPress}
      compact={compact}
      tiny={tiny}
      {...rest}
    >
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </SBanner>
  );
};

export default Banner;

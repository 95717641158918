import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useHotkeys } from 'react-hotkeys-hook';
import { SecondaryToolbar, IconType, ToolbarButton } from 'src/web/components';
import { useMediaQuery } from 'src/web/hooks';
import { useSelection } from 'src/util/selection';
import { InspectorPane } from '../types';
import { useInspector } from 'src/hooks/useInspector';
import { panesConfig } from '../config';
import { EmptyPane } from '../panes';

const StyledInspector = styled.div`
  box-sizing: border-box;
  right: 0px;
  position: absolute;
  top: ${p => (p.fullHeight ? '0px' : 'var(--navHeight)')};
  border-left: 1px solid var(--c-border);
  background: var(--c-menu);
  backdrop-filter: blur(40px) saturate(400%);
  width: var(--inspector);
  bottom: 0px;
  z-index: 99999;
  box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  @media (prefers-color-scheme: dark) {
    backdrop-filter: blur(60px) saturate(200%);
    box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 1024px) {
    box-shadow: ${p => (p.selection ? '0 0 50px -5px rgba(0, 0, 0, 0.25)' : '')};
    z-index: 99999999999999999;
    position: fixed;
    top: 0;
    transform: translateX(${p => (p.selection ? '0px' : 'var(--inspector)')});
  }
`;

const StyledInner = styled.div`
  position: absolute;
  right: 0;
  top: var(--navHeight);
  left: 0;
  bottom: 0;
  overflow: scroll;
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface TabDefinition {
  icon: IconType;
  title: string;
  Pane?: InspectorPane;
}

export interface BaseInspectorProps {
  userId: string;
  selectedPane?: InspectorPane;
  universalTabs: Array<{
    icon: IconType;
    title: string;
    Pane: InspectorPane;
  }>;
  fullHeight?: boolean;
}

export function BaseInspector({ userId, selectedPane, universalTabs, fullHeight }: BaseInspectorProps) {
  const { navbarOpen, inspectorClosed, setInspectorClosed } = useSelection();
  const { selectedId, selectedType, currentTab, setCurrentTab } = useInspector();

  const matches = useMediaQuery('(max-width: 1024px)');

  const { tabs, tabKeys } = useMemo(() => {
    const tabs: Array<TabDefinition> = [{ title: 'Selected', icon: 'Info', Pane: undefined }, ...universalTabs];
    const tabKeys = Array.from({ length: tabs.length }, (_, i) => i + 1).join(', ');
    return { tabs, tabKeys };
  }, [selectedPane, universalTabs])

  useHotkeys(tabKeys, ({ key }) => setCurrentTab(parseInt(key)), {});

  return (
    <StyledInspector selection={!!selectedId && !navbarOpen && !inspectorClosed} fullHeight={fullHeight}>
      <SecondaryToolbar transparent center={!matches}>
        <ToolbarButton
          icon="Close"
          tooltip="Close"
          onPress={() => setInspectorClosed(true)}
          className="mobile-only"
        />

        {tabs.map((tab, index) => (
          <ToolbarButton
            key={tab.title}
            icon={tab.icon}
            onPress={() => setCurrentTab(index + 1)}
            active={index + 1 === currentTab}
            tooltip={tab.title}
            keyCombo={`${index + 1}`}
            disabled={!userId}
          />
        ))}
      </SecondaryToolbar>
      <StyledInner>
        <StyledContent>
          {tabs.map((tab, index) => {
            if (index + 1 !== currentTab) return null;

            // dynamic logic for specifying the pane
            const Pane = tab.Pane || panesConfig[selectedType] || selectedPane || EmptyPane;
            return <Pane key={`${tab.title}-pane`} userId={userId} id={selectedId} />;
          })}
        </StyledContent>
      </StyledInner>
    </StyledInspector>
  );
}
import { Auth0Provider } from '@auth0/auth0-react';
import { env } from 'src/global';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
}

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { makeName, makeLabels, isActiveNow, getSourceLogo } from 'src/web/utility';
import { TaskRow } from 'src/web/components';
import { makeLabelIcons } from 'src/web/utility/tasks';
import { SelectedType } from 'src/hooks/useInspector';

export const HRow = ({ item, selected, setSelected, standalone }) => {
  const [live, setLive] = useState(isActiveNow(item?.lastUserUpdate));

  React.useEffect(() => {
    const handle = setInterval(
      () => setLive(isActiveNow(item?.lastUserUpdate), [item?.lastUserUpdate]),
      60000,
    );
    return () => clearInterval(handle);
  }, []);

  const route = useMemo(() => item?.lastUsedRoute?.replaceAll('-', ' ')?.toProperCase(), [
    item?.lastUsedRoute,
  ]);
  const labels = useMemo(() => makeLabels(item, { small: true }), [item]);
  const icons = useMemo(() => makeLabelIcons(item), [item]);
  const logo = useMemo(() => getSourceLogo(item?.signupCode), [item?.signupCode]);
  const name = useMemo(() => makeName({ givenName: item?.givenName, familyName: item?.familyName }), [
    item?.givenName,
  ]);

  return (
    <TaskRow
      key={item?.id}
      id={item?.id}
      small
      user={{
        id: item?.ownerID,
        avatarImage: item?.avatarImage,
        givenName: item?.givenName,
        familyName: item?.familyName,
      }}
      date={item?.lastUserUpdate}
      showOwner
      owner={item?.task?.assignee}
      live={live}
      showAvatar
      standalone={standalone}
      placeholder={item?.placeholder}
      page={route}
      labels={labels}
      labelIcons={icons}
      rightIcon={logo}
      defaultName={item?.email || item?.phoneNumber}
      selected={selected}
      stuck={item?.applicationStatus === 'ENROLLMENT_STUCK'}
      needsManualAction={item?.idProofResult === 'PENDING_MANUAL_REVIEW'}
      name={name}
      onPress={() => {
        setSelected(item?.id, {
          type: SelectedType.EDE,
          uid: item?.ownerID,
        });
      }}
    />
  );
};

export const Carousel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  padding: 0 0;
  padding-left: 24px;
  padding-right: calc(24px + var(--inspector));
  @media (max-width: 1024px) {
    padding-right: 24px;
  }
  bottom: 0;
  background: var(--c-bg-gray);
  @media (prefers-color-scheme: dark) {
    background: var(--c-bg-0);
  }
  .kanban-col-wrap {
    height: 100%;
    position: relative;
    width: 340px;
    margin-right: 12px;
    padding: 0px 0 0 0;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
    .row-group-header {
      margin-left: 16px;
    }
  }
  .kanban-col {
    position: absolute;
    overflow-x: visible;
    width: 100%;
    padding: 0px 0 0px 0;
    top: 48px;
    bottom: 0;
    overflow-y: scroll;
  }
  .kanban-status {
    width: 340px;
    height: 48px;
  }
`;

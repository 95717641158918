import { useParams } from 'react-router-dom';
import { NetworkStatus,  } from '@apollo/client';
import { AppPage, Scroll,  } from 'src/web/components';
import { Explorer } from 'src/web/components/health/Explorer';
import { usePublicMutation, usePublicQuery } from 'src/data/hooks';
import { PUBLIC_EXPLORER, UPSERT_PUBLIC_EXPLORER, UPSERT_PUBLIC_EXPLORER_MEMBERS } from 'src/data';

export function ExplorerSessionPage() {
  const { explorerId } = useParams();

  const { loading, data, refetch, networkStatus } = usePublicQuery(PUBLIC_EXPLORER, {
    variables: { healthID: explorerId },
    skip: !explorerId,
    notifyOnNetworkStatusChange: true,
  });

  const [upsertExplorer, { loading: upserting}] = usePublicMutation(UPSERT_PUBLIC_EXPLORER);
  const [upsertMembers, { loading: upsertingMembers}] = usePublicMutation(UPSERT_PUBLIC_EXPLORER_MEMBERS);

  const explorer = data?.getPublicHealthExplorerData;

  const handleUpdate = (data) => {
    upsertExplorer({
      variables: {
        input: {
          id: explorerId,
          zipcode: explorer?.zipcode,
          countyfips: explorer?.countyfips,
          state: explorer?.state,
          coverageYear: explorer?.coverageYear,
          ...data
        }
      }
    })
  }

  const handleMembers = (members) => {
    upsertMembers({ variables: { members, explorerID: explorerId }})
  }

  const handleRemove = (id) => {
    const filteredMembers = explorer?.deps?.filter(m => m.id !== id);

    handleUpdate({
      dependents: filteredMembers
    });
  }

  return (
    <AppPage 
      title={`PX ${explorerId}`}
      noPanel
      main={() => (
        <Scroll loading={loading || upserting || upsertingMembers}>
          <Explorer
            loading={loading || upserting} 
            refetch={refetch}
            refetching={networkStatus === NetworkStatus.refetch}
            explorerType="PX" 
            explorer={explorer}
            pref={{
              financialPreference: explorer?.financialPreference,
              plannedUsage: explorer?.plannedUsage,
              providersWanted: explorer?.providersWanted,
              drugsWanted: explorer?.drugsWanted,
            }}
            upsertExplorer={handleUpdate}
            upsertMembers={handleMembers}
            upsertHealthPreferences={handleUpdate}
            removeMember={handleRemove}
          />
        </Scroll>
      )}
    />
  );
};

import React from 'react';
import styled from 'styled-components';

import { Menu, Avatar, I, IconType } from '..';
import { initials } from 'src/web/utility';
import { useMediaQuery } from 'src/web/hooks';

const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  border-radius: 99px;
  height: 28px;
  padding: 1px 10px;
  margin-right: 2px;
  box-sizing: border-box;
  cursor: var(--cursor);
  flex-shrink: 0;
  background: ${p => (p.active ? 'var(--fg)' : 'var(--c-bg-1)')};
  font-weight: 500;
  font-size: var(--ffp);
  color: ${p => (p.active ? 'var(--bg)' : 'var(--fg)')};
  user-select: none;
  line-height: 1;
  box-shadow: ${p => (p.active ? 'var(--box-shadow)' : 'none')};
  * {
    cursor: var(--cursor);
    line-height: 1;
  }
  &:active, &:hover, &.ant-popover-open {
    opacity: 1;
    background: ${p => (p.active ? 'var(--fg)' : 'var(--c-border-light)')} !important;
  }
  @media (max-width: 1024px) {
    padding: 1px 6px;
    margin-right: 6px;
  }
`;

export const Filters = styled.div<{ spacing?: 'options' }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;

  ${p => p.spacing === 'options' ? `
    padding-top: 12px;
    flex-wrap: wrap;

    .filter {
      margin-top: 4px;
    }
  ` : `
    padding-left: 24px;
    margin-right: 24px;
  `}
`;

export const Title = styled.div`
  font-size: var(--ffp);
  color: var(--c-fg-1);
  font-weight: 500;
  margin: 6px 0;
  padding-left: 16px;
`;

export interface FilterProps {
  title?: string;
  text: string; // the display string
  icon?: IconType;
  menuContent: React.ReactNode;
  active?: boolean;
}

export default ({
  icon,
  text,
  title,
  menuContent,
  active,
  menuPlacement = 'bottomLeft',
  person,
  disabled,
  noDismiss,
  fillIcon,
  onPress,
}: FilterProps) => {
  const mobile = useMediaQuery('(max-width: 1024px)');
  return (
    <Menu
      content={
        <>
          {!!title && <Title>{title}</Title>}
          {menuContent}
        </>
      }
      placement={menuPlacement}
      noDismiss={noDismiss}
    >
      <StyledFilter active={active} icon={!!icon || !!person} disabled={disabled} onClick={onPress} className="filter">
        {!!person ? (
          <Avatar
            image={person?.avatarImage}
            initials={initials(person)}
            size={20}
            style={{
              backgroundColor: '#FFFFFF11',
              borderRadius: 999,
              flexShrink: 0,
              margin: 0,
              marginRight: !!text ? 8 : -6,
              marginLeft: -6,
              marginTop: -1.5,
            }}
          />
        ) : (
          !!icon && (
            <I
              name={icon}
              color={active ? 'var(--bg)' : 'var(--fg)'}
              square
              containLg={!fillIcon}
              noShadow
              style={{
                flexShrink: 0,
                marginRight: mobile ? 0 : !!text ? 2 : -4,
                marginLeft: mobile ? 0 : -4,
              }}
              size={18}
            />
          )
        )}
        <span>{text}</span>
      </StyledFilter>
    </Menu>
  );
};

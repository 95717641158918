import { ConversionStatusEnum } from 'src/data';

/**
 * Show support for these years in the admin facing UI
 * They will be shown in addition to any years the user has a status for
 */
export const POSSIBLE_CONVERSION_YEARS = [2025, 2024];
export const DEFAULT_CONVERSION_YEAR = 2024;

const groups: Array<Array<ConversionStatusEnum>> = [
  [ConversionStatusEnum.ExplorerStarted, ConversionStatusEnum.PlansChosen],
  [ConversionStatusEnum.StateDiy, ConversionStatusEnum.BrokerAdded, ConversionStatusEnum.Handoff],
  [ConversionStatusEnum.AppStarted, ConversionStatusEnum.AppSubmitted, ConversionStatusEnum.AppEnrolled],
  [
    ConversionStatusEnum.Effectuated,
    ConversionStatusEnum.Terminated,
    ConversionStatusEnum.Canceled,
    ConversionStatusEnum.GracePeriodDelinquency,
  ],
];

const deprecated: Array<ConversionStatusEnum> = [
  ConversionStatusEnum.AccountCreated,
  ConversionStatusEnum.OeReady,
  ConversionStatusEnum.PriorPlanYearRenewal,
  ConversionStatusEnum.IntakeRequested,
  ConversionStatusEnum.IntakeScheduled,
  ConversionStatusEnum.IntakeCompleted,
  ConversionStatusEnum.PlansSent,
  ConversionStatusEnum.Closed,
];

const flattenedGroups = [...deprecated, ...groups.flat(Infinity)];

const notInGroup = Object.values(ConversionStatusEnum).reduce((ungrouped, status) => {
  return flattenedGroups.includes(status) ? ungrouped : [...ungrouped, status];
}, []);

export const conversionStatusGroups = notInGroup.length === 0 ? groups : [notInGroup, ...groups];

export const conversionStatusOrder = Object.values(ConversionStatusEnum).sort((a, b) => {
  const indexA = flattenedGroups.indexOf(a);
  const indexB = flattenedGroups.indexOf(b);
  return indexA > indexB ? 1 : -1;
});

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import I from './I';
import { Spinner } from './Spinner';

const StyledRowGroupInner = styled.div`
  overflow: hidden;
  padding: 0 var(--padding) ${p => (p.open ? 24 : 0)}px;
  border-radius: ${p => (p.rounded && !p.compact ? 'var(--radius)' : 0)}px;
  max-height: ${p => (p.open ? 'none' : 0)}px;
  box-sizing: border-box;
  transition: var(--transition);
  will-change: max-height, padding;
`;

const StyledRowGroup = styled.div<{ $loading: boolean }>`
  ${p => (p.$loading ? 0.3 : 1)};
  border-top: 1px solid ${p => (p.borderless ? 'transparent' : 'var(--c-border-light)')};
  margin: 0 calc(-1 * var(--padding));
  &:first-child {
    border: none;
  }
`;

const StyledDiv = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRowGroupHeader = styled.div`
  min-height: var(--navHeightSm);
  padding: 0 var(--padding);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  top: 0;
  font-size: calc(var(--ffp) - 1px);
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: uppercase;
  cursor: default;
  z-index: 99999;
  color: ${p => (p.subtle ? 'var(--c-fg-2)' : 'var(--c-fg-0)')};
  .b-size-collapse {
    transition: transform var(--transition);
    transform: translateY(0px) translateX(-6px) rotate(${p => (p.open ? 90 : 0)}deg);
  }
  &:hover {
    background: var(--fga);
    color: ${p => (p.subtle ? 'var(--c-fg-1)' : 'var(--c-fg-0)')};
  }
`;

export interface RowGroupProps {
  loading?: boolean;
  title?: string | number;
  subtitle?: string;
  children: React.ReactNode;
  accessory?: React.ReactNode;
  subtle?: boolean;
  close?: boolean;
  borderless?: boolean;
}

export function RowGroup({
  loading,
  title,
  subtitle,
  children,
  accessory,
  subtle,
  close,
  borderless,
}: RowGroupProps) {
  const body = useRef(null);
  const [open, setOpen] = useState(!close);
  return (
    <StyledRowGroup borderless={borderless} className="row-group">
      {(!!title || !!accessory) && (
        <StyledRowGroupHeader
          className="row-group-header"
          subtle={subtle}
          open={open}
          onClick={() => setOpen(!open)}
        >
          {!!title && (
            <StyledDiv>
              <div>
                <div style={{ lineHeight: 1 }}>{title}</div>
                {!!subtitle && (
                  <div style={{ marginTop: 4, fontWeight: 500, opacity: 0.5, lineHeight: 1 }}>{subtitle}</div>
                )}
              </div>
              <I
                name="Caret"
                size={24}
                className="b-size-collapse"
                color={subtle ? 'var(--c-fg-2)' : 'var(--c-fg-1)'}
              />
            </StyledDiv>
          )}
          <div>{accessory}</div>
        </StyledRowGroupHeader>
      )}

      <StyledRowGroupInner open={open} height={body?.current?.clientHeight}>
        <div ref={body}>{children}</div>
      </StyledRowGroupInner>
    </StyledRowGroup>
  );
}

import { gql } from '../generated';

gql(`
  fragment BankAccount on BankAccount {
    id
    accountMask
    availableBalance
    pendingBalance
    totalBalance
  }
`);

gql(`
  fragment BankApplication on BankApplication {
    id
    status
    provider
  }
`);

gql(`
  fragment FullBankApplication on BankApplication {
    id
    addressId
    customerId
    dob
    email
    name
    phoneNumber
    provider
    ssn
    status
  }
`);

export const GET_BANK_ACCOUNT = gql(`
  query GetBankAccount($customerID: ID!) {
    customer(id: $customerID) {
      id
      bankAccount {
        ...BankAccount
      }
      bankApplication {
        ...BankApplication
      }
    }
  }
`);

export const GET_BANK_APPLICATION = gql(`
  query GetBankApplication($customerID: ID!) {
    customer(id: $customerID) {
      id
      bankApplication {
        ...FullBankApplication
      }
    }
  }
`);

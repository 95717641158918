import styled from 'styled-components';
import { useField } from 'react-final-form';
import { Input, Select } from 'src/web/components';
import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { COUNTIES } from 'src/data';

const Container = styled.div`
  width: 100%;
  > *:not(:last-child) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const pathways = {
  STATE_EXCHANGE: 'State Exchange',
  EDE: 'FFM'
}

export function ZipcodeInput(props) {
  const pathwayType = useField('pathwayType');
  const fips = useField('countyfips');
  const state = useField('state');

  const validZip = props.value.length === 5;

  const { loading, data } = useQuery(COUNTIES, {
    variables: { zipcode: props.value },
    skip: !validZip,
  });

  const { counties, countiesByFips, countiesByName, matchMultiple, county, options } = useMemo(() => {
    const ffmStates = data?.reference?.health?.ffmStates || [];

    const counties = (data?.reference.counties || [])?.map((c, i) => ({
      ...c,
      pathway: !!ffmStates?.find((s) => s.code === c.state) ? 'EDE' : 'STATE_EXCHANGE'
    }));

    const countiesByFips = counties.reduce((acc, county, i) => {
      return { ...acc, [county.fips]: county };
    }, {});

    const countiesByName = counties.reduce((acc, county, i) => {
      return { ...acc, [county.name]: county };
    }, {});

    return {
      counties,
      countiesByFips,
      countiesByName,
      matchMultiple: counties?.length > 1,
      hasMatches: counties?.length > 0,
      county: counties?.length === 1 && validZip ? counties[0] : undefined,
      options: counties.map(county => ({ label: county.name, value: county.fips }))
    };
  }, [data]);

  // handles setting the county when there is only one
  useEffect(() => {
    if (county) {
      handleChange(county?.fips);
    }
  }, [county]);


  const handleChange = (value) => {
    const county = countiesByFips[value];

    fips.input.onChange(county.fips);
    state.input.onChange(county.state);
    pathwayType.input.onChange(county.pathway);
  } 


  return (
    <Container>
      <Input {...props} />
      {loading ? 
        <div>Loading...</div> : 
        matchMultiple ? 
        <Select placeholder="Select county" options={options} value={fips.input.value} onChange={e => handleChange(e.target.value)} /> :
        county ? 
        <div>{county?.name}, {county?.state} ({pathways[county.pathway]})</div> 
        : null
      }
    </Container>
  );
}
import styled from 'styled-components';

export type TextSize = 'title' | 'subtitle';

export interface TextProps {
  size?: TextSize;
  children: React.ReactNode;
}

const styles: Record<TextSize, { size: string, color: string, weight: number }> = {
  title: {
    size: 'var(--p)',
    weight: 500,
    color: 'var(--c-fg-0)',
  },
  subtitle: {
    size: 'var(--fp)',
    weight: 400,
    color: 'var(--c-fg-1)',
  },
}


const StyledText = styled.div<{ size?: TextSize }>`
  flex: 1;
  max-width: ${p => (p.small ? '200px' : '250px')};
  white-space: nowrap;
  mask-image: linear-gradient(to right, black ${p => (p.small ? '135px' : '225px')}, #00000000 100%);


  ${p => p.size && `
    font-size: ${styles[p.size].size};
    font-weight: ${styles[p.size].weight};
    color: ${styles[p.size].color};
  `}

`;


export function Text({ size, children }: TextProps) {
  return <StyledText size={size}>{children}</StyledText>;
}
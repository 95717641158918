import { create } from 'zustand';

export interface ModalState {
  isOpen: boolean;
  content: React.ReactNode;
  openModal: (content: React.ReactNode) => void;
  closeModal: () => void;
}

export const useModal = create<ModalState>((set, get) => ({
  isOpen: false,
  content: null,

  // opens a modal
  openModal: content => {
    set({ isOpen: true, content });
  },

  // closes the current modal
  closeModal: () => {
    set({ isOpen: false, content: null });
  },
}));

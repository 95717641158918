import React from 'react';
import styled from 'styled-components';

export default styled.div`
  backdrop-filter: ${p => p.blur && 'blur(15px)'};
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  > div {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { create } from 'zustand';

export enum SelectedType {
  UPLOAD = 'upload',
  BANK_LINK = 'bank_link',
  BANK_APPLICATION = 'bank_application',
  BANK_ACCOUNT = 'bank_account',
  EVENT = 'event',
  POLICY = 'policy',
  APP = 'app',
  LINK = 'link',
  TAX_PAYMENT = 'tax_payment',
  TAX_PAYMENT_TASK = 'tax_payment_task',
  GOAL = 'goal',
  INCOME_SOURCE = 'source',
  ID_REVIEW = 'id_review',
  EDE = 'ede',
  CONVERSION = 'conversion',
}

interface Options {
  type: SelectedType;
  uid?: string;
}

export interface TabState {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
}

export const useTabs = create<TabState>(set => ({
  currentTab: 1,
  setCurrentTab: currentTab => set({ currentTab }),
}));

export function useInspector() {
  const { currentTab, setCurrentTab } = useTabs();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedId = searchParams.get('id');
  const userId = searchParams.get('uid');
  const selectedType = searchParams.get('type');

  const setSelected = (id: string, options: Options) => {
    const payload = { id, type: options.type };
    if (options.uid) payload['uid'] = options.uid;

    const params = createSearchParams(payload);
    setSearchParams(params);
    setCurrentTab(1);
  };

  return {
    currentTab,
    setCurrentTab,
    selectedId,
    selectedType,
    setSelected,
  };
}

import { gql } from '../generated';

gql(`
  fragment ActivityEvent on ActivityEvent {
    id
    eventType
    label
    metadata
    createdOn
    subject {
        id
        givenName
        familyName
        avatarImage
      }
  }
`);

export const GET_EVENT = gql(`
  query Event($id: ID!) {
    event(id: $id) {
      ...ActivityEvent
      triggeredBy {
        id
        givenName
        familyName
        avatarImage
      }
      subject {
        id
        givenName
        familyName
        avatarImage
      }
    }
  }
`);

export const GET_USER_EVENTS = gql(`
  query UserEvents($userId: ID!) {
    person(id: $userId) {
      id
      events {
        ...ActivityEvent
      }
    }
  }
`);

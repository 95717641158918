const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const DEFAULT_URL = `https://s.catch.co/img/sources/default.png`;

export { copyToClipboard };

export {
  GOAL_NAMES,
  GOAL_FOR_FEE,
  exclusions,
  toPercent,
  toDate,
  toObjectsName,
  toStatus,
  TAX_GOAL,
  PTO_GOAL,
  RETIREMENT_GOAL,
  HEALTH_SAVINGS_GOAL,
  EMERGENCY_SAVINGS_GOAL,
  FAMILY_LEAVE_GOAL,
  QUERIES,
  NAMES,
  IRA,
  DDA,
  formatter,
  fieldTypes,
  portfolioName,
  goalIcon,
  goalStatusColor,
  goalRingColor,
  sortActiveBenefits,
  goalColor,
} from './goals';
export {
  groupBy,
  formatPercentage,
  formatDate,
  formatDateOnly,
  formatRelativeDate,
  formatCurrency,
  getBankLogo,
  initials,
  rowFormatters,
  makeCSV,
  makeName,
  formatRelativeDatePlain,
  formatRelativeDateStrict,
  userSummary,
  externalLinks,
  isActiveNow,
  makeSSN,
  getSourceName,
  enrollmentName,
  getSourceLogo,
  makeAddress,
  taxPaymentEntity,
} from './general';

export {
  getDeductible,
  makeHealthCSV,
  FFM_STATES,
  OSCAR_STATES,
  LICENSES,
  STATE_EXCHANGES,
  HOLDER_EMAILS,
  getSEP,
  makeHealthStatus,
  IDP_ENUM,
  parterHealthStatus,
  makeHealthStatusSet,
  OSCAR_ACTION_STATUS,
  Q_MAIN_VARS,
  Q_MAIN_CONFIG,
  PAGINATION,
  FETCH_CONFIG,
  EDE_ROUTES,
  PHASES,
  EDE_ROUTE_APP_START,
  EDE_ROUTE_APP_START_IDX,
  EDE_ROUTES_STARTING,
  EDE_ROUTES_APPLYING,
  EDE_ROUTES_RENEW,
  PARTNER_ROUTES,
  IDP_ENUM_NEW,
} from './health';
export { FEATURES, cleanName, featureLogo } from './paid';
export { cleanTaskName, taskIcon, makeLabels, tasks, isSOEP, isARP } from './tasks';

export {
  FILTER_GROUPS,
  decode,
  decodeOption,
  encode,
  createFilterQuery,
  aggregateFilterGroups,
} from './filters';
export { COUNTRIES, STATES } from './geo';

export const dupla = (arr = []) => [...(arr || [])];
export const duplo = (obj = {}) => ({ ...(obj || {}) });

export const ROLL_COPY = {
  ALWAYS: 'Autoroll',
  SOMETIMES: 'Ask',
  NEVER: 'Ignore',
};

import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { publicClient } from 'src/lib/apollo';
import { Filter, Filters, IRow, MenuDivider, MenuItem, Scroll, SecondaryToolbar } from 'src/web/components';
import { PARTNERS } from 'src/web/data';
import { useContent } from 'src/web/hooks';
import ExplorerModal from 'src/web/components/modal/ExplorerModal';

export const cleanSource = source => source?.replace(' ', '')?.toLowerCase();

enum AuthType {
  REGISTER = 'Create Account',
  LOGIN = 'Login',
}

enum FlowType {
  BASIC = 'Basic',
  CONCIERGE_WITH_INTAKE = 'Intake + Concierge',
  FASTLANE = 'Fastlane',
  DIRECT_SCHEDULING = 'Premium Direct Schedule',
}


const PX = gql`
  query PublicHealthSession($healthID: ID!) {
    getPublicHealthExplorerData(id: $healthID) {
      id
      email
      zipcode
      countyfips
      coverageYear
      state
      income
      providerPlanID
      pathwayType
      email
      financialPreference
      plannedUsage
      drugsWanted {
        rxcui
        name
      }
      providersWanted {
        npi
        name
      }
      dependents {
        age
        relation
        sex
        isSmoker
      }
      marketplaceEligibility {
        aptc
        csr
        hardshipExemption
        isMedicaidChip
      }
    }
  }
`


const LinkGeneratorsPage = ({}) => {
  // tracks 
  const [auth, setAuth] = useState<AuthType>(AuthType.REGISTER);
  const [code, setCode] = useState('');
  const [flow, setFlow] = useState<FlowType>(FlowType.BASIC);
  const [healthID, setHealthID] = useState('');

  // external requests
  const { data } = useContent(PARTNERS);

  const xplr = useQuery(PX, {
    variables: { healthID },
    skip: !healthID,
    client: publicClient,
  })

  const explorer = xplr?.data?.getPublicHealthExplorerData || {};

  // for explorer modal
  const [isOpen, setOpen] = useState(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  // pull partner data for current code
  const partner = useMemo(() => {
    return data?.partners?.find(p => p.slug === code);
  }, [code, data])

  const link = useMemo(() => {
    const base = 'https://app.catch.co/auth/';
    const path = auth === AuthType.REGISTER ? 'sign-up' : 'sign-in';

    const params = new URLSearchParams('');
    if (code) params.append('r', code);
    if (healthID) params.append('hid', healthID);

    if (flow === FlowType.CONCIERGE_WITH_INTAKE) {
      params.append('direct', 'benefits_advisor');
    }

      if (flow === FlowType.FASTLANE) {
      params.append('direct', 'fastlane');
    }

    if (flow === FlowType.DIRECT_SCHEDULING) {
      params.append('direct', 'benefits_advisor');
      params.append('sk', 'true');
    }

    const append = params.size > 0 ? `?${params.toString()}` : '';
    return `${base}${path}${append}`;
  }, [code, auth, flow, healthID]);


  return (
    <>
      <SecondaryToolbar>
        <Filters>
          <Filter
            icon="User"
            title="Auth Type"
            text={auth}
            menuContent={Object.values(AuthType).map(val => (
              <MenuItem key={val} text={val} onPress={() => setAuth(val)} />
            ))}
          />
          <Filter
            title="Flow Type"
            icon="Map"
            text={flow}
            menuContent={Object.values(FlowType).map(val => (
              <MenuItem key={val} text={val} onPress={() => setFlow(val)} />
            ))}
          />
          <Filter
            title="Partner"
            icon="App"
            text={partner?.name || 'Partner'}
            menuContent={
              <>
                <MenuItem
                  text="None"
                  onPress={() => {
                    setCode('');
                  }}
                />
                <MenuDivider />
                {data?.partners?.map(k => (
                  <MenuItem
                    fillIcon
                    icon={k?.logo?.url}
                    key={k}
                    text={k.name}
                    onPress={() => {
                      setCode(k.slug);
                    }}
                  />
                ))}
              </>
            }
          />
          <Filter
            title="Explorer"
            icon="Health"
            text={healthID || 'No PX'}
            menuContent={
              <>
                <MenuItem
                  text="None"
                  onPress={() => {
                    setHealthID('');
                  }}
                />
                <MenuDivider />
                <MenuItem
                  text="Add Health ID"
                  onPress={open}
                />
              </>
            }
          />
        </Filters>
      </SecondaryToolbar>
      <Scroll toolbar>
        <IRow noMax label="Generated Link" type="mono" value={link} />
        <MenuDivider invert />
        <IRow label="Pathway" value={explorer.pathwayType} />
        <IRow type="state" label="State" value={explorer.state} />
        <IRow label="Zip" value={explorer.zipcode} />
        <IRow type="currency" label="Income" value={explorer.income} />
        <IRow 
          label="Members" 
          value={explorer?.dependents?.reduce((acc, m) => `${acc ? `${acc}, ` : ''} ${m.age}${m.sex[0]}`, '')}
        />
        <IRow label="Selected Plan" value={explorer.providerPlanID} />
      </Scroll>
    
      {isOpen && (
        <ExplorerModal 
          healthID={healthID}
          onRequestClose={close}
          setHealthID={setHealthID}
        />
      )}
    </>
  );
};

export default LinkGeneratorsPage;

import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { TASKS } from 'src/web/data';
import { makeLabels, makeName, taskIcon } from 'src/web/utility';
import { TaskRow, Carousel, Scroll, Toolbar, RowGroup } from 'src/web/components';
import { useAdmins } from 'src/web/hooks';

const MyTasksPage = ({ selectedId, setSelected, inInspector }) => {
  const { adminUser } = useAdmins();

  const { data } = useQuery(TASKS, {
    skip: !adminUser?.id,
    variables: {
      input: {
        assignee: adminUser?.id,
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
      },
    },
  });

  const _tasks = data?.tasks?.edges;

  const BUCKETS = [
    { name: 'Todo', items: _tasks?.filter((el, i) => el?.status === 'TODO') },
    {
      name: 'In Progress',
      status: 'MIDDLE',
      items: _tasks?.filter((el, i) => el?.status === 'IN_PROGRESS' || el?.status === 'PENDING'),
    },
    { name: 'Complete', items: _tasks?.filter((el, i) => el?.status === 'COMPLETE') },
    { name: 'Canceled', items: _tasks?.filter((el, i) => el?.status === 'CANCELED') },
  ];

  return (
    <>
      <Toolbar title="My Tasks" overInspector icon="Inbox" />
      <Scroll large>
        <Carousel>
          {BUCKETS?.map((bucket, idx) => (
            <div className="kanban-col-wrap" key={idx}>
              <RowGroup title={bucket?.name} borderless>
                <div className="kanban-col">
                  {bucket?.items?.map((item, idx) => (
                    <TaskRow
                      compact={inInspector}
                      small
                      index={idx}
                      showAvatar
                      standalone
                      key={item.id}
                      date={item?.lastUpdated}
                      inlineDate
                      owner={item?.assignee}
                      name={item?.type === 'CUSTOM' ? item?.notes : makeName(item?.user)}
                      defaultName={item?.user?.email}
                      selected={item?.id === selectedId}
                      onPress={() => setSelected(item?.id, { user: item?.user?.id })}
                      rightIcon={taskIcon(item?.type)}
                      amount={item?.savingsAccount?.balance}
                      user={item?.user}
                      status={item?.status}
                      priority={item?.priority}
                      labels={makeLabels(item?.enrollment || item, { small: true })}
                    />
                  ))}
                </div>
              </RowGroup>
            </div>
          ))}
        </Carousel>
      </Scroll>
    </>
  );
};

export default MyTasksPage;

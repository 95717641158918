import { ActivityEventFragment } from "src/data";
import { EventRow } from "./EventRow";

export interface EventFeedProps {
  events: Array<ActivityEventFragment>;
}

export function EventFeed({ events }) {
  
  if (!events) {
    return <div className="full">Loading...</div>
  }

  if (events.length === 0) {
    return <div className="full">No events</div>
  }

  return (
    <div>
      {events.map(event => <EventRow key={event.id} event={event} /> )}
    </div>
  );
}
import React from 'react';
import styled from 'styled-components';

export const STATUS_COLOR_MAP = {
  green: 'var(--c-good-0)',
  greenLight: 'var(--c-good-1)',

  red: 'var(--c-bad-0)',
  redLight: 'var(--c-bad-1)',

  yellow: 'var(--c-medium-0)',
  yellowLight: 'var(--c-medium-1)',

  orange: 'var(--c-orange-0)',
  orangeLight: 'var(--c-orange-1)',

  cerise: 'var(--c-done-0)',
  ceriseLight: 'var(--c-done-1)',

  gray: 'var(--c-fg-1)',
  grayLight: 'var(--c-border)',

  light: 'var(--c-border)',
  lightLight: 'transparent',
};

const TYPES = {
  INFO: {
    color: 'cerise',
  },
  GOOD: {
    color: 'green',
  },
  MID: {
    color: 'yellow',
  },
  BAD: {
    color: 'red',
  },
  DRAFT: {
    color: 'gray',
  },
  LIGHT: {
    color: 'light',
  },
};

export const STATUS_RENAME = {
  CounterpartyInsufficientFunds: 'Pre R01',
  InsufficientFunds: 'B01',
  PENDING_OFFLINE_VERIFICATION: 'Needs Experian Call',
  PENDING_MANUAL_REVIEW: 'Needs Manual IDP',
  INITIAL: 'None',
  DailyACHCreditLimitExceeded: 'Daily Limit',
  DailyACHDebitLimitExceeded: 'Daily Limit',
  MonthlyACHCreditLimitExceeded: 'Monthly Limit',
  MonthlyACHDebitLimitExceeded: 'Monthly Limit',
};

export const getStatusTextColor = status => {
  if (!status) return 'var(--c-fg-1)';
  const c =
    COLORS[status?.replaceAll(/\_/g, ' ')?.toLowerCase()]?.color || COLORS[status?.toLowerCase()]?.color;
  return STATUS_COLOR_MAP[c?.color] || 'var(--c-fg-1)';
};

export const COLORS = {
  match: TYPES.GOOD,
  'bar pending': TYPES.LIGHT,
  'owner match': TYPES.GOOD,
  mismatch: TYPES.BAD,
  'owner mismatch': TYPES.BAD,
  'owner unverified': TYPES.ID_DRAFT,
  bad: TYPES.BAD,
  created: TYPES.MID,
  error: TYPES.BAD,
  active: TYPES.GOOD,
  completed: TYPES.GOOD,
  good: TYPES.GOOD,
  paused: TYPES.MID,
  pending: TYPES.MID,
  pending_documents: TYPES.MID,
  login_error: TYPES.MID,
  auto: TYPES.INFO,
  'login error': TYPES.MID,
  connected: TYPES.GOOD,
  locked: TYPES.BAD,
  not_locked: TYPES.GOOD,
  canceled: TYPES.BAD,
  cancelled: TYPES.BAD,
  'action needed': TYPES.BAD,
  'complete enrolled': TYPES.GOOD,
  action_needed: TYPES.BAD,
  ACTION_NEEDED: TYPES.BAD,
  failed: TYPES.BAD,
  user_pending: TYPES.MID,
  waiting: TYPES.MID,
  approved: TYPES.GOOD,
  settled: TYPES.GOOD,
  complete: TYPES.GOOD,
  skipped: TYPES.DRAFT,
  not_income: TYPES.LIGHT,
  expired: TYPES.DRAFT,
  vital: TYPES.BAD,
  important: TYPES.MID,
  consider: TYPES.GOOD,
  none: TYPES.DRAFT,
  draft: TYPES.DRAFT,
  prefill: TYPES.DRAFT,
  inactive: TYPES.DRAFT,
  unverified: TYPES.DRAFT,
  not_enrolled: TYPES.BAD,
  goal_met: TYPES.GOOD,
  kyc_good: TYPES.GOOD,
  kyc_pending: TYPES.MID,
  kyc_review: TYPES.MID,
  kyc_denied: TYPES.BAD,
  cip_good: TYPES.GOOD,
  cip_pending: TYPES.MID,
  cip_review: TYPES.MID,
  cip_denied: TYPES.BAD,
  tbd: TYPES.DRAFT,
  processing: TYPES.MID,
  expiring: TYPES.MID,
  delinquent: TYPES.BAD,
  autopilot: TYPES.INFO,
  not_covered: TYPES.BAD,
  nc: TYPES.BAD,
  always: TYPES.GOOD,
  never: TYPES.BAD,
  terminated: TYPES.BAD,
  sometimes: TYPES.MID,
  bronze: { color: '#AF926A' },
  silver: { color: 'gray' },
  gold: { color: 'plusColor' },
  platinum: { color: 'cerise' },
  'Expanded Bronze': { color: '#AF926A' },
  catastrophic: { color: 'red' },
  primary: TYPES.INFO,
  submitted: TYPES.GOOD,
  screening: TYPES.DRAFT,
  'id proofing': TYPES.DRAFT,
  in_progress: TYPES.MID,
  initiated: TYPES.BAD,
  deleted: TYPES.BAD,
  not_implemented: TYPES.BAD,
  enrollment_stuck: TYPES.BAD,
  pending_enrollment: TYPES.MID,
  import_pending: TYPES.MID,
  pending_import: TYPES.MID,
  active_pending_payment: TYPES.MID,
  enrolled: TYPES.INFO,
  initial: TYPES.MID,
  initial_oep: TYPES.DRAFT,
  initial_sep: TYPES.DRAFT,
  initial: TYPES.DRAFT,
  'initial oep': TYPES.DRAFT,
  'initial sep': TYPES.DRAFT,
  ready: TYPES.GOOD,
  recommended: TYPES.INFO,
  not_started: TYPES.DRAFT,
  explorer: TYPES.DRAFT,
  'docs needed': TYPES.BAD,
  'pending payment': TYPES.MID,
  pending_docs: TYPES.MID,
  ready: TYPES.GOOD,
  sep: TYPES.DRAFT,
  soep: TYPES.DRAFT,
  arp: TYPES.DRAFT,
  se21: TYPES.DRAFT,
  pending_start_date: TYPES.MID,
  'pending start date': TYPES.MID,
  'active pending payment': TYPES.MID,
  'pending first payment': TYPES.MID,
  'choosing plan': TYPES.MID,
  'needs quiz': TYPES.MID,
  user_waiting: TYPES.MID,
  applying: TYPES.MID,
  starting: TYPES.DRAFT,
  enrolling: TYPES.GOOD,
  'pending carrier': TYPES.GOOD_WAIT,
  'pending withdrawal': TYPES.MID,
  payment: TYPES.MID,
  pending_support_task: TYPES.BAD,
  catch_action_needed: TYPES.BAD,
  catch_error: TYPES.MID,
  effectuated: TYPES.DONE_ALT,
  not_eligible: TYPES.BAD,
  DMI: TYPES.DRAFT,
  SVI: TYPES.DRAFT,
  pending_first_payment: TYPES.MID,
  true: TYPES.GOOD,
  false: TYPES.BAD,
  dda: { color: 'green' },
  ira: { color: 'cerise' },
  ede: { color: 'red' },
  plaid: { color: 'plusColor' },
  '–': { color: 'gray' },
  acc: TYPES.GOOD,
  rf1: TYPES.MID,
  rf2: TYPES.MID,
  rf3: TYPES.MID,
  rf4: TYPES.BAD,
  todo: TYPES.BAD,
  stuck: TYPES.BAD,
  'in progress': TYPES.MID,
  'user in progress': TYPES.DRAFT,
  'user exploring': TYPES.DRAFT,
  'user applying': TYPES.DRAFT,
  'submitted to carrier': TYPES.MID,
  cms: TYPES.INFO,
  link: TYPES.DRAFT,
  import: TYPES.DRAFT,
  'carrier issue': TYPES.MID,
  exploring: TYPES.DRAFT,
  'resolving issues': TYPES.MID,
  renew: TYPES.INFO,
  renewing: TYPES.MID,
  renewal: TYPES.MID,
  legacy: TYPES.BAD,
  bbva: TYPES.BAD,
  bond: TYPES.GOOD,
  autoroll: TYPES.INFO,
  ask: TYPES.DRAFT,
  ignore: TYPES.BAD,
  denied: TYPES.BAD,
  unlocked: TYPES.GOOD,
  verified: TYPES.GOOD,
  'able to check': TYPES.GOOD,
  'unable to verify': TYPES.MID,
  'needs quiz': TYPES.MID,
  needs_quiz: TYPES.MID,
  pending: TYPES.MID,
  complete: TYPES.GOOD,
  'Pre R01': TYPES.BAD,
  B01: TYPES.BAD,
  'nothing linked': TYPES.DRAFT,
  needs_docs: TYPES.MID,
  locked_out: TYPES.MID,
  server_issue: TYPES.MID,
  unknown: TYPES.DRAFT,
  'Needs Experian Call': TYPES.MID,
  'Needs Manual IDP': TYPES.MID,
  None: TYPES.DRAFT,
  any: TYPES.LIGHT,
};

const backstop = status => {
  const _s = status?.toUpperCase();
  if (/PENDING/.test(_s)) return 'pending';
  if (/COMPLETE/.test(_s)) return 'complete';
};

export const getStatusColor = status => {
  if (!status) return 'var(--c-fg-1)';

  const c =
    COLORS[status?.replaceAll(/\_/g, ' ')?.toLowerCase()]?.color ||
    COLORS[status?.toLowerCase()]?.color ||
    COLORS[backstop(status)?.toLowerCase()];
  return STATUS_COLOR_MAP[c] || 'var(--c-fg-1)';
};

export const getStatusColorLight = status => {
  if (!status) return 'var(--c-border-light)';
  const c =
    COLORS[status?.replaceAll(/\_/g, ' ')?.toLowerCase()]?.color ||
    COLORS[status?.toLowerCase()]?.color ||
    COLORS[backstop(status)?.toLowerCase()];
  return STATUS_COLOR_MAP[c + 'Light'] || 'var(--c-border-light)';
};

const StyledOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  border-radius: 999px;
  background: ${p => p.bg};
  box-sizing: border-box;
  line-height: 1;
  height: ${p => (p.small ? 16 : 24)}px;
  font-size: var(--fp);
  flex-shrink: 0;

  .dot {
    height: ${p => (p.small ? 16 : 24)}px;
    width: ${p => (p.small ? 16 : 24)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      height: 8px;
      width: 8px;
      border-radius: 999px;
      background: ${p => p.color};
    }
  }
`;

const StyledText = styled.div`
  font-weight: 500;
  font-size: ${p => (p.small ? 'calc(var(--ffp) - 0.5px)' : 'calc(var(--fp) - 0.5px)')};
  line-height: 1;
  transition: none;
  padding-right: ${p => (p.small ? 8 : 12)}px;
  text-transform: ${p => (p.noCap ? '' : 'capitalize')};
  color: var(--c-fg-0);
  blend: var(--blend);
`;

export const formatStatus = status => {
  return (
    STATUS_RENAME[status] ||
    status
      ?.replaceAll('REC_', '')
      ?.replaceAll(/\_/g, ' ')
      ?.replaceAll('PAYMENT_STATUS_', '')
      .replaceAll('true', 'Yes')
      .replaceAll('false', 'No')
      .toProperCase()
  );
};

export const getStatusIcon = () => {
  return 'Circle';
};

export const StatusIcon = () => {
  return null;
};

const Status = ({ children, status, hideText, reverse, style, noCap, small, compact, statusText }) => {
  var s = !!status && status?.toLowerCase ? STATUS_RENAME[status] || status?.toLowerCase() : '';

  return (
    <StyledOuter
      small={small}
      hideText={hideText}
      status={s}
      style={style}
      bg={STATUS_COLOR_MAP[(COLORS[s]?.color || COLORS[backstop(s)]?.color) + 'Light']}
      color={STATUS_COLOR_MAP[COLORS[s]?.color || COLORS[backstop(s)]?.color]}
    >
      <div className="dot">
        <div></div>
      </div>

      {!!hideText || (
        <StyledText
          color={STATUS_COLOR_MAP[COLORS[s]?.color || COLORS[backstop(s)]?.color]}
          small={small || compact}
          status={s}
          reverse={reverse}
          noCap={noCap}
        >
          {STATUS_RENAME[statusText] ||
            children ||
            (!!noCap ? status : STATUS_RENAME[status] || formatStatus(s))}
        </StyledText>
      )}
    </StyledOuter>
  );
};

export default Status;

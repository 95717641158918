import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useToken } from 'src/global';
import { signApolloIn, signApolloOut } from 'src/lib/apollo';

/**
 * Note: there should only be one instance of useAuth that
 * is in charge of getting the token and doing user lookup
 * We use the isRoot option to denote that - see the useEffect
 */
export const useAuth = (options: { isRoot?: boolean } = {}) => {
  const { token, setToken, clearToken } = useToken();
  const { isLoading, isAuthenticated, ...auth0 } = useAuth0();

  /**
   * Handles looking up the token
   */
  async function getToken() {
    const token = await auth0.getAccessTokenSilently();

    if (token) {
      setToken(token);
      signApolloIn();
    }
  }

  /**
   * When Auth0 says we're authenticated, we stash the token
   * Note: only the root instance of useAuth should invoke getToken
   */
  useEffect(() => {
    if (isAuthenticated && options.isRoot) {
      getToken();
    }
  }, [isAuthenticated, options.isRoot]);

  /**
   * Triggers a redirect to the Auth0 login page
   */
  const login = () => {
    auth0.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'login',
      },
    });
  };

  /**
   * Logs the user out of Auth0
   * We also clear the token from storage
   */
  const logout = () => {
    signApolloOut();
    clearToken();

    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return {
    isLoading,
    isAuthenticated,
    token,
    login,
    logout,
  };
};

import React from 'react';
import { useParams } from 'react-router';

const EDE_DOCS = [
  {
    link: `ses_app`,
    html: `ses.html`,
    name: `Application (SES)`,
  },
  {
    link: `permission`,
    html: `permission.html`,
    name: `Consumer Permission`,
  },
  {
    link: `consumer_search`,
    html: `consumer_search.html`,
    name: `Consumer Search`,
  },
  {
    link: `doc_upload`,
    html: `doc_upload.html`,
    name: `Document Upload`,
  },
  {
    link: `doc_upload_flow`,
    html: `doc_upload_flow.pdf`,
    name: `Document Upload Flow`,
  },
  {
    link: `event_proc`,
    html: `event_proc.html`,
    name: `Event Processing`,
  },
  {
    link: `get_enrollment`,
    html: `get_enrollment.html`,
    name: `Get Enrollment`,
  },
  {
    link: `id_proofing_pdf`,
    html: `ridp.pdf`,
    name: `ID Proofing (RIDP)`,
    external: true,
  },
  {
    link: `id_proofing`,
    html: `id_proofing.html`,
    name: `ID Proofing (Store)`,
  },
  {
    link: `dmi`,
    html: `dmi.html`,
    name: `Issues – DMI`,
  },
  {
    link: `svi`,
    html: `svi.html`,
    name: `Issues – SVI`,
  },
  {
    link: `metadata_search`,
    html: `metadata_search.html`,
    name: `Metadata Search`,
  },
  {
    link: `notice_retrieval`,
    html: `notice_retrieval.html`,
    name: `Notice Retrieval`,
  },
  {
    link: `notice_retrieval_flow`,
    html: `notice_retrieval_flow.pdf`,
    name: `Notice Retrieval Process Flow`,
  },
  {
    link: `payment_redirect`,
    html: `payment_redirect.html`,
    name: `Payment Redirect`,
  },
  {
    link: `ses_get_states`,
    html: `ses_get_states.html`,
    name: `Reference (SES)`,
  },
  {
    link: `submit_enrollment`,
    html: `submit_enrollment.pdf`,
    name: `Submit Enrollment`,
    external: true,
  },
  {
    link: `update_policy`,
    html: `update_policy.html`,
    name: `Update Policy`,
  },
];

const getItem = (id, vert = 'EDE') => {
  if (!id) return null;
  return EDE_DOCS.filter((item, idx) => item.link === id)[0];
};

const EDEPage = () => {
  const { id } = useParams();
  const item = getItem(id);

  return (
    <>
      <div
        id="iframe"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          overflow: 'hidden',
          background: !!item?.html ? 'white' : '#ffffffaa',
        }}
      >
        <iframe
          id="iframe-inner"
          title="Doc"
          src={`/api/${item?.html}`}
          height="100%"
          width="100%"
          frameBorder="0"
        />
      </div>
    </>
  );
};

export default EDEPage;

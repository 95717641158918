import React from 'react';

import { Scroll, Toolbar } from 'src/web/components';

const HomePage = ({}) => {
  return (
    <>
      <Toolbar icon="Home" title="Home" overInspector />
      <Scroll>
        <div className="full">Catch Admin</div>
      </Scroll>
    </>
  );
};

export default HomePage;
